var JOIN_REQUESTS = [];
 var distance_in_mile = -1
 var addto_cart=true;
 $( document ).ready(function() {
console.log("is loaded++++++++++++++++++++++++")
  var MEETS_PREFERENCES = null;
  var MY_FOOD_PREFERENCES = null;
  let userData = null
  $.promisedAjax({
      url: `/users/profile/detail`,
      method: 'GET',
  }).then(res => {
    userData = res
  });

  let url = new URL(window.location.href);
  let rateOnlyId = url && url.searchParams ? url.searchParams.get('rateOnlyId') : '';
  let orderAheadId = url && url.searchParams ? url.searchParams.get('orderAheadId') : '';
 console.log("orderAheadId====",orderAheadId)
  myFoodPreferences();
  function myFoodPreferences(){
    $.promisedAjax({
        url: `/my-food-preferences-venue?venueId=${$("#venueId").val()}`,
        method: 'GET',
    }).then(res => { $("#user-dietary-preferences").val(JSON.stringify(res.data)) });
  }
  $(document).on('click', '.select-suitable-dish-all', function(){
    var id = $(this).parent().data('itemId');
     select_all_preferences($(this),id,'menu');
   })
  //$('.select-suitable-dish').unbind('click');
   $(document).on('click', '.select-suitable-dish', function() {
   select_preferences($(this));
   })
   function select_all_preferences(Elem,nodeKey,type){
    var deitPrefvalue = Elem.data('value')
    let litags = $("#dietPrefContainer .item-preferences").find('li');
    let liSelecttags = $("#dietPrefContainer .item-preferences").find('li.select-suitable-dish[data-value="'+ deitPrefvalue +'"]').addClass('active');

    // let litagsREG = $('.regular-menu #collapse-'+type+'-item-'+nodeKey).find('li');
    // console.log("litagsREG2:", litagsREG)
    // let liSelecttagsREG = $('.regular-menu #collapse-'+type+'-item-'+nodeKey).find('li.select-suitable-dish[data-value="'+ deitPrefvalue +'"]').addClass('active');
    // console.log("liSelecttagsREG2:", liSelecttagsREG)



      let getAllSelect = liSelecttags.parent();
      var selectedVal = [];
      var getval = []
      Object.keys(getAllSelect).map(function(key) {
        Object.keys(getAllSelect[key]).map(function(key2) {
          var numbers = /^[-+]?[0-9]+$/;
          if(key2.match(numbers)){
            if(getAllSelect && getAllSelect[key2]){
            let pushdata = getAllSelect[key2].getAttribute('data-preference');
            selectedVal.push(pushdata);
            }
          }
        });
      });
      console.log("selectedVal:", selectedVal)
      litags.removeClass('active');
      liSelecttags.addClass('active');
      litags.css({'background-color': 'white'})

      // litagsREG.removeClass('active');
      // liSelecttagsREG.addClass('active');
      // litagsREG.css({'background-color': 'white'})


      switch(Elem.data('value')) {
      case 'yes':
        liSelecttags.css({'background-color': '#4caf50'})
        // liSelecttagsREG.css({'background-color': '#4caf50'})
        Elem.css({'background-color': '#4caf50'})
        break;
      case 'no':
        liSelecttags.css({'background-color': '#ff5722'})
        // liSelecttagsREG.css({'background-color': '#ff5722'})
        Elem.css({'background-color': '#ff5722'})
        break;
      case 'canrequest':
        liSelecttags.css({'background-color': '#ffc107'})
        // liSelecttagsREG.css({'background-color': '#ffc107'})
        Elem.css({'background-color': '#ffc107'})
        break;
      case 'notsure':
        liSelecttags.css({'background-color': '#9e9e9e'})
        // liSelecttagsREG.css({'background-color': '#9e9e9e'})
        Elem.css({'background-color': '#9e9e9e'})
        break;
      case 'mostly':
        liSelecttags.css({'background-color': '#8bc34a'})
        // liSelecttagsREG.css({'background-color': '#8bc34a'})
        Elem.css({'background-color': '#8bc34a'})
        break;
      case 'na':
        liSelecttags.css({'background-color': '#9e9e9e'})
        // liSelecttagsREG.css({'background-color': '#9e9e9e'})
        Elem.css({'background-color': '#9e9e9e'})
        break;
      default:
        // code block
      }
      let allPrefdata=[];
      selectedVal.forEach(function(pref){
      let infor = {
        item_id: nodeKey,
      preference: pref,
      value: Elem.data('value')
      }
      allPrefdata.push(infor);
      })
      if(!allPrefdata) return false;
       return save_all_my_food_preferences(allPrefdata);
    }


    function save_all_my_food_preferences(infor = {}){
      let data = {
        'infor' : infor,
        'venueId' : $('#venue-id').text().trim()
      }
      $.ajax({
        url: "/my-all-food-preferences/save",
        type: "POST",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),
        success: function (res) {
          toastr.success('Your dietary preference is saved');
          MEETS_PREFERENCES = false;
          let item_id=null;
          if(infor && infor.length>0){
            item_id = infor[0].item_id
          }
          meets_my_food_perferences(item_id); //to update the button value
        },
        error: function (e) {
          return false;
        }
      })
    }


    function select_preferences(Elem){
      let litags = Elem.parent().find('li');
      console.log("litagsingle:", litags)
      let litagsval = Elem.data('value');
      let selectAlltag = Elem.parents('.my-food-preferences').find('.select-suitable-dish-all');
      let selectAllUl = Elem.parents('.my-food-preferences').find('.select-suitable-list-all li[data-value='+litagsval+']');
      litags.removeClass('active');
      Elem.addClass('active');
      let selecttag = Elem.parents('.my-food-preferences').find('.select-suitable-list li.active');
      let selecttagcount = selecttag.length;
      let getAllFood = Elem.parents('.my-food-preferences').find('.select-suitable-list');
      let data = [];
      _.forEach(selecttag, (item)=>{
        let selected = $(item).data('value');
         switch($(item).data('value')) {
          case 'yes':
            data.push('yes');
            break;
          case 'no':
          case 'strictly-no':
            data.push('no');
            break;
          case 'canrequest':
            data.push('canrequest');
            break;
          case 'notsure':
            data.push('notsure');
            break;
          case 'mostly':
            data.push('mostly');
            break;
          case 'na':
            data.push('na');
            break;
          default:
            // code block
        }
      });

      let pre_css = {};
        switch(litagsval) {
          case 'yes':
            pre_css = {'background-color': '#4caf50'};
            break;
          case 'no':
            pre_css = {'background-color': '#ff5722'};
            break;
          case 'strictly-no':
            pre_css = {'background-color': '#ef5350'};
            break;
          case 'canrequest':
            pre_css = {'background-color': '#ffc107'};
            break;
          case 'notsure':
            pre_css = {'background-color': '#9e9e9e'};
            break;
          case 'mostly':
            pre_css = {'background-color': '#8bc34a'};
            break;
          case 'na':
            pre_css = {'background-color': '#9e9e9e'};
            break;
          default:
        }
        selectAlltag.removeClass('active');
        litags.css({'background-color': 'white'})
        Elem.css(pre_css);
        var count = data.reduce(function(n, val) {
          return n + (val === litagsval);
        }, 0);
        if(count==getAllFood.length){
          selectAllUl.css(pre_css);
        }else{
          selectAlltag.css({'background-color': 'white'});
        }
      let infor = {
        item_id: Elem.parent().data('item-id'),
        preference: Elem.parent().data('preference'),
        value: Elem.data('value')
      }
      return save_my_food_preferences(infor);
    }

    function save_my_food_preferences(infor = {}){
      let data = {
        venue_id: $('#venue-id').text().trim(),
        item_id: infor.item_id,
        preference: infor.preference,
        value: infor.value
      }
      $.ajax({
        url: "/my-food-preferences/save",
        type: "POST",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),
        success: function (res) {
          toastr.success('Saving ...');
          MEETS_PREFERENCES = false;
          meets_my_food_perferences(infor.item_id); //to update the button value
        },
        error: function (e) {
          return false;
        }
      })
    }

    function meets_my_food_perferences(item_id) {
      if(MEETS_PREFERENCES){
        return fill_color_for_items(item_id);
      };
      var venueId =$("#venueId").val();
      let data = {
        venueId: venueId
      }
      $.ajax({
        url: '/menuitems/findMenuItemsDietPreferences',
        method: 'POST',
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),
        dataType: 'json',
        error: function (err) {
          return false;
        }
      }).then(function (infor) {
        MEETS_PREFERENCES = infor;
        fill_color_for_items(item_id);
        myFoodPreferences();
      })
    }


    function fill_color_for_items(element){
      element = element.trim();
      let show_not_meeting = $('#show-food-not-meet').prop('checked');

  //5d0f51ca4495f1125bb6db27: "notsure", 5e6f9007d0cc18356c8ac1d1: "notsure", 5d0f51ca4495f1125bb6db28: "strictly-no", 5d0f51ca4495f1125bb6db29: "na", 5d0f51ca4495f1125bb6db2a: "strictly-no", …}

    // whne new user come

    _.forIn(MEETS_PREFERENCES,(value, key)=>{

        if(key != element) return;
        let elem = $(`#menu-meets-preferences-item-${element},#favorites-meets-preferences-item-${element}`);
        if(elem && elem.attr('class')) {
          let button_class = elem.attr('class').match(/cus-btn-(?!fluid)\S+/g);
          if(button_class) elem.removeClass(button_class.join(' '))
        }
        switch (value){
          case 'yes':
            elem.addClass('cus-btn-green');
            elem.html('<i class="fa fa-heart" aria-hidden="true" style="font-size: inherit;"></i><span style=""> Meets</span>')
            break
          case 'mostly':
              elem.addClass('cus-btn-light-green');
              elem.html('<i class="fa fa-check-circle" aria-hidden="true" style="font-size: inherit;"></i><span style=""> Mostly meets </span>')
              break
          case 'canrequest':
            elem.addClass('cus-btn-amber');
            elem.html('<i class="fa fa-question-circle" aria-hidden="true" style="font-size: inherit;"></i><span style=""> Can Request </span>')
            break
          case 'notsure':
            //elem.addClass('cus-btn-grey');
            elem.html('<i class="fa fa-exclamation-triangle" aria-hidden="true" style="font-size: inherit;"></i><span style=""> Not sure </span>')
            break
          case 'no':
          case 'strictly-no':
            elem.addClass('cus-btn-red');
            elem.html(`<i class="fa fa-times-circle-o" aria-hidden="true" style="font-size: inherit;"></i><span style=""> Doesn't meet </span>`)
            let regular_elem = $(`#regular_${element}`);
            let recommended_elem = $(`#favorite_${element}`)
            if(show_not_meeting){
              regular_elem.hide()
              recommended_elem.fadeOut('slow','swing')
              recommended_elem.hide()
            }else{
              regular_elem.show()
              recommended_elem.show()
            }
            break
          case 'na':
            //elem.addClass('cus-btn-grey');
            elem.html('<i class="fa fa-exclamation-circle" aria-hidden="true" style="font-size: inherit;"></i><span style=""> May meet</span>')
            break
        }
      })
    }

  //RETRIEVE NEEDEd GLOBAL STATES FROM DOM
  const USER_ID = $('#userId').val();
  const USER_NAME = $("#user-name").text().trim();
  const USER_TIP = $("#user-tip").text().trim();
  let USER_DIETARY_PREFERENCES = null;
  let account_info = $("#account-infor").text();
  if(account_info){
    USER_DIETARY_PREFERENCES = JSON.parse(account_info).dietaryPreferences;
  }
  let USER_TASTER_PROFILE = null;
  let taster_profile = $("#user-taster-profile").text()
  if(taster_profile){
    USER_TASTER_PROFILE = JSON.parse(taster_profile);
  }
  console.log("users pref...", USER_DIETARY_PREFERENCES, USER_TASTER_PROFILE);
  const USER_EMAIL = $("#user-email").val();
  const USER_PHONE = $("#user-phone").val();
  const RESTAURANT_POSTAL_CODE = $('#venue-zip').text().trim();
  const VENUE_ID = $("#venue-id").text().trim();
  /* ********* TODO Data from Venue ***********/
  //TODO: Load venue distance from user current location, than if user is within 3 miles radius
  const RESTAURANT_OPEN_TIME = 420; // 7:00 am - start time in minutes ( this should come from api )
  const RESTAURANT_CLOSE_TIME = 1260 // 9:00 pm - closing time in minutes ( this should come from api )
  const RESTAURANT_DELIVERY_RADIUS = 0.5 // 0.5 miles  ( this should come from api )
  const RESTAURANT_DELIVERY_INTERVALS = 15; //minutes interval
  const RESTAURANT_LAST_ORDER_CALL = 30; // minutes before closing (last available order time)
  const RESTAURANT_DELIVERY_PERIOD = ['AM', 'PM']; // AM-PM btn
  const $closedToday = $('#closed-today');
  const TODAY_DATE = new Date();
  const $CART_TOTALS_CONTAINER = $('#cart-items-totals');
  const $NO_SALES_ON_THE_SYSTEM = $('#noSalesOnTheSystem');
  const $HAS_DELIVERY_ORDER = $('#hasDeliveryOrder');
  const $HAS_PICKUP_ORDER = $('#hasPickupOrder');
  const $HAS_ORDER_AHEAD = $('#hasOrderAhead');
  const $HAS_WAITLIST = $('#hasWaitList');
  const $ORDER_PRELOADER = $('#order-preloader');
  const $ORDER_CONTAINER = $('#order-container');
  const $INCART_STATE_CONTAINER = $('#incart-state-container');
  const $CHECKOUT_STATE_CONTAINER = $('#checkout-state-container');
  const COMPLEX_ORDER_TYPES = ['order-ahead', 'waitlist', 'pickup', 'delivery'];
  let JOIN_CHECKOUTS = [];
  let IS_FREE_FOOD = true;
  let TYPE_FREE_FOOD = true;
  let RESTAURANT_FREE_FOOD = 0;
  let PLATERATE_FREE_FOOD = 0;
  let DISTANCE_IN_MILE = 0;
  let POSITION_SCROLL_ORDER = ['#headingMyOrder', '#collapseMyOrder'];
  userIsLoggedIn = (userIsLoggedIn == 'true')? true : false;
  var isMobile = false;
  let checkPayOrder = false;
  let isUpdatePlaceDate = false;
  let placeDateDefault = '';
  if( /android|webos|iphone|ipad|ipod|blackBerry|iemobile|opera Mini/i.test(navigator.userAgent.toLowerCase()) ) {
      isMobile = true;
  }

  let isShowModalTips = false;
  let checkChooseTip = false;
  let PAYMENT_IN_PROCESS = false;
  let checkContactless = true;
  let listItemInCart = null;
  let hasAlcoholItems = false;
  var ordered_item_key = null; //to store the selected item, esp for add item to cart


  if(screen.width < 576) {
    $("#modal-select-order-type").find('.border-in-device').css({'border': ''})
    $("#modal-select-order-type").find('#rate-only-type').addClass('mt-5')
  }
  $("#modal-select-order-type").find('#infor-order-in-ahead').popover({
    html: true,
    content: `
      <ul style="font-size: 15px">
        <li><b><i>Order in</i></b> = Sit down in the restaurant and order from PlateRate to earn free food/drink by rating your menu items in the restaurant</li>
        <li class="mt-3"><b><i>Order ahead</i></b> = Order ahead is when you get a reservation, order and pay for your meal in advance and it is served as soon as you sit down for your reservation, for when you're in a rush!</li>
      </ul>
      `,
    placement: 'left',

  });
  $(document).on("click","#paywithpaypal",function(e){
      if($(this).prop("checked") == true){
        $(".paypalbutoonformobile").css("display", "block ");
      }
      else{
        $(".paypalbutoonformobile").css("display",'none ');
      }
  });
if (USER_ID){
  window.onload = function(){
    ShoppingCart.loadCurrentCartItems();
  };
  $(window).focus(function() {
    initSocketConnection().then(status => {
      if (status == 'created'){
        loadingOrderAnimation('show');
        Order.checkForInCartOrder().then((foundIncartOrder) => {
          if (foundIncartOrder) {
            //Load and render the cart items
            if (foundIncartOrder.parent_order_id) {
              let option = { parentOrderUpdate : true }
              ShoppingCart.loadCurrentCartItems(option);
            } else if(foundIncartOrder.order_type == 'order-ahead' && foundIncartOrder.request_reservation == 'invited') {
              let option = { RequestedReservation : true }
              ShoppingCart.loadCurrentCartItems(option);
            }else{
              ShoppingCart.loadCurrentCartItems();
            }
          }
          Order.checkForOrdersInCheckout(foundIncartOrder, false, {noScroll: false, firstLoad: true});
        }).finally(() => {
          loadingOrderAnimation('hide');
        });
      }
    })
  });
}

$('#getCurrentTime').click(function() {
        let getVenueId = $('#venueId').val();
         $.ajax({
        url: '/restaurant/LocalTime/'+getVenueId,
        type: 'GET',
        async: true,
        success : function(getRestaurantLocalTime){
        let timeZone= getRestaurantLocalTime.timeZone;
              let date = new Date();
                var today = new Date(date.toLocaleString('en-US', {
                  timeZone: timeZone
                }));

              const currentMinute = today.getMinutes();
              const currentHour = today.getHours();
              let newHour = currentHour;
              let newMinute = currentMinute;

              if([0, 15, 30, 45].includes(currentMinute)) {
                newMinute = ('0' + currentMinute.toString()).slice(-2);
              } else if(currentMinute < 15) {
                newMinute = '15';
              } else if(currentMinute > 15 && currentMinute < 30) {
                newMinute = '30';
              } else if(currentMinute > 30 && currentMinute < 45) {
                newMinute = '45';
              } else {
                newMinute = '00';
                today.setHours(newHour + 1);
                today.setMinutes(newMinute);
                $('#order-ahead-date').data('DateTimePicker').date(today);
                return;
              }
              today.setHours(newHour);
              today.setMinutes(newMinute);
              $('#order-ahead-date').data('DateTimePicker').date(today);

        },
        error : function(err){
          Swal.fire("Oops", "Please try again and reload the page once!", "error");
        },
    });


});

var latestOrderTipPercent = $("#latestOrderTipPercent").val();

const getLatestOrderTipPercent = (orderType) => {
    return new Promise(function (resolve, reject) {
      return $.ajax({
        url: '/orders/get-latest-tip-for-order-type',
        method: 'POST',
        data: {orderType: orderType},
        dataType: 'json',
        success: function (res) {
            if(res.data) {
              latestOrderTipPercent = res.data;
            }
        },
        error: function (err) {
        console.log("error in getting latest order types tip...", err);
        },
      });
   })
};

function loadingOrderAnimation(status = "show"){
  if (status == "show"){
    $('#buy-btn').prop('disabled', true);
    $ORDER_PRELOADER.css('visibility', 'visible');
    $ORDER_CONTAINER.css('opacity', 0.5);
    $ORDER_CONTAINER.css('pointer-events', 'none');
  } else if (status == "hide"){
    $('#buy-btn').prop('disabled', false);
    $ORDER_PRELOADER.css('visibility', 'hidden');
    $ORDER_CONTAINER.css('opacity', 1);
    $ORDER_CONTAINER.css('pointer-events', 'auto');
  }
}
function getEstimateTime(){
  let delivery_term = $('p#delivery-term').text().trim();
  let pickup_term = $('p#pickup-term').text().trim();
  let delivery_time = '';
  let pickup_time = '';
  let min_time, max_time;
  let terms = {
    delivery: '1 - 1.5 hrs',
    pickup: '1 - 1.5 hrs'
  };
  if(delivery_term){
    try {
      delivery_term = JSON.parse(delivery_term);
      if(delivery_term.isDelivery){
          min_time = Number(delivery_term.deliveryMinTime);
          if(min_time < 60){
            delivery_time += `${min_time} min`
          }else{
            delivery_time += `${parseFloat(min_time/60).toFixed(1)} hrs`;
          }
          max_time = Number(delivery_term.deliveryMaxTime);
          if(max_time < 60){
            delivery_time += ` - ${max_time} min`
          }else{
            delivery_time += ` - ${parseFloat(max_time/60).toFixed(1)} hrs`;
          }
          terms.delivery = delivery_time;
      }
    } catch (error) {
      //
    }
  }
  if(pickup_term){
    try {
      pickup_term = JSON.parse(pickup_term);
      if(pickup_term.isPickupOrders){
          min_time = Number(pickup_term.pickupMinTime);
          if(min_time < 60){
            pickup_time += `${min_time} min`
          }else{
            pickup_time += `${parseFloat(min_time/60).toFixed(1)} hrs`;
          }
          max_time = Number(pickup_term.pickupMaxTime);
          if(max_time < 60){
            pickup_time += ` - ${max_time} min`
          }else{
            pickup_time += ` - ${parseFloat(max_time/60).toFixed(1)} hrs`;
          }
          terms.pickup = pickup_time;
      }
    } catch (error) {
      //
    }
  }
  return terms
}

var previousWindowKeyDown = window.onkeydown;
window.Swal.close = function() {
  Swal.close();
  window.onkeydown = previousWindowKeyDown;
};

/* For My Orders CHECKOUT accordion */
// $('body').on('shown.bs.collapse', "[id^='collapseCheckout-']", function () {
//   $(this).unbind('shown.bs.collapse')
//   let accordionId = $(this).data("accordion-id");
//   $(`[id^='chevron_toggleable_order_checkout_${accordionId}']`).addClass('glyphicon-chevron-down').removeClass('glyphicon-chevron-right');
//   $(`#headingCheckout-${accordionId}`).trigger('click');
//   const orderType = $(this).data('order-type');
//   if(orderType === 'order-ahead'){
//     $.promisedAjax({
//       url: `/orders/checkouts/notification?orderId=${accordionId}`,
//       type: 'GET',
//       contentType: 'application/json',
//       dataType: 'json'
//     }).then((result) =>{
//       if(result.data.length > 0) JOIN_CHECKOUTS = [...result.data];
//       renderjoinRequestNotifications(JOIN_CHECKOUTS, true);
//     }).catch((error)=>{
//       console.error('Unable to load notification checkout', error)
//     })
//   }
// });
// $('body').on('hidden.bs.collapse', "[id^='collapseCheckout-']", function () {
//   let accordionId = $(this).data("accordion-id");
//   $(`[id^='chevron_toggleable_order_checkout_${accordionId}']`).addClass('glyphicon-chevron-right').removeClass('glyphicon-chevron-down');
// });

function convertToNumber(numberString) {
  if (!numberString) return 0;
  numberString = numberString.replace(/\,/g, '');
  numberString = numberString.replace('$', '');
  numberString = numberString.replace(' %', '');
  numberString = numberString.replace('%', '');
  const [integer, decimalpoint] = numberString.split('.');
  if (parseInt(decimalpoint) > 0) {
      return numberString;
  } else {
      return integer;
  }
};

const setItemAddon = (item, addons, resetData) => {
  const addonsData = [];
  if(addons && addons.length) {
    addons.forEach((addon) => {
      if(addon.children && addon.children.length) {
        const addonsDate = setItemAddon(item, addon.children, resetData);
        addonsData.push(...addonsDate);
      } else {
        let quantity = 0;
        let inp = $(addon.tr).find('input');
        //inp is defined when doing edit from orders screen; not defined when going via menu's add on popup
        if(typeof inp !== undefined && inp && inp.length>0) {
          let inp_type = inp.attr('type')
          if(_.includes(['radio','checkbox'],inp_type)){
            quantity = Number(inp.prop('checked'));
          } else{
            quantity = Number(inp.val());
          }
         }
	       else {
	          //check if addon quantity is added to localStorage via menu selection, and then unset it also
            let menuaddonqty = localStorage.getItem("addonquantity-"+addon.key);
            if(menuaddonqty){
              quantity=localStorage.getItem("addonquantity-"+addon.key);
              if(resetData){
                localStorage.removeItem("addonquantity-"+addon.key);
                console.log("removing addonquantity ffor in setItemAddon.."+addon.key);
              }
            }
          }
          if(quantity > 0) {
            let addonData = {
              item_id : addon.key,
              parent_item_id: item.item_id,
              item_type: 'addon',
              item_name: item.item_name,
              rating: item.rating,
              quantity: item.quantity,
              retail_price: item.retail_price,
              menu_name: item.menu_name,
              section_name: item.section_name,
              menuType: item.menuType,
              addon_name: addon.title,
              addon_rating: addon.rating,
              addon_quantity: quantity,
              addon_price: (addon.price && addon.price !== "-") ? addon.price : 0,
              special_instructions: addon.special_instructions
            };
            addonsData.push(addonData);
          }
      }
    })
  }
  return addonsData;
}

  /* Validate addon before add to cart */
  const validateItemAddons = (items) => {
    let isVal =  true;
    if(_.size(items.children)) {
      _.forEach(items.children,(item)=>{
          const validation = validateItem(item, item.data);
          if(_.hasIn(validation.data,'min') && Number(validation.data.min) > validation.selected){
            isVal = false;
          }
          if(_.hasIn(validation.data,'max') ){
            if(validation.data.max && validation.selected > Number(validation.data.max)) {
              isVal = false;
            }
          }
      })
    }
    return isVal;
  }

  /**
   * GENERAL Functions
   */
   //this seems to be called when creating a order
   //get data for selected from dataTree since there, attributes like setup etc may have been updated
  const getMenuSelectedItems = () => {
//redesign    let fancyTreeSelectedItems = $('#tree').fancytree('getTree').getSelectedNodes();
    //if not in tree, get from localstorage, and set selected in tree
    let fancyTreeSelectedItems = [];
    if (__selectedMenuItems.length == 0) {
     let items = localStorage.getItem('selectedItems');
        if(items) {
            items = JSON.parse(items);
            for(let j = 0; j<items.length; j++){
              let item = items[j];
//              let node = $('#tree').fancytree('getTree').getNodeByKey(item);
              let node = findMenuItemInDataTree(item, dataTree);
              if(node) {
                __selectedMenuItems.push(node); //tbd check if only id can be pushed
                fancyTreeSelectedItems.push(node); //tbd check if only id can be pushed
              } else {
                console.log("did not find item with key in dataTree...",item);
              }
            }
          } //items exist
          return returnMenuSelectedItems(fancyTreeSelectedItems);
    }
    else{
    //even for selected menuitem,s get from data tree; thoer attributes are updated after page load from db
      for(let j = 0; j<__selectedMenuItems.length; j++){
        let item = __selectedMenuItems[j];
        let node = findMenuItemInDataTree(item.key, dataTree);
         if(node) {
          fancyTreeSelectedItems.push(node); //tbd check if only id can be pushed
        } else {
          console.log("did not find item of the selecteditems with key in dataTree...",item);
        }
      }
     return returnMenuSelectedItems(fancyTreeSelectedItems);
    }
  };


  function returnMenuSelectedItems(fancyTreeSelectedItems , resetData = true){
  //if user not logged in, set restData to false so that the addon selected before logged in, stays in local storage addonquantity-<key>
    if(!userIsLoggedIn) {
      resetData = false;
    }
    let parsedSelectedItems = [];
    let crowdItem = null;

    const enableOrderType = {
      'pickup': true,
      'delivery': true,
      'in-restaurant': true,
      'only_rate': true,
      'order-ahead': true,
      'waitlist': true,
      'item': {
        'pickup': null,
        'delivery': null,
        'in-restaurant': null,
        'only_rate': null,
        'order-ahead': null,
        'waitlist': null,
      }
    }
    //Parse items data
    if(fancyTreeSelectedItems.length>0){
      fancyTreeSelectedItems.forEach((item) => {
        let rateable = true;
        if(item && item.type === 'addon') rateable = false;
        var orderType = enableOrderType;
        if(!item.setup || typeof item.setup === "undefined") { //hack, this may happen whe item is added to cart from homepage, but using this since user came from buy btn only
        } else {
          orderType = validateOrderType(item.setup);
        }
        if(orderType){
          if (rateable) {
            let menu_name;
            let section_name;

            _.forEach(__menu.source, elem =>{
              menu_name = elem.title
              _.forEach(elem.children, el => {
                section_name = el.title
                if(_.find(el.children, e => e.key == item.key)) {
                  return false
                } else {
                  section_name = ''
                }
              })
              return !section_name
            })
           
            let itemData = {
              item_id : item.key,
              parent_item_id: null,
              item_type: 'item',
              item_name: item.title,
              alcohol: item.alcohol,
              sku: item.sku,
              upc: item.upc,
              rating: item.rating,
              quantity: Number($("#itemqty-"+item.key).val() || 1), //has been set in the menutree or Default of 1
              retail_price: (item.price && item.price !== "-") ? item.price : 0,
              menu_name: menu_name,
              section_name: section_name,
              menuType: item.menuType,
              special_instructions: $("#itemspecialinstr-"+item.key).val() || ''
            };
            //set the qty and spl instr back to blank; redesgin check whether we need below 2
            if(resetData){
              $("#itemqty-"+item.key).val('');
              $("#itemspecialinstr-"+item.key).val('');
            }
            if(item && item.menuType === 1) {
              crowdItem = itemData;
            }
            //const hasAddons = validateItemAddons(item);
            // if(!hasAddons) {
            //   const addons = setItemAddon(itemData, item.children);
            //   parsedSelectedItems.push(...addons);
            // } else
            if(item && item.children && item.children.length) {
              const addons = setItemAddon(itemData, item.children, resetData);
              parsedSelectedItems.push(...addons);
              itemData.hasAddons = true;
              parsedSelectedItems.push(itemData);
            } else {
              parsedSelectedItems.push(itemData);
            }

            if(!orderType.pickup && enableOrderType.pickup) {
              enableOrderType.pickup = false;
              enableOrderType.item['pickup'] = itemData;
            }

            if(!orderType.delivery && enableOrderType.delivery) {
              enableOrderType.delivery = false;
              enableOrderType.item['delivery'] = itemData;
            }
            if(!orderType.in_restaurant && enableOrderType['in-restaurant'] ) {
              enableOrderType['in-restaurant'] = false;
              enableOrderType.item['in-restaurant'] = itemData;
            }
            if(!orderType.rating && enableOrderType['rating']) {
              enableOrderType['only_rate'] = false;
              enableOrderType.item['only_rate'] = itemData;
            }
            if(orderType.order_ahead == false && enableOrderType['order-ahead'] ) {
              enableOrderType['order-ahead'] = false;
              enableOrderType.item['order-ahead'] = itemData;
            }
            if(!orderType.waitlist && enableOrderType['waitlist'] ) {
              enableOrderType['waitlist'] = false;
              enableOrderType.item['waitlist'] = itemData;
            }
          }
        } //end of orderType not null
      });
    }
    return {
      items: parsedSelectedItems,
      enableType: enableOrderType,
      crowdItem: crowdItem
    }
  }
  //Format date for transactions
  const formatDate = (date) => {
    let month = date.getMonth()+1;
    let day = date.getDate();
    let formatedDate =  (month<10 ? '0' : '') + month +
      '/' + (day<10 ? '0' : '') + day +
      '/' + date.getFullYear();
    return formatedDate;
  };
  // Check if given time is within limit time
  const checkIfClosed = (closeTime, lastCallTime) => {
    const limit = (closeTime - lastCallTime) / 60
    const hour = TODAY_DATE.getHours() + (TODAY_DATE.getMinutes() / 60);
    return (hour >= limit);
  };

  const validateOrderType = (setting) => {
    if(setting && setting.orderType){
      const orderType = setting.orderType || [];
    const data = {
        pickup: true,
        delivery: true,
        in_restaurant: true,
        rating: true,
        order_ahead: true,
        waitlist: true
      }
      if(orderType.length) {
        data.pickup = orderType.includes('pickup');
        data.delivery = orderType.includes('delivery');
        data.in_restaurant = orderType.includes('in-restaurant');
        data.rating = orderType.includes('rating');
        data.order_ahead = orderType.includes('order-ahead');
        // data.waitlist = orderType.includes('waitlist');
      }
      // ********TEMP:
      data.waitlist = true;
      return data;
    }
    else return null;
  }

  const validateTypeOrderItems = (items = []) => {
    const order = Order.getOrderDetail('orderId');
    let parsedSelectedItems = [];
    let userMenuItem = null;
    const enableOrderType = {
      'pickup': true,
      'delivery': true,
      'in-restaurant': true,
      'only_rate': true,
      'order-ahead': true,
      'waitlist': true,
      'item': {
        'pickup': null,
        'delivery': null,
        'in-restaurant': null,
        'only_rate': null,
        'order-ahead': null,
        'waitlist': null,
      }
    }
    if (__selectedMenuItems.length > 0) {
      __selectedMenuItems.forEach((item) => {
        let rateable = true;
        if(item.type === 'addon'&& !item.rateable) rateable = false;

        const orderType = validateOrderType(item.setup);
        if(orderType){
          if (rateable) {
            let menu_name = item.parent && item.parent.parent && item.parent.parent.title;
            let section_name = item.parent.title;
            let itemData = {
              item_id : item.key,
              item_name: item.title,
              rating: item.rating,
              quantity: 1,
              retail_price: (item.price && item.price !== "-") ? item.price : 0,
              menu_name: menu_name,
              section_name: section_name
            };
            if(item.menuType === 1) {
              userMenuItem = itemData;
            } else {
              parsedSelectedItems.push(itemData);
            }

            if(!orderType.pickup && enableOrderType.pickup) {
              enableOrderType.pickup = false;
              enableOrderType.item['pickup'] = itemData;
            }

            if(!orderType.delivery && enableOrderType.pickup) {
              enableOrderType.delivery = false;
              enableOrderType.item['delivery'] = itemData;
            }
            if(!orderType.in_restaurant && enableOrderType['in-restaurant'] ) {
              enableOrderType['in-restaurant'] = false;
              enableOrderType.item['in-restaurant'] = itemData;
            }
            if(!orderType.rating && enableOrderType['only_rate']) {
              enableOrderType.only_rate = false;
              enableOrderType.item['only_rate'] = itemData;
            }
            if(!orderType.order_ahead && enableOrderType['order-ahead'] ) {
              enableOrderType['order-ahead'] = false;
              enableOrderType.item['order-ahead'] = itemData;
            }
            if(!orderType.waitlist && enableOrderType['waitlist'] ) {
              enableOrderType['waitlist'] = false;
              enableOrderType.item['waitlist'] = itemData;
            }
          }
        } //orderType exists
      })
    }
    return {
      items: userMenuItem ? userMenuItem : parsedSelectedItems,
      enableType: enableOrderType,
      crowdItem: userMenuItem,
    }
  }

  const validateHoursItems = (items = [], update = false) => {
    const order = update ? Order.getOrderDetails(true, 'order-ahead') : Order.getOrderDetails();
    let enableTime = true;
    let daysRequired = false;
    const daysItem = { days: 0, item: null };
    let menuName = '';
    if(order.orderType !== 'only_rate') {
      const orderDate = new Date(order.orderDate);
      const order_date = !isNaN(orderDate) ? orderDate : new Date();
      const current_date = new Date();
      const orderTime = moment(order_date).format('hh:mma')
      let  selectItems = [];
      const DAYS_OF_THE_WEEK = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
      const today = order_date.getDay();
      const index_day = DAYS_OF_THE_WEEK[today];
      // For menu order in restaurant
      __selectedMenuItems.forEach((node) => {
        if(items.includes(node.key)) {
          selectItems.push(node);
        }
      })
      let setupMenus = {};
      if (selectItems.length > 0) {
        selectItems.forEach((item) => {
          const setup = item.setup;
          if (setup && setup.menuName) {
            const daysRequired = item.daysRequired || 0; // Default to 0 if undefined
            setupMenus[setup.menuName] = setup.hours || 0; // Default to 0 if undefined
            if (daysItem.days < daysRequired) {
              daysItem.days = daysRequired;
              daysItem.item = item;
            }
          } else {
            console.warn('Missing setup or menuName for item:', item);
          }
        });
      }
      // user only receive notifications between fromTime and toTime
      const time_to_minute = function(time_convert = "00:00am"){
        time_convert = time_convert.split(':');
        let _hour = Number(time_convert[0]);
        let _minute = Number(time_convert[1].substring(0,2));
        if(time_convert[1].substring(2) == 'pm' || time_convert[1].substring(2) == 'PM') _hour += 12;
        if(_hour > 23) _hour = 0;
        return (_hour * 60) + _minute;
      }

      // Set date order
      let order_time = (order_date.getHours() * 60) + order_date.getMinutes();

      if(orderTime && !isNaN(new Date(orderTime))) {
        const _current_date = new Date(orderTime);
        order_time = (_current_date.getHours() * 60) + _current_date.getMinutes();
      } else if(orderTime) {
        let _time = Number(time_to_minute(orderTime));
        if(_time && !isNaN(_time)) {
          order_time = _time;
        }
      }
      // Check days in advance required to order
      if(daysItem.days > 0) {
        const time_required = daysItem.days * 24 * 60;
        order_date.setMinutes(order_time);
        const diff = Math.abs(order_date - current_date);
        const time_distance = Math.floor((diff/1000)/60);
        if(time_distance < time_required) {
          daysRequired = true;
        }
      }

      // Check time setup in menu to order
      for(var key in setupMenus) {
        const { anyTime, days } = setupMenus[key];

        if(enableTime && !anyTime && days && days[index_day]) {
          const day = days[index_day];
          if(day.fromTime && day.toTime){
            // convert fromTime and toTime to minutes
            let available_fromTime = time_to_minute(day.fromTime);
            let available_toTime = time_to_minute(day.toTime);
            // convert current time to minutes
            if(!(available_fromTime <= order_time && order_time <= available_toTime)){
              enableTime = false;
              menuName = key
            }
          }
        }
      }
    }
    return {
      enableTime,
      menuName,
      order_type: order.orderType == 'order-ahead' ? 'order ahead' :  order.orderType,
      daysItem: daysRequired ? daysItem : null,
    }
  }
  function modal_render_addons(nodeInfo = [], node){
    let html = '';
    _.forEach(nodeInfo,(item)=>{
      item.parent = node;
      let addons_lv1 = item.parentkey == ordered_item_key;
      let _time = (new Date()).getTime();
      if(!_.size(item.children)){
        html += `
          <div class="form-group" style="border-bottom: 1px solid #f2e2e2;">
            <div class="form-row"  style="width: 100%;">
              <div class="col-6">
                  <p class="" style="font-size: 16px; margin-bottom: 2px;">${item.title}<p>
                  <p class="" style="font-size: 12px;">${item.description}<p>
              </div>
              <div class="col-3" style="font-size: 16px;">
                $${parseFloat(item.price || 0).toFixed(2)}
              </div>
              <div class="col-3 text-right mblAddToCartModalField">
                  ${modal_render_input(item)}
              </div>
            </div>
            <div id="modal-add-on-error-${item.key}" class="alert alert-danger" role="alert" style="display: none">
            </div>
          </div>
        `;
        return true;
      }
      let cond = `${item.min || 0}`;
      if(item.max){
        if(item.max != item.min) cond += ` - ${item.max}`
      }else{
        cond += ' - many'
      }
      let winwidth = $(window).width();
      html += `
      <script>function togglerFunction() {
        let addonStates = $('#addon-toggle').hasClass('collapsed');

        if (addonStates === true) {
          $('#addon-toggle').find('i').removeClass('fa-plus');
          $('#addon-toggle').find('i').addClass('fa-minus');
        } else {
          $('#addon-toggle').find('i').removeClass('fa-minus');
          $('#addon-toggle').find('i').addClass('fa-plus');
        }
    }</script>
      <a href="javascript:void(0)" onclick="togglerFunction()" id="addon-toggle" class="form-row form-group form-inline" data-toggle="collapse" data-target="#modal-${item.key}" aria-expanded="true" style="${winwidth<480?'padding-left:15px;':''}">
        <i id="icon-fav" class="modal-icon-${item.key}-${_time} fa fa-minus mr-2" style="color: green !important; ${addons_lv1 ? 'font-size: 18px;' : 'font-size: 16px;'}"></i>
        <span class="mr-1" style="color: green; ${addons_lv1 ? 'font-size: 18px; font-weight: bold' : 'font-size: 16px;'}"> ${item.title}</span>
        <span id="modal-addon-limit-info-${item.key}" style="font-size: 16px; font-style: italic"> (Select ${cond})</span>
      </a>
      <div id="modal-${item.key}" data-item-id="${item.key}-${_time}" class="modal-show-seleted-addons collapse in show w-100 pl-2 pr-3" aria-expanded="true">
          ${modal_render_addons(item.children, item)}
      </div>
    `;
    })
    return html;
  }


  function modal_render_input(node){
    let parent = node.parent; //node.parent has been set while calling this function, thats why using it here
    let data = parent; //redesign .data;
    let html = ``;
    const isChecked = data && data.children && data.children.length == 1 ? true : false
    if(data.max == 1){
        if(data.min == 1){
            html = `
              <label style="margin-top: 1px; visibility:visible; -ms-transform: scale(2);-moz-transform: scale(2); -webkit-transform: scale(2); -o-transform: scale(2);" class="float-right">
                  <input class="rest-menuitems-addons" id="modal-node-${node.key}" data-key="${node.key}" data-parent-key="${parent.key}" type="radio" name="modal-radio-${parent.key}" value="0" data-price="${node.price || 0}">
                  <span class="label-text" style="font-size: 16px !important; color: mediumseagreen"></span>
              </label>
            `;
        }else{
            html = `
            <label style="margin-top: 1px; visibility:visible; -ms-transform: scale(2);-moz-transform: scale(2); -webkit-transform: scale(2); -o-transform: scale(2);">
            <input class="rest-menuitems-addons" id="modal-node-${node.key}"  data-key="${node.key}" data-parent-key="${parent.key}" type="checkbox" name="modal-checkbox-${parent.key}" value="0" data-price="${node.price || 0}">
                  <span class="label-text" style="font-size: 1.2rem !important; color: mediumseagreen"></span>
              </label>
           `;
        }
    }else{
      if(node.max == 1){
        html = `
          <label style="margin-top: 1px; visibility:visible; -ms-transform: scale(2);-moz-transform: scale(2); -webkit-transform: scale(2); -o-transform: scale(2);">
            <input class="rest-menuitems-addons" id="modal-node-${node.key}"  data-key="${node.key}" data-parent-key="${parent.key}" type="checkbox" name="modal-checkbox-${parent.key}" ${isChecked ? "checked" : ""} value="0" data-price="${node.price || 0}">
            <span class="label-text" style="font-size: 1.2rem !important; color: mediumseagreen"></span>
          </label>
        `;
      } else {
        html = `
          <input id="modal-node-${node.key}" type="number" min="0" max="${node.max}" data-key="${node.key}" data-parent-key="${parent.key}" class="form-control priceinput rest-menuitems-addons" data-price="${node.price || 0}">
        `;
      }
    }
    return html;
  }
  /**
   * Shopping Cart
   */
  const ShoppingCart = (() => {
      let CART_ITEMS = [];
      let TOTAL_CART_ITEMS = CART_ITEMS.length; //Keep reference count of items on DOM;
      const $itemsListContainer = $('#cart-items-container');
      let ORDER_ITEMS = [];

      /**
       * Update local cart items
       */
      const updateLocalCartItems = (newItems) => {
        if (Array.isArray(newItems)) {
          CART_ITEMS = newItems;
        }
      };
      /**
       * Get current cart items
       */
      const getCurrentCartItems = () => {
          return CART_ITEMS;
      };
      /**
       * Send request to Retieve cart items stored by user
       * @param {String} userId
       */
      const getItems = (_orderId = false) => {
        let orderId;
        if(_orderId){
          orderId = _orderId;
        }else{
          orderId = Order.getOrderDetail('orderId');
        }
        // if(!orderId) return Promise.resolve(null)
        let url = `/orders/shoppingcart/get?userId=${USER_ID}&venueId=${VENUE_ID}&orderId=${orderId}`;
        return $.promisedAjax({
          url: url,
          contentType: 'application/json',
          dataType: 'json'
        }).then((response) => {
          if (response.data) {
            let cartItems = response.data;
            setOrderItems(cartItems);
            return cartItems;
          }
          return [];
        })
      };

      const setOrderItems = (cartItems) => {
        if(cartItems.userCartOrder && cartItems.userCartOrder.userCartItems && cartItems.userCartOrder.userCartItems) {
          ORDER_ITEMS = cartItems.userCartOrder.userCartItems;
        }
      }

      const updateItemsOrder = (orderId = false, items = false) => {
        if(orderId && items) {
          let data = {
            venueId: VENUE_ID,
            orderId,
            items
          }
          return $.promisedAjax({
            url: `/orders/updateItemsOrder`,
            type: 'POST',
            data: JSON.stringify(data),
            contentType: 'application/json',
            dataType: 'json'
          })
        }
      };

      const editItemsOrder = (orderId = false, items = false) => {
        if(orderId && items) {
          let data = {
            venueId: VENUE_ID,
            orderId,
            items
          }
          return $.promisedAjax({
            url: `/orders/editItemsOrder`,
            type: 'POST',
            data: JSON.stringify(data),
            contentType: 'application/json',
            dataType: 'json'
          })
        }
      };

      // get my friend order-ahead
      const getFriendsOrderAhead = (_orderId = false, host=true) => {
        let orderId;
        orderId = _orderId || Order.getOrderDetail('orderId');
        let url = `/orders/order-ahead/friends?venueId=${VENUE_ID}&orderId=${orderId}`;
        return $.promisedAjax({
          url: url,
          type: 'GET',
          contentType: 'application/json',
          dataType: 'json'
        }).then((response) => {
          if (response.data) {
            return response.data;
          }
          return [];
        })
      };
      var default_rsvp = function(){
        let _date = $('#order-ahead-date').val().trim();
        if(!_date) return
        let _hour = $('.order-ahead-hour').val();
        let _minute = $('.order-ahead-minute').val();
        let _apm = $('.order-ahead-apm').val();
        if(_hour == 'h' || _minute == 'm'){
          _hour = '11';
          _minute = '59';
          _apm = 'pm';
        }
        let _time = _hour + ':' + _minute + ' ' + _apm;
        let date_order_ahead = moment(_date + ' ' + _time);
        let date_now = moment();
        if(!moment(_date).isAfter(date_now)){
          $('#invites-day').val(0);
          $('#invites-day').trigger('change')
          return
        }
        let days = Math.floor(date_order_ahead.diff(date_now, 'hours') / 24);
        if(days > 2){
          $('#invites-day').val(2);
        }else{
          $('#invites-day').val(days-1 >= 0 ? days-1 : 0);
        }
        $('#invites-day').trigger('change')
      }

      let minDateReserve  = moment(new Date).subtract(30, "minutes").toDate();
     $('#order-ahead-date').datetimepicker({
        showClose: true,
        icons: {
          close: 'closeText'
        },
        minDate : minDateReserve,
        defaultDate: new Date(),
      }).on('dp.hide', function(e){
        default_rsvp();
        $('#btn-update-order-ahead').prop('disabled', false);
        $(".order-change-times").first().trigger('change', [{isDateChange:true}])
      }).on('dp.change', function(e){
        let date_time = $('#order-ahead-date').val();
        Order.updateOrderDetail('orderDate', date_time)
      });
      $("#order-ahead-date").on("dp.show", function(e) {
        $('.OK').html("OK").css("background-color","darkgrey");
      });

      const getDisabledDay = ( selectedDay) => {
        let daysOfWeekDisabled = [];
        let dayskk;
      
        // Log the input values
        //console.log("latestOrderType=================", latestOrderType);
        console.log("selectedDay=================", selectedDay);
      
        // Determine the day of the week to enable based on the selectedDay
        const selectedDate = new Date(selectedDay);
        const selectedDayOfWeek = selectedDate.getDay();
        let daysOfWeekEnabled = [selectedDayOfWeek];
      
        // Disable days that are not the enabled day of the week
        daysOfWeekDisabled = [0, 1, 2, 3, 4, 5, 6].filter(day => !daysOfWeekEnabled.includes(day));
      
        // Disable days before the selected day
        const today = new Date();
        
        if (today < selectedDate) {
          const daysToDisableBeforeSelected = [];
          
          // Loop through the days before the selected day to disable them
          for (let i = 0; i < 7; i++) {
            const dayToCheck = new Date(selectedDate);
            dayToCheck.setDate(dayToCheck.getDate() - i);
            
            if (dayToCheck < today) {
              const dayOfWeek = dayToCheck.getDay();
              if (!daysOfWeekDisabled.includes(dayOfWeek)) {
                daysOfWeekDisabled.push(dayOfWeek);
              }
            }
          }
        }
      
        return daysOfWeekDisabled;
      }

      
 
      $('#orderdatepicker').datetimepicker({
        showClose: true,
        icons: {
          close: 'closeText'
        },
        minDate : minDateReserve,
        ignoreReadonly: true,
        // inline: true,
        // sideBySide: true,
        // disabledDates: true,
      }).on('dp.show', function(e){
        let date_time = $('#orderdatepicker').val()
        $('#btn-orderdatepicker').text(date_time);
        let periodBtnElement = $('.bootstrap-datetimepicker-widget .btn-primary.btn[data-action="togglePeriod"]');
        $('.table-condensed > tr:nth-child(1) > td:nth-child(4)').html('<span class="fa fa-chevron-up order-period-up"></span>');
        $('.table-condensed > tr:nth-child(3) > td:nth-child(4)').html('<span class="fa fa-chevron-down order-period-down"></span>');
        $('.bootstrap-datetimepicker-widget .order-period-up').on('click', function (e) {
          periodBtnElement.click();
          return false;
        })
        $('.bootstrap-datetimepicker-widget .order-period-down').on('click', function (e) {
          periodBtnElement.click();
          return false;
        })
      }).on('dp.hide', function(e){

      }).on('dp.change', function(e){
        let date_time = $('#orderdatepicker').val()
        const isMealPrep = $('#is-meal-preps').val();
        console.log("date_time====",date_time)
        if(isMealPrep && isMealPrep==='true'){
          date_time = moment(date_time).format("MM/DD/YYYY");
        }else{
          date_time = date_time;
        }
        $('#btn-orderdatepicker').addClass('active').text(date_time);
        Order.updateOrderDetail('orderDate', date_time);
        Order.updateOrderDetail('pickuporderTime', date_time)

      });

      // $('#order-ahead-date').datetimepicker({
      // }).on('dp.change', function(e){
        // default_rsvp();
        // $('#btn-update-order-ahead').prop('disabled', false);
      // });
      $('.order-ahead-hour, .order-ahead-minute, .order-ahead-apm').on('change',function(){
        default_rsvp();
      })
      const renderFriendOrderAhead = (orderId, userEmail, host=true, parentId, orderName, orderType = "order-ahead") => {
        getFriendsOrderAhead((parentId || orderId),host).then((friends) => {
          let htmlfriend = '';
          if(friends && friends.length) {
            friends.forEach((friend) => {
                htmlfriend += `
                    <div class="${friend.readonly ? '' : 'change-friend-ahead'}" data-friend="${friend.readonly ? '' : friend._id}">
                        ${friend.editName
                          ? `<div class=""></div>`
                          : `<div class="">${friend.userName}</div>`
                        }
                        <div class="">${friend.email}</div>
                        <div class="form-inline">
                            <select class="form-control mr-1" name="friend-status" ${(friend.email != userEmail) ? 'disabled': ''} style="max-width:150px;">
                            ${friend.status == "invited" ?
                              `<option "selected" value="invited">Invited</option>
                                <option value="confirmed">Confirmed</option>
                                <option value="declined">Decline</option>
                              `
                            : friend.status == "confirmed" ?
                              `<option "selected" value="confirmed">Confirmed</option>
                                <option value="declined">Decline</option>
                              `
                            :
                            `<option "selected" value="declined">Declined</option>
                              <option value="confirmed">Confirm</option>
                            `
                            }
                            </select>
                            <input type="number" name="friend-guests" value="${friend.guests}" ${(friend.email != userEmail || friend.status != "confirmed") ? 'disabled': ''} class="form-control" style="max-width:100px; display:inline-block;">
                            <label style="display:inline-block; margin-left:10px; margin-bottom:10px;">guests</label>
                        </div>
                    </div>
                    <div style="margin-top:20px; border-top: 1px solid grey;"></div>
                `;
            })
          }
            htmlfriend += `
                <div class="text-center mt-3">
                  <input type="text" class="form-control form-group text-center" id="invite-additional-people" ${host===false ? 'disabled' : ''} placeholder="Separate emails with commas" style="width: 75%; margin: auto">
                  ${ host===false ? '' : '<button type="button" class="btn btn-success mt-3" id="additional-invite">Invite</button>'}
                  <span style="width: 250px;" class="help-block"></span>
                </div>
            `;
            $(`#${orderType} #invites-friends-order-ahead`).html(htmlfriend)

          let confirmed_list = _.filter(friends,(item)=>{
            return item.status == 'confirmed';
          })
          let attending_total = _.size(confirmed_list) + _.sumBy(confirmed_list, (ele)=> { return ele.guests; });
          $(`#${orderType} #currently-attending`).text(attending_total);
        })
      }

      // Determine if there is any local cart items
      const hasItems = () => {
        return CART_ITEMS.length > 0;
      };

      /**
       * Verify if any of the new items is already in the shopping_cart
       * @param {*}        newItems :
                (optional) orderId  :  for adding item to extendable order eg. order-ahead, in-restaurant
       */
      const _verifyNewCartItems = (newItems, orderId = false) => {
        let alreadyAddedItems = [];
        let updatedCartItems = newItems;
        let tempItems  = newItems;
        let _cartItems = orderId ? SubOrder.getItems(orderId) : CART_ITEMS;
        _cartItems     = _cartItems.filter(i => i.payment_id == null);

        if (_cartItems.length > 0) {
          updatedCartItems = newItems.reduce((inCartItems, newItem) => {
              //Check if item is already on cart
              let existingCartItem = _cartItems.find((cartItem) => cartItem.item_id === newItem.item_id)
              if (existingCartItem) {
                //Update the item current item quantity
                existingCartItem.quantity += newItem.quantity;
                alreadyAddedItems.push({id: newItem.item_id, name: newItem.item_name});
                inCartItems.push(existingCartItem);
              } else {
                //Only use the new item
                inCartItems.push(newItem);
              }
              return inCartItems;
              }, [])
        }
        return {updatedCartItems, alreadyAddedItems};
      };

      /**
       * Send request Add new item or item(s) to cart
       * @param {Array}  items
                {Number} extendableOrderId : orderId for adding additional items
       */
      const addItems = (items, extendableOrderId = false, orderType, noSalesOnSystem) => {
        __checkout_load.get_orders = false
        let url = `/orders/shoppingcart/add`;
        let orderId;
        let data;
        let newItems;
        if (extendableOrderId){
          newItems = _verifyNewCartItems(items, extendableOrderId);
          data = {
            orderId: extendableOrderId,
            cartType: "sub-order",
            userId: USER_ID,
            venueId: VENUE_ID,
            items: newItems.updatedCartItems,
            orderType : orderType,
            noSalesOnSystem : noSalesOnSystem
          };
        } else {
          newItems = _verifyNewCartItems(items);
          orderId = Order.getOrderDetail('orderId');
          data = {
            orderId: orderId,
            userId: USER_ID,
            venueId: VENUE_ID,
            items: newItems.updatedCartItems,
            orderType : orderType,
            noSalesOnSystem : noSalesOnSystem
          };
        }

        return $.promisedAjax({
          url: url,
          type: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json',
          dataType: 'json'
        }).then((res) => {
          if (res.success){
            //Add the new updated items to local CART
            newItems.updatedCartItems.forEach((item) => CART_ITEMS.push(item));
          }
          return res;
        })
        .catch((err) => {
          return err;
        })
        .finally(()=>{
          __checkout_load.get_orders = true
        })
      };
      /**
       * Send request to Remove item from Cart list
       * @param {String} itemId
       */
      const _removeItem = (itemId, groupId = null) => {
        let orderId = Order.getOrderDetail('orderId');
        let data = { userId: USER_ID, itemId, groupId, venueId: VENUE_ID, orderId };
        return $.promisedAjax({
          url: '/orders/shoppingcart/delete',
          type: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json',
          dataType: 'json'
        });
        //TODO Remove item from local Cart
      };

      const _removeItemPaid = (orderId, cartId, subOrderId, $contentItem) => {
        if($contentItem.hasClass('disabled') && subOrderId) return;
        const data = {venueId: VENUE_ID, orderId: orderId, subOrderId: subOrderId, arrItemId: [cartId]};
        if(data && data.orderId && data.arrItemId && data.arrItemId.length) {
          const order_time = Order.getOrderDetail('orderTime');
          let date_order = new Date(order_time);
          let date_now = new Date();
          let difference = date_order - date_now;
          let minutes = Math.round(difference/(1000*60));
          if(1==1) {
            cancalItemPaidHasRefund(data, $contentItem, this)
          } else {
            cancalItemPaidNotRefund(data, $contentItem, this);
          }
        }
      }

      // Handle cancel item paid has refund
      function cancalItemPaidHasRefund(data, $contentItem, self) {

        function swalCancelItem() {
          Swal.fire({
            title: `You want to cancel item`,
            text: 'Cancelling this menu item paid, are you sure you want to do that?',
            icon: "warning",
            showCancelButton: false,
            showConfirmButton:false,
            allowOutsideClick: true
          });
        }
        swalExtend.call(self, {
          swalFunction: swalCancelItem,
          hasCancelButton: true,
          buttonNum: 2,
          buttonColor: ["#b30000", "#3CB371"],
          buttonNames: ["No","Yes"],
          clickFunctionList: [
            function() {
              return false;
            },
            function() {
              $contentItem.html('<small class="red">Removing...</small>');
              $contentItem.addClass('disabled');
              $.ajax({
                url: '/orders/cancelItemsOrderPaid',
                type: 'POST',
                data: JSON.stringify(data),
                contentType: 'application/json',
                dataType: 'json',
                error: function(err) {
                  $contentItem.html('<i class="fa fa-lg fa-times-circle red"></i>');
                  $contentItem.removeClass('disabled');
                  Swal.fire('Unable to cancel selected items', 'The kitchen has already started preparing your order so you can no longer cancel.', 'error');
                },
                success: function(res) {
                  if(res['clear_order']){
                    Order.clearOrderDetails();
                    $('#myorder-accordion').hide()
                    $('#collapseMyOrder').modal('hide');
                    // $('.order-this-item').focus();
                    return __get_all_orders_infor();
                  }
                  $contentItem.html('<i class="fa fa-lg fa-times-circle red"></i>');
                  $contentItem.removeClass('disabled');
                  Swal.fire('Cancel selected items successful', '', 'success');
                  loadCurrentCartItems();
                }
              })
              return true;
            },
          ]
        });
      }

      // Handle cancel item paid not refund
      function cancalItemPaidNotRefund(data, $contentItem, self) {
        function swalCancelItem() {
          Swal.fire({
            title: `You want to cancel item`,
            text: "If you cancel this menu item now, you won't get a refund since the restaurant has already started preparing your food.",
            icon: "warning",
            showCancelButton: false,
            showConfirmButton:false,
            allowOutsideClick: true
          });
        }
        swalExtend.call(self, {
          swalFunction: swalCancelItem,
          hasCancelButton: true,
          buttonNum: 2,
          buttonColor: ["#b30000", "#3CB371"],
          buttonNames: ["Cancel anyway","Don't cancel"],
          clickFunctionList: [
            function() {
              $contentItem.html('<small class="red">Removing...</small>');
              $contentItem.addClass('disabled');
              $.ajax({
                url: '/orders/cancelItemsOrderPaidNotRefund',
                type: 'POST',
                data: JSON.stringify(data),
                contentType: 'application/json',
                dataType: 'json',
                error: function(err) {
                  $contentItem.html('<i class="fa fa-lg fa-times-circle red"></i>');
                  $contentItem.removeClass('disabled');
                  Swal.fire('Unable to cancel selected items', 'The kitchen has already started preparing your order so you can no longer cancel.', 'error');
                },
                success: function(res) {
                  $contentItem.html('<i class="fa fa-lg fa-times-circle red"></i>');
                  $contentItem.removeClass('disabled');
                  Swal.fire('Cancel selected items successful', '', 'success');
                  loadCurrentCartItems();
                }
              })
              return true;
            },
            function() {
              return false;
            },
          ]
        });
      }

     /**
      * Send request to Update item details
      * @param {Object} newItemDetails
      */
      const updateItem = (newItemDetails = {}) => {
        if (newItemDetails.item_id && newItemDetails.retail_price) {
          let orderId = Order.getOrderDetail('orderId');
          let data = {orderId, userId: USER_ID, venueId: VENUE_ID, itemDetails: {item_id : newItemDetails.item_id, retail_price: parseFloat(newItemDetails.retail_price)}};
          if (newItemDetails.hasOwnProperty('quantity')) data.itemDetails.quantity = parseInt(newItemDetails.quantity);
          if (newItemDetails.hasOwnProperty('special_instructions')) data.itemDetails.special_instructions = newItemDetails.special_instructions;
          return $.promisedAjax({
            url: '/orders/shoppingcart/updateItem',
            type: 'POST',
            data: JSON.stringify(data),
            contentType: 'application/json',
            dataType: 'json'
          });
          //TODO: update this item on local cart too
        } else {
          return Promise.reject(new Error('Missing required fields to update item'));
        }
      };

      const updateAddons = (newItemDetails, currentItem, itemData, primarydiv="edit-item-container") => {
        if (newItemDetails.key) {
          let orderId = Order.getOrderDetail('orderId');
          const $addons = $("#"+primarydiv).find('.option-group');
          const addons = [];
          const menu_name = newItemDetails.parent.parent.title;
          const section_name = newItemDetails.parent.title;

          let newItemData = {
            item_id : newItemDetails.key,
            parent_item_id: null,
            item_type: 'item',
            hasAddons: true,
            item_name: newItemDetails.title,
            rating: newItemDetails.rating,
            quantity: itemData.quantity || currentItem.quantity || 1,
            special_instructions: itemData.special_instructions,
            retail_price: (newItemDetails.price && newItemDetails.price !== "-") ? newItemDetails.price : 0,
            menu_name: menu_name,
            section_name: section_name,
            menuType: newItemDetails.menuType
          };
          addons.push(newItemData);

          $addons.each(function() {
            const $input = $(this).find('input');
            const item_id = $(this).find('.item-id').text();
            const item_name = $(this).find('.item-name').text();
            const item_rating = $(this).find('.item-rating').text();
            let item_price = $(this).find('.item-price').text();
            let quantity = 0;
            if(item_price && Number(item_price) > 0) item_price = Number(item_price)
            const input_type = $input.attr('type');

            if(input_type == 'radio' && $input.is(':checked')) quantity = 1;
            else if(input_type == 'checkbox' && $input.is(':checked')) quantity = 1;
            else if(input_type == 'number') quantity = Number($input.val());

            if(quantity && quantity > 0) {
              let addon= {
                item_id: item_id,
                parent_item_id: newItemDetails.key,
                item_type: 'addon',
                item_name: newItemDetails.title,
                rating: newItemDetails.rating,
                quantity: 1,
                retail_price: (newItemDetails.price && newItemDetails.price !== "-") ? newItemDetails.price : 0,
                menu_name: menu_name,
                section_name: section_name,
                menuType: newItemDetails.menuType,
                addon_name: item_name,
                addon_rating: item_rating,
                addon_quantity: quantity,
                addon_price: item_price,
              }
              addons.push(addon);
            }
          })

          const message = validateItemAddons(newItemDetails);

          let data = {
            orderId,
            userId: USER_ID,
            venueId: VENUE_ID,
            parent_item_id: newItemDetails.key,
            group_id: currentItem.group_id,
            addons: addons,
          };

          if(message) {
            return Promise.resolve({message});
          } else {
            return $.promisedAjax({
              url: '/orders/shoppingcart/updateAddons',
              type: 'POST',
              data: JSON.stringify(data),
              contentType: 'application/json',
              dataType: 'json'
            });
          }
          //TODO: update this item on local cart too
        } else {
          return Promise.reject(new Error('Missing required fields to update item'));
        }
      };






      const _removeItemBtnHandler = (e) => {
          e.preventDefault();
          const $contentItem = $(e.currentTarget);
          const $thisItemLi = $contentItem.parent();
          const itemId = e.currentTarget.id.split('-')[1];
          const groupId = e.currentTarget.id.split('-')[2];

          const paid = $contentItem.data('paid');
          const cart_item = $contentItem.data('item');
          const order_id = $contentItem.data('order-id');
          const suborder_id = $contentItem.data('suborder-id');
          if(paid) {
            _removeItemPaid(order_id, cart_item, suborder_id, $contentItem)
          } else {
            //TODO: Show confirmation prompt before deleting item
            loadingOrderAnimation('show');

            _removeItem(itemId, groupId).then((res) => {
              $thisItemLi.remove();
              loadingOrderAnimation('hide');
              CART_ITEMS = CART_ITEMS.filter((item) => { return item.item_id != itemId; });
              TOTAL_CART_ITEMS -= 1;
              console.log("TOTAL_CART_ITEMS====",TOTAL_CART_ITEMS)
              if(TOTAL_CART_ITEMS==0){
                $(".order-total").text(0);
                $(".order-total-info").val(0);
              }
              loadCurrentCartItems();
              if(res.data['clear_order']){
                Order.clearOrderDetails();
                $('#myorder-accordion').hide()
                $('#collapseMyOrder').modal('hide');
                // $('.order-this-item').focus();
                return __get_all_orders_infor();
              }
              return Order.renderOrderTotals();
            }).catch((e) => {
              alert('unable to remove item')
            });
          }

      };
      //Fancy Tree handlers
 var getRatingHTML = function (itemRating, reviews, maxRating = 5,myrate, idval , rating , review, upc, sku) {
  var mainDiv = $(`<div id="mainRatingDiv"></div>`);
  var $html;
  var $html1;
     $html = $(`<div id="${myrate == true ? idval : ''}" data-myRate="${myrate}" class='highest-rated-items-ratebox colorgreen' style='position: relative; display:${myrate == true ? 'block' : 'none'}' data-rating=${rating} data-reviews=${review} data-upc='${upc}' data-sku='${sku}'></div>`);

     $html1 = $(`<div class='highest-rated-items-ratebox colorOrange form-inline' style='position: relative; ${myrate == true ? 'display: none' : ''}'  ></div>`);
  if (itemRating > maxRating) {
    itemRating = maxRating;
  }
  if (itemRating && itemRating > 0){
    $html.append(loadRate(itemRating, maxRating));
  }
   if(rating && rating > 0){
     $html1.append(loadRate(rating, maxRating));
   }
   if(review > 0){
    $html1.append(`<span style="display:inline !important">${review} <img src="/images/verifiedBadge.svg"></span>`)
   }
  mainDiv.append($html);
  mainDiv.append($html1);
  return mainDiv[0];

};

let rateid = 0;
function loadRate(itemRating, maxRating) {
  if(itemRating > 5) itemRating = 5
  let ratehtml = document.createElement('div');
  ratehtml.innerHTML = "<div class='ratebox' data-id=" + rateid + " data-rating=" + itemRating + " style='cursor: default'></div>";
  $(ratehtml.children).rateYo({
    rating: itemRating,
    starWidth: "20px",
    spacing: "3px",
    ratedFill: "green",
    normalFill: "#bdbcbc",
    starSvg: __platerate_icons.heart_plate
  });
  rateid = rateid + 1;
  return ratehtml.innerHTML;
}

//recursive function for checking children of node
//if no child, then show radiobn, checkbox, etc
  //the ids here will be same as trees, but suffixed with _popup; also in tree, the id is with the label above the checkbox, but here it is with the options
  //not sure but groupId seems to be getting set in the orders screen and not the menu tree

  function price_range(item_data){
    let addons = item_data.children
    let item_price = _.toNumber(item_data.price) + calculate_price(addons, _.size(addons))
    return item_price
  }

function calculate_price(data, pmin){
    if(!_.size(data) || !pmin) return 0
    data = _.sortBy(data, el => _.toNumber(el.price) || 0)
    let s = 0
    let a = 0
    _.forEach(data, item =>{
        if(_.size(item.children)) return s += calculate_price(item.children, item.min)
        // let min = _.toNumber(item.min) || 0
        let max = _.toNumber(item.max || item.maxAmt) || 0
        if(!max) {
            s += _.toNumber(item.price) * pmin
            return false
        }
        if(a + max <= pmin){
            s += item.price * max
            a += max
        }else{
            s += item.price * (pmin - a)
            a = pmin
        }
        return a < pmin
    })
    return s
}
let specialInstructions='';
  $('body').append('<div class="modal-order-component"></div>')
  function modal_render_item_ordered(item_key){

//    let item_node = $('#tree').fancytree('getTree').getNodeByKey(item_key);

    const itemId = item_key;
    let noSalesOnsystem = $('#rest-noSalesOnsystem').val();
    let latestOrderType = $('#rest-latestOrderType').val();
    let orderTypeRate = $('#rest-OrderType').val();
    let item_node = $('#menuitemData_'+item_key);
    let img_item_node = $('#carousel-menu-item-'+item_key+' .carousel-inner .carousel-item img').attr('src');
    let winwidth = $(window).width();

    let item_nodee = $(`.menuitem-key-${item_key}`)[0];
    const $itemCardd = $(item_nodee);

    let userHasSetFlavorMatch = $("#user-has-set-flavor-match").val() != "false";
    let userHasSetDietaryPrefs = $("#user-has-dietary-prefs-match").val() != "false";
    let userHasSetFlavMatch = $("#user-has-set-flavor-matchh").val() != "false";
    let menuitemFlavorMatchitem = $("#menuitem_flavorMatchitem").val() /* != "false"; */
    let flavMatchLinks = $("#user-has-not-set-flavor-match-link").val() /* != "false"; */


    const menuName = $itemCardd.data('menu-name');
    const sectionName = $itemCardd.data('section-name');
    const menu = dataTree.find(r => r.title == menuName);
    const section = menu.children.find(r => r.title == sectionName);
    const menuitem = section.children.find(r => r.key == item_key);

    let itemFlavors = menuitem.expertReview ||  null;
    let expertReviewAvailable = false;
    for (const key in itemFlavors) {
      if (!expertReviewAvailable && menuitem.expertReview[key] > 0) expertReviewAvailable = true;
    }
    let itemFlavorsLow =  menuitem.expertReviewLow || null;
    let itemFlavorsHigh = menuitem.expertReviewHigh || null;

    var sliders = ``;
    for (var key in itemFlavors) {
      if (itemFlavors.hasOwnProperty != undefined && itemFlavors.hasOwnProperty) {
        var keyValue = key;
        if (keyValue == 'savory') {
          keyValue = 'Umami (meaty taste)';
        } else if (keyValue == 'valueForPrice') {
          keyValue = "What it's worth";
        }
        sliders += `
          <div class="col-md-6 col-12">
            <div class="row flavor-title">
              <div class="col-12">
                ${keyValue.charAt(0).toUpperCase()}${keyValue.slice(1)}
              </div>
            </div>
            <div class="row flavor-slider ">
              <div class="col-12">
                <input type="text" class="flavormatch-slider-class range-slider-flavor flat-slider" data-provide="slider" data-slider-value="[${itemFlavorsLow[key]},${itemFlavorsHigh[key]}]"
                  data-slider-selectedval="${key === 'valueForPrice' && itemFlavors[key] === parseFloat(menuitem.price) ? 50 : itemFlavors[key]}" />
              </div>
            </div>
          </div>
        `
      }
    }

//This block of code switches btw the special instructions, dietray pref, and flavor match
  $('.modal-order-component').unbind('click');
  $('.modal-order-component').on('click','.btnModal',function(event){
    $(".btnModal").removeClass('activedietary');
    if ($(event.currentTarget).is('#special-Instructions')) {
      $("#special-Instructions").addClass('activedietary')
      $("#Div1").css("display", "block");
      $("#Div2").css("display", "none");
      $("#Div3").css("display", "none");
      $(".dietary-Preference").attr("src","/images/dietaryPrefLeave.svg");
      $(".flavour-Match").attr("src","/images/flavorIcon.svg");
    }
    else if ($(event.currentTarget).is('#dietary-Preference')) {
      $('#dietary-Preference').addClass('activedietary')
      $("#Div1").css("display", "none");
      dietrayfun();
      $(".dietary-Preference").attr("src","/images/dietaryPrefLeavewhite.png");
      $(".flavour-Match").attr("src","/images/flavorIcon.svg");

      $("#Div2").css("display", "block");
      $("#Div3").css("display", "none");  ;
    }
    else if($(event.currentTarget).is('#flavour-Match')) {
      $("#flavour-Match").addClass('activedietary');
      $("#Div1").css("display", "none");
      $("#Div2").css("display", "none");
      $(".dietary-Preference").attr("src","/images/dietaryPrefLeave.svg");
      $(".flavour-Match").attr("src","/images/flavorIconwhite.png");
      flavorMatchfun();
      $("#Div3").css("display", "block");
    }
  });
  function dietrayfun() {
    const account = $('#account-infor').text() ? JSON.parse($('#account-infor').text()) : null;
    let MY_FOOD_PREFERENCES = $('#user-dietary-preferences').val() ? JSON.parse($('#user-dietary-preferences').val()) : null;
    let chooseBoxes = ``;
    if(account && account.dietaryPreferences && MY_FOOD_PREFERENCES){
      account.dietaryPreferences.forEach((ele)=> {
        if(ele.name){
          chooseBoxes += `
            <div class="form-group item-preferences mb-0">
        <div class="foodpref-font">Is this dish ${ele.name} ${MY_FOOD_PREFERENCES[menuitem.key][ele.name]}?</div>
                <ul class="select-suitable-list d-flex justify-content-between pl-0 mb-0" data-item-id="${menuitem.key}" data-preference="${ele.name}">
              <li class="select-suitable-dish text-center ${MY_FOOD_PREFERENCES[menuitem.key][ele.name] == 'yes' ? 'active' : '' }" data-value="yes" style="background-color: ${ MY_FOOD_PREFERENCES[menuitem.key][ele.name] == 'yes' ? '#4caf50' : 'white' }">
                <a class="text-black" href="javascript:void(0)">Yes</a>
              </li>
              <li class="select-suitable-dish text-center ${MY_FOOD_PREFERENCES[menuitem.key][ele.name] == 'mostly' ? 'active' : '' }" data-value="mostly" style="background-color: ${ MY_FOOD_PREFERENCES[menuitem.key][ele.name] == 'mostly' ? '#8bc34a' : 'white'}">
                <a class="text-black" href="javascript:void(0)">Mostly</a>
              </li>
              <li class="select-suitable-dish text-center ${MY_FOOD_PREFERENCES[menuitem.key][ele.name] == 'canrequest' ? 'active' : '' }" data-value="canrequest" style="background-color: ${ MY_FOOD_PREFERENCES[menuitem.key][ele.name] == 'canrequest' ? '#ffc107' : 'white'}">
                <a class="text-black" href="javascript:void(0)">Can Request</a>
              </li>
              <li class="select-suitable-dish text-center ${MY_FOOD_PREFERENCES[menuitem.key][ele.name] == 'notsure' ? 'active' : '' }" data-value="notsure" style="background-color: ${ MY_FOOD_PREFERENCES[menuitem.key][ele.name] == 'notsure' ? '#9e9e9e' : 'white'}">
                <a class="text-black" href="javascript:void(0)">Not Sure</a>
              </li>
              <li class="select-suitable-dish text-center ${MY_FOOD_PREFERENCES[menuitem.key][ele.name] == 'no' ? 'active' : '' }" data-value="no" style="background-color: ${ MY_FOOD_PREFERENCES[menuitem.key][ele.name] ==  'no' ? '#ff5722' : 'white'}">
                <a class="text-black" href="javascript:void(0)">No</a>
              </li>
              <li class="select-suitable-dish text-center ${MY_FOOD_PREFERENCES[menuitem.key][ele.name] == 'na' ? 'active' : '' }" data-value="na" style="background-color: ${ MY_FOOD_PREFERENCES[menuitem.key][ele.name] ==  'na' ? '#9e9e9e' : 'white'}">
                <a class="text-black" href="javascript:void(0)">N/A</a>
              </li>
              </ul>
            </div>
          `
        }
      })
    }
      $(".dietrayfundiv").html(`
              ${ userHasSetDietaryPrefs ?
                `
                <div id="Div2" class="form-group mb-0 item-preferences" style="width: 100%; display: none; margin: 7% auto;">
              <div class="popupModalNavTarget all-dietary-preferences">
              <p>Does this menu item meet your dietary preferences?</p>
              <div class="foodpref-font">Set all Dietary Preferences to</div>
              <ul class="select-suitable-list-all d-flex justify-content-between pl-0 mb-0" data-item-id="${menuitem.key}" data-preference="">
                <li class="select-suitable-dish-all text-center" data-value="yes" style="background-color: 'white';">
                  <a class="text-black" href="javascript:void(0)">Yes</a>
                </li>
                <li class="select-suitable-dish-all text-center" data-value="mostly" style="background-color: 'white';">
                  <a class="text-black" href="javascript:void(0)">Mostly</a>
                </li>
                <li class="select-suitable-dish-all text-center" data-value="canrequest" style="background-color: 'white';">
                  <a class="text-black" href="javascript:void(0)">Can Request</a>
                </li>
                <li class="select-suitable-dish-all text-center" data-value="notsure" style="background-color: 'white';">
                  <a class="text-black" href="javascript:void(0)">Not Sure</a>
                </li>
                <li class="select-suitable-dish-all text-center" data-value="no" style="background-color: 'white';">
                  <a class="text-black" href="javascript:void(0)">No</a>
                </li>
                <li class="select-suitable-dish-all text-center" data-value="na" style="background-color:'white';">
                  <a class="text-black" href="javascript:void(0)">N/A</a>
                </li>
              </ul>
              </div>
              <div class="divChoosebox">
                ${chooseBoxes}
              </div>
            </div>
              ` :
              `
              <div id="Div2" class="row" style="width: 95%; margin: 7% auto; display: none;">
               <div style="margin-bottom: 20px !important; text-align: center; margin: auto;">
                 Set your <span style="color: #02843D">Dietary Preferences</span>  and let us know if a menu item doesn't meet them. This will remove the item from your menu and that of people with the same preference.
               </div>
               <div style="margin-top:15px; text-align: center; margin: auto; border: 1px solid #02843D; width: 60%; border-radius: 7px; padding: 7px 0;">
               <a class="set-diet-pref" href=${userId ? `/users/healthy-eating-profile?tab=collapseFoodPref&next=${location.pathname}?type=setFoodPrefProfile&buymenuitemid=${menuitem.key}` : `/users/login?tab=collapseFoodPref&next=${location.pathname}?type=setFoodPrefProfile&buymenuitemid=${menuitem.key}`}>
               <img src="/images/dietaryPrefLeave.svg" alt="" />
                 Set Dietary Preference
             </a>
               </div>
              </div>
              `}
              `);
    }
  function flavorMatchfun() {
    $(".flavorMatchfundiv").html(`
     ${userHasSetFlavorMatch ?
         `
         <div class="expertReviewDetail popupModalNavTarget">
         <div id="Div3" class="row" style="width: 95%; margin: 7% auto; display: none;">
           <div class="col-6">
             <div class="form-row form-group d-flex justify-content-between">
               <div>FlavorMatch®: <span class="green">${menuitem.flavorMatchitem || '-'}% </span></div>
               <div><i class="fa fa-circle" aria-hidden="true" style="color: orange"></i> Dish Score</div>
               <div><i class="fa fa-circle" aria-hidden="true" style="color: green"></i> My preferences</div>
             </div>
           </div>

           <div class="row">
            <div class=" ${winwidth<=480? 'col-12': 'col-6'}">
              <div class="d-flex justify-content-between" style="font-size:12px;">
                <div style="font-weight: bold">Low</div>
                <div style="font-weight: bold">High</div>
              </div>
            </div>
            <div class="col-6 ${winwidth<=480? 'hide': 'show'}">
              <div class="d-flex justify-content-between" style="font-size:12px;">
                <div style="font-weight: bold">Low</div>
                <div style="font-weight: bold">High</div>
              </div>
            </div>
           </div>

           <div class="row">
             ${sliders}
           </div>
         </div>
       </div>
         `
         :
         `
        <div class="expertReviewDetail popupModalNavTarget">
         <div id="Div3" class="row" style="width: 95%; margin: 7% auto; display: none;">
          <div style="margin-bottom: 20px !important; text-align: center; margin: auto;">
            Set your <span style="color: #02843D">FlavorMatch</span> preferences so we can tell you if menu items are compatible with your palette.
          </div>
          <div style="margin-top:15px; text-align: center; margin: auto; border: 1px solid #02843D; width: 60%; border-radius: 7px; padding: 7px 0;">
          <a class="set-flavour" href=${userId ? `/users/healthy-eating-profile?tab=collapseTasterProfile&next=${location.pathname}?type=setFoodPrefProfile&buymenuitemid=${menuitem.key}` : `/users/login?next=/users/healthy-eating-profile?tab=collapseTasterProfile&next=${location.pathname}?type=setFoodPrefProfile&buymenuitemid=${menuitem.key}`}><img src="/images/flavorIcon.svg" alt="" />Set FlavorMatch</a>
          </div>
         </div>
       </div>
         `
         }
   `)
    //converts the looped input fields to sliders for the flavor match
    $(`#dietPrefContainer .flat-slider`).bootstrapSlider({
      min: 0,
      max: 100,
      tooltip: 'false',
      values: [40, 60],
      formatter: function (value) {
        return;
      },
      disable: true,
      disabled: true,
    });
    $('.flavorMatchfundiv .value-slider-handle').css('display', expertReviewAvailable ? 'block' : 'none'); // hide arrow if no expertReview Rating
  }

  if(!item_node) return;
  try{
    item_node=JSON.parse(item_node.val());
  }catch(err){
    console.log("error parsing node in modal_render_item_ordered  ...",  err);
  }
  ordered_item_key = item_node.key;
	let itemDiscount = $('#maxDiscountMenuItem_'+ordered_item_key);
	let range_discount = '';
	if(itemDiscount.length>0){
		let discount = itemDiscount.val();
		if(typeof discount != "undefined" && discount && Number(discount)>0){
			discount = Number(discount);
			let calculated_discount =  parseFloat( item_node.price * discount / 100);
			if(calculated_discount > 0){
				range_discount = `<div class="text-right pr-0"><p class="card_earnup_item pr-0 mr-0 menu_item_title_fonts" ><span id="${item_node.key}" style="color:#02843d;font-size:16px;">Earn up to $${calculated_discount.toFixed(2)}</span></p></div>`;
			}
		}
	}
  const isCheckedDefault = item_node && item_node.children && item_node.children.length == 1
                    && item_node.children[0].children.length == 1 && item_node.children[0].max == 1 ? true : false
  let spanbuttonaddtocart='' ;
  let childItemPrices = []
  let childItemPrice = item_node && item_node.children && item_node.children.map(elem => {
    elem && elem.children.map(child => child.price != null ? childItemPrices.push(child.price) : childItemPrices.push('0'))
  })
   let minPrice = childItemPrices && childItemPrices.length > 0 ?  Math.min(...childItemPrices)  : 0;
   console.log("latestOrderType====",orderTypeRate)
    if(orderTypeRate=='false'){
      spanbuttonaddtocart+=`<span id="modal-total-price-item" style="font-weight:bold; padding-left: 3px;">$${parseFloat(item_node.price || 0).toFixed(2) && !isNaN(item_node.price) ? parseFloat(item_node.price || 0).toFixed(2) : 0.00}</span>`;
    }else{
       spanbuttonaddtocart+=`<span id="modal-total-price-item" style="font-weight:bold; padding-left: 3px;"></span>`;
     
    }
    let html = `
      <div id="modal-order-item-addons" class="modal fade" role="dialog" style="${winwidth<480?'height:100%;':''};z-index:9999"">
        <div class="modal-dialog modal-dialog-scrollable ${winwidth>480?'modal-dialog-centered':''}" role="document">

          <div class="modal-content p-md-3">
             <a class="close mt-3 mt-md-0 mb-3 px-md-0 px-3" data-dismiss="modal">
                <i class="fa fa-times " aria-hidden="true" style="float:right;" ></i>
             </a>
            <div class="modal-body scrollModal ${winwidth<480?'p-0 w-100':''}">
            ${img_item_node ? 
            `<div id="res-parentmenuimage" class="${winwidth<480?'p-0':''}">
            <div id="res-menuimage" class="images-for-item-${item_node.key} aspect-ratio-box">
              <div class="aspect-ratio-box-inside">
                <img class="w-100" src="${img_item_node}"/>
              </div>
            </div>
          </div>` : ''
            }
        <p class="boxLabelTop cursor-pointer py-1 mt-1 ml-3 ml-md-1 mb-2" data-toggle="tooltip" data-placement="bottom" data-original-title="${item_node.sectionName}">${item_node.sectionName}</p>

            <div class="mb-3 px-md-0 px-3 mx-2">
              <div class="pt-md-0 pl-md-0 res-details">
                <div class="row m-0">
                  <div class="col-9 col-md-9 p-0">
                    <div class="selected-item-name menu_item_title_fonts" data-item-key="${item_node.key}">
                      ${item_node.title}
                    </div>
                  </div>
                  <div class="col-3 col-md-3 pr-md-2 p-0 text-right">
                    <span class="item-addons-retail-price menu_item_title_fonts">
                      $${item_node.priceDisplay && !isNaN(item_node.priceDisplay)  ? (item_node.isMenuAddon ? (item_node.price && !isNaN(item_node.price) ? `${parseFloat(item_node.price).toFixed(2)}+` : `${parseFloat(minPrice || 0).toFixed(2)}+`) : item_node.priceDisplay) : '0.00'}
                    </span>
		              </div>
                </div>
                <div class="justify-content-between mt-3">
                  <div class="ratings" style="padding: 0px;">
                   </div>
			              ${range_discount}
                </div>

                  <div class="col-md-12 orderinformation rest-menuitem-description mt-1 p-0">
                    ${item_node && item_node.description ? item_node.description : '' }
                  </div>
              </div>
            </div>
            <div id="popupModalNav">
            <div class="popupModalNav" >


               <button class="btnModal activedietary " id="special-Instructions"   target="1">
                Special Instructions
              </button>
              <button class="btnModal " id="dietary-Preference"  target="2" ><img src="/images/dietaryPrefLeave.svg" alt="" class="dietary-Preference" />
                Dietary Preferences
              </button>
              <button class="btnModal " id="flavour-Match"   target="3"><img src="/images/flavorIcon.svg" alt="" class="flavour-Match"/>
                Flavor Match
              </button>
            </div>
            </div>
              <section class="target_box" id="dietPrefContainer">
              <div>
              <div id="Div1" class="form-row pt-3 mb-3 popupModalNavTarget" style="${winwidth<480?'padding-left:15px;width: 100%;':''} width: 95%; margin: 7% auto;">
                <div style="margin-top: 5%; margin-left: 3%;width: 100%;">
                  ${modal_render_addons(menuitem.children,item_node)}
                </div>
                <textarea data-id="${menuitem.key}" class="modal-item-special-instructions p-2" rows="3" cols="60" placeholder="Have any requests?" style="border-radius: 4px"></textarea>
              </div>
              <div class="form-row selected-items-errors">
              </div>
              </div>
              <div class="dietrayfundiv"></div>
              <div class="flavorMatchfundiv"></div>

              </section>
              </div>

            <div class="modal-footer add-to-cart">
                <div
                  class="add-to-cart-actions-wrapper form-inline justify-content-between align-center w-100"
                >
                  <div class="d-flex mt-1">
                    <div class="mr-1 mt-1" style="font-weight: bold;">Quantity</div>
                    <div class="input-group">
                        <div class="input-group-append">
                          <a href="javascript:void(0)" class="input-group-text decrease-number form-control" style="border-right: none"><i class="fa fa-minus green"></i></a>
                        </div>
                        <input id="modal-node-${item_node.key}" data-key="${item_node.key}" type="text" class="form-control form-control-sm text-center item-input selected-item-quantity" value="1" data-price="${item_node.price}" style="font-size: 14px; max-width:40px; border-left: none; border-right: none"></input>
                        <div class="input-group-append">
                          <a href="javascript:void(0)" class="input-group-text increase-number form-control"  style="border-left: none"><i class="fa fa-plus green"></i></a>
                        </div>
                    </div>
                  </div>

                  <button id="add-to-order" class="btn btn-success text-white d-flex mt-1" data-key="${item_node.key}" style="font-size: 14px!important;border-radius: 4px; width: max-content;" >
                     Add To ${noSalesOnsystem=='true' || (latestOrderType && latestOrderType=='only_rate')? 'Rate': 'Cart'}
                   
                    ${spanbuttonaddtocart}
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
    $('.speciaI').click(function () {
      $('.popupModalNavTarget').hide();
      $('#div'+$(this).attr('popupModalNavTarget')).show();
    });
    $('.modal-order-component').html(html);


    // store modal-item-special-instructions in localstorage
    $('#dietary-Preference, #flavour-Match, set-diet-pref, set-flavour').click(function () {
      var specialInstructions2 = localStorage.getItem('specialInstructions2_');
      let spclInstrSave = {
        spclInstr: '',
        menuitem : ''
      }
      if(specialInstructions2){
        $('#specialInstructions2').val(specialInstructions2);
      }
      $('#specialInstructions2').on('keyup', function(){
        spclInstrSave.spclInstr = $(this).val()
        spclInstrSave.menuitem = menuitem.key
        localStorage.setItem('specialInstructions2_' , spclInstrSave);
      });

    })

    $(".modal-item-special-instructions").each(function () {
      $(this).val(localStorage.getItem("specialInstructions2" ));
    });

    $(".modal-item-special-instructions").on("keyup", function (itm) {
      console.log("special-instructions click==0000===")
      let spclInstrSave = {
        spclInstr:  $(this).val(),
        menuitem : menuitem.key
      }
      localStorage.setItem ('specialInstructions2_', JSON.stringify(spclInstrSave));
    })

    if(isCheckedDefault){
      modal_calculate_price();
    }
    $(".ratings").html(getRatingHTML(item_node.rating,item_node.reviews,5,false,item_node.key,item_node.rating,item_node.reviews,item_node.upc,item_node.sku));
    // this timeout because for getting the image and appned takes time and we have to hide a div if no image
//  201102 seems to show images ok without timeout, so commenting  setTimeout(()=>{
      if($('#res-menuimage').children().length>0){
        $("#res-parentmenuimage").css('display','block');
        $("#res-menuimage .carousel-image").css("height", 'auto');
        $("#res-menuimage .carousel-image").css('width','auto');
        $("#res-menuimage .carousel-image").css('max-width','200px');
      }
//    },3000);
    $('#modal-order-item-addons').find('#add-to-order').unbind('click');
    $('#modal-order-item-addons').on('click','#add-to-order',function(){
      if(!validateMenu({showPopupMessageOn: 'all'}).valid) return false;
      $('.selected-items-errors').css({'display': ''});
      let id = $(this).data('key');
      let node = $("#menuitemData_"+id).val();
      
      

      let nodeTree = findMenuItemInDataTree(id, JSON.parse($('#tree-data').val()));
      let { crowdItem } = returnMenuSelectedItems([nodeTree],false)

      try{
        node=JSON.parse(node);
        
        let spclinstr =   $('#modal-order-item-addons').find('.modal-item-special-instructions').val();
        if(node && spclinstr){
          node.description = spclinstr
          $("#itemspecialinstr-"+ id).val(spclinstr);
         } else {
          node.description= specialInstructions;
         }
         localStorage.removeItem("specialInstructions2_")
         localStorage.setItem('description', node.description);

      }catch(err){
        console.log("error parsing node in orders.js  ...", node, err);
      }
      // Remove duplicate menu item in add to cart
      let isHasMenuItem;
      if(__selectedMenuItems){
        isHasMenuItem = __selectedMenuItems.some(item => {
          return item.key === node.key
        })
      }
      if(__selectedMenuItems.indexOf(node)==-1 && !isHasMenuItem){
        __selectedMenuItems.push(node);
      }
      if(!userIsLoggedIn) {

        localStorage.setItem('orderedBeforeLogin', true);
        localStorage.setItem('urlOrderBeforeLogin', window.location.href);

      }
      else {
        localStorage.setItem('orderedBeforeLogin', false);
        const currentUrl = window.location.href;
        const parsedUrl = new URL(currentUrl);
        parsedUrl.search = '';
        window.history.replaceState({}, document.title, parsedUrl.toString());
      }
      $('#buy-btn').trigger('click');
//this was hiding the addon popup firsttime even if no addon selected      $("#modal-order-item-addons").modal('hide')
    })
  $('#modal-order-item-addons').on('click','.rest-menuitems-addons',function(e){
      if (e.target.type == "number") return; // only process for radio/ checkbox case

      let id = $("#add-to-order").data('key');
      let node = $("#menuitemData_"+id).val();
      let addonSectionKey = $(e.target).data("parent-key");
      node = node ? JSON.parse(node) : null;
      let validation = validateMenu({ showPopupMessageOn: 'max-limit-error' }, {node, addonSectionKey})

      if(validation.type == 'max') {
        $(e.target).click();
        return true;
      }

      if(validation.valid) {
        $('.selected-items-errors').css({'display': 'none'})
        return modal_calculate_price()
      }
      if(validation.type == 'min') return true
      let input_type =  $(e.target).attr('type')
      if(input_type == 'radio') return true
    });
    $('#modal-order-item-addons').unbind('input');
    $('#modal-order-item-addons').on('input','input',function(e){
      let oldVal = $(e.target).data("old-value");
      let currentVal = $(e.target).val();

      let id = $("#add-to-order").data('key');
      let node = $("#menuitemData_"+id).val();
      let addonSectionKey = $(e.target).data("parent-key");
      node = node ? JSON.parse(node) : null;

      let validation = validateMenu({ showPopupMessageOn: 'max-limit-error' }, {node, addonSectionKey})
      if(validation.valid) {
        $('.selected-items-errors').css({'display': 'none'})
        return modal_calculate_price()
      }
      if(validation.type == 'min') return true;
      let input_type = $(this).attr('type')
      if(input_type == 'radio') return true
      if(input_type == 'checkbox') $(this).prop('checked', false)
      if(input_type == 'number') {
        let val = $(this).val()
        console.log("val====",val)
        $(this).val(val-1)
      }
    });

    $('#modal-order-item-addons').find('.decrease-number').unbind('click');
    $('#modal-order-item-addons').on('click','.decrease-number',function(){
      let inp = $('#modal-order-item-addons').find('.selected-item-quantity');
      let val = Number(inp.val()) - 1;
      if(val < 1) val = 1;
      inp.val(val);
      inp.trigger('input');
    });
    $('#modal-order-item-addons').keyup(function(e){
      if(e.keyCode == 8){
        let inp = $('#modal-order-item-addons').find('.selected-item-quantity');
        let val = Number(inp.val()) - 1;
        if(val < 1) val = 1;
        inp.val(val);
        inp.trigger('input');
      }
    }) 
 // Listen for the 'focusout' event on the #modal-order-item-addons element
$('#modal-order-item-addons').on("focusout", function() {
  let inp = $(this).find('.selected-item-quantity');
  let val = Number(inp.val());
  // Check if the parsed value is less than 1, and if so, set it to 1
  if (val < 1) {
      val = 1;
  }
  inp.val(val);
  inp.trigger('input');
})

  // .on( "blur", function() {
  //   blur++;
  //   $( "#blur-count" ).text( "blur fired: " + blur + "x" );
  // } ); 


  
    // $('#modal-order-item-addons').on('click','.decrease-number',function(){
    //   let inp = $('#modal-order-item-addons').find('.selected-item-quantity');
    //   alert("jjjj")
    //   let val = Number(inp.val()) - 1;
    //   console.log("hhh===",val)
    //   if(val < 1) val = 1;
    //   inp.val(val);
    //   inp.trigger('input');
    // });

    $('#modal-order-item-addons').find('.increase-number').unbind('click');
    $('#modal-order-item-addons').on('click','.increase-number',function(){
      let inp = $('#modal-order-item-addons').find('.selected-item-quantity');

      let val = Number(inp.val()) + 1;
      inp.val(val)
      inp.trigger('input');
    });


    $('#modal-order-item-addons').on('shown.bs.modal', function (e) {
      $('.modal-backdrop').last().css('z-index', 1048);
    });

    $('#modal-order-item-addons').on('hidden.bs.modal', function (e) {
      let spclInstInStorage = localStorage.getItem("specialInstructions2_")
      let spclInstrObj = JSON.parse(spclInstInStorage)
      if (spclInstInStorage && spclInstrObj && spclInstrObj.spclInstr && spclInstrObj.spclInstr != '') {
        localStorage.removeItem("specialInstructions2_")
      }
      __selectedMenuItems = []
    })


    $('#modal-order-item-addons').find('.modal-item-special-instructions').unbind('change');
    $('#modal-order-item-addons').on('change','.modal-item-special-instructions',function(){
      let item_id = $(this).data('id');
       specialInstructions = $(this).val().trim();
      $("#itemspecialinstr-"+ item_id).val(specialInstructions);
    })
    $('.free-food-pop-over').popover('hide');
    return $("#modal-order-item-addons").modal()

  }
  function modal_calculate_price(){
  console.log("in modal_calculate_price...");
    let inputs = $('#modal-order-item-addons').find('input');
    let root_item;
    let total_price = 0;
    $.each(inputs,function(index, item){
      if ($(item).hasClass("flavormatch-slider-class")) return;
      let key = $(item).data('key');
      let item_val = _.toNumber($(item).val()) || 0;
      if(!item_val && $(item).hasClass('item-input')) item_val = 0;
      $(item).val(item_val);
      $(`#itemqty-${key}`).val(item_val);

      if($(item).hasClass('item-input')) return root_item = $(item);
      switch ($(item).attr('type')){
        case 'radio':
        case 'checkbox':
          if($(item).prop('checked')){
            let item_price = $(item).data('price');
            total_price += item_price
            localStorage[`addonquantity-${key}`] = 1
          }else{
            localStorage.removeItem(`addonquantity-${key}`);
          }
          break;
        default:
            let item_price = _.toNumber($(item).data('price'));
            let item_value = _.toNumber($(item).val());
            total_price += item_price * item_value;
            if(item_value){
              localStorage[`addonquantity-${key}`] = item_value
            }else{
              localStorage.removeItem(`addonquantity-${key}`);
            }
          break;
      }
    })

    let number_item = _.toNumber(root_item.val());
    total_price += _.toNumber(root_item.data('price'))
    $('#modal-order-item-addons').find('.item-addons-retail-price').text('$'+parseFloat(total_price).toFixed(2));
    total_price *= number_item

    total_price = parseFloat(total_price).toFixed(2);
    let noSalesOnsystem = $('#rest-noSalesOnsystem').val();
    let latestOrderType = $('#rest-latestOrderType').val();
    if(noSalesOnsystem=='true' || (latestOrderType && latestOrderType=='only_rate')) {
      $('#modal-total-price-item').text('')
    } else {
      $('#modal-total-price-item').text('$ ' + total_price)
    }
    return;
  }
  

  function modal_calculate_price_edit(){
    let inputs = $("#modal-order-item-addons_edit").find('input');
   let root_item;
    let total_price = 0;
    $.each(inputs,function(index, item){
      if ($(item).hasClass("flavormatch-slider-class")) return;
      let key = $(item).data('key');
      let item_val = _.toNumber($(item).val()) || 0;
      if(!item_val && $(item).hasClass('item-input')) item_val = 1;
      $(item).val(item_val);
      $(`#itemqty-${key}`).val(item_val);

      if($(item).hasClass('item-input')) return root_item = $(item);
      switch ($(item).attr('type')){
        case 'radio':
        case 'checkbox':
          if($(item).prop('checked')){
            let item_price = $(item).data('price');
            total_price += item_price
            localStorage[`addonquantity-${key}`] = 1
            console.log("setting addonquantity to 1 for ...", key);
          }else{
            localStorage.removeItem(`addonquantity-${key}`);
            console.log("removing addonquantity to 1 for ...", key);
          }
          break;
        default:
            let item_price = _.toNumber($(item).data('price'));
            let item_value = _.toNumber($(item).val());
            total_price += item_price * item_value;
            if(item_value){
              localStorage[`addonquantity-${key}`] = item_value
              console.log("setting addonquantity for ...", key);
            }else{
              console.log("removing addonquantity for ...", key);
              localStorage.removeItem(`addonquantity-${key}`);
            }
          break;
      }
    })

    let number_item = _.toNumber(root_item.val());
    total_price += _.toNumber(root_item.data('price'))
    $('#modal-order-item-addons_edit').find('.item-addons-retail-price').text('$'+parseFloat(total_price).toFixed(2));
    total_price *= number_item
    total_price = parseFloat(total_price).toFixed(2);
    let noSalesOnsystem = $('#rest-noSalesOnsystem').val();
    let latestOrderType = $('#rest-latestOrderType').val();
    if(noSalesOnsystem=='true' || (latestOrderType && latestOrderType=='only_rate')) {
      $('#edit-item-order').text('')
    } else {
      $('#edit-item-order').text('Update $'+total_price);
    }
  }

      const _editItemBtnHandler = (e) => {
          e.preventDefault();
          const $thisItem = $(e.target);
          const $thisItemDetails = $thisItem.parent().parent();
          const cart_items_id = e.currentTarget.id.split('-')[1];
          const itemDetails = _.find(listItemInCart,(item)=>{
            return item.cart_items_id == cart_items_id;
          })
          modal_render_item_edit(itemDetails);
          $('#collapseMyOrder').modal('hide');
          // $('.order-this-item').focus();
      };

      function modal_render_item_edit(itemDetails){
        const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);
        let item_node = $('#menuitemData_'+itemDetails.item_id);
        if(!item_node) return;
        item_node=item_node.val();
        try{
          item_node = JSON.parse(item_node)
        }catch(err){
          console.log("error parsing node in modal_render_item_edit....",item_node, err)
        }
        ordered_item_key = item_node.key;
        let winwidth = $(window).width();
        let img_item_node = $('#carousel-menu-item-'+item_node.key+' .carousel-inner .carousel-item img').attr('src');
        var total__price = '';
        var addon_total__price = '';
        if(itemDetails && itemDetails.quantity > 1 && item_node && item_node.price){
          total__price = '$'+ parseFloat(item_node.price*itemDetails.quantity).toFixed(2);
        }else if(item_node && item_node.isMenuAddon && itemDetails && itemDetails.quantity){
          let addons = itemDetails.addons && itemDetails.addons.map(addon => {
            let addon_total = 0;
            addon_total += addon.addon_extended_price;
            addon_total__price = '$'+ parseFloat(addon_total * itemDetails.quantity).toFixed(2);
          })
        }
        let html = `
          <div id="modal-order-item-addons_edit" class="modal fade" role="dialog" style="${winwidth<480?'height:100%;':''};z-index:9999">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content" style="${winwidth>480?'padding: 20px;':''} max-height: 100vh; overflow-y: auto;">
                  <a class="close mt-3 mt-md-0 mb-3" data-dismiss="modal" style="background-color: transparent;${winwidth<480?'padding:0px 20px;':''}">
                    <i class="fa fa-times " aria-hidden="true" ></i>
                  </a>
                <div class="modal-body scrollModal" style="${winwidth<480?'width:100%':''};">
                <div class="row mb-5 mb-md-3" style=${winwidth<480?'margin-left:10px!important;':''}>
                  <div class="col-md-5" id="res-parentmenuimage" style="${winwidth<480?'padding:0px;':''}">
                  <div id="res-menuimage" class="images-for-item-${item_node.key} aspect-ratio-box" style="${winwidth<480?'padding-top:0px !important;':''}">
                  <div class="aspect-ratio-box-inside">
                    <img style="${winwidth<480?'width:131px !important;height:131px':'min-height:100%;height:100%'}; width: 100%; object-fit: cover;" src="${img_item_node?img_item_node:'/images/tempfoodnotext.png'}"/>
                  </div>
                </div>
                  </div>
                  <div class="col-md-7 pt-md-0 pl-md-0 res-details" style="${winwidth<480?'margin-left:130px; padding-right:9px; min-height: 131px':''}">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="selected-item-name" data-item-key="${item_node.key}" style="color:#000">${item_node.title}</div>
                      </div>
                      <div class="col-md-4">
                        <span class="item-addons-retail-price" style="color:#000"> $${parseFloat(item_node.price || 0).toFixed(2)} </span>
                      </div>
                      <div class="col-md-8 ratings" >
                        </div>
                      <div class="col-md-4">

                      </div>

                      <div class="col-md-12 orderinformation">
                        ${item_node && item_node.description ? item_node.description : '' }
                      </div>
                    </div>
                  </div>
                </div>
                  ${modal_render_addons_edit(item_node.children,item_node,itemDetails)}
                  <div class="form-row pt-3 mb-3">
                    <p class="instructionsheading"> Special Instructions</p>
                    <textarea data-id="${item_node.key}" class="modal-item-special-instructions" rows="3" placeholder="Enter any special instructions for this item...">${itemDetails.special_instructions}</textarea>
                  </div>
                  <div class="form-row selected-items-errors">
                  </div>
                </div>
                <div class="modal-footer">
                    <div class="form-inline justify-content-between w-100">
                      <div class="d-flex mt-1">
                        <div class="mr-1 mt-1" style="font-weight: bold;">Quantity</div>
                        <div class="input-group">
                            <div class="input-group-append">
                              <a href="javascript:void(0)" class="input-group-text decrease-number form-control" style="border-right: none"><i class="fa fa-minus green"></i></a>
                            </div>
                            <input id="modal-node-${item_node.key}" data-key="${item_node.key}" type="text" class="form-control form-control-sm text-center item-input selected-item-quantity" value="${itemDetails.quantity}" data-price="${item_node.price}" style="font-size: 14px; max-width:40px; border-left: none; border-right: none">
                            <div class="input-group-append">
                              <a href="javascript:void(0)" class="input-group-text increase-number form-control" style="border-left: none"><i class="fa fa-plus green"></i></a>
                            </div>
                        </div>
                      </div>
                      <button id="edit-item-order" type="button" class="btn btn-success text-white d-flex mt-1" data-key="${item_node.key}" style="font-size: 20px; border-radius: 5px; min-width: 100px" >
                          Update
                        <span id="modal-total-price-item pl-1" style="font-weight: bold; white-space: nowrap;">${item_node.isMenuAddon ? addon_total__price : total__price}</span>
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        `;
        $('.modal-order-component').html(html);
        // $(".ratings").html(getRatingHTML(item_node.rating,item_node.reviews,5,false,item_node.key,item_node.rating,item_node.reviews,item_node.upc,item_node.sku));
        // // this timeout because for getting the image and appned takes time and we have to hide a div if no image
        // setTimeout(()=>{
        //   console.log("$('#res-menuimage').children().length====",$('#res-menuimage').children().length)
        //   if($('#res-menuimage').children().length>0){
        //     $("#res-parentmenuimage").css('display','block');
        //     $("#res-menuimage .carousel-image").css("height", 'auto');
        //     $("#res-menuimage .carousel-image").css('width','auto');
        //     $("#res-menuimage .carousel-image").css('max-width','200px');
        //   }
        // },3000);
        // $('#modal-order-item-addons').find('#add-to-order').unbind('click');
        $('#modal-order-item-addons_edit').on('click','#edit-item-order',function(){
            let listitem = [];
            let quantity = Number($('#modal-order-item-addons_edit').find('.selected-item-quantity').val());
            let spclInst = $('#modal-order-item-addons_edit').find('.modal-item-special-instructions').val();
            console.log("edited spclInst=====", spclInst)
            let item = {
              cart_items_id : itemDetails.cart_items_id,
              item_id: itemDetails.item_id,
              item_name: itemDetails.item_name,
              item_type: "item",
              menuType: 2,
              parent_item_id: null,
              quantity: quantity,
              rating: itemDetails.rating,
              retail_price: itemDetails.retail_price,
              cart_type : itemDetails.cart_type,
              group_id : itemDetails.group_id,
              sku: itemDetails.sku,
              special_instructions: spclInst,
              upc: itemDetails.upc,
              menu_name: itemDetails.menu_name,
              section_name: itemDetails.section_name
            };
            listitem.push(item);


            // add addon
            let inp = $("#modal-order-item-addons_edit .modal-show-seleted-addons").find('input');
            inp.each(function(){
              let addon_quantity = 0;
              let inp_type = $(this).attr('type')
              if(_.includes(['radio','checkbox'],inp_type)){
                addon_quantity = Number($(this).prop('checked'));
              } else{
                addon_quantity = Number($(this).val());
              }

              if(addon_quantity > 0) {
                let data = item_node.children;
                let key = $(this).data('key')

                let addon = null;
                item_node.children.forEach(children => {
                  if (!addon){
                    addon = _.find(children.children,(itemAddon)=>{
                      return itemAddon.key == key;
                    })
                  }
                });

                let addonData = {
                  item_id :key,
                  parent_item_id: itemDetails.item_id,
                  item_type: 'addon',
                  item_name: itemDetails.item_name,
                  rating: itemDetails.rating,
                  quantity: itemDetails.quantity,
                  retail_price: itemDetails.retail_price,
                  menu_name: itemDetails.menu_name,
                  section_name: itemDetails.section_name,
                  menuType: itemDetails.menuType,
                  group_id : itemDetails.group_id,
                  cart_type : itemDetails.cart_type,
                  addon_name: addon ? addon.title :'',
                  addon_rating: addon ? addon.rating :0,
                  addon_quantity: addon_quantity,
                  addon_price: (addon && addon.price && addon.price !== "-") ? addon.price : 0,
                  special_instructions: addon ? addon.special_instructions :''
                };
                listitem.push(addonData);
              }
            });
           loadingOrderAnimation('show');
            editItemsOrder(itemDetails.order_id,listitem).then((response) =>{
              if(response.status == 200) {
                $('#modal-order-item-addons_edit').modal('hide');
                ShoppingCart.loadCurrentCartItems({scrollToMyOrder : false}).finally(() => {
                  loadingOrderAnimation('hide');
                });
              } else {
                Swal.fire('Unable to update item !', '', 'error');
                loadingOrderAnimation('hide');
              }
            })
          });

          $('#modal-order-item-addons_edit').on('click','.rest-menuitems-addons-edit',function(){
            $(this).find('input:radio').prop('checked', true)
            let validation = validateMenu();
             if(validation.valid) {
              $('.selected-items-errors').css({'display': 'none'})
              return modal_calculate_price_edit()
            }
            if(validation.type == 'min') return true
            let input_type = $(this).find('input:radio').attr('type')
            if(input_type == 'radio') return true
            if(input_type == 'checkbox') $(this).prop('checked', false)

            let addon_key = $(this).data('key');
            
            let message = `Please select ${validation.max} in maximum of total add-ons`
            $(`#modal-add-on-error-${addon_key}`).text(message).css({'display': ''})
            setTimeout(()=>{
              $(`#modal-add-on-error-${addon_key}`).css({'display': 'none'})

            }, 3000)
          });

          $('#modal-order-item-addons_edit').on('shown.bs.modal', function (e) {
            $('.modal-backdrop').last().css('z-index', 1048);
            window.body_scroll_pos = $(window).scrollTop();
            $(window).css('overflow-y','hidden');
            // $('#modal-order-item-addons_edit').find('.modal-content').css('max-height','85vh');
          });
          $('#modal-order-item-addons').on('hidden.bs.modal', function (e) {
            $(window).css('overflow-y','scroll');
            $(window).scrollTop(window.body_scroll_pos);
          });

          $('#modal-order-item-addons_edit').unbind('input');
          $('#modal-order-item-addons_edit').on('input','input',function(){
            let validation = validateMenu()
            if(validation.valid) {
              $('.selected-items-errors').css({'display': 'none'})
              return modal_calculate_price_edit()
            }
            if(validation.type == 'min') return true
            let input_type = $(this).attr('type')
            if(input_type == 'radio') return true
            if(input_type == 'checkbox') $(this).prop('checked', false)
            if(input_type == 'number') {
              let val = $(this).val()
              $(this).val(val-1)
            }

            
            let addon_key = $(this).data('key')
            // let message = `Please select ${validation.max} in maximum of total add-ons`
            // $(`#modal-add-on-error-${addon_key}`).text(message).css({'display': ''})
            // setTimeout(()=>{
            //   $(`#modal-add-on-error-${addon_key}`).css({'display': 'none'})

            // }, 3000)
          });

          $('#modal-order-item-addons_edit').find('.decrease-number').unbind('click');
          $('#modal-order-item-addons_edit').on('click','.decrease-number',function(){
            let inp = $('#modal-order-item-addons_edit').find('.selected-item-quantity');
            let val = Number(inp.val()) - 1;
            console.log("hhh===",val)
            if(val < 1) val = 1;
            inp.val(val);
            inp.trigger('input');
          });

          $('#modal-order-item-addons_edit').find('.increase-number').unbind('click');
          $('#modal-order-item-addons_edit').on('click','.increase-number',function(){
            let inp = $('#modal-order-item-addons_edit').find('.selected-item-quantity');
            inp.val(Number(inp.val()) + 1)
            itemDetails.quantity = Number(inp.val()) + 1;
            inp.trigger('input');
          });


          $('#modal-order-item-addons_edit').find('.modal-item-special-instructions').unbind('change');
          $('#modal-order-item-addons_edit').on('change','.modal-item-special-instructions',function(){
            let item_id = $(this).data('id');
            let specialInstructions = $(this).val().trim();
            $("#itemspecialinstr-"+ item_id).val(specialInstructions);
          })
          $('.free-food-pop-over').popover('hide');
          return $("#modal-order-item-addons_edit").modal()
    }


    function modal_render_addons_edit(nodeInfo = [], node,itemDetails1){
      const itemDetails =itemDetails1
      let html = '';
      _.forEach(nodeInfo,(item)=>{
        item.parent = node;
        let addons_lv1 = item.parentkey == ordered_item_key;
        let _time = (new Date()).getTime();

        let addon = null
        if(itemDetails && itemDetails.addons)
        addon = _.find(itemDetails.addons,(itemAddon)=>{
          return itemAddon.item_id == item.key;
        })

        if(!_.size(item.children)){
          html += `
            <div class="form-group rest-menuitems-addons-edit" style="border-bottom: 1px solid #f2e2e2;">
              <div class="form-row">
                <div class="col-6">
                    <p class="" style="font-size: 20px;">${item.title}<p>
                </div>
                <div class="col-3" style="font-size: 23px;">
                  $${parseFloat(item.price || 0).toFixed(2)}
                </div>
                <div class="col-3 text-right">
                    ${modal_render_input_edit(item,addon)}
                </div>
              </div>
              <div id="modal-add-on-error-${item.key}" class="alert alert-danger" role="alert" style="display: none">
              </div>
            </div>
          `;
          return true;
        }
        let cond = `${item.min || 0}`;
        if(item.max){
          if(item.max != item.min) cond += ` - ${item.max}`
        }else{
          cond += ' - many'
        }
        html += `
        <a href="javascript:void(0)" class="form-row form-group form-inline" data-toggle="collapse" data-target="#modal-${item.key}" aria-expanded="true">
          <i id="icon-fav" class="modal-icon-${item.key}-${_time} fa fa-plus mr-2" style="color: green !important; ${addons_lv1 ? 'font-size: 23px;' : 'font-size: 20px;'}"></i>
          <span class="mr-1" style="color: green; ${addons_lv1 ? 'font-size: 23px; font-weight: bold' : 'font-size: 20px;'}"> ${item.title}</span>
          <span style="font-size: 20px; font-style: italic"> (Select ${cond})</span>
        </a>
        <div id="modal-${item.key}" data-item-id="${item.key}-${_time}" class="modal-show-seleted-addons collapse in show w-100 pl-2 pr-3" aria-expanded="true">
            ${modal_render_addons_edit(item.children, item,itemDetails1)}
        </div>
      `;
      })
      return html;
    }

      function modal_render_input_edit(node,addon){
          let parent = node.parent;
          let data = parent; //redesign .data;
          let html = ``;
          if(data.max == 1){
              if(data.min == 1){
                  html = `
                    <label style="margin-top: 1px; visibility:visible; -ms-transform: scale(2);-moz-transform: scale(2); -webkit-transform: scale(2); -o-transform: scale(2);">
                        <input id="modal-node-${node.key}" data-parent-key="${parent.key}"  data-key="${node.key}" type="radio" name="modal-radio-${parent.key}"  value="0" data-price="${node.price || 0}" ${addon && addon.item_id  ==  node.key? "checked": ''}>
                        <span class="label-text" style="font-size: 1.2rem !important; color: mediumseagreen"></span>
                    </label>
                  `;
              }else{
                  html = `
                  <label style="margin-top: 1px; visibility:visible; -ms-transform: scale(2);-moz-transform: scale(2); -webkit-transform: scale(2); -o-transform: scale(2);">
                        <input id="modal-node-${node.key}"  data-key="${node.key}" type="checkbox" name="modal-checkbox-${parent.key}" data-parent-key="${parent.key}" value="0" data-price="${node.price || 0}" ${addon && addon.item_id  ==  node.key? "checked": ''}>
                        <span class="label-text" style="font-size: 1.2rem !important; color: mediumseagreen"></span>
                    </label>
                 `;
              }
          }else{
            if(node.max && node.max == 1){
              html = `
                  <label style="margin-top: 1px; visibility:visible; -ms-transform: scale(2);-moz-transform: scale(2); -webkit-transform: scale(2); -o-transform: scale(2);">
                        <input id="modal-node-${node.key}" data-parent-key="${parent.key}"  data-key="${node.key}" type="checkbox" name="modal-checkbox-${parent.key}" value="0" data-price="${node.price || 0}" ${addon && addon.item_id  ==  node.key? "checked": ''}>
                        <span class="label-text" style="font-size: 1.2rem !important; color: mediumseagreen"></span>
                    </label>
                 `;
            } else {
              html = `
                <input id="modal-node-${node.key}" type="number" min="0" max="${data.max}" data-key="${node.key}" data-parent-key="${parent.key}" class="form-control" data-price="${node.price || 0}" >
              `;
            }
          }
          setTimeout(function(){
           
            if(addon && addon.item_id  ==  node.key){
              $("#modal-node-"+node.key).trigger('click');
              
              $("#modal-node-"+node.key).prop("checked", true);

      
              let value=addon && addon.addon_quantity ? addon.addon_quantity : ''
              console.log("value===",value)
              $("#modal-node-"+node.key).val(value);
            }

             $("#modal-node-"+node.key).click(function(e){
              console.log("hhh====",e.target.checked)
              if(e.target.checked) {
                console.log("if ====")
                $("#modal-node-"+node.key).prop("checked", true);
              }else{
                console.log("else ====")
                $("#modal-node-"+node.key).prop("checked", false);
              }
              
            });
         },100)
          return html;

        }

      $('#edit-order-name').customTextEditTrigger({
        selector: '#order-name-title',
        dataObject: {
        	orderName : 'Your Order',
        },
        inputHTML: {
          orderName: '<input type="text" class="form-control" style="display:inline;"/>',
        },
        editHTML: 'CHANGE',
        doneHTML: 'DONE',
        formatter: function( data ){
        	return Object.values(data).join(', ')
        },
        onChange: function( data ){
          if (!data._dataChanged) return;
          let orderName = data['orderName'];
          if(orderName) orderName = orderName.trim();
          const $container       = $('#edit-order-name');
          const currentOrderName = $container.data('order-name');
          const orderId          = Order.getOrderDetail('orderId');

          if (!orderName) orderName = currentOrderName;
          let orderData = {
            orderId : orderId,
            updateFields: {
              order_name : orderName,
            }
          };
          return $.ajax({
            url: '/orders/update',
            type: 'POST',
            data: JSON.stringify(orderData),
            contentType: 'application/json',
            dataType: 'json',
            error: function(err) {
              Swal.fire('Unable to change order name', 'Please Try Again!', 'error');
            },
            success: function(res) {
              if (res.data.changedRows > 0){
                // $('.order-tree').find('span.order-name').text(orderName);
                // $(".edit-order-name").data('order-name',orderName);
                Swal.fire('Updated Successfully', '', 'success');
              } else {
                Swal.fire('Unable to change order name', 'Please Try Again!', 'error');
              }
            }
          })
        },
      });

    var joined_listOfNodeKeys = {};
    function joined_getSelectedKeysOfNode(listOfKeys){
      _.forEach(listOfKeys,(item)=>{
          if(joined_listOfNodeKeys[item]) return
          joined_listOfNodeKeys[item] = true;
//redesign          let parentSource = tree_source.getNodeByKey(item);
          let parentSource = $("#menuitemData_"+item).val();
          while(parentSource){
            joined_listOfNodeKeys[parentSource.key] = true;
            parentSource = parentSource.parent
          }
      })
    }
    function joined_render_cart(items){
      let html = '';
      _.forEach(items,(item, key)=>{
          if(item.item_type != 'item') return true;
          let elements = _.filter(items,(ele)=>{
            return ele.group_id == item.group_id;
          });
//redesign          let node = tree_source.getNodeByKey(item.item_id);
      let totalPrice = 0;
      _.forEach(elements, item => {
        if(item.item_type === "item" && item.extended_price > 0){
          totalPrice += item.extended_price
        }
        if(item.item_type === "addon" && item.addon_price > 0){
          totalPrice += item.addon_price
        }
      })
          let node = $("#menuitemData_"+item.item_id).val();
          try{
            node = JSON.parse(node)
          }catch(err){
            node = {}
          }
          let listOfKeys = _.map(elements,(ele)=>{return ele.item_id});
          joined_getSelectedKeysOfNode(listOfKeys)
          html += joined_render_addons([node], joined_listOfNodeKeys, item, totalPrice);
      })
      return html;
    }

    function joined_render_addons(nodeInfo = [], nodeKeys = false, joinedItem = null, totalPrice = 0){
      let html = '';
      _.forEach(nodeInfo,(item)=>{
        if(nodeKeys && !nodeKeys[item.key]) return true;
        let _time = (new Date()).getTime();
        let rateDiv = ""
          if(joinedItem.starRating && joinedItem.starRating > 0){
            rateDiv += `<div class="cart-stars-container"><div class="cart-item-stars" data-id="citem-stars-${joinedItem.key}" data-rating="${joinedItem.starRating}" data-rateyo-rating="${joinedItem.starRating}"></div></div>`
          }
          else if(item.rating && item.rating > 0){
            rateDiv += `<div class="cart-stars-container"><div class="cart-item-stars" data-id="citem-stars-${item.key}" data-rating="${item.rating}" data-rateyo-rating="${item.rating}"></div></div>`
          }
        if(!_.size(item.children)){
          html += `
              <div class="px-0 py-1 w-100">
              <div class="d-flex justify-content-between">
                <p id="joined-${item.key}" style="display: inline-block; color: green; margin-bottom: 0"><span class="glyphicon glyphicon-stop"></span> ${item.title}</p>
                <div class="d-flex justify-content-between">
                <div>
                  ${rateDiv || ""}
                  </div>
                  <div style=${item.rating > 0 ? "": "margin-right:-5px"}>
                  <div class="joined-addon-price" style="color: black !important; min-width: 55px; text-align:right;">$${parseFloat(totalPrice || item.priceDisplay || 0).toFixed(2)}</div>
                  </div>
                  </div>
                </div>
              </div>
          `;
          return true;
        }
        html += `
        <a href="javascript:void(0)" class="w-100 d-block px-0 py-1" data-toggle="collapse" data-target="#joined-${item.key}" aria-expanded="true">
          <div class="d-flex justify-content-between">
          <div style="display: inherit">
            <span class="joined-icon-${item.key}-${_time} glyphicon glyphicon-menu-down" style="display: inline-block; color: green !important; font-size: 15px;"></span>
            <div style="color: green"> ${item.title}</div>
          </div>
          <div>
          ${item.type == 'item' ?
            `<div class="d-flex justify-content-between" ${item.rating > 0 ? "width: 180px" : ""}>
            ${rateDiv || ""}
            <div style="margin-right:-5px">
              <div class="joined-addon-price" style="color: black !important; min-width: 55px; text-align:right">$${parseFloat(totalPrice || item.priceDisplay || 0).toFixed(2)}</div>
            </div>
            </div>`
          : '' }
          </div>
          </div>
        </a>
        <div id="joined-${item.key}" data-item-id="${item.key}-${_time}" class="joined-show-seleted-addons collapse in row col-sm-11 col-sm-offset-1 col-md-11 col-md-offset-0" aria-expanded="true">
          <div style="padding-left: 10px">
            ${joined_render_addons(item.children, nodeKeys)}
          </div>
        </div>
      `;
      })
      return html;
    }
    //

    /**
     * Render items to the items list container on DOM
     * @param {Array} items
     */
     const renderItems = (items) => {
        // Empty container
        let isAllOrderFriendPaid = 0;
        let statusIsPaid= `<span class="badge" style="background-color: #E6F3EC; color: #02843D !important;border-radius: 10px;">Paid </span>`;
        let statusIsOrdring = `<span class="badge" style="background-color:#FFF3CE ; color: #4A4A4A !important;border-radius: 10px;">Ordering</span>`
        let statusFriendIsOrdring = `<span class="badge"  id="statusFriendIsOrdring" style="background-color:#FFF3CE ; color: #4A4A4A !important;border-radius: 10px;">Ordering</span>`

        let alcoholDeliveryAllowed = $("#alcohol-delivery-allowed").val();
        let userCartOrder = items.userCartOrder;
        let userCartItems  = userCartOrder.userCartItems;
        let joinedCartOrders  = items.joinedCartOrders;
        let latestOrderType = $('#rest-latestOrderType').val();
        console.log("latestOrderType=======", latestOrderType)
        console.log("$NO_SALES_ON_THE_SYSTEM=======", $NO_SALES_ON_THE_SYSTEM.val())

        var creditvalue =[];
        $itemsListContainer.empty();
        TOTAL_CART_ITEMS = 0; //reset total count
        const $itemsList = $('<ul/>', {
            id: 'cart-items-list'
        });

        let arrayItems = []
        userCartItems = _.orderBy(userCartItems,['updated_date'],['desc']);
        let paid_items = _.remove(userCartItems,(item)=>{
          return item.payment_id
        });
        userCartItems = _.concat(userCartItems,paid_items);
        userCartItems.forEach((item) => {
          let push = true;
          if(arrayItems && arrayItems.length) {

            if(typeof(arrayItems)=="object"){

              var array = $.map(arrayItems, function(value, index){
                return [value];
              });
              if(array.length>0){
                array.forEach((i) => {
                if(i.item_id == item.item_id && i.addons && item.addons && i.addons.length == item.addons.length) {

                  item.addons.forEach((ad) => {
                    push = (push && !i.addons.find((add) => add.item_id == ad.item_id));
                  })
                  if(!push) {

                    i.quantity = i.quantity + item.quantity
                  }
                }
              })
              }

            }else{
              arrayItems.forEach((i) => {
                if(i.item_id == item.item_id && i.addons && item.addons && i.addons.length == item.addons.length) {

                  item.addons.forEach((ad) => {
                    push = (push && !i.addons.find((add) => add.item_id == ad.item_id));
                  })
                  if(!push) {

                    i.quantity = i.quantity + item.quantity
                  }
                }
              })
            }


            if(push) {
              arrayItems.push(item);
            }
          } else {
            arrayItems.push(item);
          }
        })
        hasAlcoholItems = false;
        $('[data-toggle="tooltip"]').tooltip();
        
        var val=0;
        var spitval ='';
        var sum = 0;
        arrayItems.forEach((item) => {
          if(!item.item_id) return
          if(item.alcohol) hasAlcoholItems = true;
          listItemInCart = arrayItems ;
          let $itemLi = $("<li/>").css({'min-height': '30px'});
          //Set item price to fixed 2 decimals for display
          const priceAddons = totalPriceAddon(item);
          const itemExtendedPrice = (Number(item.extended_price) + priceAddons*item.quantity).toFixed(2);

          //EDit item
          const editItemBtn = $('<a/>', {
            id: `eitem-${item.cart_items_id}-${item.group_id || ''}`,
            click: _editItemBtnHandler,
            class: '',
          }).append('Edit');
          editItemBtn.css({'cursor': 'pointer', 'margin-right' : '10px', 'color': 'grey'});

          let $actions = $('<div class="col-12">')

          if (!item.payment_id){
            $actions.append(editItemBtn);
          }
          // if (!item.payment_id){
          const $deleteBtn = $('<a/>', {
            id:`citem-${item.item_id}-${item.group_id || ''}`,
            'data-paid': item.payment_id || '',
            'data-item': item.cart_items_id || '',
            'data-order-id': item.order_id || '',
            'data-suborder-id': item.suborder_id || '',
            click: _removeItemBtnHandler,
            class: '',
          }).append('Remove');
          $deleteBtn.css({'cursor' : 'pointer','color': 'grey'});
          //Append reove item btn
          $actions.append($deleteBtn);
          val=$(`.discount_right_rate_`+item.item_id+` span`).text();
          spitval=val.split("$");
          
          if(typeof(spitval[1])!='undefined'){
            console.log("val==1=",spitval[1])
            creditvalue.push(spitval[1]);
          }
          

          
          // }
          let htmlAddon = '';
          if(item.addons && item.addons.length > 0){
            console.log("items======>",item.addons);
            let addonsArr = item.addons.sort((a, b) => a.cart_items_id - b.cart_items_id);
            addonsArr.forEach((addon) => {
              htmlAddon += `<p class="wrapper-item-addon" style="
              margin-left: 20px;
              left: 30px;
              font-size: 10px !important;
          "><span class="addon-quantity mr-1" style="
              font-size: 15px;
          ">${addon.addon_quantity  != 1 ? addon.addon_quantity : ''}</span> <span class="addon-name" style="margin-left: 3px;white-space: break-spaces;font-size: 15px;">${addon.addon_name}</span></p>`;
            })
          }
          let cartItemName = '';
          if(item.alcohol==true && userCartOrder.orderType=="delivery" && alcoholDeliveryAllowed!= 'true'){
            cartItemName = `<p class="wrapper-item-info"><span class="item-quantity mr-1">${item.quantity}</span> <span class="item-name red" style="margin-left: 3px;white-space: break-spaces; color: green; font-size: 16px"  data-toggle="tooltip" data-placement="top" title="Contains alcohol, remove or change order type.">${item.item_name}</span></p>`;
          }else{
            cartItemName = `<p class="wrapper-item-info"><span class="item-quantity mr-1">${item.quantity}</span> <span class="item-name" style="margin-left: 3px;white-space: break-spaces; color: green; font-size: 16px">${item.item_name}</span></p>`;
          }
          let rateDiv = "";
          let currentRating = $(`#mainRatingDiv-${item.item_id} .highest-rated-items-ratebox .rate-heart-plate`).data('rating');
          if(item.starRating && item.starRating > 0){
            rateDiv +=  `<div class="cart-stars-container"><div class="cart-item-stars" data-id="citem-stars-${item.key}" data-rating="${item.starRating}" data-rateyo-rating="${item.starRating}"></div></div>`
          }
          else {
            if(currentRating && currentRating > 0){
              rateDiv +=  `<div class="cart-stars-container"><div class="cart-item-stars" data-id="citem-stars-${item.key}" data-rating="${currentRating}" data-rateyo-rating="${currentRating}"></div></div>`
            }
          }
          //Generate item details
          let $itemDetails = $(`
              <div class="flex-row-cart cart-item-details row">
                  <span class="parent_item_id hidden">${item.parent_item_id ? item.parent_item_id : ''}</span>
                  <div class="d-flex justify-content-between" style="width:98%; z-index:0">
                    <div class="px-0" style="text-align:left;">
                      ${cartItemName}
                      ${htmlAddon}
                    </div>
                    <div>
                    <div class="d-flex">
                      ${rateDiv || ""}
                    <div class="px-0" style="text-align:right;">
                    ${$NO_SALES_ON_THE_SYSTEM.val() =="true" || (latestOrderType && latestOrderType=='only_rate') ? `` :
                      `<p style="${`min-width: 55px; ${$(window).width() <= 424 && "margin-right: -15px;"}`}">$<span data-unit-price="${item.retail_price}" class="item-price" style="font-size:16px">${itemExtendedPrice}</span> </p>`}
                    </div>
                    </div>
                  </div>
              </div>
          `)
          .append($actions)

          let $itemInstruction = $(`
            <div style="margin-left: 30px;">
            ${renderAddon(item)}
            </div>

            <fieldset id="instructionDiv-${item.item_id}-${item.group_id || ''}" class="fieldset fieldset-small" style="margin-bottom:10px; display:none; margin-left: 30px;border:0px; ">
               <legend align="left">Menu Item Special Instructions</legend>
               <span class="black">${item.special_instructions || ""}</span>
             </fieldset>
          `);

          $itemLi.append($itemDetails);
          if (item.payment_id){
            $itemLi.append($(`<label class="badge yourItemPaid" style="background-color: #E6F3EC; color: #02843D !important;border-radius: 10px;">Paid</label>`))
          }
          $itemLi.append($itemInstruction);
          $itemsList.append($itemLi);
          $itemsList.append(`<div class="mt-1 mb-3" style="border-top: 1px lightgrey solid"></div>`);
          TOTAL_CART_ITEMS += 1;
        });
        
        
        
        if(creditvalue && creditvalue.length>0){
        sum +=  creditvalue.reduce((x, y) => Number(x) + Number(y));
        }
        console.log("sum==11=1=",sum)
        renderEligibleCreditLabel(parseFloat(sum).toFixed(2),0)
        let joinedItemList = `<div class="form-check position-rel joined-orders">`;
        let _joIndex = 0;
        let isPaidJoinOrder = true;
        $.each(joinedCartOrders, function(name, items) {
          if(items.isPaid = 0){
            isAllOrderFriendPaid +=0
          } else{
            isAllOrderFriendPaid +=1
          }
          let userShortName = '';
          let arr = _.split(name,' ');
          if(_.size(arr)< 2){
            userShortName = name;
          }else{
            userShortName = `${arr[0]} ${arr[1].substring(0,1)}.`
          }
          if(!items[0].isPaid){
            isPaidJoinOrder = false;
          }
          joinedItemList += `
              <div class="mb-2 d-flex justify-content-between align-items-center" style="margin-right: -15px">
                <a href="#collapse_joined_order_${_joIndex}" data-toggle="collapse" class="title-handle collapsed">
                    <span>${userShortName}</span>
                    ${(items.length > 0 && items[0].isPaid) ? statusIsPaid : statusFriendIsOrdring }
                </a>
                ${ (items.length > 0 && !userCartOrder.parentId)  ?
                  `<button class="btn remove-from-order" style="background-color: white; color: red" data-user-id="${items[0].user_id}" data-order-id="${items[0].order_id}" data-order-type="${userCartOrder.orderType}" data-parent-name="${userCartOrder.orderName}" data-parent-id="${items[0].parent_order_id}" ><span><img src="/images/icon-remove-from-order.svg" style="height: 18px;"></span>Remove from Order</button>`
                  : ''
                }
              </div>
                <div id="collapse_joined_order_${_joIndex}" class="collapse"  style="margin-left: 27px; margin-top: 10px;">
                  ${joined_render_cart(items)}
                </div>`;

          _joIndex++;

        });
        if(userCartOrder.orderType == 'order-ahead' && userCartOrder.isPaid && !isPaidJoinOrder ){
          let html = `<h1 class="black text-center">Waiting for your friends...</h1>
                      <div class="text-center">
                        <p class="black">Other people in your order still haven't submitted their orders yet.</p>
                        <p class="black">When they do you will move to the next screen.</p>
                      </div>`;
          $('#waiting_order_ahead').html(html);
        }else{
          $('#waiting_order_ahead').html('');
        }
        joinedItemList += '</div>';

        let my_order = _.find(userCartOrder.userCartItems,(item)=>{
          return item.user_id == USER_ID;
        })

        let isJoinedOrder = Object.entries(joinedCartOrders).length !== 0;
        let orderHTML = `<div id="cart-details" class="form-check position-rel order-tree">
                            ${
                              isJoinedOrder ?
                                `<a href="#collapse_your_order" data-toggle="collapse" class="title-handle black">
                                      <span>Your items</span>
                                          ${(userCartOrder.isPaid) ? statusIsPaid : statusIsOrdring }
                                      </span>
                                  </a>
                                  ${!userCartOrder.parentId ? '':
                                  `<button type="button" id="separate-from-order" data-order-id="${my_order.order_id}" data-parent-id="${userCartOrder.parentId}" data-order-name="${userCartOrder.user}" class="btn btn-warning d-none btn-sm" style="background-color: rgb(238, 162, 54) !important; font-size: 15px;">Separate from order</button>`
                                  }
                                  <div id="collapse_your_order" class="collapse in show"  style="margin-top: 10px;">
                                  </div>
                                `
                              :
                              ''}
                          </div>`;
        $itemsListContainer.append(orderHTML);
        // add button split order and pay after have anyone joined
        let $orderList = $(`#cart-details`);
        if (!isJoinedOrder){
          $orderList.append($itemsList);
          if($('#wrap-btn-split-pay').length){
            $("#wrap-btn-split-pay").hide();
          }
        } else {
          let $userCartItemsDiv = $(`#collapse_your_order`);

          $userCartItemsDiv.append($itemsList);
          $orderList.prepend(joinedItemList);
          if( $('#wrap-btn-split-pay').length === 0){
            $("#wrap-btn-cancel-order").after(`<div id="wrap-btn-split-pay" class="flex-grow-1" style="margin-left: 5px;">
              <button id="split-and-pay" data-title="Split and" class="btn btn-danger btn-block ">Split Order & Pay </button>
            </div>`)
            $("#split-and-pay").click(async function(){
              let data = {};
              data.userId = my_order && my_order.user_id || '';
              data.orderId = my_order &&  my_order.order_id || '';
              data.orderType = userCartOrder &&  userCartOrder.orderType || '';
              data.parentName = userCartOrder &&  userCartOrder.orderName || '';
              data.parentId = userCartOrder &&  userCartOrder.parentId;
              data.mySelf = true;

              await $.promisedAjax({
                url: `/orders/removefromorder`,
                type: 'POST',
                data: JSON.stringify(data),
                contentType: 'application/json',
                dataType: 'json'
              }).then(async (res) => {
                await checkForInCartOrder();
                await loadCurrentCartItems();
                $("#wrap-btn-split-pay").hide();
                toastr.success("Separate successfully");
                $('#waiting_order_ahead').html('');
              })
              .catch((err) => {
                toastr.error("Separate failed");
              })
              $("#create-paypal-order").trigger("click");
            })
          } else {
            $("#wrap-btn-split-pay").show();
          }
        }
        $('.remove-from-order').on('click',function(){
          let data = {};
          data.userId = $(this).data('user-id');
          data.orderId = $(this).data('order-id');
          data.orderType = $(this).data('order-type');
          data.parentName = $(this).data('parent-name');
          data.parentId = $(this).data('parent-id');
          return $.promisedAjax({
            url: `/orders/removefromorder`,
            type: 'POST',
            data: JSON.stringify(data),
            contentType: 'application/json',
            dataType: 'json'
          }).then((res) => {
            if(data.orderType == 'order-ahead'){
               checkForInCartOrder();
               $('#waiting_order_ahead').html('');
            }
            loadCurrentCartItems();
            toastr.success("Remove successfully");
          })
          .catch((err) => {
            toastr.error("Remove failed");
          })
        })

        // $itemsListContainer.html(orderHTML);
        //Append generated list to container
        //Initialize ratings stars
        _initRatingStars();
        //Display order totals
        loadingOrderAnimation('hide');
        if($('label.yourItemPaid').length && $('#statusFriendIsOrdring').length) {
          $('#waiting-your-friend').removeClass("d-none");
          $('#wrap-btn-split-pay').addClass("hide");
          $(".waiting-your-friend-button").removeClass("d-none")
          $('.order-this-item').addClass("d-none");
          $('#wrap-btn-cancel-order').css("max-width","")
          $('#wrap-btn-cancel-order').css("width","40%")
        }
        return Order.renderOrderTotals(null, true, {subOrder: true});
      };

      /**Render Addons */
      const renderAddon = (item) => {
        let htmlAddon = '';
//        let menuItem = $('#tree').fancytree('getTree').getNodeByKey(item.item_id);
        let menuItem = $('#menuitemData_'+item.item_id);
        if(!menuItem) return;
        menuItem = menuItem.val();
        if(menuItem && menuItem.children && item.addons && item.addons.length) {
          htmlAddon = renderForEchAddons(menuItem.children, item)
        }
        return htmlAddon;
      }

      const totalPriceAddon = (item) => {
        let total = 0;
        if(item && item.addons && item.addons.length) {
          item.addons.forEach((addon) => {
            let price_addon = Number(addon.addon_extended_price);
            if(price_addon > 0) total += price_addon;
          })
        }
        return total;
      }

      const renderForEchAddons = (menuAddons, item) => {
        let htmlAddon = '';
        const addons =  item.addons
        if(menuAddons && menuAddons.length) {
          menuAddons.forEach((addon) => {
            if(addon && addon.children) {
              let htmlChild = renderForEchAddons(addon.children, item)
              if(htmlChild) {
                htmlAddon += `<div><p style="margin: 0px; font-size: 14px;${ item.status == "cancelled" ? 'text-decoration: line-through;' : ''}">${ addon.title }</p> ${ htmlChild }</div>`
              }
            } else {
              let cartAddon = addons.find((i)=> i.item_id == addon.key);
              let addon_quantity = 0;
              let addon_extended_price = 0;
              let addon_price = addon.data && addon.price ? addon.price : 0;
              if(cartAddon && cartAddon.addon_quantity) {
                addon_quantity = cartAddon.addon_quantity;
                addon_extended_price = cartAddon.addon_extended_price;

                let total_quantity = cartAddon.addon_quantity * (item.quantity || 1);
                let total_price = cartAddon.addon_extended_price * (item.quantity || 1);

                htmlAddon +=  `<div style="margin-left: 20px;${ item.status == "cancelled" ? 'text-decoration: line-through;' : ''}">
                                <div class="hidden" id="addon-${addon.key}-${item.group_id}">
                                  <input type="hidden" class="quantity" value="${addon_quantity}">
                                </div>
                                <small>${addon.title} ${total_quantity} x $${addon_price} = $${total_price}</small>
                              </div>`
              }
            }
          })
        }
        return htmlAddon;
      }

      /**
       * Load and render the current cart items
       */
      const loadCurrentCartItems = (options = {}) => {
        const { formerAction } = options;
        //$('#modal-empty').css('display','none');
          $('#emptytext').css('display','none');
        return getItems().then((foundItems) => {
          const itemCart = foundItems.userCartOrder.userCartItems.length;
          if(itemCart != 0){
            $('#modal-empty').css('display', 'none');
            $('#emptytext').css('display','none');
            $('#order-modal-content').css('display','block');
            $('#order-modal-dialog').attr("style","height : 100% ");
            $('#order-modal-dialog').attr("style","width : 100% ");
          }else{
            $('#order-modal-content').css('display', 'none');
            $('#modal-empty').css('display','block');
            $('#emptytext').css('display','block');
            $('#order-modal-dialog').attr("style","height : auto ");
          }
          // HANDLE JOIN ORDER ATTRIBUTE
          // When is child order, disable order type changes and delivery address checking
          if ( (foundItems.userCartOrder.parentId || foundItems.userCartOrder.hasPaidJoinedOrder)){
            $('.order-type li').addClass("disabled");
          } else {
            $('.order-type li').removeClass("disabled");
          }
          if($('#edit-order-name').data('customTextEditTrigger')){
          $('#edit-order-name').data('customTextEditTrigger').setData({orderName : foundItems.userCartOrder.orderName})
          }
          if (foundItems.userCartOrder && foundItems.userCartOrder.parentId && options.parentOrderUpdate){
            $('#delivery-address-edit-trigger').data('customTextEditTrigger').setData({
              deliveryAddress : foundItems.userCartOrder.deliveryAddress,
              deliveryAddressDetails : foundItems.userCartOrder.deliveryAddressDetails,
              apartmentNumber : foundItems.userCartOrder.apartmentNumber,
            })
            $('.order-type li').removeClass("disabled");
            let $orderTypeTab = $('.myorders-nav').find(`a[name="${foundItems.userCartOrder.orderType}"]`);
            $orderTypeTab.trigger('click', [true]);
           // $('.order-type li').addClass("disabled");
            $("#create-paypal-order").attr('title', `Please ask ${foundItems.userCartOrder.hostUser} to set the delivery address so you can complete your order.`);
            Order.updateOrderDetail('parentId', foundItems.userCartOrder.parentId);
            Order.updateOrderDetail('hostUser', foundItems.userCartOrder.hostUser);
          } else {
            Order.updateOrderDetail('childOrders', foundItems.userCartOrder.childOrders);
          }
          let requested = false;
          if(foundItems.userCartOrder.userCartItems[0] && foundItems.userCartOrder.userCartItems[0].request_reservation == "invited") requested = true;
          if(foundItems.userCartOrder.orderType == 'order-ahead' && (options.RequestedReservation || requested)){
            $('.order-type li').addClass("disabled");
          }

          if(foundItems.userCartOrder.orderType == 'waitlist' && (options.RequestedReservation || requested)){
            $('.order-type li').addClass("disabled");
          }

          if(foundItems.userCartOrder.modify && foundItems.userCartOrder.isPaid){
            $('.order-type li').addClass("disabled");
          }

          //Auto check delivery address in range
          if (foundItems.userCartOrder.orderType == 'delivery'){
            let currentLocation = $("#head-location-input-search").val();
            if(foundItems.userCartOrder.deliveryAddress != '' || currentLocation){
              $('#delivery-address-edit-trigger').data('customTextEditTrigger').setData({
                deliveryAddress : foundItems.userCartOrder.deliveryAddress,
                deliveryAddressDetails : foundItems.userCartOrder.deliveryAddressDetails,
                apartmentNumber: foundItems.userCartOrder.apartmentNumber
              })

              var profileAddress = $("#hfProfileAddress").val();
              var orderupdateAddress = (sessionStorage.getItem('orderupdateAddress'))
                console.log("orderAddress=4===",orderupdateAddress)
                if(orderupdateAddress){
                  $("#delivery-address-edit-trigger-container").css('display', 'none');
                  $('#delivery-address').text(orderupdateAddress);
                  profileAddress=orderupdateAddress;
                  Delivery.checkDeliveryAddress(profileAddress, '', foundItems.userCartOrder.apartmentNumber);
             
                }
              else if(profileAddress && (currentLocation.trim() == profileAddress.trim()) ){
                console.log("111===")
                $('#delivery-address-edit-trigger').data('customTextEditTrigger').setData({
                  deliveryAddress : profileAddress,
                  deliveryAddressDetails : '',
                })
                Delivery.checkDeliveryAddress(profileAddress, '', foundItems.userCartOrder.apartmentNumber);
              }else{
                if ( ['create-cart', 'change-order-type'].includes(formerAction)
                  && currentLocation  && !currentLocation.includes('Enter Your Location')
                  && ![profileAddress, foundItems.userCartOrder.deliveryAddress].includes(currentLocation)
                  && !Order.deniedToUseCurrentLocation
                  ){
                  Swal.fire({
                    title: "Delivery Address",
                    text: `Is your delivery address  ${currentLocation}?`,
                    icon: "info",
                    customClass: 'swal-cancel-red',
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    confirmButtonColor: "#3cb371",
                    cancelButtonColor: "#d9534f",
                    showCancelButton:true,
                    showConfirmButton:true,
                    allowOutsideClick: true,
                    closeOnCancel: true,
                  }).then((result) => {
                    if(result.isConfirmed){
                      $('#delivery-address-edit-trigger').data('customTextEditTrigger').setData({
                        deliveryAddress : currentLocation,
                        deliveryAddressDetails : '',
                      })
                      Order.deniedToUseCurrentLocation = true;
                    } else {
                      Order.deniedToUseCurrentLocation = false;
                    }
                    let { deliveryAddress, deliveryAddressDetails, apartmentNumber } = $('#delivery-address-edit-trigger').data('customTextEditTrigger').getData();
                    if(deliveryAddress == ""){
                      if($('#delivery-address-edit-trigger-container input').length <= 0) {
                        $('#delivery-address-edit-trigger').click();
                        $('#delivery-address_input').focus();
                      }else{
                        $('#delivery-address_input').focus();
                      }
                    } else {
                      deliveryAddress = deliveryAddress.trim();
                      Delivery.checkDeliveryAddress(deliveryAddress, deliveryAddressDetails, apartmentNumber);
                      setTimeout(function(){
                        $('#delivery-address-edit-trigger').data('customTextEditTrigger').triggerOnChange(true);
                      }, 500)
                    }

                  });
                } else {
                  let { deliveryAddress, deliveryAddressDetails, apartmentNumber } = $('#delivery-address-edit-trigger').data('customTextEditTrigger').getData();
                  if(deliveryAddress == ""){
                    if($('#delivery-address-edit-trigger-container input').length <= 0) {
                      $('#delivery-address-edit-trigger').click();
                      $('#delivery-address_input').focus();
                    }else{
                      $('#delivery-address_input').focus();
                    }
                  } else {
                    Delivery.checkDeliveryAddress(deliveryAddress, deliveryAddressDetails, apartmentNumber);
                    setTimeout(function(){
                      $('#delivery-address-edit-trigger').data('customTextEditTrigger').triggerOnChange(true);
                    }, 500)
                  }
                }
              }
            }else{
              let { deliveryAddress, deliveryAddressDetails, apartmentNumber } = $('#delivery-address-edit-trigger').data('customTextEditTrigger').getData();
              if(deliveryAddress == ""){
                let HomeAddress = localStorage.getItem("HomeAddr")
                console.log("HomeAddress====",HomeAddress)
                if($('#delivery-address-edit-trigger-container input').length <= 0) {
                  $('#delivery-address-edit-trigger').click();
                  $('#delivery-address_input').focus();
                  console.log("sessionStorage.getItem('orderupdateAddress')====",sessionStorage.getItem('orderupdateAddress'))
                  var orderupdateAddress = (sessionStorage.getItem('orderupdateAddress'))
                  console.log("orderAddress=1===",orderupdateAddress)
                  if(orderupdateAddress){
                    console.log("here for aff")
                    $("#delivery-address-edit-trigger-container").css('display', 'none');
                    $('#delivery-address').text(orderupdateAddress);
                  }
                  else if(HomeAddress) {
                    console.log("here for 333aff")
                      // $('#delivery-address_input').val(HomeAddress);
                      $("#delivery-address-edit-trigger-container").css('display', 'none');
                      $('#delivery-address').text(HomeAddress);
                  }

                }else{
                  $('#delivery-address_input').focus();
                  let orderupdateAddress = (sessionStorage.getItem('orderupdateAddress'))
                  console.log("orderAddress====",orderupdateAddress)
                  if(orderupdateAddress){
                    $("#delivery-address-edit-trigger-container").css('display', 'none');
                    $('#delivery-address').text(orderupdateAddress);
                  }
                  else if(HomeAddress) {
                      // $('#delivery-address_input').val(HomeAddress);
                      $("#delivery-address-edit-trigger-container").css('display', 'none');
                      $('#delivery-address').text(HomeAddress);
                   }
                }
              }
            }
          } else {
            $("#create-paypal-order").prop('disabled', false);
          }

          let isPaidInAdvance = false
          if (foundItems.userCartOrder.userCartItems && foundItems.userCartOrder.userCartItems.length > 0){
            isPaidInAdvance = foundItems.userCartOrder.userCartItems.find(i => !i.payment_id) ? false : true; // all items has been paid
          }
          // Check if there are items to continue paying or all items have been paid
          if (isPaidInAdvance){ // disable and show all paid in advance
            $(".checkout-actions #create-paypal-order").text('Paid in advance');
            $(".checkout-actions #create-paypal-order").addClass('btn-danger');
            $(".checkout-actions #create-paypal-order").prop('disabled', true);
            if(foundItems.userCartOrder.orderType == 'order-ahead') {
              $(".wrap-btn-cart-pay").hide();
            }
          } else {
            $(".checkout-actions #create-paypal-order").text('Pay');
            $(".checkout-actions #create-paypal-order").removeClass('btn-danger');
            $(".checkout-actions #create-paypal-order").prop('disabled', false);
            if(foundItems.userCartOrder.orderType == 'order-ahead') {
              if (orderAheadId) {
                $(".wrap-btn-cart-pay").hide();
              } else {
                $(".wrap-btn-cart-pay").show();
              }

            }
          }

          if (foundItems.userCartOrder.inWaitlist) Order.updateOrderDetail("inWaitlist", foundItems.userCartOrder.inWaitlist);
          if (foundItems.userCartOrder.loyaltyTimeSaver) Order.updateOrderDetail("loyaltyTimeSaver", foundItems.userCartOrder.loyaltyTimeSaver);
          if (foundItems.userCartOrder.orderType === "waitlist"){
            let orderId = Order.getOrderDetail('orderId')

            if (isPaidInAdvance){ // disable and show all paid in advance
              $(".available-in-waitlist #create-paypal-order").text('Paid in advance');
              $(".available-in-waitlist #create-paypal-order").addClass('btn-danger');
              $(".available-in-waitlist #create-paypal-order").prop('disabled', true);
              if(foundItems.userCartOrder.orderType == 'order-ahead') {
                $(".wrap-btn-cart-pay").hide();
              }
            } else {
              $(".available-in-waitlist #create-paypal-order").text('Pay via Paypal');
              $(".available-in-waitlist #create-paypal-order").removeClass('btn-danger');
              $(".available-in-waitlist #create-paypal-order").prop('disabled', false);
            }

            // Show/hide sections
            if (foundItems.userCartOrder.inWaitlist){
              $(".added-to-waitlist").show()
              $(".not-added-to-waitlist").hide()
              Waitlist.checkSeatCriteria(orderId)
            } else {
              Waitlist.checkAddCriteria(orderId)
              $(".added-to-waitlist").hide()
              $(".not-added-to-waitlist").show()
            }
            Waitlist.displayLoyaltyTimeSaver(orderId)
            Waitlist.refreshWaitlistTable(VENUE_ID)
          }
          if($('label.yourItemPaid').length && $('#split-and-complete').length == 0 && $('#statusFriendIsOrdring').length != 0){
            $(".wrap-btn-cart-pay").hide();
            $("#wrap-btn-cancel-order").after(`<div id="wrap-btn-split-complete" class="flex-grow-1" style="margin-left: 5px; background-color: rgba(247, 70, 70, 0.1)!important;">
              <button id="split-and-complete" data-title="Split and" class="btn btn-block" style="color: red;">Split & Complete Order</button>
            </div>`)
            $("#split-and-complete").click(async function(){
              let data = {};
              data.userId = USER_ID || '';
              data.orderId = Order.getOrderDetail('orderId') || '';
              data.orderType = foundItems && foundItems.userCartOrder &&  foundItems.userCartOrder.orderType || '';
              data.parentName = foundItems && foundItems.userCartOrder &&  foundItems.userCartOrder.orderName || '';
              data.parentId = foundItems && foundItems.userCartOrder &&  foundItems.userCartOrder.parentId;
              data.mySelf = true;
              data.splitAndComplete = true;

              await $.promisedAjax({
                url: `/orders/removefromorder`,
                type: 'POST',
                data: JSON.stringify(data),
                contentType: 'application/json',
                dataType: 'json'
              }).then(async (res) => {
                url =  window.location.href;
                const newUrl = url.split("restaurant")[0] + "rate/order/" + data.orderId;
                window.location.href = newUrl ;
              })
              .catch((err) => {
                toastr.error("Separate failed");
              })
            })
          }
          JOIN_REQUESTS = foundItems.joinRequests;
          renderjoinRequestNotifications(foundItems.joinRequests);
          if ( jQuery.isEmptyObject(foundItems.joinedCartOrders) ){
            renderOtherOrders(foundItems.otherOrders);
          } else {
            $('#other-orders-container').empty();
          }

          updateLocalCartItems(foundItems.userCartOrder.userCartItems);
          
          var orderupdateAddress = (sessionStorage.getItem('orderupdateAddress'))
          console.log("orderAddress=5===",orderupdateAddress)
          foundItems.userCartOrder.deliveryAddress=orderupdateAddress;
           return renderItems(foundItems)

        }).catch((err) => {
          console.error('Unable to load items', err)
        })
      };

      /**
       * Load and render the current cart items
       */
      const refreshOtherOrders = () => {
        return getItems().then((foundItems) => {
          $('#other-orders-container').empty();
          if ( jQuery.isEmptyObject(foundItems.joinedCartOrders) ) renderOtherOrders(foundItems.otherOrders);
          renderItems(foundItems);
        }).catch((err) => {
          console.error('Unable to refresh other orders list', err);
        })
      };

      /**
       * Render other orders of the restaurant
       * @param {Array} orders
       */
       const renderOtherOrders = (orders) => {
          let otherOrdersHTML = ``;
          let $otherOrdersContainer = $('#other-orders-container');
          orders.forEach((order) => {
            let requestFunc = `<a href="javascript:void(0);" class="btn btn-sm btn-success add-me p-0 pr-4 pl-4"
                                  data-order-id = '${order.orderId}' data-user-id = '${order.userId}'>Join</a>`
            if (order.requested) {
              requestFunc = `<span class='green'>Requested</span>`;
            }

            otherOrdersHTML += `<div class="col-9">
                                  <span>${(order.orderName) ? order.orderName : "[no_name]"}</span>
                                </div>
                                <div class="col-3">
                                  ${requestFunc}
                                </div>`;
          });
          otherOrdersHTML += otherOrdersHTML ? '<div class="col-12"><hr class="order-total-divider"></div>' : '';
          $otherOrdersContainer.html(otherOrdersHTML);
        };

        /**
        * Render Join request Notification badges
        * @param {Array} joinRequests
        */
        const renderjoinRequestNotifications = (joinRequests, checkouts = false) => {
            let total = joinRequests.length;
            let $bagde = checkouts ? $('#join-requests-checkouts').find('span') : $('#join-requests').find('span');
            if (total){
              $('#join-requests').toggleClass('orange', true);
              $bagde.text(total);
              $bagde.show();
            } else {
              $('#join-requests').toggleClass('orange', false);
              $bagde.text('');
              $bagde.hide();
            }
        };

        /**
        * Update join request from push notification
        * @param {Array} joinRequest
        */
        const addJoinOrderNotification = (joinRequest) => {
          JOIN_REQUESTS.unshift(joinRequest);
          return JOIN_REQUESTS;
        };
        // Set global function for order module

        /**
        * Join request Notification
        * @param {Array} joinRequests
        */

        const joinRequestNotifications = (checkoutsData = []) => {
          const isCheckoutNoti = !!checkoutsData.length;
          let orderId = Order.getOrderDetail('orderId') || $('#join-requests-checkouts').data('id-notification');
          if (JOIN_REQUESTS.length > 0 || checkoutsData.length > 0){
            let order = JOIN_REQUESTS[0] || checkoutsData[0];
            const orderName = !_.isEmpty(checkoutsData) && _.has(order, 'order_name') ? order.order_name.split(`'s`) : [];
            if(orderName[0]) order.user = orderName[0];
            const targetUserId = order.userId || order.user_id;
            const orderType = !_.isEmpty(checkoutsData) ? checkoutsData[0].order_type : Order.getOrderDetail('orderType');
            const totalJoinRequest = checkoutsData.filter(checkout => checkout.join_request_to === orderId);
            function swalJoinRequestApproval() {
              Swal.fire({
                icon: "warning",
                title: `${order.user} wants to join your order.`,
                text: 'Do you want to accept?',
                showCancelButton: false,
                showConfirmButton:false,
                allowOutsideClick: true
              });
            }
            swalExtend.call({}, {
              swalFunction: swalJoinRequestApproval,
              hasCancelButton: true,
              buttonNum: 2,
              buttonColor: ["#b30000", "#3CB371"],
              buttonNames: ["No", "Yes"],
              clickFunctionList: [
                function() { // Reject
                  let order = JOIN_REQUESTS[0] || checkoutsData[0];
                  loadingOrderAnimation('show');
                  if(!_.isEmpty(checkoutsData)){
                    if(totalJoinRequest > 1) {
                      $(`#notify-checkout-${orderId}`).text((totalJoinRequest.length -1).toString());
                    } else $(`#notify-checkout-${orderId}`).text('');
                  }
                  handleJoinRequest(order.orderId || order.order_id, orderId, targetUserId, "reject", orderType).then((res) => {
                    if (res) {
                      if(checkoutsData.length > 0){
                        JOIN_CHECKOUTS.splice(0, 1);
                      } else {
                        JOIN_REQUESTS.splice(0, 1);
                      }
                      if(!_.isEmpty(checkoutsData)) {
                        const orderIdCheckout = checkoutsData[0].order_id;
                        JOIN_REQUESTS = JOIN_REQUESTS.filter(request => request.orderId !== orderIdCheckout);
                      }
                      toastr.success(`Rejected successfully`);
                      return loadCurrentCartItems();
                    } else {
                      toastr.error(`Failed to reject request.`);
                    }
                  }).finally(()=>{
                      loadingOrderAnimation('hide');
                  });
                },
                function() { // Approve
                  let order = JOIN_REQUESTS[0] || checkoutsData[0];
                  loadingOrderAnimation('show');
                  if(!_.isEmpty(checkoutsData)){
                    const orderIdCheckout = checkoutsData[0].order_id;
                    JOIN_REQUESTS = JOIN_REQUESTS.filter(request => request.orderId !== orderIdCheckout);
                    if(totalJoinRequest > 1) {
                      $(`#notify-checkout-${orderId}`).text((totalJoinRequest.length -1).toString());
                    } else $(`#notify-checkout-${orderId}`).text('');
                  }
                  handleJoinRequest(order.orderId || order.order_id, orderId, targetUserId, "approve", orderType, !_.isEmpty(checkoutsData[0])).then((res) => {
                    if (res) {
                      if(res.order_type == 'order-ahead' && res.request_reservation == "invited"){
                        Order.checkForInCartOrder()
                      }
                      toastr.success(`Approved successfully`);
                      if(isCheckoutNoti){
                        JOIN_CHECKOUTS.splice(0, 1);
                        Order.checkForOrdersInCheckout(null, orderId, {noScroll: true});
                        return;
                      } else {
                        JOIN_REQUESTS.splice(0, 1);
                      }
                      return loadCurrentCartItems();
                    } else {
                      toastr.error(`Failed to approve request.`);
                    }
                  }).finally(()=>{
                      loadingOrderAnimation('hide');
                  });
                }
              ]
            });
          }
        };

        const handleJoinRequest = (requestedOrderId, parentId, targetUserId, command, orderType, isJoinCheckOut) => {
          return $.promisedAjax({
              url: '/orders/handleJoinRequest',
              type: 'POST',
              contentType: 'application/json; charset=utf-8',
              data: JSON.stringify({
                  requestedOrderId: requestedOrderId,
                  parentId        : parentId,
                  command         : command,
                  targetUserId    : targetUserId,
                  venueId         : VENUE_ID,
                  orderType       : orderType,
                  isJoinCheckOut,
              }),
              error: function (err) {
                toastr.error(`Failed to ${command} request.`);
              },
          }).then((res) => {
              return res.success;
          });
        };
      //PUBLIC METHODS

      window.addJoinOrderNotification = addJoinOrderNotification;
      window.joinRequestNotifications = joinRequestNotifications;
      window.renderjoinRequestNotifications = renderjoinRequestNotifications;
      window.loadCurrentCartItems           = loadCurrentCartItems;
      window.refreshOtherOrders             = refreshOtherOrders;
      window.renderOtherOrders = renderOtherOrders;
      window.modal_render_item_ordered           = modal_render_item_ordered;


      return {
        addItems,
        getItems,
        updateItemsOrder,
        getFriendsOrderAhead,
        hasItems,
        renderItems,
        renderAddon,
        totalPriceAddon,
        renderFriendOrderAhead,
        loadCurrentCartItems,
        updateLocalCartItems,
        joinRequestNotifications,
        getCurrentCartItems,
        addJoinOrderNotification,
        modal_render_item_ordered,
        getDisabledDay
      };
  })();


  // UTILITIES
  /**
   * Initialize ratings stars on cart items
   */
  const _initRatingStars = () => {
    let starvale= $(".cart-item-stars").attr('rating');
    let starvalue=starvale !="undefined" && starvale ? starvale: 0;
    $('.cart-item-stars').rateYo({
      rating: starvalue,
      readOnly: true,
      starWidth: "20px",
      spacing: "5px",
      ratedFill: "green",
      normalFill: "#bdbcbc",
      starSvg: __platerate_icons.my_heart_plate,
    });
  };
  // UTILITIES




  /**
   * JOIN ORDER HANDLER
   */
   $('body').on('click', '#join-requests', function(e){
    ShoppingCart.joinRequestNotifications();
   });

   $('body').on('click', '#join-requests-checkouts', function(e){
    ShoppingCart.joinRequestNotifications(JOIN_CHECKOUTS);
  });

    $('#invites-friends-order-ahead').on('change', '.change-friend-ahead', function(e){
      const friendId = $(this).data('friend');
      if(!friendId) return false;
      const userName = $(this).find('input[name="friend-name"]').val();
      const status = $(this).find('select[name="friend-status"]').val();
      const guests = $(this).find('input[name="friend-guests"]').val();
      if(guests < 0){
        Swal.fire('The number of guests is invalid','Please check again !','error')
        $(this).find('input[name="friend-guests"]').val(0);
        return
      }
      const dataFriend = {
        friendId: friendId
      }
      if(userName) dataFriend['userName'] = userName;
      if(status) dataFriend['status'] = status;
      dataFriend['guests'] = _.toNumber(guests) || 0;
      if(status == 'confirmed') dataFriend['changeRSVP'] = true;
      if($('#confirm-invitee').length) dataFriend['withInviteLink'] = true;
      if(e.target.name != 'friend-guests') dataFriend['changeStatus'] = true;
      dataFriend['friendOrderId'] = Order.getOrderDetail('orderId');
      dataFriend['parentId'] = Order.getOrderDetail('parentId');
      dataFriend['venueId'] = VENUE_ID;
      $.ajax({
        url: '/orders/order-ahead/friend/update',
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(dataFriend),
        success: function (res) {
            toastr.success(res.message || 'Update successfully');
            if($('#confirm-invitee').length){
              return window.location.href = window.location.href.split('?')[0] + '?show=MyOrder';
            }
            Order.checkForInCartOrder();
            loadCurrentCartItems();
        },
        error: function (err) {
          toastr.error("Updated failed");
        },
      });

    });


    // $('#order-ahead #space-people').val(2);
    $('#order-ahead #reservation-hours').val(1);
    $('#order-ahead #reservation-minutes').val(30);
    $('#invites-day').val(1);
    $("#invite-day-number").text(1);

    // Change update reservation
    $(' #order-ahead-Instructions, #space-people, #reservation-hours, #reservation-minutes, #email-my-friends, #invites-day')
    .on('change', function(){
      $('#btn-update-order-ahead').prop('disabled', false);
    })
    $('#invites-friends-order-ahead').on('click', '#invite-additional-people',function(){
      $('#btn-update-order-ahead').prop('disabled', false);
      $(this).parent().parent().removeClass('has-error');
      $(this).parent().parent().find('span.help-block').html('');
    })
    $('#invites-friends-order-ahead').on('click', '#confirm-invitee',function(){
        let _confirm = $('#confirm-invitee').parent().find('select');
        _confirm.val('confirmed');
        _confirm.trigger('change');
    })
    $('#invites-friends-order-ahead').on('click', '#decline-invitee',function(){
      let _confirm = $('#decline-invitee').parent().find('select');
      _confirm.val('declined');
      _confirm.trigger('change');
  })
    $('.available-in-order-ahead').on('click', '#additional-invite',function(){
      let list_emails = $('#invite-additional-people').val().replace(/\s/g, '');
      if(!list_emails){
        Swal.fire("Add at least one email", 'Please check again !', "error");
        $('#invite-additional-people').val('')
        return
      }else{
        $('#btn-update-order-ahead').data('listmails',list_emails);
      }

      $('#btn-update-order-ahead').data('addmails',true);
      $('#btn-update-order-ahead').trigger('click');
    })


    $('#cart-items-container').on('click', '#separate-from-order',function(){
      let data = {};
      data.userId = $('#userId').val();
      data.orderId = $(this).data('order-id');
      data.orderType = 'order-ahead';
      data.parentId = $(this).data('parent-id');
      data.orderName = $(this).data('order-name');
      data.mySelf = true;
      return $.promisedAjax({
        url: `/orders/removefromorder`,
        type: 'POST',
        data: JSON.stringify(data),
        contentType: 'application/json',
        dataType: 'json'
      }).then((res) => {
        checkForInCartOrder();
        loadCurrentCartItems();
        toastr.success("Separate successfully");
        $('#waiting_order_ahead').html('');
      })
      .catch((err) => {
        toastr.error("Separate failed");
      })

  })
    $('#other-orders-container, #waitlist-queue').on('click', '.add-me', function(e){
      let orderId;
      orderId = Order.getOrderDetail('orderId');
     let $this = $(this);
     let orderType = $('#incart-state-container').find('.active').find('a').attr('name') || $('#incart-state-container').find('.active').attr('name');
     $.ajax({
         url: '/orders/requestJoinOrder',
         type: 'POST',
         contentType: 'application/json; charset=utf-8',
         data: JSON.stringify({
             orderId: orderId,
             targetOrderId: $(this).data('order-id'),
             targetUserId: $(this).data('user-id'),
             venueId : VENUE_ID,
             orderType: orderType
         }),
         success: function (res) {
             if (res.success) {
              Swal.fire("Request sent!", "", "success");
               $this.replaceWith( "<span class='green'>Requested</span>" );
             } else {
               if (res.code == "full-slot"){
                Swal.fire("This order is full", "", 'warning');
               } else {
                Swal.fire("Failed to send request.", "", 'error');
               }
             }
         },
         error: function (err) {
          Swal.fire("Failed to send request.", err.message,'error');
         },
     });
   });

  let deniedSaveHomeAddress = false;
  $('#delivery-address-edit-trigger').customTextEditTrigger({
    selector: '#delivery-address',
    dataObject: {
      deliveryAddress : '',
      apartmentNumber: ''
      // deliveryAddressDetails : '',
    },
    // inEditTitle: '<span class="dark-grey">Delivery Address (required)</span>',
    inputContainer: '#delivery-address-edit-trigger-container',
    inputHTML: {
      // onblur="saveDeliveryAddress()"
      deliveryAddress : `
        <input type="search" class="form-control mt-3"
          id="delivery-address_input"
          onkeypress="saveDeliveryAddressOnEnter(event)"
          placeholder="Enter delivery address"
          style="border: none; border-bottom: 1px grey solid; border-radius: 0; height: 40px; font-size: 18px;"
          />

      `,
      apartmentNumber: `
        <input type="text" id="apartment-number" class="form-control mt-3"
          placeholder="Enter Apartment Number (Optional)"
          style="border: none; border-bottom: 1px grey solid; border-radius: 0; height: 40px; font-size: 18px;"
        />
        <div class="col-12">
          <button class="btn btn-success mt-3 mb-2 centerBlock" id="saveDeliveryAddress" onclick="saveDeliveryAddress()">Save Address</button>
        </div>
      `
      // deliveryAddressDetails : '<input class="form-control wide-input"/>',
    },
    editHTML: 'CHANGE',
    // doneHTML: 'DONE',
    formatter: function( data ){
      if(!data
          || !data['deliveryAddress']
          || data['deliveryAddress'].length === 0
          || data['deliveryAddress'].trim() === "United States" // this is default value, when address is not set
          || data['deliveryAddress'].trim() === "Alabama, United States") { // this is for backward compatibility, records that already have Alabama state saved in the database

        return `<span class="red cursor-pointer" for="clickAddressDelivery">Click Edit to set Delivery address</span>`;
      } else {
       return `${data['deliveryAddress'] ? data['deliveryAddress'] : ''}`;
      }
    },
    onChange: function( data ){
      $('.cart-actions-sticky').show();
      // $('.eligible-credits-label').show();

      if (!data._dataChanged || data.deliveryAddress == '') {
        return;
      }
      $('#delivery').css('box-shadow', 'none');

      let { deliveryAddress, deliveryAddressDetails, apartmentNumber } = $('#delivery-address-edit-trigger').data('customTextEditTrigger').getData();
      let profileAddressEmpty = $("#hfProfileAddressIsSet").val() === "false";
      let Homeaddr = localStorage.getItem('HomeAddr');
      console.log("deliveryAddress====",deliveryAddress);
      console.log("deniedSaveHomeAddress====",deniedSaveHomeAddress);
	 console.log("profileAddressEmpty====",profileAddressEmpty);
      if(profileAddressEmpty && deliveryAddress && deniedSaveHomeAddress != deliveryAddress && !Homeaddr ) {
        Swal.fire({
          title: "Profile Address",
          text: `Would you like to save this as your home address?`,
          icon: "info",
          customClass: 'swal-cancel-red',
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonColor: "#3cb371",
          cancelButtonColor: "#d9534f",
          showCancelButton:true,
          showConfirmButton:true,
          allowOutsideClick: true,
          closeOnCancel: true,
        }).then((result) => {
          if (!result.isConfirmed) {
            deniedSaveHomeAddress = deliveryAddress
            return
          }

          $.promisedAjax({
            url: '/users/profile/update',
            type: 'POST',
            dataType: 'json',
            data: {
              deliveryAddress: deliveryAddress,
              apartment: apartmentNumber
            }
          })
          .then(function (res) {
            $("#hfProfileAddressIsSet").val('true');
            localStorage.setItem("HomeAddr", deliveryAddress)
            toastr.success('Saved home address successfully');
          })
          .catch(function (err) {
              console.log("Error saving home address..",err);
              toastr.error('Error saving home address');
          });
        });
      }

      if(deliveryAddress) deliveryAddress = deliveryAddress.trim();
      var orderId = Order.getOrderDetail('orderId');
      let updateData = {
        delivery_address : deliveryAddress,
        delivery_address_details : deliveryAddressDetails,
        apartment_number : apartmentNumber
      }
      Order._submitOrderUpdates(orderId, updateData);      // if(deliveryAddress.length > 0) $('.delivery-section').css('box-shadow', 'none');
      Delivery.checkDeliveryAddress(deliveryAddress, deliveryAddressDetails, apartmentNumber);
    },
    showEdit: function( data ){
      $('#delivery-address_input').focus();
      if(isMobile || $(window).width() <= 424){
        $('.eligible-credits-label').hide();
        $("#delivery-address_input, #apartment-number, #describe-delivery-contactless, #specialInstructions").unbind();
        $("#delivery-address_input, #apartment-number, #describe-delivery-contactless, #specialInstructions").focus(function(){
          $('.cart-actions-sticky').show();
          $('.eligible-credits-label').hide();
          
        });
        $("#delivery-address_input,  #apartment-number, #describe-delivery-contactless, #specialInstructions").focusout(function(){
          $('.cart-actions-sticky').show();
          $('.eligible-credits-label').show();

          Order.renderOrderTotals();
        });
      }else{
        $("#delivery-address-edit-trigger-container").css("display",'block')
      }
      setTimeout(()=>{
        initializeAutocompleteAddress();
      },200);
    }
  });
  if(isMobile || $(window).width() <= 424){
      $('body').on('focus', '.order-total-info', function(){
        $('.cart-actions-sticky').show();
      });
      $('body').on('blur', '.order-total-info', function(){
        $('.cart-actions-sticky').show();
      });
  }
  $("#order-calculated-tip, #order-modal-content").scroll(function() {
    $(".pac-container").hide();
  });
   var geocoder;
   attach('https://maps.googleapis.com/maps/api/js?key=' + GOOGLE_MAP_API_KEY + '&libraries=places').then(function (res) {
     geocoder = new google.maps.Geocoder();
     initializeAutocompleteAddress();
     displayLocation(__user_location.latitude ,__user_location.longitude );
    });

   function displayLocation(latitude,longitude){
    var latlng = new google.maps.LatLng(latitude, longitude);
    geocoder.geocode(
      {'latLng': latlng},
      function(results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[0]) {
           __address_location = results[0].formatted_address || '';
            return true
          }
        }
      }
    );
  }

   function attach(path) {
     return new Promise(function (cb) {
       var el = document.createElement('script');
       el.onload = el.onerror = cb;
       el.src = path;
       document.getElementsByTagName("head")[0].appendChild(el);
     });
   }

   const getDeliveryAddressFromLatLong = function(_lat,_long){
    $.ajax({
      url: '/api/getgeocodebylatlong',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({ lat: _lat, long: _long  }),
      success: function (res) {
          // $(".detect-delivery-location").removeClass("fa-spinner fa-spin").addClass("fa-map-marker");
          if(res.code == 0 && res.data) {
            const address = res.data.formatted_address;
            updateDeliveryAddressValue(address);
          } else {
            //Allow the user to enter delivery location
            updateDeliveryAddressValue('');
          }
        }
      });
   }

  const calculateDistance = function(destination) {
    return new Promise(function(resolve, reject){
      var origin = $('.item-venue-address').data('address');
      var service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix({
        origins: [origin],
        destinations: [destination],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
        avoidHighways: false,
        avoidTolls: false
      }, function(response, status){
        resolve(response)
      });
    });
  };

   function commonfetchcurrentLocation(){
      return ipLookUp();
      // if (navigator.permissions && navigator.geolocation) {
      //   navigator.permissions.query({ name: 'geolocation' }).then(function(result) {
      //   // $(".detect-delivery-location").removeClass("fa-map-marker").addClass("fa-spinner fa-spin");
      //   if(result.state === "prompt" || result.state === "granted") {
      //         navigator.geolocation.getCurrentPosition(function(position){
      //           var newLat = position.coords.latitude;
      //           var newLon = position.coords.longitude;
      //           getDeliveryAddressFromLatLong(newLat,newLon);
      //         }, function(e) {
      //           //detect location from ip
      //           ipLookUp();
      //       });
      //     } else {
      //         ipLookUp();
      //     }
      //   })
      //   } else {
      //     //Allow the user to enter delivery location
      //     updateDeliveryAddressValue('');
      //   }
   }

   const fetchCurrentLocation = (() => {
    if(!check_iOSapp() && !check_androidApp()){
        var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (is_safari){
            var googlelocationValue=Cookies.get('locationPermission');
            if(googlelocationValue!='granted'){
              commonfetchcurrentLocation();
            }else{
               ipLookUp();
            }
        }else{
          commonfetchcurrentLocation();
        }
      } else {
        var newLat = $("#nativeAppLatitude").val();
        var newLon = $("#nativeAppLongitude").val();
        getDeliveryAddressFromLatLong(newLat,newLon);
      }
   });


   const ipLookUp = function(){
    const ipaip = function() {
      $.ajax('https://ipapi.co/json/?key='+$("#ipapiSecret").val().toString())
      .then(
          function success(response) {
              // $(".detect-delivery-location").removeClass("fa-spinner fa-spin").addClass("fa-map-marker");
              if(response.latitude && response.longitude){
                var position = {coords:{latitude: response.latitude, longitude: response.longitude},fromIp:true};
                var newLat = position.coords.latitude;
                var newLon = position.coords.longitude;
                getDeliveryAddressFromLatLong(newLat,newLon);
              }
          },
          function fail(data, status) {
            // $(".detect-delivery-location").removeClass("fa-spinner fa-spin").addClass("fa-map-marker");
            //Allow the user to enter delivery location
            updateDeliveryAddressValue('');
          }
      );
    };

    //Find address by ip access
    $.ajax('/geoip/address').then((res) => {
      if(res && res.ll.length) {
        // $(".detect-delivery-location").removeClass("fa-spinner fa-spin").addClass("fa-map-marker");
        if(res.ll[0] && res.ll[1]){
          getDeliveryAddressFromLatLong(res.ll[0], res.ll[1]);
        }
      } else {
          ipaip()
      }
  });
};

  /**
   * DELIVERY
   */
  const Delivery = (() => {
    const $deliveryAddressInput = $('#delivery-address');
    const $editAddressForm = $("#edit-delivery-address-form");

    /* DELIVERY METHODS */
    const detectUserDeliveryLocation = () => {
      //Try retrieving/detecting the user current location
      fetchCurrentLocation();
    };

    const checkDeliveryAddressInRange = (_address, _addressDetails, distance_in_mile, apartment_number = '') => {
        return new Promise(function (resolve, reject) {
          const orderId     = Order.getOrderDetail("orderId");
          const childOrders = Order.getOrderDetail('childOrders');
          let orderupdateAddress = (sessionStorage.getItem('orderupdateAddress'))
          console.log("orderupdateAddress==4==",orderupdateAddress)    
          if(orderupdateAddress &&  orderupdateAddress!='Click Edit to set Delivery address'){
            _address=orderupdateAddress;
          }else{
            _address=_address
          }
          console.log("orderupdateAddress==3==",_address)
          let deliveryMethod = $('#delivery-method').val();
          let partnerId=$("#partnerId").val()
          return $.ajax({
            url: '/api/getgeocodebyaddress',
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify({ address: _address }),
            success: function (res) {
                if(res.code == 0 && res.data) {
                    const result = res.data.geometry;
                    let data = {
                      venueId  : $('#venueId').val(),
                      address  : _address,
                      apartmentNumber  : apartment_number,
                      deliveryAddressDetails : _addressDetails,
                      distanceMile : distance_in_mile,
                      orderId  : orderId,
                      childOrders : childOrders,
                      location : {
                        lat: res.data.lat,
                        lng: res.data.lng
                      },
                      deliveryMethod: deliveryMethod,
                      partnerId: partnerId
                    };
                    return $.ajax({
                      url: '/orders/checkDeliveryAddressInRange',
                      type: 'POST',
                      data: JSON.stringify(data),
                      contentType: 'application/json',
                      dataType: 'json',
                    }).then(function(result){
                      if (result.success){
                         Order.renderOrderTotals();
                         resolve(result.data);
                          $("#distance-failure").hide();
                        
                      }else{
                        $("#distance-failure").hide();
                        console.log("result.error=====",result)
                        if(result.data && result.data.burq && result.data.burq !='undefined' && result.data.burq.error==true ){
                          $("#distance-failure").show();
                          $("#distance-failure").text(result.data.burq.message+ "Please ensure your address includes a complete street address.");
                          return false;
                        }else{
                         // console.log("lefe=====",result.errMsg)
                          $(".distance-message .fa-refresh").hide();
                          $(".not-available-in-only-rate").find('#create-paypal-order').data('outofrange',true)
                          if(result.errMsg=='undefined'){
                            $("#distance-failure").show();
                            $("#distance-failure").text(`This address does not exist, Please enter the valid address.`);
                          }
                          else{
                            $("#distance-failure").text(result.errMsg);
                           }
                          $("#distance-failure").show();
                          $("#distance-success").hide();
                          cart_scroll_to('.cart-options--user-location',true);
                          // $("#payment_gif").css("display" , "block");
                          $(".checkout-actions #create-paypal-order").css("disabled" , "disabled");     
                          return false;
                         
                        }
                        $("#distance-failure").show();
                        $("#distance-success").hide();
                        resolve(result)
                      }
                    });
                } else {
                  console.log("tatus=======");
                  const result = res.data;
                  $("#distance-failure").show();
                  $("#distance-failure").text( "This address does not exist, Please enter the valid address.");
                  if($('#distance-failure').css('display') == 'block'){
                     cart_scroll_to('.cart-options--user-location',true);
                          
                  }
                  //resolve(result)
                }
            },
            error: function (err) {
              resolve(false)
            },
          });
        })
    };

    const checkDeliveryAddress = (_address, _addressDetails, apartmentNumber) => {
      $(".distance-message").show();
      $(".distance-message .fa-refresh").show();
      $("#distance-success").hide();
      $("#distance-failure").hide();
      return new Promise(function (resolve, reject) {
        if (!_address){
          $("#distance-success").hide();
          $("#distance-failure").hide();
          $(".distance-message .fa-refresh").hide();
          $(".not-available-in-only-rate").find('#create-paypal-order').data('outofrange',true);
          return resolve(false)
        } else {
          $('#delivery').css('box-shadow', 'none');
          calculateDistance(_address).then(function (response) {
           
            var origin = response.originAddresses[0];
            var destination = response.destinationAddresses[0];
            let status = response.rows[0].elements[0].status;
          
            if ( !["NOT_FOUND", "ZERO_RESULTS"].includes(status) ){
              var distance = response.rows[0].elements[0].distance;
              distance_in_mile = (distance.value / 1609.34).toFixed(2);
            }else{
              distance_in_mile=-1;
            }
		
            checkDeliveryAddressInRange(_address, _addressDetails, distance_in_mile, apartmentNumber) // also save the address to db
            .then(function (res) {
              console.log("status===",status,res);
              if (status === "NOT_FOUND"){
                $(".distance-message .fa-refresh").hide();
                $(".not-available-in-only-rate").find('#create-paypal-order').data('outofrange',true)
                $("#distance-failure").text(`This address does not exist, Please enter the valid address.`);
                $("#distance-failure").show();
                $("#distance-success").hide();
                cart_scroll_to('.cart-options--user-location',true);
                // $("#payment_gif").css("display" , "block");
                $(".checkout-actions #create-paypal-order").css("disabled" , "disabled");   	
                return resolve(false);
              } 
              else if (status === "ZERO_RESULTS"){
                $(".distance-message .fa-refresh").hide();
                $(".not-available-in-only-rate").find('#create-paypal-order').data('outofrange',true)
                $("#distance-failure").text(`There is no route to the delivery address`);
                $("#distance-failure").show();
                cart_scroll_to('.cart-options--user-location',true);
              
                $("#payment_gif").css("display" , "block");
               $(".checkout-actions #create-paypal-order").css("display" , "none");   
                $("#distance-success").hide();
                return resolve(false);
              }
              else if (status.success === false) {
                $(".distance-message .fa-refresh").hide();
                $(".not-available-in-only-rate").find('#create-paypal-order').data('outofrange',true)
                $("#distance-failure").text(status.errMsg);
                $("#distance-failure").show();
                cart_scroll_to('.cart-options--user-location',true);
              
		            $("#payment_gif").css("display" , "block");
               $(".checkout-actions #create-paypal-order").css("display" , "none");   
                $("#distance-success").hide();
                return resolve(false);
              } else if(res.inRange==false){
                console.log("hhjooo=33===")
                $(".distance-message .fa-refresh").hide();
                if (res && res.burq && res.burq.error){
                  $(".not-available-in-only-rate").find('#create-paypal-order').data('outofrange',true)
                  $("#distance-failure").text(`The address is ${distance_in_mile} miles out of range.`);
                  $("#distance-failure").show();
                 cart_scroll_to('.cart-options--user-location',true);
              
                  $("#distance-success").hide();
                }else if (res && res.inRange && !res.burq.error){
                  $(".not-available-in-only-rate").find('#create-paypal-order').data('outofrange',false);
                  $("#distance-success").show();
                  cart_scroll_to('.cart-options--user-location',true);
              
                  $("#distance-failure").hide();
                }else if (res && res.inRange){
                  $(".not-available-in-only-rate").find('#create-paypal-order').data('outofrange',false);
                  $("#distance-success").show();
               cart_scroll_to('.cart-options--user-location',true);
              
                  $("#distance-failure").hide();
                }else if (res && res.deliveryRadius && res.distance){
                  $(".not-available-in-only-rate").find('#create-paypal-order').data('outofrange',true)
                  $("#distance-failure").text(`Delivery radius is ${res.deliveryRadius} mile and you are ${res.distance} miles away`);
                  $("#distance-failure").show();
               cart_scroll_to('.cart-options--user-location',true);
              
                  $("#distance-success").hide();
                }else {
                  $(".not-available-in-only-rate").find('#create-paypal-order').data('outofrange',true)
                  $("#distance-failure").show();
                cart_scroll_to('.cart-options--user-location',true);
              
                  $("#distance-success").hide();
                }
                return resolve(res)
              }else{
                return resolve(res)
              }
              
            })
            
          });

        }
      }).catch((error)=>{
              console.log("reject====",error)
              return resolve(false)
            })
    };


    const updateDeliveryAddressValue = (newAddress, newAddressDetails) => {
      Order.updateOrderDetail('deliveryAddress', newAddress);
      Order.updateOrderDetail('deliveryAddressDetails', newAddressDetails);
      $('#delivery-address-edit-trigger').data('customTextEditTrigger').setData({
        deliveryAddress : newAddress,
        deliveryAddressDetails : newAddressDetails,
      })
  };


    const updateDeliveryAddress = ($street, $city, $state, $zipCode) => {
      const street = $street.val();
      const city = $city.val();
      const state = $state.val();
      const zipCode = $zipCode.val();
      //TODO: Validate adress with Google Geo API
      if (!$.isEmpty(street) && !$.isEmpty(city) && !$.isEmpty(state)  && !$.isEmpty(zipCode)) {
        let newAddress = `${street}, ${city}, ${state} ${zipCode}`;
        _updateDeliveryAddressValue(newAddress);
        $editAddressForm.collapse("hide");
        //clear inputs
        $street.val(" ");
        $city.val(" ");
        $state.val(" ");
        $zipCode.val(" ");
      } else {
        alert('All fields are required to update delivery address');
      }
    };

    window.updateDeliveryAddressValue = updateDeliveryAddressValue;
    window.updateDeliveryAddress = updateDeliveryAddress;
    window.detectUserDeliveryLocation = detectUserDeliveryLocation;

    //PUBLIC METHODS
    return {
      updateDeliveryAddress,
      updateDeliveryAddressValue,
      detectUserDeliveryLocation,
      checkDeliveryAddressInRange,
      checkDeliveryAddress
    };
  })();




  $(".allownumericwithoutdecimal").keypress(function (e) {
     if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) return false;
  });

  // get user tip
  function getUserTip (orderType) {
    let defaultTip = $('#default-tip-info').text();
    try {
      defaultTip = JSON.parse(defaultTip);
    } catch (error) {
      defaultTip = {
        default_delivery_tip: 10,
        default_pick_up_tip: 5,
        default_order_ahead_tip: 18,
      }
    }
    let tip = 0.15;
    switch(orderType) {
      case 'delivery':
        tip = Number(defaultTip.default_delivery_tip)/100;
        break;
      case 'pickup':
        tip = Number(defaultTip.default_pick_up_tip)/100;
        break;
        case 'order-ahead':
        tip = defaultTip && defaultTip.orderaheadPreferences && defaultTip.orderaheadPreferences.default_order_ahead_tip ? Number(defaultTip.orderaheadPreferences.default_order_ahead_tip)/100 : 0.15;
        break;
      default:
        tip = 0.15;
    }
    return tip;
  }


  const renderEligibleCreditLabel = (value) => {
    const $ELIGIBLE_CREDIT_LABEL       = $('.eligible-credits-label');
    const $ELIGIBLE_CREDIT_LABEL_VALUE = $('.eligible-credits-label-value');
    console.log("value====",value)
    if(value== 0 || value===undefined || NaN || null){
      console.log("value====",value)
      $("#ratearn").css("display",'none')
    }else{
      $ELIGIBLE_CREDIT_LABEL_VALUE.text(value);
    }

    if (value != 'hide' && Number(value) > 0) {
      $('#incentive-credits').show();
      $('.eligible-credits-label').show();
      $('#incentive-credits').data('value', value);
    } else {
      $ELIGIBLE_CREDIT_LABEL.hide();
      $('.eligible-credits-label').hide();
      $('#incentive-credits').data('value', '');
    }
  }
  /**
   * ORDER
   */
  const Order = (() => {

    //Internal states for the order
    const orderDetails = {
          orderType : 'delivery', //Default order type
          orderTime : "asap",
          orderDate: "asap",
          orderPayment: "paypal",
          orderTotal: null,
          orderId: null, //The id for the in-cart orderparent_order_id
          parentId: null, //The id for the in-cart orderparent_order_id
          hostUser: null, //The id for the in-cart orderparent_order_id
          deliveryAddress : null,
          specialInstructions: null,
          reserveSpace: null,
          textDateTime: null,
          peopleRSVPing: null,
          reservationHours: null,
          reservationMinutes: null,
          durationOrderAhead: null,
          invitesDay: null,
          myFriends: null,
          inWaitlist: false,
          loyaltyTimeSaver: null,
          noNapkinsPlasticware: true,
          delivery_contactless: null,
          delivery_contactless_note : null,
          userOrderTip: {
            tipLater: null,
            type: null,
            value: null
          },
          childOrders : [],
          businessType: 'Restaurant',
          mealPrepTimeSlot: null,
          deliverectChannelLinkid: null
    };

    const deniedToUseCurrentLocation = false;

    const clearOrderDetails = () => {
      if (orderDetails.userOrderTip.type ==='custom') {
        // $('#other-tip-percent').val(0);
        // $('#other-tip-amount').val(0);
        // $('.m-slider').val(0);
        $('#collapse-other-tip').collapse('hide');
      }
      orderDetails.orderType = 'delivery';
      orderDetails.orderTime = "asap";
      orderDetails.orderDate= "asap";
      orderDetails.orderPayment= "paypal";
      orderDetails.orderId = null;
      orderDetails.parentId = null;
      orderDetails.hostUser = null;
      orderDetails.deliveryAddress = null;
      orderDetails.specialInstructions = null;
      orderDetails.reserveSpace = null;
      orderDetails.textDateTime =null;
      orderDetails.peopleRSVPing = null;
      orderDetails.reservationHours = null;
      orderDetails.reservationMinutes = null;
      orderDetails.durationOrderAhead = null;
      orderDetails.invitesDay = null;
      orderDetails.myFriends = null;
      orderDetails.inWaitlist = false;
      orderDetails.loyaltyTimeSaver = null;
      orderDetails.delivery_contactless = '';
      orderDetails.delivery_contactless_note = '';
      orderDetails.noNapkinsPlasticware = true;
      orderDetails.userOrderTip = {
        tipLater: null,
        type: null,
        value: null
      };
      orderDetails.childOrders = [];
			if($('#orderdatepicker').data('DateTimePicker')){
        let getVenueId = $('#venueId').val();
        $.ajax({
        url: '/restaurant/LocalTime/'+getVenueId,
        type: 'GET',
        async: true,
        success : function(getRestaurantLocalTime){
            let timeZone = getRestaurantLocalTime.timeZone;
            let date = new Date();
            let countryTime = new Date(date.toLocaleString('en-US', {
              timeZone: timeZone
            }));
            let time = moment(countryTime).format("MM/DD/YYYY HH:mm A");
            $('#orderdatepicker').data('DateTimePicker').date(time)
          }
        });
				//$('#orderdatepicker').data('DateTimePicker').date(new Date())
			}


      $('.order-hour').val('h');
      $('.order-minute').val('m');
      $('.order-apm').val('am');
      clearOrderAhead();
      renderEligibleCreditLabel('hide');
    };

    const clearOrderAhead = () => {
      let max_hours = $('#orderAheadMaxHour').val();
      let max_minutes = $('#orderAheadMaxMinute').val();
      let total_time = Number(max_hours) * 60  + Number(max_minutes)
      if(!total_time || total_time > 90){
        max_hours = 1;
        max_minutes = 30
      }
      $( document ).ready(function() {
        let getVenueId = $('#venueId').val();
        if(getVenueId && typeof getVenueId !== undefined)
          $("input[name='order-ahead-date']").datetimepicker();

          $.ajax({
              url: '/restaurant/LocalTime/' + getVenueId,
              type: 'GET',
              async: true,
              success: function(getRestaurantLocalTime) {
                  let timeZone = getRestaurantLocalTime.timeZone;
                  let date = new Date();
                  console.log("date++++++++++++++", date);
                  let countryTime = new Date(date.toLocaleString('en-US', {
                      timeZone: timeZone
                  }));
                  let time = moment(countryTime).format("MM/DD/YYYY HH:mm A");
                  console.log("time++++++++++++++", time);

                  // Check if DateTimePicker is properly initialized before setting the date
                  let dateTimePicker = $("input[name='order-ahead-date']").data('DateTimePicker');
                  if (dateTimePicker) {
                      dateTimePicker.date(time);
                  } else {
                      console.log("DateTimePicker is not initialized.");
                  }
              }
          });
   });


      $('.order-ahead-hour').val('h');
      $('.order-ahead-minute').val('m');
      $('.order-ahead-apm').val('am');
      $('#order-ahead-Instructions').val('');
      $('#space-people').val('');
      $('#reservation-hours').val(max_hours);
      $('#reservation-minutes').val(max_minutes);
      $('#invites-day').val(1);
      $('#email-my-friends').val('');
      $("#invites-friends-order-ahead").empty();
      $('#input-email-my-friends').show();
      $('.available-request-order-ahead').show();
      $('.not-available-request-order-ahead').hide();
      $('.myorders-nav').find('a[name="only_rate"]').parent().removeClass('disabled');
      $('.myorders-nav').find('a[name="delivery"]').parent().removeClass('disabled');
      $('.myorders-nav').find('a[name="pickup"]').parent().removeClass('disabled');
      $('#btn-update-order-ahead').data('addmails',false);
    }

    let intervalStart, timeIntervals = [];
    const $specialInstructions = $("#specialInstructions");
    //Update an spefic detail in the order
    const updateOrderDetail = (key, value, persist = false, change_type = false) => {
      orderDetails[key] = value || '';
      //Currently only submit the orderType or orgAmount update to be
      //updated on DB. But we might want to persist other order details as well
      if (key == 'code') { //hack for 505 error ER_BAD_FIELD_ERROR: Unknown column 'code' in 'field list'
          persist = false;
      }
      if(change_type) {
        orderDetails.userOrderTip = {
          tipLater: null,
          type: null,
          value: null
        }
      }
      if (persist){
        let updateData={};
        if (key === 'orderType') {
          updateData.order_type = value
        }
        else if (key === 'organization_payment') {
          updateData.organization_payment = value
        }
        return _submitOrderUpdates(orderDetails.orderId, updateData);
      }
      //Return the update value;
      return new Promise((resolve, reject) => {
          resolve(orderDetails[key]);
      });
    };
    //retrieve an spefic value from order details
    const getOrderDetail = (key) => {
      return orderDetails.hasOwnProperty(key) ? orderDetails[key] : new Error("Key is not on order details");
    };
    //Retrieve all the order details
    const getOrderDetails = (updateFromView = false, createType = false) => {
      if (updateFromView){
        if(createType == 'order-ahead') {
          let _date = $('#order-ahead-date').val();
          orderDetails['orderDate'] = moment(_date);
          let _time = $('.order-ahead-hour').val() + ':' + $('.order-ahead-minute').val() + ' ' + $('.order-ahead-apm').val()
          orderDetails['orderTime'] = moment(_date + ' ' + _time);
          orderDetails['textDateTime'] =  'on '+$('#order-ahead-date').val() + ' at '+ _time;
          orderDetails['specialInstructions'] = $('#order-ahead-Instructions').val();
          orderDetails['reserveSpace'] = $('#space-people').val() || '';
          orderDetails['reservationHours'] = $('#reservation-hours').val() || '';
          orderDetails['reservationMinutes'] = $('#reservation-minutes').val() || '';
          orderDetails['durationOrderAhead'] = $('#reservations-duration-time').val() || null;
          orderDetails['invitesDay'] = $('#invites-day').val() || '';
          orderDetails['myFriends'] = [];
          let current_emails = $('#email-my-friends').val() || '';
          let add_emails = $('#invite-additional-people').val() || '';
          let addmails = $('#btn-update-order-ahead').data('addmails');
          if(addmails){
            _.forEach(add_emails.split(','),(item)=>{
              if(!_.includes(current_emails,item)){
                orderDetails['myFriends'].push({email: item, sent:false})
              }
            })
          }else{
            _.forEach(current_emails.split(','),(item)=>{
                orderDetails['myFriends'].push({email: item, sent:false})
            });
          }
          let delivery_contactless = false;

           if($('#check-delivery-contactless').is(":checked")){
            delivery_contactless = true;
           }
           orderDetails['deliveryContactless'] = delivery_contactless;
          return orderDetails;
        } else {
      
          let specialInstructions = $('#specialInstructions').val() || 'None'; //not using .text() since it was not getting entered value
          let deliveryAddress = $("#delivery-address").text();
          let noNapkinsPlasticware = $('#napkins').is(':checked')
          let deliveryMethod = $('#restDeliveryMethod').val();

          orderDetails['orderDate'] = 'asap'
          orderDetails['reservationHours'] = $('#reservation-hours').val() || '';
          orderDetails['reservationMinutes'] = $('#reservation-minutes').val() || '';
          orderDetails['durationOrderAhead'] = $('#reservations-duration-time').val() || null;
          orderDetails['mealPrepTimeSlot'] = $('.meal-prep-time-slots').val() || null;

          if($("#later").is(":checked")) orderDetails['orderDate'] = moment($("#orderdatepicker").val());
          if($("#later").is(":checked")) orderDetails['orderTime'] = moment($("#orderdatepicker").val());
          if($("#later").is(":checked")) orderDetails['pickuporderTime'] = $("#orderdatepicker").val();

          let delivery_contactless = false;

           if($('#check-delivery-contactless').is(":checked")){
            delivery_contactless = true;
           }
           orderDetails['deliveryContactless'] = delivery_contactless;

           return {...orderDetails, specialInstructions, deliveryAddress, noNapkinsPlasticware, deliveryMethod};
        }
      }
      return orderDetails;
    };

    const _updateOrderTip = (type, value, tipLater = null) => {
      orderDetails.userOrderTip.type = type;
      orderDetails.userOrderTip.value = value;
      orderDetails.userOrderTip.tipLater = tipLater;
    };

    $("#specialInstructions").on("blur", function(e) {
       var orderId = Order.getOrderDetail('orderId');
       let updateData={};
       updateData.special_instructions = $(this).val();
       _submitOrderUpdates(orderId, updateData)
    });

    const updateSpecialInstructions = (userInstructions) => {
      orderDetails["specialinstructions"] = userInstructions;
      $specialInstructions.val(userInstructions);
    };

    //Submit given update fields(in MySQL format) for given orderId
    const _submitOrderUpdates = (orderId, updateFields) => {
     
      if (orderId && updateFields) {
        if($('#distance-failure').css('display') == 'block'){
          // $(document).scrollTop( $("#distance-failure").offset().top - 100 )
                cart_scroll_to('.cart-options--user-location',true);     
        }
        $("#payment_gif").css("display" , "block");
        $(".checkout-actions #create-paypal-order").css("display" , "none");       
     
        return $.promisedAjax({
            url: `/orders/update?orderId=${orderId}`,
            type: 'POST',
            data: JSON.stringify({
              updateFields: updateFields,
              venueId: VENUE_ID,
              childOrders: (updateFields.order_type)? getOrderDetail('childOrders') : []
            }),
            contentType: 'application/json',
            dataType: 'json'
          }).then((res) => {
            $("#payment_gif").css("display" , "none");
            $(".checkout-actions #create-paypal-order").css("display" , "block");       
     
            return res;
            
          })
          .catch((err) => {
            console.error('Unable to update order values', err);
          })
      }
      return new Promise((resolve, reject) => {
        reject("Require orderId && updateFields params");
      });
    };

    /* Set available delivery/pickup time intervals */
    const setOrderTimeIntervals = () => {
      $("#orderTime").empty().append('<option class="dropdown-option" value=null>HH:MM</option>')
      /* if delivery/pickup is today start with current time
      if not start with opening time */
      const orderDate = Order.getOrderDetail("orderDate");
      if(orderDate === formatDate(TODAY_DATE)) {
        checkIfClosed(RESTAURANT_CLOSE_TIME, RESTAURANT_LAST_ORDER_CALL) ? $closedToday.show() : $closedToday.hide()
        intervalStart = (Math.ceil(((TODAY_DATE.getHours() * 60) + TODAY_DATE.getMinutes()) / 10 ) * 10) + RESTAURANT_DELIVERY_INTERVALS
      } else {
        $closedToday.hide()
        intervalStart = RESTAURANT_OPEN_TIME;
      }

      for (let i=0; intervalStart <= (RESTAURANT_CLOSE_TIME - RESTAURANT_LAST_ORDER_CALL); i++) {
        let hh = Math.floor(intervalStart / 60); // getting hours of day in 0-24 format
        let hhFormated = (hh % 12);
        if(hhFormated == 0) { hhFormated = 12 } // format 0 to 12
        let mm = (intervalStart % 60); // getting minutes of the hour in 0-55 format

        timeIntervals[i] = ("0" + hhFormated).slice(-2) + ':' + ("0" + mm).slice(-2) + " " +RESTAURANT_DELIVERY_PERIOD[Math.floor(hh/12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
        intervalStart = intervalStart + RESTAURANT_DELIVERY_INTERVALS;

        $("#orderTime").fadeIn().append('<option value="' + timeIntervals[i] + '">' + timeIntervals[i] + '</option>')
      }

      if($('#later').is(':checked')) {
        updateOrderDetail('orderTime', timeIntervals[0]);
      }
    };
    function isValidDate(dateString) {
      // Use moment to check if the date is valid
      return moment(dateString, 'MM/DD/YYYY', true).isValid();
    }
    //Update the order time options
    const updateOrderTimeOptions = () => {
      const $orderTimeOptions = $('.pick-order-date-time');
      const isMealPreps = $('#is-meal-preps').val();
      const isRestaurant = $('#is-restaurant').val();
      if($('#later').is(':checked')) {
        
        let _date = moment(date);
        let tomorrow  = moment().add(1,'days');
        let tomorrowdate=tomorrow.format('MM/DD/YYYY')
        
        var time = "12:00";
        var tomorrowdatetime = moment(tomorrowdate + ' ' + time);
        console.log("tomorrowval===",tomorrowdatetime)
        let date;
        let date2 = $('#orderdatepicker').val();
        var result = isValidDate(date2)
        console.log("date2++++++++++++",date2)
        let orderType = localStorage.getItem('orderType')
        if(isMealPreps==="true" && result && (orderType=="pickup" || orderType=="delivery")){
          date = $('#orderdatepicker').val(moment(date2).format('MM/DD/YYYY'));
        }else if(isRestaurant && isRestaurant==='true'){
          date = $('#orderdatepicker').val(tomorrowdatetime.format('MM/DD/YYYY h:mm:ss A'));
        }else{
          date = $('#orderdatepicker').val(tomorrowdatetime.format('MM/DD/YYYY h:mm:ss A'));
        }
        
        let _time = $('.order-hour').val() + ':' + $('.order-minute').val() + ' ' + $('.order-apm').val()
        _time = moment(date + ' ' + _time);

        updateOrderDetail('orderDate', _date);
        updateOrderDetail('orderTime', _time);
        $('.optradio').val("later")
        $orderTimeOptions.show();
      } else if ($('#asap').is(':checked')) {
        updateOrderDetail('orderTime', "asap");
        updateOrderDetail('orderDate', "asap");
        sessionStorage.setItem("ordertypeoption", "asap");
        //setTransactionDate(TODAY_DATE);
        const datePicker = $('#orderdatepicker').data('DateTimePicker');
        if (datePicker) {
        let getVenueId = $('#venueId').val();
        if(getVenueId && typeof getVenueId !== undefined)
          $.ajax({
            url: '/restaurant/LocalTime/'+getVenueId,
            type: 'GET',
            async: true,
            success : function(getRestaurantLocalTime){
                let timeZone = getRestaurantLocalTime.timeZone;
                let date = new Date();
                let countryTime = new Date(date.toLocaleString('en-US', {
                  timeZone: timeZone
                }));
                let time = moment(countryTime).format("MM/DD/YYYY HH:mm A");
                $('#orderdatepicker').data('DateTimePicker').date(time)
              }
          });
        //$('#orderdatepicker').data('DateTimePicker').date(new Date())
        }
        $('.optradio').val("asap")
        $orderTimeOptions.hide();
      }
    };

    const _showEditOrderTipModal = (checkout = false, calculateSubOrder = false , type = false , info = false) => {
      /*if ( info  == "pickup" ) {
            $('#edit-delivery-tip-container h3').text('Select Pickup Tip');
            $('#edit-delivery-tip-container #tip-cash').show();
            $('#edit-delivery-tip-container #custom').css("display" , "none");
            $('#edit-delivery-tip-container #percent10').show();
            $('#edit-delivery-tip-container #percent3').val(0.03);
            $('#edit-delivery-tip-container #percent3').text("3%");
            $('#edit-delivery-tip-container #percent5').val(0.05);
            $('#edit-delivery-tip-container #percent5').text("5%");
            $('#edit-delivery-tip-container #percent7').val(0.07);
            $('#edit-delivery-tip-container #percent7').text("7%");
            if($(`#pay-tip-${checkout}`).length > 0 ){
              $('#edit-delivery-tip-container #tip-later-model').css("display" , "none");
            }
      } else if ( info == "order-ahead" ) {
            $('#edit-delivery-tip-container h3').text('Select Order-Ahead Tip');
            $('#edit-delivery-tip-container #tip-cash').show();
            $('#edit-delivery-tip-container #custom').show();
            $('#edit-delivery-tip-container #tip-later-model').css("display" , "none");
            $('#edit-delivery-tip-container #percent10').css("display" , "none");
            $('#edit-delivery-tip-container #percent3').val(0.15);
            $('#edit-delivery-tip-container #percent3').text("15%");
            $('#edit-delivery-tip-container #percent5').val(0.20);
            $('#edit-delivery-tip-container #percent5').text("20%");
            $('#edit-delivery-tip-container #percent7').val(0.25);
            $('#edit-delivery-tip-container #percent7').text("25%");
      } else {
            $('#edit-delivery-tip-container h3').text('Select Delivery Tip');
            $('#edit-delivery-tip-container #tip-cash').show();
            $('#edit-delivery-tip-container #custom').show();
            $('#edit-delivery-tip-container #percent10').css("display" , "none");
            $('#edit-delivery-tip-container #percent3').val(0.15);
            $('#edit-delivery-tip-container #percent3').text("15%");
            $('#edit-delivery-tip-container #percent5').val(0.20);
            $('#edit-delivery-tip-container #percent5').text("20%");
            $('#edit-delivery-tip-container #percent7').val(0.25);
            $('#edit-delivery-tip-container #percent7').text("25%");
            if($(`#pay-tip-${checkout}`).length > 0 ){
              $('#edit-delivery-tip-container #tip-later-model').css("display" , "none");
            }
      }*/
      if ( type == 'chosseTip' ) {
          checkChooseTip = true;
      }
      if ( type  == 'pay-tip' ) {
          isShowModalTips = true;
      }
      if ( type = 'pay-order' ) {
          checkPayOrder = true;
      }
      let _subTotal;
      let price_order_detail =  parseFloat($(`#sub-order-items-${checkout} .value-total-price`).text().replace('$' , ''));
      const $updatedTip = $(".updated-tip-amt");
      // let order_tip_percent = $("#cart-total .order-tip-percent").text()
      _subTotal= parseFloat($(`#order-checkout-totals-${checkout} .price-subtotal`).text().replace('$' , ''));
      $updatedTip.text('');
      if(!price_order_detail){
          price_order_detail = _subTotal;
      }
      if(!price_order_detail){
        price_order_detail = parseFloat($(`#cart-items-totals .sub-total`).text().replace('$' , ''));
      }
      if (checkout) {
        $('#order-tip-update').hide();
        $('#checkout-tip-update').show();
        $('.update-checkout-tip').data('order-id', checkout);
        $('.update-checkout-tip').data('sub-order', calculateSubOrder);
        let _tipType   = $(`#temp-checkout-tip-type-${checkout}`).val();
        let _tipAmt    = $(`#temp-checkout-tip-value-${checkout}`).val();
        let tip_amount_to_usd = ((_tipAmt*100) * price_order_detail) / 100;
        tip_amount_to_usd = parseFloat(Math.round(tip_amount_to_usd * 100) / 100).toFixed(2);
         if (_tipType == "custom") {
          $updatedTip.text(`$${parseFloat(Math.round(_tipAmt * 100) / 100).toFixed(2)}`);
          $("#custom-tip-amt").val(_tipAmt);
          $("#custom-tip-per-amt").val('');
        } else if (_tipType == "percent") {
          _tipAmt = _tipAmt*100;
          if(!isNaN(tip_amount_to_usd)){
            $updatedTip.text(`${_tipAmt}% - $${tip_amount_to_usd}`);
          }else{
            $updatedTip.text(`${_tipAmt}%`);
          }
          $("#custom-tip-per-amt").val(_tipAmt);
          $("#custom-tip-amt").val('');
        }
      } else {
        $('#order-tip-update').show();
        $('#checkout-tip-update').hide();
        $('.update-checkout-tip').data('order-id', '');
        let _orderDetails = getOrderDetails();
        let _tipType   = _orderDetails.userOrderTip.type;
        let _tipAmt    = _orderDetails.userOrderTip.value;
        _tipAmt = parseFloat(Math.round(_tipAmt * 100) / 100).toFixed(2);
        if (_tipType == "custom") {
          $updatedTip.text(`$${_tipAmt}`);

          $("#custom-tip-amt").val(_tipAmt);
          $("#custom-tip-per-amt").val('');
        } else if (_tipType == "percent") {
          _tipAmt = (_tipAmt*100).toFixed(2);
          let _tipToUsd = (_tipAmt * price_order_detail) / 100;
          _tipToUsd = parseFloat(Math.round(_tipToUsd * 100) / 100).toFixed(2);
          if(!isNaN(_tipToUsd)){
            $updatedTip.text(`${_tipAmt}% - $${_tipToUsd}`);
          }else{
            $updatedTip.text(`${_tipAmt}%`);
          }
          $("#custom-tip-per-amt").val(_tipAmt);
          $("#custom-tip-amt").val('');
        }
      }
      //$('.order-tip-btn').unbind("click");
      $(document).on('click', '#edit-delivery-tip-container', function(e) {
      let btnValue =  $(e.target).attr('value');

        switch (btnValue) {
          case "custom-per":
              //Show modal to update custo tip amt
              $('#upate-custom-tip-per').unbind("click");
              $('#custom-tip-per-container').modal();
              $('#upate-custom-tip-per').on('click', (e) => {
                let $tip = $('#custom-tip-per-amt').val();
                let tipAmt = ($tip !== '') ? parseFloat($tip) : 0;
                if (tipAmt >= 0) {
                  $updatedTip.text(`${tipAmt}%  -  $${parseFloat(Math.round((tipAmt * price_order_detail) / 100 * 100) / 100).toFixed(2)}`);
                  tipAmt = parseFloat(tipAmt/100).toFixed(2);
                  if (checkout){
                    $(`#temp-checkout-tip-type-${checkout}`).val("custom");
                    $(`#temp-checkout-tip-value-${checkout}`).val(tipAmt);
                  } else {
                    _updateOrderTip("custom", tipAmt);
                  }
                } else {
                  Swal.fire({
                    title: "Valid Tip % Amount is required",
                    icon: "error",
                    confirmButtonColor: "#3cb371",
                  })
                }
              })
            break;
          case "custom":
              //Show modal to update custo tip amt
              $('#upate-custom-tip').unbind("click");
              $('#custom-tip-container').modal();
              $('#upate-custom-tip-per').on('click', (e) => {
                let $tip = $('#custom-tip-amt').val();
                let tipAmt = ($tip !== '') ? parseFloat(Math.round($tip/ 100)).toFixed(2) : 0;
                if (tipAmt >= 0) {
                  $updatedTip.text(`$${tipAmt}`);
                  if (checkout){
                    $(`#temp-checkout-tip-type-${checkout}`).val("custom");
                    $(`#temp-checkout-tip-value-${checkout}`).val(tipAmt);
                  } else {
                    _updateOrderTip("custom", tipAmt);
                  }
              } else {
                   Swal.fire({
                    title: "Valid Tip $ Amount is required",
                    icon: "error",
                    confirmButtonColor: "#3cb371",
                  })
                }
              })
            break;
          case "cash" :
              $updatedTip.text(`cash`);
              if (checkout){
                $(`#temp-checkout-tip-type-${checkout}`).val("cash");
                $(`#temp-checkout-tip-value-${checkout}`).val(null);
              } else {
                _updateOrderTip("cash", null);
              }
              break;
          case "tip-later" :
              $updatedTip.text(` Tip in-app after you get your food.`);
              _updateOrderTip('percent', null, 'later');
              break;
          default:
            let tipPrice = ((btnValue*100) / 100) * price_order_detail;

            tipPrice = parseFloat((tipPrice)).toFixed(2);

            let percent  = btnValue*100 ;
            if ( percent ==  7.000000000000001 ) {
                  percent = 7
            }
            if(!isNaN(tipPrice)){
              $updatedTip.text(`${percent}%  -  $${tipPrice}`);
            }else{
              $updatedTip.text(`${percent}%`);
            }
            let percentage = parseFloat(btnValue);
            if (checkout){
              $(`#temp-checkout-tip-type-${checkout}`).val("percent");
              $(`#temp-checkout-tip-value-${checkout}`).val(percentage);
            } else {
              _updateOrderTip("percent", percentage);
            }
            break;
        }
      });

     $('#custom-tip-per-container').modal();
    }

    // Handle update delivery tip
    $('body').on('click', ".update-delivery-tip", (e) => {
      $('#custom-tip-per-container').modal();
      Order.renderOrderTotals();
    })
     $('body').on('click', "#saveDeliveryAddress", (e) => {
      initializeAutocompleteAddress();

      setTimeout(function(){
       let aa=$("#delivery-address").text();
       console.log("aa=====",aa)
       if(aa!='Click Edit to set Delivery address'){
         sessionStorage.setItem("orderupdateAddress", aa);
         console.log("in renderOrderTotals  orderDetails.", aa);
         Order.renderOrderTotals();
       }
        
     },500);
     });

    //For close update tip popup
    $("#close_edit_tip_modal").on('click',function(){
    		$('#custom-tip-per-container').modal('toggle');
    })

    // Handle update checkout tip
    $('body').on('click', ".update-checkout-tip", (e) => {
      const orderId = $(e.target).data('order-id');
      const isSubOrder = $(e.target).data('sub-order');
      const orderDetails = {
          orderId : orderId,
          orderType : $(`#temp-checkout-order-type-${orderId}`).val(),
          userOrderTip : {
            tipLater : (isSubOrder) ? null : 'later',
            type     : $(`#temp-checkout-tip-type-${orderId}`).val(),
            value    : ($(`#temp-checkout-tip-value-${orderId}`).val()) ? $(`#temp-checkout-tip-value-${orderId}`).val() : 0
          },
        };
        let type = isSubOrder ? 'sub-order' : 'order';
      calculateCheckoutTip(orderDetails, {calculateTipOnly: true, type : type}).then((res) => {
        const cartTotals = res.data;
        const orderTipType = cartTotals.tip_type;

        if (isSubOrder){
          SubOrder.loadSubTotal(orderId);
          // Order.checkForOrdersInCheckout(null, orderId, {noScroll: true});
        } else {
          if (cartTotals.tip == 0){
            $(`#checkout-completed-btn-${orderId}`).data('no-tip', true);
            $(`#pay-tip-${orderId}, #tip-status-${orderId}`).hide();
          } else {
            $(`#checkout-completed-btn-${orderId}`).data('no-tip', false);
            $(`#pay-tip-${orderId}, #tip-status-${orderId}`).show();
          }

          if (orderTipType) {
              const tipPercent     = (orderTipType == "percent") ? (cartTotals.tip * 100) : null;
              const orderTipAmt    = (orderTipType !== "cash") ? cartTotals.tip_amount.toFixed(2) : 'Cash Tip';
              const tipPercentText = (orderTipType == "percent" && tipPercent >= 0) ? `(${tipPercent.toFixed(0)}%)` : '';
              $(`#checkout-tip-percentage-${orderId}`).text(tipPercentText);
              $(`#checkout-tip-value-${orderId}`).text(`$${orderTipAmt}`);
              renderTipFreeFood(orderId);
          }
        }
      });
    })

    //Handler to receieve events to edit delivery tip
    const _editUserOrderTipHandler = (e) => {
        e.preventDefault();
        let info = $('#incart-state-container').find('.active').find('a').attr('name') || $('#incart-state-container').find('.active').attr('name');
        const $this = $(e.target);
        _showEditOrderTipModal(false, false, 'chosseTip', info);
    };

    /**
    * Send request to calculate cart totals
    */
    const _getOrderTotals = () => {
      var orderId='';
      $("#payment_gif").css("display" , "none");
      $(".checkout-actions #create-paypal-order").css("display" , "block");       
       if($('#distance-failure').css('display') == 'block'){
               cart_scroll_to('.cart-options--user-location',true);
                
        }
      if(orderDetails.orderId){
        orderId =  orderDetails.orderId;
        console.log("in _getOrderTotals...for orderId..", orderId);
        $("#delivery-address-edit-trigger-container").css('display', 'block');
       }else{
        orderId = JSON.parse(sessionStorage.getItem('orderId'))
        console.log("in _getOrderTotals...for orderId from storage..", orderId);
       }
        sessionStorage.removeItem('orderId');
      let   delivery_contactless = "contactless";
      let   describe_delivery_contactless = $('#describe-delivery-contactless').val();

      if(!$('#check-delivery-contactless').is(":checked")){
            delivery_contactless = "contact";
      }
      let partnerId ='';
       partnerId=$("#partnerId").val()
        const data = {
          userId: USER_ID,
          venueId: VENUE_ID,
          restaurantPostalCode: RESTAURANT_POSTAL_CODE,
          orderType: orderDetails.orderType,
          userOrderTip: orderDetails.userOrderTip,
          freeFood: IS_FREE_FOOD,
          restaurantFreeFood: RESTAURANT_FREE_FOOD,
          platerateFreeFood: PLATERATE_FREE_FOOD,
          orderId: orderId,
          delivery_contactless: delivery_contactless,
          describe_delivery_contactless :describe_delivery_contactless,
          partnerId:partnerId
        };

        let urlTotal = '/orders/total';
        // if(COMPLEX_ORDER_TYPES.includes(orderDetails.orderType)) {
        //   urlTotal = '/orders/order-ahead/total';
        // }
/* this is leading to payment error maybe coz 2 backend calls
        var paymentStatus='';
          $.ajax({
            url: '/orders/gettotal',
            type: 'GET',
            data: {'orderId':data.orderId},
            contentType: 'application/json',
            dataType: 'json',
            error: function(e){

            },
            success: function(results){
              paymentStatus=results.data[0] ? results.data[0].payment_status :'';
            }
          })
        if(paymentStatus!='unpaid' && data.orderId){
*/
        $('#create-paypal-order, #rate-menu-items, #btn-resquest-order-ahead, #btn-update-order-ahead').data("order-total-state", 'calculating');
        return $.promisedAjax({
                url: urlTotal,
                type: 'POST',
                data: JSON.stringify(data),
                contentType: 'application/json',
                dataType: 'json',
              }).then((result) => {
               
                $(".sweet-overlay").css("display","none");
                $(".calculating-order").css("display","none");
                $('#create-paypal-order, #rate-menu-items, #btn-resquest-order-ahead, #btn-update-order-ahead').data("order-total-state", '');
                return result;
              }).catch(e => {
                $(".sweet-overlay").css("display","none");
                $(".calculating-order").css("display","none");
                $('#create-paypal-order, #rate-menu-items, #btn-resquest-order-ahead, #btn-update-order-ahead').data("order-total-state", '');
                return e;
              });
      };
    /**
     * Get and Render the cart total to cart total container
     */

    // $('body').on('change', '#use-free-food', function(){
    //   IS_FREE_FOOD = $(this).is(':checked')
    //   renderOrderTotals();
    // })

    /**Input free food */
    $('body').on('input', '#restaurant-free-food, #platerate-free-food', _.debounce(()=>{
      let orderInfo = $CART_TOTALS_CONTAINER.find('#cart-total');
      let subTotal = orderInfo.find('.sub-total').text();
      subTotal = _.replace(subTotal,/([$\s,])+/g,'')
      let saleTax = orderInfo.find('.sales-tax').text();
      saleTax = _.replace(saleTax,/([$\s,])+/g,'')
      let tip = orderInfo.find('.order-tip').val() || 0;
      tip = _.replace(tip,/([$\s,])+/g,'')

      let totalPrice = Number(subTotal) + Number(saleTax) + Number(tip);
      let restaurantCredit = $('#restaurant-free-food').val() || 0;
      restaurantCredit = _.replace(restaurantCredit,/([$\s,])+/g,'');
      restaurantCredit = Number(restaurantCredit);
      RESTAURANT_FREE_FOOD = restaurantCredit;
      let platerateCredit = $('#platerate-free-food').val() || 0;
      platerateCredit = _.replace(platerateCredit,/([$\s,])+/g,'');
      platerateCredit = Number(platerateCredit);
      PLATERATE_FREE_FOOD = platerateCredit;
      let payPal = totalPrice - restaurantCredit - platerateCredit;
      IS_FREE_FOOD = false;
      orderInfo.find('.order-total').text(`$${parseFloat(payPal).toFixed(2)}`);
      $("#create-paypal-order").prop('disabled', true);

      renderOrderTotals().finally(res => {
        $("#create-paypal-order").prop('disabled', false);
      })
    },1000))

    $('.btn-cancel-order').on('click', function(){
      const orderId  = Order.getOrderDetail('orderId')
      console.log("going to cancel orderid..", orderId);
      if(!orderId) return toastr.warning("You don't have any open order", "", {"positionClass": "toast-bottom-right"})
    })

    const renderUpdateTotals = () => {
      return _getOrderTotals()
      .then((response) => {
      if(response && response.data){
        const cartTotals = response.data;
        const itemsSubtotal = cartTotals.taxable_subtotal ? cartTotals.taxable_subtotal.toFixed(2) : '0.00';
        const salesTax = cartTotals.tax_amount.toFixed(2);
        const credit_restaurant = cartTotals.credit_restaurant && !isNaN(Number(cartTotals.credit_restaurant)) ? Number(cartTotals.credit_restaurant).toFixed(2) : '0.00'
        const credit_platerate = cartTotals.credit_platerate && !isNaN(Number(cartTotals.credit_platerate)) ? Number(cartTotals.credit_platerate).toFixed(2) : '0.00'
        const restaurant_free_food = cartTotals.restaurant_free_food && !isNaN(Number(cartTotals.restaurant_free_food)) ? Number(cartTotals.restaurant_free_food).toFixed(2) : '0.00'
        const platerate_free_food = cartTotals.platerate_free_food && !isNaN(Number(cartTotals.platerate_free_food)) ? Number(cartTotals.platerate_free_food).toFixed(2) : '0.00'
        const totalPrice = parseFloat(cartTotals.total_price).toFixed(2);
        const credit_restaurant_max = cartTotals.credit_restaurant_max
        const credit_platerate_max = cartTotals.credit_platerate_max
        const $groupTotal = $CART_TOTALS_CONTAINER.find('#cart-total');
        const delivery_contactless = cartTotals.delivery_contactless;
        const delivery_contactless_note = cartTotals.delivery_contactless_note;
        sessionStorage.setItem('agreedcreditExpiresAfterPeriod-'+cartTotals.credits_info.orderId, cartTotals.credits_info.agreedcreditExpiresAfterPeriod || false);
        sessionStorage.setItem('totalCreditsEarn-'+cartTotals.credits_info.orderId, cartTotals.credits_info.totalCredits );
        sessionStorage.setItem('total_credits_infor-'+cartTotals.credits_info.orderId, JSON.stringify(cartTotals.credits_info) );

        console.log("cartTotals.restaurant_free_food========>",cartTotals.restaurant_free_food)
        console.log("restaurant_free_food========>",restaurant_free_food)

        TYPE_FREE_FOOD = true;
        $(".btn-payment").text('Pay');
        if(cartTotals.total_price == 0) {
          $(".btn-payment").text('Pay with free food');
          TYPE_FREE_FOOD = false;
        }
        if(delivery_contactless == ""){
          $('.show-delivery-contactless').css('display', 'none');
        }else{
          $('.show-delivery-contactless').css('display', 'block');
          if(delivery_contactless == "contactless"){
            // $('#check-delivery-contactless').bootstrapToggle('on');
            //$('#show-note-delivery-contactless').css('display', 'block');
          }else{
            // $('#check-delivery-contactless').bootstrapToggle('off');
            $('#show-note-delivery-contactless').css('display', 'none');
          }
        }

        if (!COMPLEX_ORDER_TYPES.includes(orderDetails.orderType)){
          const orderTipType = cartTotals.tip_type;
          const orderTipLater = cartTotals.tip_later;
          if (orderTipType && !orderTipLater) {
              const tipPercent = (orderTipType === "percent") ? (cartTotals.tip * 100) : null;
              const orderTipAmt = (orderTipType !== "cash") ? cartTotals.tip_amount.toFixed(2) : 'Cash Tip';
              $groupTotal.find('.order-tip').val(`$${orderTipAmt}`);
              // if (tipPercent){
              //   $groupTotal.find('.order-tip-percent').text(`(${tipPercent.toFixed(0)}%)`);
              // }

          }
          renderListTip(cartTotals,orderDetails.orderType);
        }
        updateOrderDetail('orderTotal', totalPrice);
        $groupTotal.find('.sub-total').text(`$${itemsSubtotal}`);
        $groupTotal.find('.sales-tax').text(`$${salesTax}`);
        $groupTotal.find('.total-restaurant-free-food').text(`($${credit_restaurant})`);
        $groupTotal.find('#restaurant-free-food').val(`$${restaurant_free_food}`).data('max', credit_restaurant_max);
        $groupTotal.find('.total-platerate-free-food').text(`($${credit_platerate})`);
        $groupTotal.find('#platerate-free-food').val(`$${platerate_free_food}`).data('max', credit_platerate_max);
        $groupTotal.find('.order-total').text(`$${totalPrice}`);
        $("#create-paypal-order").prop('disabled', false);
       }
       else {
       updateOrderDetail('orderTotal', "error");
      }
      })
      .catch((err) => {
        console.log("in renderuptotals exception....", err);
        updateOrderDetail('orderTotal', "error");
        // $CART_TOTALS_CONTAINER.html(`
        //   <label class="badge badge-danger"
        //          style="padding: 5px 10px;background-color:#c30000">
        //     Unable to calculate cart totals
        //   </label>
        //   `);
      })
    }

    let check_order_containing_alcohol = (items, order_type)=>{
      $('#order-containing-alcohol').hide()
      let alert_text = ''
      if(order_type == 'delivery'){
        alert_text = 'Note: you will be asked for a photo Id from a delivery person, because you are buying alcohol.'
      }else{
        alert_text = 'Note: you will be asked for a photo ID because you are buying alcohol.'
      }
      _.forEach(items, item =>{
        let item_infor = findMenuItemInDataTree(item.item_id, dataTree)
        if(item_infor && item_infor['alcohol']){
          $('#order-containing-alcohol').find('.alert').text(alert_text)
          $('#order-containing-alcohol').show()
          return false
        }
      })

    }

   

    const renderOrderTotals = (type, resetTip=false, tips=false) => {
      $('#order-container').find('#tipContainer').css({"pointer-events": "none"});
      $(".order-total-info").html(`$<span class="loading-span"><span> -</span><span> -</span><span> -</span></span>`)
      $(".order-total-action").prop('disabled', true)
        console.log("tips==33==",tips)
        const noSalesOnTheSystem = ($NO_SALES_ON_THE_SYSTEM.val() === 'true');
        const orderDetails = getOrderDetails();
        
        // let changeaddress=$("#delivery-address").text();
        //console.log("changeaddress====",changeaddress)

	     var orderupdateAddress = (sessionStorage.getItem('orderupdateAddress'))
        console.log("orderAddress=77=1==",orderupdateAddress)
        if(orderupdateAddress==null || orderupdateAddress==''){
            let  deliveryAddress  = $('#delivery-address-edit-trigger').data('customTextEditTrigger').getData();
            orderDetails.deliveryAddress=deliveryAddress.deliveryAddress;
            console.log("deliveryAddress====",deliveryAddress.deliveryAddress);
             sessionStorage.setItem("orderupdateAddress", deliveryAddress.deliveryAddress);
        }else{
            orderDetails.deliveryAddress=orderupdateAddress;
        }
      //  console.log("orderData=888===",orderDetails)

	
        //orderDetails.deliveryAddress=changeaddress;
        
       
        $CART_TOTALS_CONTAINER.removeClass('hide');
        let items = ShoppingCart.getCurrentCartItems();
        check_order_containing_alcohol(items, orderDetails.orderType)

        if (orderDetails.orderType == 'only_rate') {
          $('#other-orders-container').hide();
          $('#cart-items-totals').hide();
          $('#tipInfo').hide();
        } else {
          $('#other-orders-container').show();
          $('#cart-items-totals').show();
        }

        if(!_.size(items)) {
          console.log("in renderOrderTotals no items in shopping cart, setting to 0...");
          updateOrderDetail('orderTotal', 0);
          return null; //to not call _getOrderTotals if no items in shopping cart
        }
        return _getOrderTotals().then((response) => {
        console.log("in renderOrderTotals after _getOrderTotals...", response);
        if(response && response.data){
          if(tips!=true){
            console.log("tips====",tips)
          __get_all_orders_infor(); //this is leading to this function being called twice when page loads; however it is needed to update cart icon if item added to cart
          }
          const cartTotals = response.data;
          if (orderDetails.orderType == 'only_rate') {
            console.log("after __get_all_orders_infor, ordertype is rate, so returning");
            return null;
          }
          const $cartTotalsDiv = $("<div/>", {
            id: 'cart-total',
	          class: 'col-12'
          })
          if(cartTotals.credits_info && cartTotals.credits_info && cartTotals.credits_info.totalCredits){
            renderEligibleCreditLabel(parseFloat(cartTotals.credits_info.totalCredits).toFixed(2))
          }
          const itemsSubtotal = cartTotals.taxable_subtotal ? cartTotals.taxable_subtotal.toFixed(2): "0.00";
          const salesTax = cartTotals.tax_amount ? cartTotals.tax_amount.toFixed(2) : "0.00";
          const taxesAndFees = cartTotals.taxes_and_fees ? cartTotals.taxes_and_fees.toFixed(2) : "0.00";
          const credit_restaurant = cartTotals.credit_restaurant && !isNaN(Number(cartTotals.credit_restaurant)) ? Number(cartTotals.credit_restaurant).toFixed(2) : '0.00'
          const credit_platerate = cartTotals.credit_platerate && !isNaN(Number(cartTotals.credit_platerate)) ? Number(cartTotals.credit_platerate).toFixed(2) : '0.00'
          const restaurant_free_food = cartTotals.restaurant_free_food && !isNaN(Number(cartTotals.restaurant_free_food)) ? Number(cartTotals.restaurant_free_food).toFixed(2) : '0.00'
          const platerate_free_food = cartTotals.platerate_free_food && !isNaN(Number(cartTotals.platerate_free_food)) ? Number(cartTotals.platerate_free_food).toFixed(2) : '0.00'
          const credit_restaurant_max = cartTotals.credit_restaurant_max
          const credit_platerate_max = cartTotals.credit_platerate_max
          const alreadyPaid = cartTotals.paid_cart_total
          let credits_info = cartTotals.credits_info;
          let delivery_fee = typeof(cartTotals.delivery_fee)!=='undefined' && cartTotals.delivery_fee ? cartTotals.delivery_fee:  0 ;
          let burq_delivery_fee=typeof(cartTotals.burqdelivery_fee) !=='undefined' && cartTotals.burqdelivery_fee ? cartTotals.burqdelivery_fee: 0; 

          let total_delivery=parseFloat(burq_delivery_fee ? burq_delivery_fee: delivery_fee);
          console.log("total_delivery====",total_delivery);
          let credit_order = (cartTotals.taxable_subtotal + cartTotals.tax_amount).toFixed(2);
          const companyMealBudget = cartTotals.companyMealBudget && !isNaN(Number(cartTotals.companyMealBudget)) ? Number(cartTotals.companyMealBudget).toFixed(2) : '0.00'
          const organizationPayment = cartTotals.organization_payment && !isNaN(Number(cartTotals.organization_payment)) ? Number(cartTotals.organization_payment).toFixed(2) : '0.00'
          let totalPrice;
          let totalPriceOrgn = null;
          let totalPriceUser = null;
          var organizationId = null;
          var organizationName = null;
          totalPrice = cartTotals.total_price;
          
          totalPrice = parseFloat(totalPrice).toFixed(2)
          updateOrderDetail('orderTotal', parseFloat(totalPrice));
          $(".btn-payment").text('Pay');
          TYPE_FREE_FOOD = true;
          if(cartTotals.total_price == 0) {
            $(".btn-payment").text('Pay with free food');
            TYPE_FREE_FOOD = false;
          }

          sessionStorage.setItem('agreedcreditExpiresAfterPeriod-'+credits_info.orderId,credits_info.agreedcreditExpiresAfterPeriod || false);
          sessionStorage.setItem('totalCreditsEarn-'+credits_info.orderId,credits_info.totalCredits);
          sessionStorage.setItem('total_credits_infor-'+cartTotals.credits_info.orderId, JSON.stringify(cartTotals.credits_info) );
          sessionStorage.setItem('orderId',cartTotals.credits_info.orderId);
          const delivery_contactless = cartTotals.delivery_contactless;
          const delivery_contactless_note = cartTotals.delivery_contactless_note;


          if (orderDetails.orderType === 'delivery'){
            $('#contactless-text').html("Contactless delivery? <span class='red ml-1 font-weight-bold'>*</span>");
            $('#contactless-legend-textarea').text('Describe where the driver should drop off the delivery.');
            $('#describe-delivery-contactless').css('display','block');
            $('.cart-options--order-time').css('box-shadow', 'none');
          }
          else if (orderDetails.orderType === 'pickup'){
            //$('#contactless-text').css('display','none');
            $('#contactless-legend-textarea').css('display','none');
            $('#describe-delivery-contactless').css('display','none');
            $('#contactless-text').html("Contactless pickup? <span class='red ml-1 font-weight-bold'>*</span>");
            $('.show-delivery-contactless').css('box-shadow', 'none');
            $('.cart-options--order-time').css('box-shadow', 'none');
            //$('#check-id').css('display','none');
            // $('#contactless-legend-textarea').text('Describe where the employee should drop the food.');
          }else if(orderDetails.orderType === 'order-ahead'){
             $('.cart-options--order-time').css('box-shadow', 'none');
             updateOrderDetail("reserveSpace", $('#order-ahead #space-people').val());
             updateOrderDetail("reservationMinutes", $('#order-ahead #reservation-minutes').val());
             updateOrderDetail("reservationHours", $('#order-ahead #reservation-hours').val());
             updateOrderDetail("invitesDay", $('#order-ahead #invites-day').val() || 1);
          }

          if(delivery_contactless == ""){
            $('.show-delivery-contactless').css('display', 'none');
          }else{
            $('.show-delivery-contactless').css('display', 'block');
            $('.cart-options--order-time').css('box-shadow', 'block');
            if(delivery_contactless == "contactless"){
              // $('#check-delivery-contactless').bootstrapToggle('on');
              $('#describe-delivery-contactless').val(delivery_contactless_note);
              if (orderDetails.orderType === 'pickup'){
                $('.show-note-delivery-contactless').css('display', 'none');
              }
              else{
              $('.show-note-delivery-contactless').css('display', 'block');
            }
            }else{
              // $('#check-delivery-contactless').bootstrapToggle('off');
              $('.show-note-delivery-contactless').css('display', 'none');
            }
          }
          //Create totals to container
          const $totalsDetails = $(`<div>
            ${ orderDetails.orderType == 'order-ahead' ?
              `<p style=" color: mediumseagreen; font-style: italic;">Already paid: <span class="right-align">$${alreadyPaid.toFixed(2)}</span></p>
              <hr>` : ''
            }

            <p class="order-total-line">Subtotal: <span class="right-align sub-total order-total-info">$${itemsSubtotal}</span></p>
            <p class="order-total-line">Taxes & Fees: <span class="right-align sales-tax order-total-info">$${taxesAndFees}</span></p>
           </div>`);
          //Check if the cart has delivery type
          if (orderDetails.orderType =='delivery'){
              $totalsDetails.append(`<p class="order-total-line">Delivery fee: <span class="right-align order-total-info">$${total_delivery  ? total_delivery.toFixed(2) : 0}</span></p>`);
          }

          if (COMPLEX_ORDER_TYPES.includes(orderDetails.orderType) && !(orderDetails.orderType == 'order-ahead')){
            const orderTipType = cartTotals.tip_type;
            const orderTipLater = cartTotals.tip_later;
            if (orderTipType && !orderTipLater) {
                const tipPercent = (orderTipType === "percent") ? (cartTotals.tip * 100) : null;
                var orderTipAmt =0;
                if(orderTipType == "custom" && itemsSubtotal > 0){
                  orderTipAmt='$' + (cartTotals.tip_amount || 0 ).toFixed(2);
                }else if(orderTipType !== "cash"){
                    orderTipAmt='$' + (cartTotals.tip_amount || 0 ).toFixed(2)
                }else{
                  orderTipAmt='Cash Tip';
                }

                const $orderTip = $(`
                  ${cartTotals.items_paid ?
                     `<p class="order-total-line">Tip: <span class="right-align order-total-info">${orderTipAmt}</span></p>`
                    :
                    `<p class="order-total-line align-center">
                      Tip: <input type="text" class="right-align order-tip order-total-info form-control text-right" style="width: 80px; border-radius: 10px; font-size: 16px;border: 0px !important;" value="${orderTipAmt}"></input>
                    </p>`
                   }
                `);
                //Add the tipPercent if the user set any percentage tip
                // if (tipPercent) $orderTip.append(`<input class="sm-text order-tip-percent">(${tipPercent.toFixed(0)}%)</span>`);
                // $orderTip.append(editUserOrderTipBtn);
                $totalsDetails.append($orderTip);
            }
          }
          let freeFood=0;
          if(cartTotals.restaurant_free_food){
            freeFood=cartTotals.restaurant_free_food;
          }
          let platefreeFood=0;
          if(cartTotals.platerate_free_food){
            platefreeFood=cartTotals.restaurant_free_food;
          }
          let subtotal = parseFloat(cartTotals.taxable_subtotal) || 0;
          let fees = parseFloat(taxesAndFees) || 0;
          let tip = parseFloat(cartTotals.tip_amount) || 0;
          let dFee = parseFloat(cartTotals.delivery_fee) || 0;
          
         // Calculate the order total
          let order_total=cartTotals.total_price;

          if(cartTotals.platerate_free_food>0){
            order_total = Number(order_total)+ cartTotals.platerate_free_food ;
            order_total = parseFloat(order_total || 0).toFixed(2);
          }
          if(cartTotals.restaurant_free_food > 0){
            order_total = Number(order_total)+ cartTotals.restaurant_free_food ;
            order_total = parseFloat(order_total || 0).toFixed(2);
          }

          $totalsDetails.append(`
            <div class="d-flex justify-content-between font-weight-bold mt-4" style="color: black; font-size:20px;">
              Total
              <div class="order-total">
                $${order_total}
              </div>

            </div>
          `);
          // Add meter for loyalty credit
           try{
          if(credits_info && credits_info.dataLoyalty && credits_info.dataLoyalty.isLoyal && credits_info.dataLoyalty.creditTerm
            && credits_info.dataLoyalty.creditsProgress && credits_info.dataLoyalty.spendTerm
            && !isNaN(credits_info.dataLoyalty.creditTerm) && !isNaN(credits_info.dataLoyalty.spendTerm)
            && !isNaN(credits_info.dataLoyalty.creditsProgress) && Number(credits_info.dataLoyalty.spendTerm) > 0 ){
            const dataLoyalty = credits_info.dataLoyalty;
            const meterLoyalty = `
              <div class="loyalty-credit-terms">
                  Spend $${(Number(dataLoyalty.spendTerm) - Number(dataLoyalty.creditsProgress)).toFixed(2)} more at retail price, receive $${Number(dataLoyalty.creditTerm).toFixed(2)}  credit
              </div>
              <div class="d-flex align-items-center">
                <div style="position: relative; width: 100%">
                  <div class="loyaltyProgress mt-1">
                    <div class="loyaltyBar" style="width: ${((Number(dataLoyalty.creditsProgress) / Number(dataLoyalty.spendTerm)) * 100).toFixed(2)}%;">
                    </div>
                    <div class="loyaltyText">You have spent $${Number(dataLoyalty.creditsProgress).toFixed(2)} / $${Number(dataLoyalty.spendTerm).toFixed(2)}
                    </div>
                  </div>
                </div>
                <div class="loyaltyImg">
                  <img src="/images/${dataLoyalty.spendTerm == dataLoyalty.creditsProgress ? 'loyalSuccess.png' : 'meterLoyalty.png'}" style="width: 100%"/>
                </div>
              </div>
            `;
            // add html
            $('#loyalty-meter').html(meterLoyalty);
            $('#loyalty-meter').css({height: '100px'});
          } else {
          //  console.log("one of credits_info is not ok....",credits_info);
            $('#loyalty-meter').html("");
            $('#loyalty-meter').css({height: '0px'});
          }
          } catch(ex){
            console.log("error in credit display....", ex, credits_info);
          }
          $('#order-container').find('#tipContainer').css("pointer-events", "auto");
          renderListTip(cartTotals,orderDetails.orderType, resetTip);
          //Add the total price
          if (!noSalesOnTheSystem) {
            let venue_name = _.trim($('#venue-name').text())
            let restaurantFreeFood = `<div class="d-flex justify-content-between align-items-center mt-3">
                                        <div class="form-inline">
                                          <span class="mr-1" style="font-size: 16px; font-weight:600">${venue_name} Credits:</span>
                                          <span class="total-restaurant-free-food rpsv-disp-block" style="font-weight:600; font-size: 16px;">($${credit_restaurant})</span>
                                        </div>
                                        <input
                                            type="text"
                                            id="restaurant-free-food"
                                            value="$${restaurant_free_food}"
                                            data-free="restaurant" data-min="0" data-max="${credit_restaurant_max}"
                                            title="$0.00" placeholder="$0.00"
                                            class="form-control fee-price input-free-food order-total-action text-right "
                                            style="width: 90px; border-radius: 10px;border: 0px !important;"
                                        >
                                      </div>
                                      `
              let plalerateFreeFood = `<div class="d-flex justify-content-between align-items-center mt-3">
                                          <div class="form-inline">
                                            <span class="mr-1" style="font-size: 16px; font-weight:600">Platerate Credits:</span>
                                            <span class="total-platerate-free-food rpsv-disp-block" style="font-weight:600; font-size: 16px;">($${credit_platerate})</span>
                                          </div>
                                          <input
                                              type="text"
                                              id="platerate-free-food"
                                              value="$${platerate_free_food}"
                                              data-free="platerate" data-min="0" data-max="${credit_platerate_max}"
                                              title="$0.00" placeholder="$0.00"
                                              class="form-control text-right fee-price input-free-food order-total-action text-right "
                                              style="width: 90px; border-radius: 10px;border: 0px !important;"
                                          >
                                      </div>`

            var userPermissions = document.getElementById("userPermissions").value;
            $totalsDetails.append(`<div class="mt-1" style="border-top: 2px white solid"></div>`);
            if(Number(credit_restaurant) > 0) $totalsDetails.append(restaurantFreeFood);
            if(Number(credit_platerate) > 0) $totalsDetails.append(plalerateFreeFood);
            let orgnPriceDiv ='';
            
            const $totalPrice =  $(`${orgnPriceDiv}
            <div class="wrapper-item-paid mt-3">
              <p class="order-total-line">
                <span style="font-size: 20px!important; font-weight:600; color: black">Total Due: </span>
                <span class="right-align order-total order-total-info black ml-3" style="font-size: 20px!important;">$${totalPrice && totalPrice >0 ? totalPrice:0 }</span>
                ${cartTotals.items_paid ?
                  `<span class="price-value cart-paid-tag right-align" style="color: green;font-size:16px;">
                      <i class="fa fa-lg fa-check-square"></i> <span>Paid</span>
                    </span>
                    `
                    :
                  ``
                }
              </p>

            </div>`
            );

            let amountval = credits_info.totalCredits ? credits_info.totalCredits :0;
            let loyaltyCreditAmt = credits_info.dataLoyalty
                                  && credits_info.dataLoyalty.isLoyal
                                  && parseFloat(credits_info.dataLoyalty.creditAmount) || 0;
            amountval = amountval - loyaltyCreditAmt;

            //renderEligibleCreditLabel(parseFloat(amountval).toFixed(2))
            $totalsDetails.append($totalPrice);
            $totalsDetails.find('.fee-price').currencyFormatter();
          } else {
            $totalsDetails.empty();
           let freeFoodPrice;
            let htmlFreeFood = `<label id="tip-later-container">
                                  <span class="label-text black" style="font-size: 2rem !important;">
                                    <span style="font-size: 16px; font-weight:600">Free Food at Restaurant:</span>
                                  </span>
                                  <input type="text" id="use-free-food" class="form-control input-free-food">
                                  <span class="rpsv-disp-block" style="font-weight:600; font-size: 16px;">$${freeFoodPrice ? freeFoodPrice :0}</span>
                                </label>`
            if(Number(freeFoodPrice) > 0) $totalsDetails.append(htmlFreeFood);
            const $totalPrice =  $(`<hr/><p>Total: <span class="right-align">$0 - Rating only</span></p>`);
            $totalsDetails.append($totalPrice);
          }

          $CART_TOTALS_CONTAINER.html($cartTotalsDiv.append($totalsDetails));


          $CART_TOTALS_CONTAINER.find('.order-tip').on('focus', function(){
            $(this).select();
          })

          $CART_TOTALS_CONTAINER.find('.order-tip').on('keypress', function(evt){
            if ((evt.which < 48 || evt.which > 57) && evt.which != 46){ // 46  for decimal
                evt.preventDefault();
            }
          })
          $CART_TOTALS_CONTAINER.find('.order-tip').on('input', _.debounce((e) => {
            let tip_amount = $(e.target).val()
            tip_amount = _.replace(tip_amount, '$', '')
            Order._updateOrderTip("custom", tip_amount || 0);
            $("#other-tip-amount").val(tip_amount).trigger('input')
            Order.renderOrderTotals('tip');
            $(this).focus();
          }, 1000))


          if (!noSalesOnTheSystem && orderDetails.orderType != 'only_rate') {
            $('#tipInfo').show();
          }
          // check modify order
          if(cartTotals.modify){
            $('#show-btn-go-to-rate').show();
            $('#modify-to-rate').click(function(){
              $.ajax({
                url: `/orders/modify`,
                    method: 'POST',
                    dataType: 'json',
                    data: { orderId: credits_info.orderId , status : false},
                success: function(result) {
                  console.log("redirecting to /rate/order after order modify");
                  if(check_iOSapp() || check_androidApp()) {
                    window.location.href = `/rate/order/${credits_info.orderId}?flag=mobile`

                }else{
                       window.location.href = `/rate/order/${credits_info.orderId}`

                }
                },
                error: function(error) {
                const message = error.responseJSON.error || "Oops, something went wrong!";
                                        toastr.error(message);
                }
            });
            })
          }else{
            $('#show-btn-go-to-rate').hide();
            $('#modify-to-rate').unbind();
          }
          //event cancel button
          $('.btn-cancel-order').unbind('click').on('click', (e) => {
            if(alreadyPaid >0) {
              cancelOrderPaid();
            }else{
              cancelOrderUnPaid();
            }
          });

        } else {
       //  console.log("in renderuptotals22  blank response ....", response);
          updateOrderDetail('orderTotal', "error");
        }
        }).catch((err) => {
         console.log("in renderuptotals22  exception....", err);

		     if( err && err.responseJSON && err.responseJSON.error){
            $(".not-available-in-only-rate").find('#create-paypal-order').data('outofrange',true)
            $("#distance-failure").text(`Burq is not able to delivery at this location.The address is ${distance_in_mile} miles out of range`);
            $("#distance-failure").show();
            $("#distance-success").hide();
         }

          updateOrderDetail('orderTotal', "error");
          // $CART_TOTALS_CONTAINER.html(`
          //   <label class="badge badge-danger"
          //          style="padding: 5px 10px;background-color:#c30000">
          //     Unable to calculate cart totals
          //   </label>
          //   `);
        }).finally(function () {
          if (isMobile || $(window).width() <= 424) {
            if (type==='tip') {
              if (orderDetails.orderType !== 'only_rate') {
                setTimeout(() => {
                  $('#cart-items-totals').show();
                  $('.cart-actions-sticky').show();
                }, 2000)
              }
            }
          }
        })
      };

     // renderListTip(cartTotals,'orderType');
      const renderListTip = (cartTotals,info, resetTip=false) =>{
        let tipContainer = $('#tipContainer');
        if(info == "order-ahead"){
          tipContainer.parent().css('display','none');
          return;
        }else{
          tipContainer.parent().css('display','block');
        }

        if(cartTotals.items_paid || cartTotals.modify){
          $('.order-tip-btn').prop('disabled', true);
        }else{
          $('.order-tip-btn').prop('disabled', false);
        }

        const percent1 = tipContainer.find('#percent1');
        const percent2 = tipContainer.find('#percent2');
        const percent3 = tipContainer.find('#percent3');
        const percent4 = tipContainer.find('#percent4');
        const percent5 = tipContainer.find('#percent5');
        const percent6 = tipContainer.find('#percent6');

        //set the tip as per order type
        let tipPercents = [0.03, 0.05, 0.07];
        if(info == "delivery" || info=="order-ahead"){
          tipPercents = [0.15, 0.20, 0.25];
        }
        for(let i=0; i<tipPercents.length; i++){
          let tip=tipPercents[i];
          tipContainer.find("#percent"+Number(i+1)).attr('value',tip);
          tipContainer.find("#percent"+Number(i+1)).text(Number(tip*100).toFixed(0)+"%");
        }
        $('.order-tip-btn').removeClass('active');
        const orderTipType = cartTotals.tip_type;
        const orderTipLater = cartTotals.tip_later;
        let isActiveCustom = false;
        if (typeof (orderTipType !='undefined') && !orderTipLater) {
          if(orderTipType == "cash"){
            percent5.addClass('active');
          }
          else{
            let current_tip = Number(cartTotals.tip)
            if(Number(percent1.attr('value')) == current_tip){
              percent1.addClass('active');
            } else if(Number(percent2.attr('value')) == current_tip){
              percent2.addClass('active');
            } else if(Number(percent3.attr('value')) == current_tip){
              percent3.addClass('active');
            }else{
              percent4.addClass('active');
              isActiveCustom = true;
            }
            
            let price_order_detail = Number(cartTotals.taxable_subtotal);
            let tipTotal = cartTotals.tip_amount;
            let tipPercent = tipTotal > 0 ? parseInt(Number(tipTotal)*100/price_order_detail) : 0;
            let currentPercent = parseInt($('#other-tip-percent').val() || 0);
            // const currentTipAmount =  Number($('#other-tip-amount').val() || 0);
            
            if (currentPercent !== tipPercent && resetTip) {
              if (isActiveCustom && currentPercent > 0 && resetTip) {
                tipTotal = (currentPercent * price_order_detail/100).toFixed(2);
                Order._updateOrderTip("custom", Number(tipTotal));
                tipPercent = $('#other-tip-percent').val();
                if (resetTip) {
                  Order.renderOrderTotals();
                }
              }
              $('#other-tip-percent').val(tipPercent);
              $('#other-tip-amount').val(tipTotal);
              $('.m-slider').val(tipPercent);
              // if (firstLoad === 0) {
              let percent = (tipPercent/40) * 100;
              $('.m-slider').css('background-image',
                    '-webkit-gradient(linear, left top, right top, ' + 'color-stop(' + percent + '%, green), ' + 'color-stop(' + percent + '%, lightgrey)' + ')');
              $('.m-slider').css('background-image',
                  '-moz-linear-gradient(left center, green 0%, green ' + percent + '%, lightgrey ' + percent + '%, lightgrey 100%)');
              // }
            }
          }
        }else{
         percent6.addClass('active');
        }
        $('.order-tip-btn').unbind();
        let firstLoad = 0;
        $('.order-tip-btn').on('click', (e) => {
          var tips=true;
          let _subTotal;
          let checkout= false;
          let price_order_detail =  parseFloat($(`#sub-order-items-${checkout} .value-total-price`).text().replace('$' , ''));
          // let order_tip_percent = $("#cart-total .order-tip-percent").text()
          _subTotal= parseFloat($(`#order-checkout-totals-${checkout} .price-subtotal`).text().replace('$' , ''));
          if(!price_order_detail){
              price_order_detail = _subTotal;
          }
          if(!price_order_detail){
            price_order_detail = parseFloat($(`#cart-items-totals .sub-total`).text().replace('$' , ''));
          }

          $('.order-tip-btn').removeClass('active');
          $(e.target).addClass('active');
          let btnValue =  $(e.target).attr('value');
          switch (btnValue) {
            case "custom":
              function updateTipOther(flag, calculateFromPercentage = false, isUpdate=false) {
                let currentPrice = $(`#cart-items-totals .sub-total`).text().replace(/[^0-9.]/g,'');
                if (currentPrice) {
                  price_order_detail = parseFloat(currentPrice || 0);
                }
                let $tip = $('#other-tip-percent').val() || 0;
                let tipAmount = $('#other-tip-amount').val() || 0;
                $tip = Number($tip ? $tip.replace(/[^0-9]/g,'') : 0);
                if (Number($tip) < 0) {
                  $tip = 0;
                }
                //let tipPrice = $tip / 100 * price_order_detail;
                //tipPrice = parseFloat(tipAmount).toFixed(2);
                let tip_percent = $tip
                let tipPrice = calculateFromPercentage ? $tip / 100 * price_order_detail : tipAmount;
                tipPrice = Number(parseFloat((tipPrice)).toFixed(2));

                if ($tip < 0) {
                  $tip = 0;
                  tipPrice = 0;
                  $('#other-tip-percent').val('');
                  $('#other-tip-amount').val('');
                }
                $('.m-slider').val($tip);
                var percent = ($tip/40) * 100;
                $('.m-slider').css('background-image',
                    '-webkit-gradient(linear, left top, right top, ' + 'color-stop(' + percent + '%, green), ' + 'color-stop(' + percent + '%, lightgrey)' + ')');
                $('.m-slider').css('background-image',
                    '-moz-linear-gradient(left center, green 0%, green ' + percent + '%, lightgrey ' + percent + '%, lightgrey 100%)');
                if(flag!=true){
                  $('#other-tip-amount').val(tipPrice);
                  $('#other-tip-percent').val($tip);
                }
                if (percent > 100) {
                  $('#max-percent-label').html('40%+')
                } else {
                  $('#max-percent-label').html('40%')
                }
                if ($tip >= 0) {
                  if (checkout){
                    $(`#temp-checkout-tip-type-${checkout}`).val("custom");
                    $(`#temp-checkout-tip-value-${checkout}`).val(tipPrice);
                  } else {
                    Order._updateOrderTip("custom", Number(tipPrice));
                    if (isUpdate) {
                      Order.renderOrderTotals(null,false,tips);
                    }
                    firstLoad += 1;
                  }
                }
              }
              updateTipOther();

            $('#collapse-other-tip').collapse('toggle')

            $(".m-slider").on('input',function (e) {
              let tip_percent = $(this).val();
              let currentPrice = $(`#cart-items-totals .sub-total`).text().replace(/[^0-9.]/g,'');
              if (currentPrice) {
                price_order_detail = parseFloat(currentPrice || 0);
              }
              var val = ($(this).val() - $(this).attr('min')) / ($(this).attr('max') - $(this).attr('min'));
              var percent = val * 100;

              $(this).css('background-image',
                  '-webkit-gradient(linear, left top, right top, ' + 'color-stop(' + percent + '%, green), ' + 'color-stop(' + percent + '%, lightgrey)' + ')');

              $(this).css('background-image',
                  '-moz-linear-gradient(left center, green 0%, green ' + percent + '%, lightgrey ' + percent + '%, lightgrey 100%)');

              let tip_amount = tip_percent / 100 * price_order_detail ? tip_percent / 100 * price_order_detail : 0
              tip_amount = +parseFloat((tip_amount)).toFixed(2)
              if (Number($('#other-tip-percent').val() || 0) > 40) {
                $('#max-percent-label').html('40%+')
              } else {
                $('#max-percent-label').html('40%')
              }
              if (firstLoad >= 1) {
                $('#other-tip-percent').val(tip_percent)
                $('#other-tip-amount').val(tip_amount)
                $('.order-total-info').val(tip_amount)
              }
            });
            // $(".m-slider").trigger('input')

            $('#upate-custom-tip-per').on('click', (e) => {
              updateTipOther(true, false, true);
            })

            $('body').on('input', '#other-tip-percent', _.debounce((e)=>{
              updateTipOther(false, true, true);
            },500))

            $('body').on('input', '#other-tip-slider', _.debounce((e)=>{
              updateTipOther(false, true, true);
            },300))

            $('body').on('input', '#other-tip-amount', _.debounce((e)=>{
              var tipPrice = 0;
              tipPrice = $('#other-tip-amount').val() || 0;
             // tipPrice = tipPrice ? parseFloat(tipPrice.replace(/[^0-9\\.]+/g, '')) : 0;
              $('#other-tip-amount').val(tipPrice);
              let percent=0;
              let currentPrice = $(`#cart-items-totals .sub-total`).text().replace(/[^0-9.]/g,'');
              if (currentPrice) {
                price_order_detail = parseFloat(currentPrice || 0);
              }
              if(price_order_detail!=0){
                percent = parseInt((tipPrice/price_order_detail)*100)
              }
              console.log("percent====",percent);
              if (tipPrice < 0) {
                percent = 0;
                tipPrice = 0
              }
              $('#other-tip-percent').val(percent || '');
              $('#other-tip-amount').val(tipPrice || '');
              // this is handle when menu item is price is zero
              if(percent==0){
                tipPrice=0;
              }
              console.log("tipPrice====",tipPrice)
              var percentProcess = (percent/40) * 100;
              $('.m-slider').css('background-image',
                  '-webkit-gradient(linear, left top, right top, ' + 'color-stop(' + percentProcess + '%, green), ' + 'color-stop(' + percentProcess + '%, lightgrey)' + ')');
              $('.m-slider').css('background-image',
                  '-moz-linear-gradient(left center, green 0%, green ' + percentProcess + '%, lightgrey ' + percentProcess + '%, lightgrey 100%)');
              $('.m-slider').val(percent);
              if (percent > 40) {
                $('#max-percent-label').html('40%+')
              } else {
                $('#max-percent-label').html('40%')
              }
              if (percent >= 0) {
                if (checkout){
                  $(`#temp-checkout-tip-type-${checkout}`).val("custom");
                  $(`#temp-checkout-tip-value-${checkout}`).val(tipPrice);
                } else {
                  Order._updateOrderTip("custom", Number(tipPrice));
                  Order.renderOrderTotals(null,false,tips);
                }
              }
            },500))
            break;
            case "cash" :
                $('#collapse-other-tip').collapse("hide");
                if (checkout){
                  $(`#temp-checkout-tip-type-${checkout}`).val("cash");
                  $(`#temp-checkout-tip-value-${checkout}`).val(null);
                } else {
                  Order._updateOrderTip("cash", 0.05);
                }
                break;
            case "tip-later" :
                $('#collapse-other-tip').collapse("hide");
                Order._updateOrderTip('percent', 0.05 , 'later');
                break;
            default:
              $('#collapse-other-tip').collapse("hide");
              let tipPrice = ((btnValue*100) / 100) * price_order_detail;
              tipPrice = parseFloat((tipPrice)).toFixed(2);
              let percent  = btnValue*100 ;

              let percentage = parseFloat(btnValue);
              if (checkout){
                $(`#temp-checkout-tip-type-${checkout}`).val("percent");
                $(`#temp-checkout-tip-value-${checkout}`).val(percentage);
              } else {
                Order._updateOrderTip("percent", percentage);
              }
              break;
          }
          if (btnValue !== 'custom' || (!isActiveCustom && btnValue === 'custom' && firstLoad === 1)) {
            Order.renderOrderTotals(null,false,tips);
          }
        });
      };

      /**
       * Get and Render the cart total to cart total container
       */
      const calculateCheckoutTip = (_orderDetails, options = {}) => {
        let calculateTipOnly = options.calculateTipOnly || true;
        let type             = options.type             || 'order';

        const data = {
          userId: USER_ID,
          venueId: VENUE_ID,
          restaurantPostalCode: RESTAURANT_POSTAL_CODE,
          orderType: _orderDetails.orderType,
          userOrderTip: _orderDetails.userOrderTip,
          orderId: _orderDetails.orderId,
          calculateTipOnly: calculateTipOnly,
          type : type
        };
        return $.promisedAjax({
          url: '/orders/total',
          type: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json',
          dataType: 'json'
        })
      };

      /**
       * Check if user has an IN CART Order for this venue
       */
      const checkForInCartOrder = (selectOrderType) => {
        const ORDER_ID_FRIEND = getOrderIdFriend();
        var d = new Date();
        var time_now = d.getTime();

        let url = new URL(window.location.href);
        let rateOnlyId = url && url.searchParams ? url.searchParams.get('rateOnlyId') : '';
        let isMealPreps = $("#is_meal_preps").val();
        let mealPrepsOptions = $("#meal_preps_options").val();
        let orderAheadId = url && url.searchParams ? url.searchParams.get('orderAheadId') : '';
       // console.log("orderAheadId4====",orderAheadId)
        let urlIncart = `/orders/incart?userId=${USER_ID}&venueId=${VENUE_ID}&time=${time_now}${rateOnlyId ? `&rateOnlyId=${rateOnlyId}`:''}${orderAheadId ? `&orderAheadId=${orderAheadId}`:''}`;
       // console.log("urlIncart===",urlIncart)
        if(ORDER_ID_FRIEND) urlIncart = `/orders/incart?userId=${USER_ID}&venueId=${VENUE_ID}&orderIdFriend=${ORDER_ID_FRIEND}&time=${time_now}`;
        var inCartOrder;
   
        return $.promisedAjax({
          url: urlIncart,
          type: 'GET',
          contentType: 'application/json',
          dataType: 'json'
        }).then((response) => {
          let inCartOrder = response.data;
          if (inCartOrder) {
            if(inCartOrder.order_expected_date == 'asap' && inCartOrder.order_expected_time == 'asap'){
              $('.time-later-label').show();
              $('#btn-orderdatepicker').hide();
            }else{
              $('.time-later-label').hide()
              let orderLater = inCartOrder.order_expected_date
              $('#btn-orderdatepicker').show().text(moment(orderLater).format("MM/DD/YYYY HH:mm A"))
              if($('#btn-orderdatepicker').text() == 'Invalid date') {
                $('#btn-orderdatepicker').text("Loading...")
              }
            }
            if(inCartOrder.delivery_address && inCartOrder.delivery_address != null) $('#delivery-address').text(inCartOrder.delivery_address);

            if($('#edit-order-name').data('customTextEditTrigger') && typeof($('#edit-order-name').data('customTextEditTrigger')) !='undefined'){
              $('#edit-order-name').data('customTextEditTrigger').setData({orderName : inCartOrder.order_name})
            }

            $("#myorder-accordion").show();
            if (rateOnlyId || orderAheadId) $("#myorder-accordion").show();

            let availableOrderType   = [];
            if ($HAS_DELIVERY_ORDER.val() == 'true') availableOrderType.push("delivery");
            if ($HAS_PICKUP_ORDER.val() == 'true') availableOrderType.push("pickup");
            if ($HAS_ORDER_AHEAD.val() == 'true') availableOrderType.push("order-ahead");
            if ($HAS_WAITLIST.val() == 'true') availableOrderType.push("waitlist");
            console.log("availableOrderType====",availableOrderType)
            if (availableOrderType.length == 0) availableOrderType.push("only_rate");

            if(inCartOrder.delivery_contactless == "contactless"){
              $('#check-delivery-contactless').prop("checked",true)
              $('#show-note-delivery-contactless').css('display', 'block');
            }else{
              $('#check-delivery-contactless').prop("checked",false)
              $('#show-note-delivery-contactless').css('display', 'none');
            }
            $('#describe-delivery-contactless').val(inCartOrder.delivery_contactless_note);
            $('#specialInstructions').val(inCartOrder.special_instructions);

            if (inCartOrder.order_id) updateOrderDetail('orderId', inCartOrder.order_id);
            updateOrderDetail('inCartId', inCartOrder.in_cart_id);
            updateOrderDetail('hostOrderId', inCartOrder.parent_order_id);
            if (inCartOrder.parent_order_id) updateOrderDetail('parentId', inCartOrder.parent_order_id);

            // Check if current order type is available, if not change to the first type available
            if (!availableOrderType.includes(inCartOrder.order_type) && inCartOrder.order_type != "only_rate") {
              updateOrderDetail('orderType', availableOrderType[0], true).then(res => {
                return checkForInCartOrder();
              });
            }
            if (inCartOrder.order_type ) updateOrderDetail('orderType', inCartOrder.order_type);
            if (inCartOrder.special_instructions) updateOrderDetail('specialInstructions', inCartOrder.special_instructions);
            updateOrderDetail('noNapkinsPlasticware', inCartOrder.noNapkinsPlasticware);
            if (inCartOrder.delivery_address) {
              updateOrderDetail('deliveryAddress', inCartOrder.delivery_address, true);
            }
            if (inCartOrder.order_expected_date && inCartOrder.order_expected_date!='asap' ) updateOrderDetail("orderDate", inCartOrder.order_expected_date);
            if (inCartOrder.order_expected_time) updateOrderDetail("orderTime", inCartOrder.order_expected_time);
            _updateOrderTip(inCartOrder.tip_type, inCartOrder.tip, inCartOrder.tip_later);


            //Set special instructions
            if (inCartOrder.specialInstructions && inCartOrder.specialInstructions !== 'None') {
              $("#specialInstructions").val(inCartOrder.specialInstructions);
            }

            const isNoNapkins = inCartOrder.noNapkinsPlasticware
            $('#napkins').prop('checked', !!isNoNapkins)

            //Now update the values on DOM to display the order Details Found
            renderCurrentOrderDetailsToDOM()

            if(inCartOrder.order_type === "order-ahead") {
              if(inCartOrder.order_expected_time !== 'asap') {
                let _time = moment.utc(inCartOrder.order_expected_time).local().format('hh:mm a');
                _time = _time.split(' ')
                let _hm = _time[0].split(':');
                let _apm = _time[1];
                $('.order-ahead-apm').val(_apm);
                $('.order-ahead-minute').val(_hm[1]);
                $('.order-ahead-hour').val(_hm[0]);
              }
              $('#order-ahead-Instructions').val(inCartOrder.special_instructions);
              updateOrderDetail("reserveSpace", inCartOrder.reserve_space);
              $('#order-ahead #space-people').val(inCartOrder.reserve_space);
              if(inCartOrder.order_expected_time && inCartOrder.order_expected_time !== 'asap'){
                $('#order-ahead-date').val(moment(inCartOrder.order_expected_time).format('MM/DD/YYYY h:mm A'));
              }else{
        let getVenueId = $('#venueId').val();
        if(getVenueId && typeof getVenueId !== undefined)
		            $.ajax({
			          url: '/restaurant/LocalTime/'+getVenueId,
			          type: 'GET',
			          async: true,
			          success : function(getRestaurantLocalTime){
			           let timeZone= getRestaurantLocalTime.timeZone;
				            let date = new Date();
				            let countryTime = new Date(date.toLocaleString('en-US', {
				              timeZone: timeZone
				            }));
			          let time = moment(countryTime).format("MM/DD/YYYY HH:mm A");
			           $('#order-ahead-date').val(time);
			          }
		            });
              }
              if((inCartOrder.host === false || inCartOrder.parent_order_id) && inCartOrder.request_reservation == 'invited' ){
                $('.btn-cancel-order').parent().hide();
              }else{
                $('.btn-cancel-order').parent().show();
              }
              updateOrderDetail("peopleRSVPing", inCartOrder.people_rsvping);

              let getMaxReservationMinute = $('#orderAheadMaxMinute').val()
              updateOrderDetail("reservationMinutes", inCartOrder.reservation_minutes);
              $('#reservation-minutes').val(inCartOrder.reservation_minutes == null ? getMaxReservationMinute : inCartOrder.reservation_minutes);

              let getMaxReservationHours = $('#orderAheadMaxHour').val()
              updateOrderDetail("reservationHours", inCartOrder.reservation_hours);
              $('#reservation-hours').val(inCartOrder.reservation_hours == null ? getMaxReservationHours : inCartOrder.reservation_hours);

              updateOrderDetail("invitesDay", inCartOrder.invites_day);
              $('#invites-day').val(inCartOrder.invites_day || 1);

              updateOrderDetail("myFriends", inCartOrder.my_friends);
              $('#email-my-friends').val(inCartOrder.my_friends);

              $("#invites-friends-order-ahead").empty();
              $("#invites-friends-order-ahead").show();
              $('#btn-update-order-ahead').prop('disabled', true);
              var isHost;
              if(_.hasIn(inCartOrder,'host')){
                isHost = inCartOrder.host;
              }else{
                isHost = !inCartOrder.parent_order_id;
              }
              if(isHost){
                $('#input-email-my-friends').show();
              }else{
                $('#input-email-my-friends').hide();
              }

              $('.available-request-order-ahead').hide();
              $('.not-available-request-order-ahead').hide();
              $('#create-paypal-order').show();
              $(`#btn-resquest-order-ahead`).show();
              $(`#btn-update-order-ahead`).hide();

              if(inCartOrder.order_type != 'order-ahead' && inCartOrder.payment_status == 'paid') $('#create-paypal-order').hide();
              if(inCartOrder.request_reservation === 'invited') {  // Requested state
                ShoppingCart.renderFriendOrderAhead(inCartOrder.order_id, inCartOrder.userEmail, isHost, inCartOrder.parent_order_id, inCartOrder.order_name);
                $('#input-email-my-friends').hide();
                $('#reservation-restaurant').val('Requested');

                if(inCartOrder.order_status == "acknowledged") { // Confirmed state
                  $('#reservation-restaurant').val('Confirmed');
                  $('.available-request-order-ahead').hide();
                  if(orderAheadId){
                    $('.not-available-request-order-ahead').hide();
                    $('.add-more-item-order-ahead').show();
                    $('#order-ahead-confirm-btn').text("Submit");
                  } else {
                    $('.add-more-item-order-ahead').hide();
                    $('.not-available-request-order-ahead').show();
                    $('#order-ahead-confirm-btn').text("I've Arrived");
                  }
                } else {
                  $(`#btn-resquest-order-ahead`).hide();
                  $('.not-available-request-order-ahead').show();
                  $(`#btn-update-order-ahead`).show();
                  $('.available-request-order-ahead').hide();
                  $('.not-available-request-order-ahead').show();
                  $(' #order-ahead-Instructions, #space-people, #reservation-hours, #reservation-minutes, #email-my-friends, #invites-day')
                 .on('change', function(){
                     $('.available-request-order-ahead').show();
                     $('.btn-resquest-order-ahead').hide();
                     $('.wrap-btn-cart-pay').addClass('w-100');
                     $(`#btn-update-order-ahead`).show();
                     $(`#btn-update-order-ahead`).prop("disable", false)
                  })
                }
                if(inCartOrder.payment_status == 'paid' && $('#statusFriendIsOrdring').length == 0 && inCartOrder.request_reservation == 'invited') {
                  $('.not-available-request-order-ahead').show();
                }

                $('.myorders-nav').find('a[name="only_rate"]').parent().addClass('disabled');
                $('.myorders-nav').find('a[name="delivery"]').parent().addClass('disabled');
                $('.myorders-nav').find('a[name="pickup"]').parent().addClass('disabled');
              } else {
                $('.available-request-order-ahead').show();
                $('.not-available-request-order-ahead').hide();
                $('.myorders-nav').find('a[name="only_rate"]').parent().removeClass('disabled');
                $('.myorders-nav').find('a[name="delivery"]').parent().removeClass('disabled');
                $('.myorders-nav').find('a[name="pickup"]').parent().removeClass('disabled');
              }
            } else {
              if(inCartOrder.order_expected_time !== 'asap' || isMealPreps) {
                if(isMealPreps && isMealPreps==="true"){
                  $('#later').prop('checked', true);
                  let mealPrepsOpts = mealPrepsOptions? JSON.parse(mealPrepsOptions): null;
                //  console.log("mealPrepsOpts+++++++666+++mealPrepsOpts++++++",mealPrepsOpts)
                  if (mealPrepsOpts) {
                    const dates = calculateDates(mealPrepsOpts.cutOffDay, mealPrepsOpts.pickupDayOfWeek, mealPrepsOpts.deliveryDayOfWeek);
                    console.log("dates+++++++666+++dates++++++",dates)
                    let today = moment().format('MM/DD/YYYY');
                    let cutoffDate2 = moment(dates.cutoffDate, 'ddd MMM DD YYYY').format('MM/DD/YYYY');
                    let pickupDate = moment(dates.pickupDate, 'ddd MMM DD YYYY').format('MM/DD/YYYY');
                    let deliveryDate = moment(dates.deliveryDate, 'ddd MMM DD YYYY').format('MM/DD/YYYY');
                
                    selectOrderType = selectOrderType ? selectOrderType : inCartOrder.order_type;

                    if (today < cutoffDate2 || today === cutoffDate2) {
                        if (inCartOrder && selectOrderType === "pickup") {
                            const mealPrepTimeSlot = $(".meal-prep-time-slots").val();
                            Order.updateOrderDetail('mealPrepTimeSlot', mealPrepTimeSlot)

                            $("#pickupSlots").addClass("show");
                            $("#pickupSlots").removeClass("hide");
                            $("#deliverySlots").addClass("hide");
                            let newDatePick;
                            if (cutoffDate2 > pickupDate) {
                                newDatePick = moment(pickupDate, 'MM/DD/YYYY').add(1, 'weeks').format('MM/DD/YYYY');
                            } else {
                                newDatePick = moment(pickupDate, 'MM/DD/YYYY').format('MM/DD/YYYY');
                            }
                            let disabledDays = ShoppingCart.getDisabledDay(newDatePick);
                            $('body').find('#orderdatepicker').data("DateTimePicker").destroy();
                            $('body').find('#orderdatepicker').datetimepicker({
                                showClose: true,
                                ignoreReadonly: true,
                                icons: { close: 'closeText' },
                                minDate: newDatePick,
                                daysOfWeekDisabled: disabledDays,
                                defaultDate: newDatePick,
                                format: 'L'
                            });
                            $('#orderdatepicker').val(newDatePick);
                            $('#btn-orderdatepicker').show();
                            $('.time-later-label').hide();
                            $('#btn-orderdatepicker').text(mealPrepsOpts.pickupDayOfWeek + ", " + newDatePick);
                           // $('span[class*="time-later-label"]').text(mealPrepsOpts.pickupDayOfWeek + ", " + newDatePick);
                
                        } else if (inCartOrder && selectOrderType === "delivery") {
                          const mealPrepTimeSlot = $(".meal-prep-time-slots").val();
                          Order.updateOrderDetail('mealPrepTimeSlot', mealPrepTimeSlot)

                            $("#deliverySlots").addClass("show");
                            $("#deliverySlots").removeClass("hide");
                            $("#pickupSlots").addClass("hide");
                            let newDateDel;
                            if (cutoffDate2 >= deliveryDate) {
                                newDateDel = moment(deliveryDate, 'MM/DD/YYYY').add(1, 'weeks').format('MM/DD/YYYY');
                            } else {
                                newDateDel = moment(deliveryDate, 'MM/DD/YYYY').format('MM/DD/YYYY');
                            }
                            let disabledDays = ShoppingCart.getDisabledDay(newDateDel);
                            $('body').find('#orderdatepicker').data("DateTimePicker").destroy();
                            $('body').find('#orderdatepicker').datetimepicker({
                                showClose: true,
                                ignoreReadonly: true,
                                icons: { close: 'closeText' },
                                minDate: newDateDel,
                                daysOfWeekDisabled: disabledDays,
                                defaultDate: newDateDel,
                                format: 'L'
                            });
                
                            $('#orderdatepicker').val(newDateDel);
                            $('#btn-orderdatepicker').show();
                            $('.time-later-label').hide();
                            $('#btn-orderdatepicker').text(mealPrepsOpts.deliveryDayOfWeek + ", " + newDateDel);
                        }
                    }
                  } else {
                      $('#orderdatepicker').val(moment(inCartOrder.order_expected_date, 'MM/DD/YYYY h:mm A').format('MM/DD/YYYY h:mm A'));
                  }
                    
                    function getNextDayOfWeek(dayOfWeek) {
                        const today = new Date();
                        const resultDate = new Date(today.getTime());
                        resultDate.setDate(today.getDate() + (dayOfWeek + (7 - today.getDay())) % 7);
                        return resultDate;
                    }
            
                    function calculateDates(cutOffDay, pickupDay, deliveryDay) {
                      console.log("cutOffDay+++++++++++",cutOffDay)
                      console.log("pickupDay+++++++++++",pickupDay)
                      console.log("deliveryDay+++++++++++",deliveryDay)
                      const daysOfWeek = {
                          "Sunday": 0,
                          "Monday": 1,
                          "Tuesday": 2,
                          "Wednesday": 3,
                          "Thursday": 4,
                          "Friday": 5,
                          "Saturday": 6
                      };
          
                      const today = new Date();
                      const cutoffDate = getNextDayOfWeek(daysOfWeek[cutOffDay]);
          
                      let pickupDate, deliveryDate;
                      if (today > cutoffDate || today === cutoffDate) {
                          pickupDate = getNextDayOfWeek(daysOfWeek[pickupDay] + 7);
                          deliveryDate = getNextDayOfWeek(daysOfWeek[deliveryDay] + 7);
                      } else {
                          pickupDate = getNextDayOfWeek(daysOfWeek[pickupDay]);
                          deliveryDate = getNextDayOfWeek(daysOfWeek[deliveryDay]);
                      }
          
                      return {
                          cutoffDate: cutoffDate.toDateString(),
                          pickupDate: pickupDate.toDateString(),
                          deliveryDate: deliveryDate.toDateString()
                      };
                  }
                    // $('.static-ordertime-container').fadeOut();
                    // $('.pick-order-date-time').show()
                    // $('#asap').prop('checked', false)
                } else{
                    $('#orderdatepicker').val(moment(inCartOrder.order_expected_date).format('MM/DD/YYYY h:mm A'))
                }
              } else {
                // $('.static-ordertime-container').fadeIn();
                $('.pick-order-date-time').hide()
                $('#asap').prop('checked', true)
                $('#later').prop('checked', false);
              }
            }

            if (inCartOrder.in_waitlist) updateOrderDetail("inWaitlist", inCartOrder.in_waitlist);
            if (inCartOrder.loyalty_time_saver) updateOrderDetail("loyaltyTimeSaver", inCartOrder.loyalty_time_saver);
            if (inCartOrder.order_type === "waitlist"){
              let orderId = Order.getOrderDetail('orderId')
              let estimated_time = inCartOrder.estimated_time.estimatedSeatingTime
              let ht = moment(estimated_time).minutes()
              let mins = 15 - ht%15
              estimated_time = moment(estimated_time).add(mins, 'minutes').format('hh:mm a')
              $('.est-time-value').html(`${estimated_time} (${mins} mins)`)
              updateOrderDetail("reserveSpace", inCartOrder.reserve_space);
              $('#waitlist #space-people').val(inCartOrder.reserve_space);

              let getMaxReservationMinute = $('#orderAheadMaxMinute').val()
              updateOrderDetail("reservationMinutes", inCartOrder.reservation_minutes);
              $('#waitlist #reservation-minutes').val(inCartOrder.reservation_minutes == null ? getMaxReservationMinute : inCartOrder.reservation_minutes);

              let getMaxReservationHours = $('#orderAheadMaxHour').val()
              updateOrderDetail("reservationHours", inCartOrder.reservation_hours);
              $('#waitlist #reservation-hours').val(inCartOrder.reservation_hours == null ? getMaxReservationHours : inCartOrder.reservation_hours);

              if (inCartOrder.in_waitlist){
                ShoppingCart.renderFriendOrderAhead(inCartOrder.order_id, inCartOrder.userEmail, isHost, inCartOrder.parent_order_id, inCartOrder.order_name, "waitlist");
                $('#waitlist #input-email-my-friends').hide();
                $('#waitlist #reservation-restaurant').val('Requested')
                $(".added-to-waitlist").show()
                $(".not-added-to-waitlist").hide()
                // $('.myorders-nav').find('a[name="only_rate"]').parent().addClass('disabled')
                // $('.myorders-nav').find('a[name="delivery"]').parent().addClass('disabled')
                // $('.myorders-nav').find('a[name="pickup"]').parent().addClass('disabled')
                Waitlist.checkSeatCriteria(orderId)
              } else {
                Waitlist.checkAddCriteria(orderId)
                $(".added-to-waitlist").hide()
                $(".not-added-to-waitlist").show()
                // $('.myorders-nav').find('a[name="only_rate"]').parent().removeClass('disabled')
                // $('.myorders-nav').find('a[name="delivery"]').parent().removeClass('disabled')
                // $('.myorders-nav').find('a[name="pickup"]').parent().removeClass('disabled')
              }
              Waitlist.displayLoyaltyTimeSaver(orderId)
              Waitlist.refreshWaitlistTable(VENUE_ID)
            }

            return inCartOrder;

          } else {
            if ($NO_SALES_ON_THE_SYSTEM.val() === 'true') {
              $('.available-in-only-rate').show();
              $('.not-available-in-only-rate').hide();
              $('.available-in-order-ahead').hide();
              $('.available-in-waitlist').hide();
              $('.myorders-nav').find('a[name="only_rate"]').trigger('click');
            } else {
              $('.not-available-in-only-rate').show();
              $('.available-in-only-rate').hide();
              $('.available-in-order-ahead').hide();
              $('.available-in-waitlist').hide();
            }
            return inCartOrder;
          }
        })
        .catch((err) => {
          console.error('Unable to find in-cart orders', err);
        })
        .finally(() => {
          // for order ahead payment redirect, auto open cart after getting redirected from Paypal Payment
          let paymentRedirect = url && url.searchParams ? url.searchParams.get('paymentRedirect') : '';
          let orderAheadId = url && url.searchParams ? url.searchParams.get('orderAheadId') : '';
          console.log("orderAheadId++++++++++++",orderAheadId)
          let orderId = Order.getOrderDetail('orderId')
          if (paymentRedirect ) {
            $("#myorder-accordion").show();
            $('#collapseMyOrder').modal('show');
            localStorage.setItem("orderType", 'order-ahead');

            showModelConfirmOrder(orderId, inCartOrder.order_type == 'order-ahead' ? false : true);
          }
        })
      };

      // Get order infomations
      const GetOrderInfo = (conditions = {} )=>{
        return $.promisedAjax({
          url: '/orders/getInfo',
          type: 'POST',
          contentType: 'application/json',
          dataType: 'json',
          data: JSON.stringify(conditions),
        }).then((response) => {
          return response.data;
        })
      }
      // Get order id friend
      const getOrderIdFriend = () => {
        let url = new URL(window.location.href);
        let orderId = url && url.searchParams ? url.searchParams.get('order_id') : '';
        return orderId ? orderId : '';
      }

      const getCheckoutNotification = (orderId) =>{
        return $.promisedAjax({
          url: `/orders/checkouts/notification?orderId=${orderId}`,
          type: 'GET',
          contentType: 'application/json',
          dataType: 'json'
        }).then((result) =>{
          if(result.data.length > 0) return result.data;
          return {};
        }).catch((error)=>{
          console.error('Unable to load notification checkout', error)
        })
      }

      /**
       * Check if user has Order in Checkout for this venue
       */
      const checkForOrdersInCheckout = ( hasIncart, checkoutId = false, options = {}) => {
        __checkout_load.render_checkout = false;
        let noScroll  = options.noScroll || false;
        let firstLoad = options.firstLoad || false;
        let openOrderId = options.openOrderId || false;
        if (openOrderId) noScroll = true;
        return $.promisedAjax({
          url: `/orders/checkouts?userId=${USER_ID}&venueId=${VENUE_ID}`,
          type: 'GET',
          contentType: 'application/json',
          dataType: 'json'
        }).then((response) => {
          let url = new URL(window.location.href);
          let show = url && url.searchParams ? url.searchParams.get('show'): null;
          if (response.data && response.data.checkouts.length > 0){
            SubOrder.updateOrderAheadInCheckout(response.data.checkouts);
            SubOrder.updateTotalSubOrder(response.data.checkouts);
            // let list_orderId = [];
            // _.forEach(response.data.checkouts,(elem)=>{
            //   list_orderId.push(elem.order_id);
            // });
            // Promise.all(list_orderId.map((orderId) => getCheckoutNotification(orderId))).then(resultCheckout => {
            //   if(!_.isEmpty(resultCheckout)){
            //     list_orderId.forEach((orderId) => {
            //       const totalJoinRequest = resultCheckout.filter(checkout => checkout.join_request_to === orderId);
            //       if(totalJoinRequest > 0) $(`#notify-checkout-${orderId}`).text(totalJoinRequest.length.toString());
            //     })
            //   } else {
            //     $(`#notify-checkout-${orderId}`).text('');
            //   }
            //   renderAvailableCredits(list_orderId);
            //   if (!noScroll){
            //     if (show && show.includes("Checkout-")) {
            //       let checkoutId = show.split('-');
            //       checkoutId = checkoutId[1] || '';
            //       let inCartId   = getOrderDetail('orderId');
            //       if ( checkoutId && inCartId && (inCartId == checkoutId) ){
            //         $("#myorder-accordion").show();
            //       //  $('html, body').animate({ scrollTop: $("#headingMyOrder").offset().top }, 2000);
            //         $("#collapseMyOrder").collapse('show');
            //       } else {
            //        // $('html, body').animate({ scrollTop: $(`#heading${show}`).offset().top }, 2000);
            //         $(`#collapse${show}`).collapse('show');
            //       }
            //     }
            //   } else if (openOrderId){
            //    // $('html, body').animate({ scrollTop: $(`#headingCheckout-${openOrderId}`).offset().top }, 2000);
            //     $(`#collapseCheckout-${openOrderId}`).collapse('show');
            //   }
            // });
          } else {
            if (!noScroll){
              if (show && show.includes("Checkout-")) {
                let checkoutId = show.split('-');
                checkoutId = checkoutId[1] || '';
                let inCartId   = getOrderDetail('orderId');
                if ( checkoutId && inCartId && (inCartId == checkoutId) ){
                  $("#myorder-accordion").show();
                //;//  $('html, body').animate({ scrollTop: $("#headingMyOrder").offset().top }, 2000);
                  // $("#collapseMyOrder").collapse('show');
                }
              }
            } else if (openOrderId){
             // $('html, body').animate({ scrollTop: $(`#headingCheckout-${openOrderId}`).offset().top }, 2000);
              $(`#collapseCheckout-${openOrderId}`).collapse('show');
            }
          }
        })
        .catch((err) => {
          console.error('Unable to find checkout orders', err);
        })
        .finally(err => {
          __checkout_load.render_checkout = true;
          $('#loading-icon').hide();
        })
      };

      function renderAvailableCredits(orderIds){
        return $.promisedAjax({
          url: `/orders/available-credits`,
          type: 'POST',
          contentType: 'application/json',
          dataType: 'json',
          data: JSON.stringify({
            list_orderId: orderIds,
          }),
        }).then((response) => {
            _.forEach(response.data,(elem)=>{
                $('body').find(`.free-food-can-earn-${elem.orderId}`).text(`${parseFloat(elem.totalCredits).toFixed(2)}`);
                $('body').find(`.free-food-earned-${elem.orderId}`).text(`${parseFloat(elem.earnedCredits).toFixed(2)}`);
            });
            return Promise.resolve(response.data);
        })
      }


			function getrestaurantOrderById(orderId,venueId){
				let data = {
					venueId : venueId,
					orderId: orderId,
				};
				let orderData = [];
				$.ajax({
					url: '/orders/getRestaurantOrderById',
					type: 'POST',
					data: JSON.stringify(data),
					contentType: 'application/json',
					dataType: 'json',
					async : false,
					error: function(err) {
						//$('#loading-icon').fadeOut( "slow");
						Swal.fire('Unable to get orders', 'Please Try Again!', 'error');
					},
					success: function(res) {
						//$('#loading-icon').fadeOut( "slow");
						if(res.data && res.data.length) {
							orderData = res.data;
						} else {
							orderData = [];
						}
					}
				});
				return orderData[0];
			}


      /**
       * Load Rating section
       * @param {String} orderId
       */
      const loadRating = (orderId, ratingUrl) => {
        $(`#rating-checkout-loader-${orderId}`).css('display', 'block');
        return $.promisedAjax({
          url: ratingUrl,
          method: 'GET',
          contentType: "application/json; charset=utf-8",
          dataType: 'html',
        }).then(res => {
          $(`#rating-section-${orderId}`).html(res);
          $(`#order-checkout-${orderId} .flat-slider`).bootstrapSlider({
              min: 0,
              max: 100,
              tooltip: 'hide',
              formatter: function (value) {
                  return;
              }
          });
          $(`#order-checkout-${orderId} .range-slider`).bootstrapSlider({
              min: 0,
              max: 100,
              range: true,
              values: [40, 60]
          });
          reviewPageInit(orderId);
          renderRatingDiscounts(orderId);
        }).finally(r => {
          $(`#rating-checkout-loader-${orderId}`).css('display', 'none');
          renderSelectedAddons();

        })
      };

    var listOfNodeKeys = {};
    function getSelectedKeysOfNode(listOfKeys){
      _.forEach(listOfKeys,(item)=>{
          if(listOfNodeKeys[item]) return
          listOfNodeKeys[item] = true;
          /* redesign
          let parentSource = tree_source.getNodeByKey(item);
          while(parentSource){
            listOfNodeKeys[parentSource.key] = true;
            parentSource = parentSource.parent
          } */
          let parentSource = $("#menuitemData_"+item);
          while(parentSource){
            parentSource = parentSource.val();
            listOfNodeKeys[parentSource.key] = true;
            if(parentSource.parentItemKey){
              parentSource = $("#menuitemData_"+parentSource.parentItemKey);
            } else {
              parentSource=false;
            }
          }

      })
    }
    function renderSelectedAddons(){
      $('.list-of-selected-addons').each(function(index, elem){
        let itemInfo = $(elem).find('p').text();
        try {
          itemInfo = JSON.parse(itemInfo);
        } catch (error) {
          console.log("error in renderSelectedAddons..",error);
          return
        }
        if(!_.size(itemInfo.items.addons)) return
        let itemKey = itemInfo.items.menuItemId;
        let node = $("#menuitemData_"+itemKey);
        if(node) node=node.val();
        let listOfKeys = itemInfo.orderedItems[itemKey];
        getSelectedKeysOfNode(listOfKeys);
        let html = renderItemAddons([node], listOfNodeKeys);
        $(elem).html(html);
      })
      $('.shir-show-seleted-addons').on('shown.bs.collapse', function() {
        let _class = 'shir-icon-' + $(this).data('item-id');
        $(`.${_class}`).addClass('glyphicon-menu-down').removeClass('glyphicon-menu-right');
      });

      $('.shir-show-seleted-addons').on('hidden.bs.collapse', function() {
        let _class = 'shir-icon-' + $(this).data('item-id');
        $(`.${_class}`).addClass('glyphicon-menu-right').removeClass('glyphicon-menu-down');
      });
    }

    function renderItemAddons(nodeInfo = [], nodeKeys = false){
      let html = '';
      _.forEach(nodeInfo,(item)=>{
        if(nodeKeys && !nodeKeys[item.key]) return true;
        let _time = (new Date()).getTime();
        if(!_.size(item.children)){
          html += `
              <div class="row col-sm-12 col-md-12">
                <p id="shir-${item.key}" style="display: inline-block; color: green; padding-left: 10px;"><span class="glyphicon glyphicon-stop"></span> ${item.title}</p>
                <div class="shir-addon-price" style="display: inline-block; padding-left: 20px;">$ ${item.data.price || 0}</div>
                <div style="display: inline-block; padding-left: 20px;">
                  <div class="shir-addon-rating" data-id="${item.key}" data-rating="${item.rating}"></div>
                </div>
              </div>
          `;
          return true;
        }
        html += `
        <a href="javascript:void(0)" class="row col-sm-12 col-md-12" data-toggle="collapse" data-target="#shir-${item.key}" aria-expanded="true" style="color: green;">
          <span class="shir-icon-${item.key}-${_time} glyphicon glyphicon-menu-down" style="font-size: 15px"></span> ${item.title}
        </a>
        <div id="shir-${item.key}" data-item-id="${item.key}-${_time}" class="shir-show-seleted-addons collapse in row col-sm-11 col-sm-offset-1 col-md-11 col-md-offset-0" aria-expanded="true">
          ${renderItemAddons(item.children, nodeKeys)}
        </div>
      `;
      })
      return html;
    }
      /**
       * Load free food pay tip section
       * @param {String} orderId
       */
      const loadFreeFoodTip = () => {
        $(".tip-free-food").each(function(e) {
          const orderId = $(this).data('order-id');
          renderTipFreeFood(orderId)
        })
      }

      const renderTipFreeFood = (orderId, creditRestaurant, creditPlaterate) => {
        const $content = $(`#tip-free-food-${orderId}`);
        return $.promisedAjax({
          url: `/orders/loadCreditTip`,
          type: 'POST',
          contentType: 'application/json',
          dataType: 'json',
          data: JSON.stringify({
            orderId: orderId,
            venueId: VENUE_ID,
            creditRestaurant,
            creditPlaterate
          }),
        }).then((response) => {
          const credit_restaurant = response.restaurantFreeFood && !isNaN(Number(response.restaurantFreeFood)) ? Number(response.restaurantFreeFood).toFixed(2) : '0.00'
          const credit_platerate = response.platerateFreeFood && !isNaN(Number(response.platerateFreeFood)) ? Number(response.platerateFreeFood).toFixed(2) : '0.00'
          const restaurant_free_food = response.tip_restaurant_free_food && !isNaN(Number(response.tip_restaurant_free_food)) ? Number(response.tip_restaurant_free_food).toFixed(2) : '0.00'
          const platerate_free_food = response.tip_platerate_free_food && !isNaN(Number(response.tip_platerate_free_food)) ? Number(response.tip_platerate_free_food).toFixed(2) : '0.00'
          const restaurant_free_food_max = response.restaurant_free_food_max;
          const platerate_free_food_max = response.platerate_free_food_max;
          const tip_amount = response.tip_amount ? Number(response.tip_amount).toFixed(2) : '0.00';

          let restaurantFreeFood =  Number(credit_restaurant) > 0
                ? `<div>
                  <label id="tip-later-container" style="width: 100%">
                    <span class="label-text black" style="font-size: 1.5rem !important;">
                      <span style="font-size: 16px; font-weight:600">Free Food at Restaurant:</span>
                    </span>
                    <input type="text" class="form-control fee-price input-free-food tip-restaurant-free-food" value="$${restaurant_free_food}" data-free="restaurant" data-min="0" data-max="${restaurant_free_food_max}" title="$0.00" placeholder="$0.00" >
                    <span class="total-restaurant-free-food rpsv-disp-block" style="font-weight:600; font-size: 16px;">($${credit_restaurant})</span>
                  </label>
                </div>
                `
                : '';
          let platerateFreeFood = Number(credit_platerate) > 0
                ? `<div>
                  <label id="tip-later-container" style="width: 100%">
                    <span class="label-text black" style="font-size: 2rem !important;">
                      <span style="font-size: 16px; font-weight:600">Free Food at any Restaurant:</span>
                    </span>
                    <input type="text" class="form-control fee-price input-free-food tip-platerate-free-food" value="$${platerate_free_food}" data-free="platerate" data-min="0" data-max="${platerate_free_food_max}" title="$0.00" placeholder="$0.00">
                    <span class="total-platerate-free-food rpsv-disp-block" style="font-weight:600; font-size: 16px;">($${credit_platerate})</span>
                  </label>
                </div>`
                : '';

          $content.html(restaurantFreeFood + platerateFreeFood);
          $content.find('.fee-price').currencyFormatter();
          $(`#tip-${orderId}`).find('.price-tip').text(`$${tip_amount}`);

          if(parseFloat(tip_amount) == 0) {
            $(`#pay-tip-${orderId}`).text('Pay tip with free food');
            $(`#pay-tip-${orderId}`).attr('data-pay-tip', 'freefood');
          } else {
            $(`#pay-tip-${orderId}`).text('Pay tip via PayPal');
            $(`#pay-tip-${orderId}`).attr('data-pay-tip', 'paypal');
          }

        }).catch(() => {
          $content.empty();
        })
      }


      $('body').on('input', '.tip-restaurant-free-food, .tip-platerate-free-food', _.debounce((e)=>{
        const $tipgroup = $(e.target).parents('.tip-group');
        const orderId = $tipgroup.find('.tip-order-id').val();
        if(orderId) {
          let tip = $tipgroup.find('.price-tip').data('price') || $tipgroup.find('.price-tip').text() || 0;
          tip = _.replace(tip,/([$\s,])+/g,'');

          let totalPrice = Number(tip) > 0 ? Number(tip) : 0;
          let restaurantCredit = $tipgroup.find('.tip-restaurant-free-food').val() || 0;
          restaurantCredit = _.replace(restaurantCredit,/([$\s,])+/g,'');
          restaurantCredit = Number(restaurantCredit) > 0 ? Number(restaurantCredit) : 0;
          let platerateCredit = $tipgroup.find('.tip-platerate-free-food').val() || 0;
          platerateCredit = _.replace(platerateCredit,/([$\s,])+/g,'');
          platerateCredit = Number(platerateCredit) > 0 ? Number(platerateCredit) : 0;
          let payPal = totalPrice - restaurantCredit - platerateCredit;
          payPal = payPal > 0 ? payPal : 0;
          $tipgroup.find('.price-tip').text(`$${payPal.toFixed(2)}`);
          renderTipFreeFood(orderId, restaurantCredit, platerateCredit);
        }
      },500))

      /**
       * Split Order
       * @param {String} orderId
       */
      const splitOrder = (data) => {
        return $.promisedAjax({
          url: `/orders/split`,
          type: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json',
          dataType: 'json'
        })
      };


      /**
       * Send request to retrieve items of checkout order
       * @param {String} orderId
       */
      const getCheckoutItems = (orderId) => {
        let url = `/orders/items?orderId=${orderId}`;
        return $.promisedAjax({
          url: url,
          contentType: 'application/json',
          dataType: 'json'
        }).then((response) => {
          if (response.data) {
            let items = response.data;
            return items;
          }
          return [];
        })
      };

      /**
       * Render the current order Details to DOM
       */
      const renderCurrentOrderDetailsToDOM = () => {
        const orderDetails = getOrderDetails();
        let url = new URL(window.location.href);
        let rateOnlyId = url && url.searchParams ? url.searchParams.get('rateOnlyId') : '';

        //Set nav select orderType
        const $activeNav = $('.myorders-nav .active');

        let orderType = localStorage.getItem("orderType");
        if( orderDetails.orderId != rateOnlyId && orderDetails.orderId != orderAheadId && orderType && orderType!=null ){

          orderDetails.orderType   =orderType;
        }
        if(orderDetails.orderType === "order-ahead" || orderType =='order-ahead') {
          $('#latest-order-type-selected').text("Dine In");
        } else {
          $('#latest-order-type-selected').text(orderDetails.orderType.charAt(0).toUpperCase() + orderDetails.orderType.slice(1));
        }
        $('#latest-order-type-icon > i')
          .attr('class', $activeNav.find('i').attr('class'));
         //Hide the delivery address container is the active is not delivery
        $('.delivery-section, .cart-options--user-location').toggleClass('hide', orderDetails.orderType !== 'delivery')
        // $('.pickup-section, .cart-options--restaurant-location').toggleClass('hide', orderDetails.orderType !== 'pickup')
        $('.order-ahead-container').toggleClass('hide', orderDetails.orderType !== 'order-ahead')
        let activeTerm = getEstimateTime();
        if (orderDetails.orderType === 'delivery'){
          $('.est-time-order').text(activeTerm.delivery);
        }else if (orderDetails.orderType === 'pickup'){
          $('.est-time-order').text(activeTerm.pickup);
        }
        //IF the current active select orderType is not the orderDetails type
        //Set the orderNav to the new orderType
        if ($activeNav.text().toLowerCase() !== orderDetails.orderType) {
          $activeNav.removeClass('active');
          $(`.myorders-nav .${orderDetails.orderType}`).addClass('active');
        }
        if (orderDetails.orderType === 'only_rate'){
           $(`.myorders-nav .${orderDetails.orderType}`).removeClass('active');
           $(`.myorders-nav .${orderDetails.orderType}`).addClass('hide');
          $('.available-in-only-rate').show();
          $('.not-available-in-only-rate').hide();
          $('.available-in-order-ahead').hide();
          $('.available-in-waitlist').hide();
        } else if(orderDetails.orderType === 'order-ahead') {
          $('.available-in-order-ahead').show();
          $('.not-available-in-only-rate').show();
          $('.available-in-only-rate').hide();
          $('.available-in-waitlist').hide();
        } else if(orderDetails.orderType === 'waitlist') {
          $('.available-in-waitlist').show();
          $('.available-in-order-ahead').hide();
          $('.not-available-in-only-rate').hide();
          $('.available-in-only-rate').hide();
        } else {
          if ($(window).width() < 424) {
          $('.paywithpaypal').css({'display': 'none'})
          }else{
            $('.paywithpaypal').css({'display': 'none'})
          }
          $('.not-available-in-only-rate').show();
          $('.available-in-only-rate').hide();
          $('.available-in-order-ahead').hide();
          $('.available-in-waitlist').hide();
        }

        //Set special instructions
        if (orderDetails.specialInstructions && orderDetails.specialInstructions !== 'None') {
          $("#specialInstructions").val(orderDetails.specialInstructions);
        }

        const isNoNapkins = orderDetails.noNapkinsPlasticware
        $('#napkins').prop('checked', !!isNoNapkins)
        let { deliveryAddress } = $('#delivery-address-edit-trigger').data('customTextEditTrigger').getData();

        if(!deliveryAddress){
          if($('#delivery-address-edit-trigger-container input').length <= 0) {
            $('#delivery-address-edit-trigger').click();
            $('#delivery-address_input').focus();
          }else{
            $('#delivery-address_input').focus();
            $('#delivery').css('box-shadow', 'none');
          }
        }

      };
      /**
       * Submit request to create new order transaction, when pay button is clicked
       */
      const _submitCreateOrder = (orderData, createType) => {
        const venueName = $("#credit_venueName").val();
        orderData.orderDetails.venueName = venueName || 'Unknown Restaurant';
        console.log("createType====",createType)
        if(createType == "cart"){
          orderData.orderDetails.userOrderTip.value = getUserTip(orderData.orderDetails.orderType);
        }
        if(createType=="only-rate"){
          orderData.orderDetails.rateable='yes';
        }
        if(createType=="order-ahead"){
          let current_emails = $('#email-my-friends').val() || '';
          orderData.orderDetails.myFriends = current_emails;
       }
        let partnerId ='';
        partnerId=$("#partnerId").val();
       // orderData.partnerId=partnerId;
        var orderupdateAddress = (sessionStorage.getItem('orderupdateAddress'))
        console.log("orderAddress=6===",orderupdateAddress)
        orderData.orderDetails.deliveryAddress=orderupdateAddress;
        console.log("orderData====",orderData)
        return $.promisedAjax({
          url: `/orders/create?ctype=${createType}`,
          type: 'POST',
          data: JSON.stringify(orderData),
          contentType: 'application/json',
          dataType: 'json'
        })
      };

      /**
       * Submit request to create new order transaction
       */
      const confirmOrderAhead = () => {
        const orderId = Order.getOrderDetail("orderId");
        const hours = Order.getOrderDetail("reservationHours");
        const minutes = Order.getOrderDetail("reservationMinutes");
        const time = Number(hours) * 60 + Number(minutes)
        const durationAhed = Number($('#reservations-duration-time').val());
        const discountAhed = Number($('#reservations-discount-time').val());

        let duration_order_ahead = 0;
        let discount_order_ahead = 0;
        if(time && durationAhed && discountAhed && durationAhed >= time && discountAhed > 0) {
          duration_order_ahead = durationAhed;
          discount_order_ahead = discountAhed;
        }
        const dataConfirm = {
          orderId: orderId,
          duration_order_ahead: duration_order_ahead,
          discount_order_ahead: discount_order_ahead,
        }

        return $.promisedAjax({
          url: `/orders/confirmOrderAhead`,
          type: 'POST',
          data: JSON.stringify(dataConfirm),
          contentType: 'application/json',
          dataType: 'json'
        }).then(res => {
          if (res.status == 200){
            Order.clearOrderDetails();
            checkForOrdersInCheckout(null, false, {firstLoad: true, openOrderId: orderId});
          }
        });
      };

      /**
       *
       * @param {*} orderId
       * @param {*} venueId
       */
      const checkFreedFood = (orderId, venueId, payTip = false) => {
        return $.promisedAjax({
          url: `/orders/freefood/check`,
          type: 'POST',
          data: JSON.stringify({orderId, venueId, payTip}),
          contentType: 'application/json',
          dataType: 'json'
        })
      };

      /**
       * Submit request to create new order transaction
       */
      const _submitPayTip = (_orderId, _payType) => {
          const data = {
              userId: USER_ID,
              venueId: VENUE_ID,
              orderId: _orderId
          }
          let $POST_URL = '/orders/create_tip_payment';
          if(_payType == 'freefood') {
            $POST_URL = '/orders/execute_tip_payment_free_food';
          }
          return checkFreedFood(_orderId, VENUE_ID, true).then((result) => {
            if(result.checked) {
              return $.promisedAjax({
                url: $POST_URL,
                type: 'POST',
                data: JSON.stringify(data),
                contentType: 'application/json',
                dataType: 'json'
              })
            } else {
              return {checkFreeFood: true}
            }
          });
      };
      /**
       * Create an order transaction based on the createType given
       * @param {String} createType The type of creation for the order [cart or paypal]
       */
      const createOrder = (createType = 'cart', items, order_type = null) => {
        let currentOrderDetails = getOrderDetails(true, order_type);
        let partnerId=$("#partnerId").val()
        let orderData = {
          orderDetails: currentOrderDetails,
          userId: USER_ID,
          venueId: VENUE_ID,
          partnerId:partnerId
        };

        const isMealPreps = $('#is-meal-preps').val();
        const isRestaurant = $('#is-restaurant').val();
        const deliverectChannelId = $('#deliverectChannelId').val();
         if(isRestaurant && isRestaurant==='true'){
          orderData.orderDetails['businessType'] = "Restaurant";
        }else if(isMealPreps && isMealPreps==='true'){
          orderData.orderDetails['businessType'] = "Meal Preparation";
        }
        if(deliverectChannelId){
          orderData.orderDetails['deliverectChannelLinkid'] = deliverectChannelId;
        }else{
          orderData.orderDetails['deliverectChannelLinkid'] = null;
        }
        var orderupdateAddress = (sessionStorage.getItem('orderupdateAddress'))
        console.log("orderAddress=77====",orderupdateAddress)
        if(orderupdateAddress==null || orderupdateAddress=='' ){
            let  deliveryAddress  = $('#delivery-address-edit-trigger').data('customTextEditTrigger').getData();
            orderData.orderDetails.deliveryAddress=deliveryAddress.deliveryAddress;
            console.log("deliveryAddress====",deliveryAddress.deliveryAddress);
            sessionStorage.setItem("orderupdateAddress", deliveryAddress.deliveryAddress);
        }else{
          console.log("jk=====")
          orderData.orderDetails.deliveryAddress=orderupdateAddress;
        }
        let orderDetailsValidation = {isValidated: false, missingValues: []};
        let omittedFields = [];
           window.localStorage.setItem('orderTotalAmount', orderData.orderDetails.orderTotal);

        switch (createType) {
          case 'cart':
             orderData.orderDetails.restaurantPostalCode = RESTAURANT_POSTAL_CODE,
             orderData.items = items;
             omittedFields = [
               'orderId',
               'deliveryAddress',
               'deliveryAddressDetails',
               'orderTime',
               'orderDate',
               'restaurantPostalCode',
               'parentId',
               'hostUser',
               'childOrders',
               'reserveSpace',
               'textDateTime',
               'peopleRSVPing',
               'reservationHours',
               'reservationMinutes',
               'durationOrderAhead',
               'invitesDay',
               'myFriends',
               'inWaitlist',
               'loyaltyTimeSaver',
               'orderTotal',
               'inCartId',
               'hostOrderId',
               'items_paid',
               'organizationName',
               'partnerId',
               'delivery_contactless',
               'delivery_contactless_note',
               'userPhone',
               'userEmail',
               'userDietaryPreferences',
               'userTasterProfile',
               'deliveryMethod',
               'pickuporderTime',
               'mealPrepTimeSlot',
               'deliverectChannelLinkid'
             ];
             orderDetailsValidation = _validateOrderDetails(orderData, omittedFields);
            break;
          case 'paypal':
            if (orderData.orderDetails.orderTotal == "error") return Promise.reject({error: 'unable-to-calculate-total'});
             omittedFields = [
                'userOrderTip',
                'parentId',
                'hostUser',
                'childOrders',
                'reserveSpace',
                'textDateTime',
                'peopleRSVPing',
                'reservationHours',
                'reservationMinutes',
                'durationOrderAhead',
                'invitesDay',
                'myFriends',
                'inWaitlist',
                'loyaltyTimeSaver',
                'inCartId',
                'hostOrderId',
                'items_paid',
                'organizationName',
                'partnerId',
                'deliveryAddressDetails',
                'delivery_contactless',
                'delivery_contactless_note',
                'userPhone',
                'userEmail',
                'userDietaryPreferences',
                'userTasterProfile',
                'deliveryMethod',
                'mealPrepTimeSlot',
                'deliverectChannelLinkid'
              ];
             //Do not check for deliveryAddress if order is not for delivery
             if (orderData.orderDetails.orderType !== 'delivery') {
               omittedFields.push('deliveryAddress');
             }
             orderDetailsValidation = _validateOrderDetails(orderData, omittedFields);
            break;
          case 'only-rate':
             orderDetailsValidation.isValidated = true;
            break;
          case 'order-ahead':
            orderDetailsValidation.isValidated = true;
           break;
          case 'pay-free-food':
           orderDetailsValidation.isValidated = true;
          break;
          default:
              return Promise.reject(new Error('Invalid order createType. Valid types are "cart" or "paypal"'));
        }
        console.log("createType=1111===",createType)
        if (orderDetailsValidation.isValidated) {
          
          if(currentOrderDetails.orderId && (createType == 'pay-free-food' || createType == 'paypal')) {
            // Check free food

            return checkFreedFood(currentOrderDetails.orderId, VENUE_ID).then((result) => {
              if(result.checked) {
                return _submitCreateOrder(orderData, createType)
                  .then((orderCreated) => {
                    checkForInCartOrder();
                    return orderCreated;
                  })
              } else {
                return {checkFreeFood: true}
              }
            })
          } else {
            // No check free food
            return _submitCreateOrder(orderData, createType)
            .then((orderCreated) => {
              checkForInCartOrder()
              console.log("reloading for ordercreated...if createType is onlyrate, and also the ...", createType, orderCreated);
              if (createType == "only-rate"){
                if(check_iOSapp() || check_androidApp()) {
                    return window.location.href = `/rate/order/${orderCreated.orderId}/menu-items?flag=mobile`
                }else{
                  return window.location.href = `/rate/order/${orderCreated.orderId}/menu-items`
                }
                }
               if(orderCreated && orderCreated.data && orderCreated.data.cart_existed){
                sessionStorage.reloadPage = true;
//                 window.location.reload(); //this was leading to reload of rest page if item added from homepage
//                return false;
              }
              if (createType != "only-rate" && orderCreated.data){
                Order.updateOrderDetail('orderId', orderCreated.data.orderId);
              }
              return orderCreated;
            })
          }
        } else {
          let missingValues = orderDetailsValidation.missingValues.toString();
          // console.error('Validation error', orderDetailsValidation)
          let errDetails = `MissingValues: ${missingValues}`;
          // swal("Missing some order details to process order",errDetails, 'error');
          return Promise.reject({
            error:"missing-value",
            details:orderDetailsValidation.missingValues
          });
        }
      };
      /**
       * Validate the giving details
       * @param {Object} orderDetails
       * @param {Array} omittedFields fields to be ommited for validation
       */
      const _validateOrderDetails = (orderDetails = {}, omittedFields = []) => {
        let missingValues = [];
        for (let detail in orderDetails) {
          let detailValue = orderDetails[detail];
          if (typeof detailValue === 'object') {
            for (let innerDetail in detailValue) {
               if (!omittedFields.includes(innerDetail) && _isInvalidValue(detailValue[innerDetail]) && innerDetail != 'specialInstructions') {
                missingValues.push(innerDetail);
              }
            }
          } else if (!omittedFields.includes(detail) && _isInvalidValue(detailValue)&& detailValue != 'specialInstructions') {
            missingValues.push(detail);
          }
        }
        return {isValidated: missingValues.length === 0, missingValues};
      };

      const _isInvalidValue = (value) => {
        return  (value === "" || value === undefined || value === null);
      };

    /**
     * Delete an in-cart order
     */
    const deleteInCartOrder = () => {
       const orderId         = getOrderDetail('orderId');
       const parentId = getOrderDetail('parentId');

       return $.promisedAjax({
            url: `/orders/delete/incart?orderId=${orderId}${parentId ? `&parentId=${parentId}` : ''}`,
            type: 'DELETE',
            contentType: 'application/json',
            dataType: 'json'
          }).then((res) => {
            return res;
          })
          .catch((err) => {
            return err;
          })
    };

    const deleteOrderPaid = () => {
      const orderId  = getOrderDetail('orderId') || '';
      const parentId = getOrderDetail('parentId') || '';
      const isCancelAtCart = true
      return $.promisedAjax({
           url: `/orders/delete/paid?orderId=${orderId}&parentId=${parentId}&isCancelAtCart=${true}`,
           type: 'DELETE',
           contentType: 'application/json',
           dataType: 'json'
         }).then((res) => {
           return res;
         })
         .catch((err) => {
           return err;
         })
   };
    const resetDataOrder = (orderId) => {
      setTimeout(()=>{
        __get_all_orders_infor();
        refreshMenuDiscount();
        refreshFreeFoodScreen();
        SubOrder.removeFromList(orderId);
        $('#call-setting-menu').click();
       }, 500)
    }


    window.checkForOrdersInCheckout = checkForOrdersInCheckout;
    window.checkForInCartOrder      = checkForInCartOrder;
    window.GetOrderInfo             = GetOrderInfo;
   // window.organizationPriceChanges = organizationPriceChanges;

    //Public methods
    return {
      clearOrderDetails,
      clearOrderAhead,
      checkForInCartOrder,
      GetOrderInfo,
      checkForOrdersInCheckout,
      updateOrderDetail,
      renderCurrentOrderDetailsToDOM,
      deleteInCartOrder,
      deleteOrderPaid,
      getOrderDetail,
      getOrderDetails,
      updateSpecialInstructions,
      setOrderTimeIntervals,
      updateOrderTimeOptions,
      renderOrderTotals,
      renderUpdateTotals,
      createOrder,
      _updateOrderTip,
      _submitOrderUpdates,
      calculateCheckoutTip,
      _submitPayTip,
      confirmOrderAhead,
      _showEditOrderTipModal,
      deniedToUseCurrentLocation,
    };
  })();


// SUB-ORDER !!!!!!!!!!!!!!!!!!!!!
// SUB-ORDER !!!!!!!!!!!!!!!!!!!!!


const SubOrder = (() => {
  let orderAheadInCheckOutList = [];
  // Load sub order items and total for order ahead




  const loadSubTotal = (orderId = false, restaurant_free_food, platerate_free_food) => {
    // $(`.order-total-info-${orderId}`).html(`$<span class="loading-span"><span> -</span><span> -</span><span> -</span></span>`)
    // $(`.order-total-action-${orderId}`).prop('disabled', true)
    // return $.promisedAjax({
    //   url: `/orders/subOrderTotal`,
    //   type: 'POST',
    //   data: JSON.stringify({
    //     userId: USER_ID,
    //     venueId: VENUE_ID,
    //     orderId: orderId,
    //     freeFood: IS_FREE_FOOD,
    //     restaurantFreeFood: restaurant_free_food || 0,
    //     platerateFreeFood: platerate_free_food || 0,
    //   }),
    //   contentType: 'application/json',
    //   dataType: 'json'
    // }).then(res => {
    //   const { data } = res;
    //   console.log("in loadsubtotal after ajax call....",res);
    //   if(data) {
    //     renderSubOrderCart(data.orderId, data.items, data.total, data.orderDetails);
    //   }
    // })
    // .catch(e => {
    //   console.log("loadSubTotal error....",e);
    // });
  }

  // const renderSubOrderCart = (orderId, userCartItems = [], total, orderDetails) => {
  //   const isPaid = (total && total.items_paid);
  // console.log("renderSubOrderCart....",orderId, isPaid);
  //   let credits_info = total.credits_info;
  //   // Empty container
  //   let $itemsListContainer = $(`#sub-order-items-${orderId}`);
  //   let $suborder = $(`#sub-order-${orderId}`)

  //   // $itemsListContainer.empty();
  //   $(`#sub-order-items-list-${orderId}`).empty()
  //   const $itemsList = $('<ul/>', {
  //       id: `sub-order-items-list-${orderId}`
  //   });

  //   if(userCartItems && userCartItems.length) {
  //     userCartItems.forEach((item) => {
  //       if(!item.item_id) return
  //       let $itemLi = $("<li/>");
  //       const priceAddons = ShoppingCart.totalPriceAddon(item);
  //       const itemExtendedPrice = (Number(item.extended_price) + priceAddons).toFixed(2);


        // //EDit item
        // const editItemBtn = $('<a/>', {
        //   id: `eitem-${item.item_id}`,
        //   'data-order-id' : item.order_id,
        //   // click: _editItemBtnHandler
        // }).append('<i class="fa fa-pencil green edit-cart-item"></i>');
        // editItemBtn.css( 'cursor', 'pointer' );

        // const showSpecialInstruction = $('<a/>', {
        //   id: `instruction-${item.item_id}`,
        //   // click: function() {
        //   //   $(`#instructionDiv-${item.item_id}`).toggle();
        //   // }
        // }).append('<i class="fa fa-info-circle fa-lg orange"></i>');
        // showSpecialInstruction.css( {'cursor'        : 'pointer',
        //                               'margin-right' : '10px',
        //                               'margin-left' : '10px'
        //                             } );

        //Generate item details
        // let $itemDetails = $(`
        //     <div class="inline-flex-block cart-item-details grey">
        //         <p><span class="item-quantity">${item.quantity}</span> <span class="item-name">${item.item_name}</span></p>
        //         <div class="cart-stars-container"><div class="cart-item-stars" data-id="citem-stars-${item.item_id}" data-rating="${item.rating}"></div></div>
        //         <p>$<span data-unit-price="${item.retail_price} "class="item-price">${itemExtendedPrice}</span> </p>
        //     </div>
        // `)

        // if (!item.payment_id){
        //   const $deleteBtn = $('<a/>', {
        //     id:`citem-${item.item_id}-${item.group_id}`,
        //     'data-order-id' : item.order_id,
        //     click: _removeSubOrderItemBtnHandler
        //   }).append($('<i class="fa fa-lg fa-times-circle red"></i>'));
        //   $deleteBtn.css({'cursor': 'pointer','margin': '0px 10px'});
        //   //Append reove item btn
        //   $itemDetails.append($deleteBtn);
        // }

        // let $addonDetails = $(`<div class="group-cart-addons" style=" margin-left: 20px;">${ShoppingCart.renderAddon(item)}</div>`)
        // .append(showSpecialInstruction)
        // .append(editItemBtn);

        // let $itemInstruction = $(`
        //   <br>
        //
        //   <fieldset id="instructionDiv-${item.item_id}" class="fieldset fieldset-small" style="margin-bottom:10px;display:none;">
        //      <legend align="left">Special Instructions</legend>
        //      <span class="black">${item.special_instructions || ""}</span>
        //    </fieldset>
        // `);

  //       $itemLi.append($itemDetails);
  //       $itemLi.append($addonDetails);
  //       // $itemLi.append($itemInstruction);
  //       $itemsList.append($itemLi);
  //     });
  //   }

  //   // *******************************************************TOTAL ORDER
  //   const itemsSubtotal = Number(total.taxable_subtotal) > 0 ? Number(total.taxable_subtotal).toFixed(2): '0.00';
  //   const salesTax      = Number(total.tax_amount) > 0 ? Number(total.tax_amount).toFixed(2): '0.00';
  //   const totalPrice    = Number(total.total_price) > 0 ? Number(total.total_price).toFixed(2): '0.00';
  //   const credit_restaurant = total.credit_restaurant && !isNaN(Number(total.credit_restaurant)) ? Number(total.credit_restaurant).toFixed(2) : '0.00'
  //   const credit_platerate = total.credit_platerate && !isNaN(Number(total.credit_platerate)) ? Number(total.credit_platerate).toFixed(2) : '0.00'
  //   const restaurant_free_food = total.restaurant_free_food && !isNaN(Number(total.restaurant_free_food)) ? Number(total.restaurant_free_food).toFixed(2) : '0.00'
  //   const platerate_free_food = total.platerate_free_food && !isNaN(Number(total.platerate_free_food)) ? Number(total.platerate_free_food).toFixed(2) : '0.00'
  //   const credit_restaurant_max = total.credit_restaurant_max
  //   const credit_platerate_max = total.credit_platerate_max

  //   const duration_order_ahead = total.duration_order_ahead || 0;
  //   const discount_order_ahead = total.discount_order_ahead || 0;

  //   const subtotalPaid = total.paid_cart_total || 0

  //   const $cartTotalsDiv = $("<div/>", {
  //     id    : 'suborder-total',
  //     class : 'order-total-section'
  //   })

  //   //Create totals to container
  //   const $totalsDetails = $(`<div class="wrapper-order-pay">
  //     ${subtotalPaid
  //       ? `<p style=" color: mediumseagreen; font-style: italic;">Already paid: <span class="right-align order-total-info-${orderId}">$${subtotalPaid.toFixed(2)}</span></p><hr/>`
  //       : ''
  //     }
  //     <p>Subtotal: <span class="right-align value-total-price order-total-info-${orderId}">$${itemsSubtotal}</span></p>
  //     <p>Sales Tax: <span class="right-align order-total-info-${orderId}">$${salesTax}</span></p>
  //    </div>`);

  //    //Add the total price
  //   //Check if the cart has delivery type
  //   const orderTipType = total.tip_type;
  //   const orderTipLater = total.tip_later;
  //   // if (orderTipType) {

  //   const tipPercent = (orderTipType === "percent") ? (total.tip * 100) : null;
  //   const orderTipAmt = (orderTipType !== "cash") ? total.tip_amount.toFixed(2) : 'Cash Tip';



  //   const $orderTip = $(`<p>Tip: <span class="right-align order-total-info-${orderId}" id="checkout-tip-value-${orderId}">$${orderTipAmt}</span></p>`);
  //   //Add the tipPercent if the user set any percentage tip
  //   if (tipPercent) $orderTip.append(`<span class="sm-text" id="checkout-tip-percentage-${orderId}"> (${tipPercent.toFixed(0)}%) </span>`);

  //   //Edit user tip
  //   if (!isPaid){
  //     const editUserOrderTipBtn = $('<a />', {
  //       class           : `suborder-tip-edit order-total-action-${orderId}`,
  //       'data-order-id' : orderId
  //       // click: _editUserOrderTipHandler
  //     }).append('Edit');
  //     $orderTip.append(editUserOrderTipBtn);
  //   }


  //   $totalsDetails.append($orderTip);
  //   let restaurantFreeFood = `<div>
  //                               <label id="tip-later-container" style="width: 100%">
  //                                 <span class="label-text black" style="font-size: 1.5rem !important;">
  //                                   <span style="font-size: 16px; font-weight:600">Free Food at Restaurant:</span>
  //                                 </span>
  //                                 <input type="text" value="$${restaurant_free_food}" data-orderid="${orderId}" data-min="0" data-max="${credit_restaurant_max}" title="$0.00" placeholder="$0.00" class="form-control restaurant-free-food input-free-food order-total-action-${orderId}">
  //                                 <span class="rpsv-disp-block" style="font-weight:600; font-size: 16px;">($${credit_restaurant})</span>
  //                               </label>
  //                             </div>
  //                             `
  //   let plalerateFreeFood = `<div>
  //                             <label id="tip-later-container">
  //                               <span class="label-text black" style="font-size: 2rem !important;">
  //                                 <span style="font-size: 16px; font-weight:600">Free Food at any Restaurant:</span>
  //                               </span>
  //                               <input type="text" value="$${platerate_free_food}" data-orderid="${orderId}" data-min="0" data-max="${credit_platerate_max}" title="$0.00" placeholder="$0.00" id="" class="form-control platerate-free-food input-free-food order-total-action-${orderId}">
  //                               <span class="rpsv-disp-block" style="font-weight:600; font-size: 16px;">($${credit_platerate})</span>
  //                             </label>
  //                           </div>`
  //   if(Number(credit_restaurant) > 0) $totalsDetails.append(restaurantFreeFood);
  //   if(Number(credit_platerate) > 0) $totalsDetails.append(plalerateFreeFood);
  //   $totalsDetails.find('.restaurant-free-food').currencyFormatter();
  //   $totalsDetails.find('.platerate-free-food').currencyFormatter();

  //   if(Number(total.total_price) > 0) {
  //     $suborder.find('.pay-suborder-btn').text('Pay');
  //     $suborder.find('.pay-suborder-btn').attr('data-free-food', '');
  //   } else {
  //     $suborder.find('.pay-suborder-btn').text('Pay with free food');
  //     $suborder.find('.pay-suborder-btn').attr('data-free-food', true);
  //   }
  //   // }

  //   const $totalPrice =  $(`<hr/>
  //     <div>
  //       <p style="position: relative;">
  //         Total: <span class="right-align order-total-info-${orderId}">$${totalPrice}</span>
  //         ${isPaid ?
  //            `<span class="price-value checkout-paid-tag" style="position:relative;color: green;font-weight:500;font-size:16px;">
  //               <i class="fa fa-lg fa-check-square-o"></i> <span>Paid</span>
  //             </span>
  //             `
  //             :
  //            `<span class="price-value checkout-paid-tag" style="position:relative;color: gray;font-weight:500;font-size:16px;">
  //               <i class="fa fa-lg fa-square-o"></i> <span>Paid</span>
  //             </span>`
  //         }
  //       </p>
  //     </div>`);
  //   $totalsDetails.append($totalPrice);
  //   let freeFoodAvailable  =`
  //     <div class="row">
  //     <div class="col-sm-12 col-md-12">
  //       <p class="float-right" style="color: mediumseagreen">Free food you can earn: $<span class="free-food-can-earn-${orderId} order-total-info-${orderId}" style="font-size:16px;">${parseFloat(credits_info.totalCredits).toFixed(2)}</span></p>
  //     </div>
  //     </div>
  //     <div class="row">
  //         <div class="col-sm-12 col-md-12">
  //           <p class="float-right" style="color: mediumseagreen"> Free food earned: $<span class="free-food-earned-${orderId} order-total-info-${orderId}" style="font-size:16px;">${parseFloat(credits_info.earnedCredits).toFixed(2)}</span></p>
  //         </div>
  //     </div>
  //   `;

  //   renderEligibleCreditLabel(parseFloat(credits_info.totalCredits).toFixed(2))
  //   $totalsDetails.append(freeFoodAvailable);
  //   $cartTotalsDiv.append($totalsDetails)
  //   // *******************************************************TOTAL ORDER

  //   let $discount = '';
  //   if(discount_order_ahead && Number(discount_order_ahead) > 0) {
  //     $discount = $(`<div style=" display: inline-block; width: 100%;">
  //                     <div class="alert discount-tag pull-right" style="padding:3px; margin: 0px;">
  //                       <b>Reservations of ${duration_order_ahead} minutes or under earn you an additional credit of ${discount_order_ahead}% in free food.</b>
  //                     </div>
  //                   </div>`);
  //   }
  //   const notification =`<div class="row">
  //   <h3 class="black">Your Order Items
  //     <a href="javascript:void(0);" id="join-requests-checkouts" style="margin-left:10px;" data-id-notification=${orderId}>
  //       <i class="fa fa-2x fa-envelope-square orange"><span class="badge badge-notify" id="notify-checkout-${orderId}"style="display:none;"></span></i>
  //     </a>
  //   </h3>
  // </div>`;

  //   $itemsListContainer.empty()
  //   if(orderDetails.orderType === 'order-ahead') $itemsListContainer.append(notification);

  //   $itemsListContainer.append($itemsList);
  //   $itemsListContainer.append($discount);
  //   $itemsListContainer.append('<hr>');
  //   $itemsListContainer.append($cartTotalsDiv);

  //   //Initialize ratings stars
  //   _initRatingStars();

  // }

  $('body').on('change', '.restaurant-free-food, .platerate-free-food', function(e){
    const orderId = $(this).data('orderid');
    if(orderId) {
      const $suborder = $('body').find(`#sub-order-${orderId}`)
      if($suborder && $suborder.length) {
        IS_FREE_FOOD = false;
        let res_free_food = $suborder.find('.restaurant-free-food').val();
        let pla_free_food = $suborder.find('.platerate-free-food').val();
        res_free_food = convertToNumber(res_free_food);
        pla_free_food = convertToNumber(pla_free_food);
        loadSubTotal(orderId, res_free_food, pla_free_food)
      }
    }
  })


  const _removeSubOrderItemBtnHandler = (e) => {
      e.preventDefault();
      const $thisItemLi = $(e.currentTarget).parent();
      const itemId  = e.currentTarget.id.split('-')[1];
      const groupId  = e.currentTarget.id.split('-')[2];
      const orderId = $(e.currentTarget).data('order-id');
      _removeSubOrderItem(itemId, orderId, groupId).then((res) => {
        Order.checkForOrdersInCheckout(null, orderId, {noScroll: true});
      })
  };
  const _removeSubOrderItem = (itemId, orderId, groupId = null) => {
    let data = { userId: USER_ID, itemId, groupId, venueId: VENUE_ID, orderId };
    return $.promisedAjax({
      url: '/orders/shoppingcart/delete',
      type: 'POST',
      data: JSON.stringify(data),
      contentType: 'application/json',
      dataType: 'json'
    });
  };

  const getItems = (orderId) => {
    let subOrder = orderAheadInCheckOutList.find(c => c.orderId == orderId);
    let items = subOrder ? subOrder.items : [];
    items = items.filter(i => i.payment_id == null);
    return items;
  }

  // Update available order-ahead checkout
  const updateOrderAheadInCheckout = (checkouts) => {
    let newList = [];
    checkouts.forEach(c => {
      if (COMPLEX_ORDER_TYPES.includes(c.order_type)){
        let data = {
          orderId        : c.order_id,
          inProcessId    : c.in_process_id,
          payment_status : c.payment_status,
          items          : c.itemList
        }
        newList.push(data);
      }
    })
    orderAheadInCheckOutList = newList;
  }

  const updateTotalSubOrder = (checkouts) => {
    let orderIds = [];
    checkouts.forEach(c => {
      let id = c.order_id
      if (COMPLEX_ORDER_TYPES.includes(c.order_type) && !orderIds.includes(id)){
        orderIds.push(id);
      }
    })
    orderIds.forEach((orderId) => {
      loadSubTotal(orderId);
    })
  }

  const removeFromList = (id) => {
    orderAheadInCheckOutList = orderAheadInCheckOutList.filter(obj => obj.orderId !== id);
  }

  // Update available order-ahead checkout
  const getOrderAheadInCheckout = () => {
    return orderAheadInCheckOutList;
  }

  /**
   * Submit request to create suborder Paypal payment transaction
   */





  const submitPaySubOrder = (_orderId, payfree = false) => {
      const data = {
          userId: USER_ID,
          venueId: VENUE_ID,
          orderId: _orderId
      }
      if(payfree) {
        return $.promisedAjax({
          url: `/orders/execute_suborder_payment_free_food`,
          type: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json',
          dataType: 'json'
        })
      } else {
        return $.promisedAjax({
            url: `/orders/create_suborder_payment`,
            type: 'POST',
            data: JSON.stringify(data),
            contentType: 'application/json',
            dataType: 'json'
        })
      }
  };
  return {
    loadSubTotal,
    updateOrderAheadInCheckout,
    updateTotalSubOrder,
    getOrderAheadInCheckout,
    getItems,
    submitPaySubOrder,
    removeFromList
  };
})();
// SUB-ORDER !!!!!!!!!!!!!!!!!!!!!
// SUB-ORDER !!!!!!!!!!!!!!!!!!!!!


  // WAITLIST - &WORKING &&&&&
  const Waitlist = (() => {
    const init = (venueId) => {
      let orderId = Order.getOrderDetail('orderId')
      refreshWaitlistTable(venueId)
      checkAddCriteria(orderId)
    }

    const add = (orderId) => {
      let myFriends = [];
      let current_emails = $('#waitlist #email-my-friends').val() || '';
      _.forEach(current_emails.split(','),(item)=>{
          myFriends.push({email: item, sent:false})
      })

      let data = {
        orderId             : orderId,
        reserve_space       : $('#waitlist #space-people').val(),
        reservation_hours   : $('#waitlist #reservation-hours').val(),
        reservation_minutes : $('#waitlist #reservation-minutes').val(),
        myFriends           : myFriends
      }
      return $.promisedAjax({
        url: `/orders/waitlist/add`,
        type: 'POST',
        data: JSON.stringify(data),
        contentType: 'application/json',
        dataType: 'json'
      })
    }

    const refreshWaitlistTable = (venueId) => {
      let user_permissions = $('#user-roles').text().trim()
      try {
        user_permissions = JSON.parse(user_permissions)
      }catch (err){
        user_permissions = []
      }
      let im_admin = _.includes(user_permissions, 'Restaurant Admin')
      let orderType = Order.getOrderDetail('orderType')
      if (orderType != 'waitlist') return
      return $.promisedAjax({
        url: `/orders/waitlist/get-queue?venueId=${venueId}`,
        type: 'GET',
        contentType: 'application/json',
        dataType: 'json'
      }).then(res => {
        if ($.fn.DataTable.isDataTable("#waitlist-queue")) {
          $("#waitlist-queue").dataTable().fnDestroy();
        }
        $("#waitlist-queue tbody").html("")

        let orderId = Order.getOrderDetail('orderId')
        let parentId = Order.getOrderDetail('parentId')
        let is_in_waitlist = res.data.find(d => d.order_id == orderId || d.order_id == parentId ) ? true : false

        var table = $('#waitlist-queue').DataTable({
          ordering: false,
          retrieve: true,
          data: res.data,
          "scrollX": true,
          language: {
            emptyTable: "There are currently no orders in waitlist."
          },
          columns: [
            { data: 'order_name',
              render: function ( data, type, row, meta ) {
                 return `${meta.row+1}. ${im_admin ? data : '*'}`
              }
            },
            { data: 'party_size' },
            { data: 'estimated_wait_mins' },
            { data: 'estimated_seating_time',
              render: function ( data, type, row, meta ) {
                 return moment(data).format("hh:mm A")
              }
            },
            { data: 'added_to_waitlist_at',
              render: function ( data, type, row, meta ) {
                 return moment(data).format("hh:mm A")
              }
            },
            { data: null },
          ],
          createdRow: function (row, data, index) {
            let btn = ``
            // Set row color
            if (is_in_waitlist && orderId == data.order_id){
              btn = `<button type="button" data-order-name="${data.order_name}" class="btn btn-xs waitlist-rename">Rename</button>`
              $(row).css("background-color", "mediumspringgreen")
            } else if (is_in_waitlist && data.order_id == parentId){
              $(row).css("background-color", "mediumspringgreen")
            } else if (!is_in_waitlist){
              btn = `<button  class="btn btn-xs btn-secondary waitlist-addme add-me" data-order-id="${data.order_id}" data-user-id="${data.user_id}" disabled>Add me</button>`
              $(row).css("background-color", "lightgray")
            } else {
              $(row).css("background-color", "lightgray")
            }

            $('td', row).eq(-1).html(btn).css('text-align', 'center')
          },
        });
        $('#waitlist-queue').on('click', '.waitlist-rename', function(e){
          const name_order = $(this).data('order-name') || '';
          let html = `<div class="modal fade" id="waitlistRenameOrder" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true" style="background: none !important;">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Rename Order</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <input type="text" class="col-12 form-control" id="inputEditNameOrder" value="${name_order}"/>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" id="updateNameOrder">Save</button>
              </div>
            </div>
          </div>
          </div>`;
          if($('#modal-rename-order').length == 0){
            $("body").append('<div class="modal-rename-order"></div>');
          }
          $('.modal-rename-order').html(html);
          setTimeout(()=>{
            $('#waitlistRenameOrder').modal('show');
            $('#updateNameOrder').click(function(){
              let order_name = $('#inputEditNameOrder').val();
              Order._submitOrderUpdates(orderId, {order_name}).then(res => {
                $('#waitlistRenameOrder').modal('hide');
                Swal.fire("Successful change of order name!", "", "success")
                refreshWaitlistTable(VENUE_ID);
              })
            })
          }, 100);
        });
      })
    }

    const checkAddCriteria = (orderId) => {
      if (!orderId) return
      return $.promisedAjax({
        url: `/orders/waitlist/check-add-criteria?orderId=${orderId}`,
        type: 'GET',
        contentType: 'application/json',
        dataType: 'json'
      }).then(res => {
        renderAddCriteriaList_View(res.data.criteria)

        //Enable/ disable add to list button
        if(res.data.allow){
          $("#add-to-waitlist").removeAttr('disabled');
        } else {
          $('#add-to-waitlist').prop("disabled", false);
        }
        return res
      })
    }

    const checkSeatCriteria = (orderId) => {
      if (!orderId) return
      return $.promisedAjax({
        url: `/orders/waitlist/check-seat-criteria?orderId=${orderId}`,
        type: 'GET',
        contentType: 'application/json',
        dataType: 'json'
      }).then(res => {
        renderSeatCriteriaList_View(res.data.criteria)

        //Enable/ disable add to list button
        if(res.data.allow){
          $("#waitlist-ready-seat-btn").removeAttr('disabled');
        } else {
          $('#waitlist-ready-seat-btn').prop("disabled", true);
        }
        return res
      })
    }

    const displayLoyaltyTimeSaver = (orderId) => {
      if (!orderId) return
      let loyaltyTimeSaver = Order.getOrderDetail('loyaltyTimeSaver')
      if (loyaltyTimeSaver && loyaltyTimeSaver.totalJumpAhead > 0){
        $("#waitlist-totalJumpAhead").text(loyaltyTimeSaver.totalJumpAhead)
        $("#waitlist-totalLifetimeSpend").text(loyaltyTimeSaver.totalLifetimeSpend)
        $("#waitlist-past30DaysSpend").text(loyaltyTimeSaver.past30DaysSpend)
        $("#waitlist-averagePercentageTip").text(loyaltyTimeSaver.averagePercentageTip)
        $("#waitlist-totalSpendPerHour").text(loyaltyTimeSaver.totalSpendPerHour)

        $("#waitlist-loyalty-time-saver").show()
      } else {
        $("#waitlist-loyalty-time-saver").hide()
      }
    }

    const inviteAdditionalPeople = (orderId, myFriends) => {
      if (!orderId || myFriends.length == 0) return
      let data = {
        orderId   : orderId,
        myFriends : myFriends
      }
      return $.promisedAjax({
        url: `/orders/waitlist/invite-additional-people`,
        type: 'POST',
        data: JSON.stringify(data),
        contentType: 'application/json',
        dataType: 'json'
      })
    }

    // View handlers
    const renderAddCriteriaList_View = (criteria) => {
      let $VIEW = $("#add-criteria-list")
      let is_check_location = false
      let htmlList = ``

      // Generate list of criteria
      Object.keys(criteria).forEach(key => {
        let c = criteria[key]
        let html = ``
        if(c.pass){
          html += `<p style="color:green;"><i class="fa fa-lg fa-check-square-o"></i>`
        } else {
          html += `<p style="color:gray;"><i class="fa fa-lg fa-square-o"></i>`
        }

        switch (key) {
          case "allPrepaid":
            html += ` Everyone must have pre-paid for their food`
            break;
          case "withinRange":
            html += ` You must be within <span style="font-size: inherit !important;">${c.range}</span> miles of the restaurant`
            is_check_location = true
            break;
          case "withinRangeWholeParty":
            html += ` Your whole party must be within <span style="font-size: inherit !important;">${c.range}</span> miles of the restaurant`
            is_check_location = true
            break;
          case "allRSPVd":
            html += ` Everyone in your party must have RSVP'd`
            break;
          case "depositPutDown":
            html += ` You must put down a deposit of $<span style="font-size: inherit !important;">${c.amount}</span>`
            break;
          default:
        }
        html += `</p>`
        htmlList += html
      })

      // Show/hide check location button
      $("#check-my-location-now").toggle(is_check_location)

      $VIEW.html(htmlList)
    }

    const renderSeatCriteriaList_View = (criteria) => {
      let $VIEW = $("#ready-seat-criteria-list")
      let is_check_location = false
      let htmlList = ``

      // Generate list of criteria
      Object.keys(criteria).forEach(key => {
        let c = criteria[key]
        let html = ``
        if(c.pass){
          html += `<p style="color:green;"><i class="fa fa-lg fa-check-square-o"></i>`
        } else {
          html += `<p style="color:gray;"><i class="fa fa-lg fa-square-o"></i>`
        }

        switch (key) {
          case "allPrepaid":
            html += ` Everyone must have pre-paid for their food`
            break;
          case "withinRange":
            html += ` You must be within <span style="font-size: inherit !important;">${c.range}</span> miles of the restaurant`
            is_check_location = true
            break;
          case "withinRangeWholeParty":
            html += ` Your whole party must be within <span style="font-size: inherit !important;">${c.range}</span> miles of the restaurant`
            is_check_location = true
            break;
          case "allRSPVd":
            html += ` Everyone in your party must have RSVP'd`
            break;
          case "depositPutDown":
            html += ` You must put down a deposit of $<span style="font-size: inherit !important;">${c.amount}</span>`
            break;
          default:
        }
        html += `</p>`
        htmlList += html
      })

      // Show/hide check location button
      $("#ready-seat-check-my-location-now").toggle(is_check_location)

      $VIEW.html(htmlList)
    }


    //==============
    // Event handlers
    $('#waitlist').on('click', '#add-to-waitlist', (e) => {
      const orderId   = Order.getOrderDetail('orderId')
      const orderType = Order.getOrderDetail('orderType')

      if (orderId && validateOrderAhead(orderType)) {
        add(orderId).then(res => {
          if (res.data.status == "success"){
            init(VENUE_ID)
            Swal.fire("You has been added to waitlist!")
            Order.clearOrderDetails()
            Order.checkForInCartOrder()
          } else if (res.data.code == "criteria-not-pass"){
            Swal.fire("You hasn't met the criteria yet!", "", "warning")
          } else if (res.data.code == "full-slot"){
            Swal.fire("This order is full!", "", "warning")
          } else {
            Swal.fire("Something wrong happened!", "", "error")
          }
        })
        .catch(e => {
          Swal.fire("Failed to add to waitlist",  "", "error")
        }).finally(() => {
          let orderId = Order.getOrderDetail('orderId')
          checkAddCriteria(orderId)
        })
      }
    })

    // Update user last location
    $('#waitlist').on('click', '#check-my-location-now', (e) => {
      updateLocation()
    })

    $('#waitlist').on('click', '#additional-invite', (e) => {
      let list_emails = $('#waitlist #invite-additional-people').val().replace(/\s/g, '')
      if(!list_emails){
        Swal.fire("Add at least one email", 'Please check again !', "error");
        $('#waitlist #invite-additional-people').val('')
        return
      }
      const orderId = Order.getOrderDetail('orderId')
      let add_emails = $('#waitlist #invite-additional-people').val() || ''

      if(add_emails && validateOrderAhead("waitlist")){
        let myFriends = []
        _.forEach(add_emails.split(','),(item)=>{
          // if(!_.includes(current_emails,item)){
            myFriends.push({email: item, sent:false})
          // }
        })
        return inviteAdditionalPeople(orderId, myFriends).then(res => {
          if (res.data.status == "success"){
            Swal.fire("Invitation was sent!", "", "success")
            $('#waitlist #invite-additional-people').val('')
          } else if (res.data.code == "full-slot"){
            Swal.fire("This order is full", "", 'warning');
          }
        }).catch(err => {
          Swal.fire("Something wrong happened.", "", "error")
        })
      }
    })

    $('#waitlist').on('click', '#refresh-waitlist-table', (e) => {
      refreshWaitlistTable(VENUE_ID)
    })



    return {
      init,
      add,
      refreshWaitlistTable,
      checkAddCriteria,
      checkSeatCriteria,
      displayLoyaltyTimeSaver
    }
  })()



  function run_recorded_actions(){
    let url = new URL(document.location);
    const params = (url).searchParams;
    let menuitemid = params.get('buymenuitemid');
    // trinhnt get menuItemId in param
    const type = params.get('type');
    let buymenuitemquantity = params.get('buymenuitemquantity');
    let orderedBeforeLogin = localStorage.getItem('orderedBeforeLogin');
    if(type === "setFoodPrefProfile" && menuitemid) {
      let spclInstInStorage = localStorage.getItem("specialInstructions2_")
      let spclInstrObj = JSON.parse(spclInstInStorage)
      console.log("spclInstrObj=======", spclInstrObj)
      modal_render_item_ordered(menuitemid)
      if (spclInstInStorage && spclInstrObj && spclInstrObj.spclInstr && spclInstrObj.spclInstr != '' && (menuitemid == spclInstrObj.menuitem)) {
        $('#modal-order-item-addons').find('.modal-item-special-instructions').val(spclInstrObj.spclInstr);
      }
    } else if(menuitemid && url.pathname && url.pathname.includes("/restaurant/")){
      /*below important for homepagebuy->reg->rest flow */
      orderedBeforeLogin = "false";
      orderedBeforeLogin = buy_item_homepage( menuitemid, buymenuitemquantity); //returns "true" if addtocartitemhandler; "false" if invalid item or has addons
      if(orderedBeforeLogin == "true"){
        localStorage.removeItem('url_before_logging_in');
        localStorage.removeItem('orderedBeforeLogin');
        addCartItemHandler();
      }
      localStorage.removeItem('buymenuitemid');
      localStorage.removeItem('buymenuitemquantity');
    } else {
      rate_time_homepage()
    }
  }
  function rate_time_homepage(){
    const menuItemId = sessionStorage.getItem('rate_item_home');
    if(menuItemId){
      let   node = $('#menuitemData_'+menuItemId).val();
      if(menuItemId && node){
        __selectedMenuItems.push(JSON.parse(node));
          const { items } = getMenuSelectedItems();
          addItemToCartByOrderType('only_rate', items)
          sessionStorage.removeItem('rate_item_home');
          //added below since for rate items, the shopping cart was not showing up
            $('#myorder-accordion').show()
            $('#collapseMyOrder').modal('show');
      }
    }
  }

//if it is opening the item popup or any error, then return false to stop flow of addcartitemhandler
//else return true;
function buy_item_homepage(menuItemId, buymenuitemquantity=1){
  //console.log("in buy_item_homepage..menuItemId...",menuItemId, buymenuitemquantity);
  let   node = $('#menuitemData_'+menuItemId);
  if(menuItemId && node){
    localStorage.setItem("buymenuitemquantity", buymenuitemquantity);
    localStorage.setItem("buymenuitemid", menuItemId);
    try{
      node=JSON.parse(node.val());
    }catch(err){
      console.log("error parsing node in buy_item_homepage  ...",err);
      return "false";
    }
    //if item with addons and not selected before logging in, show item popup, else go to order popup
    if(node.children && node.children.length>0 && !window.location.href.includes("buymenuitemid")){
      let showAddonPopup = true;
      console.log("showing item popup for addons");
      node.children.forEach((chld)=>{
        let key = chld.key;
       // console.log("CHILD..=", chld, key);
        //another level since this top level is like "choose"
        if(chld && chld.children){
          chld.children.forEach((gchld)=>{
            let key1 = gchld.key;
            let storedQty = localStorage.getItem(`addonquantity-${key1}`)
            console.log("storedQty....",storedQty);
            if(storedQty) showAddonPopup = false;
          });
        } else {
          let storedQty = localStorage.getItem(`addonquantity-${key}`)
          console.log("storedQty....",storedQty);
        }
      });
      if(showAddonPopup) {
        modal_render_item_ordered(menuItemId);
        return false;
      } else {
        __selectedMenuItems.push(node);
        return "true";
      }
    } else {
      __selectedMenuItems.push(node);
      return "true";
    }
  } else {
    console.log("in buy_item_homepage..node menuitemData_ not found for menuItemId...",menuItemId);
    return "false";
  }
}


// for new user, below runs before running recorded_actions; hence moving the call to within below if (userIsLoggedIn)
// __load.handler = run_recorded_actions;

  if (userIsLoggedIn) {
    console.log("user is logged in, running recorded actions..", new Date());
    run_recorded_actions();
    console.log("after running the run_recorded_actions, and showing cart..",  new Date());
    //INit check for in cart order
    loadingOrderAnimation('show');
    Order.checkForInCartOrder().then((foundIncartOrder) => {
      if (foundIncartOrder) {
        //Load and render the cart items
        if (foundIncartOrder.parent_order_id) {
          let option = { parentOrderUpdate : true }
          ShoppingCart.loadCurrentCartItems(option);
        } else if(foundIncartOrder.order_type == 'order-ahead' && foundIncartOrder.request_reservation == 'invited') {
          let option = { RequestedReservation : true }
          ShoppingCart.loadCurrentCartItems(option);
        }else{
          ShoppingCart.loadCurrentCartItems();
        }
      }

//set the organization special instructions if exists
      let splInstr = $("#specialInstructions").val();
      let orgInstr = $("#orgDeskInstructions").val();
      console.log("specialInstructions. and orginstr..",splInstr, orgInstr);
      if (!splInstr && orgInstr){
        $("#specialInstructions").val(orgInstr);
      }

      Order.checkForOrdersInCheckout(foundIncartOrder, false, {noScroll: false, firstLoad: true});

      // If user access invite link order-ahead
      let order_ahead_invitation, url, hostId_orderAhead, userEmail;
      try{
        order_ahead_invitation = JSON.parse(sessionStorage.order_ahead_invitation);
        sessionStorage.removeItem('order_ahead_invitation')
      }catch(err){}

      if(order_ahead_invitation){
        hostId_orderAhead = order_ahead_invitation.order_id;
        userEmail = order_ahead_invitation.email;
      }else{
        url = new URL(window.location.href);
        hostId_orderAhead = url.searchParams.get('order_id');
        userEmail = url.searchParams.get('email');
      }

      if(hostId_orderAhead && userEmail){
        if(foundIncartOrder){
          return toastr.warning('You are having an order in your cart. Please complete it before joining another order')
        }
        let userId = $('#userId').val();
        Order.GetOrderInfo({order_id: hostId_orderAhead, user_id: userId, email: userEmail}).then((orderInfo)=>{
          if(orderInfo == 403){
            Swal.fire({
              title: "Current account is not avaiable",
              text: `You need to log in with account: ${userEmail} to confirm the invitation !`,
              icon: "error",
              showCancelButton: false,
              showConfirmButton:true,
              allowOutsideClick: true
            });
            return
          }else if(orderInfo == 404 || orderInfo == 402){
            Swal.fire({
              title: "Can not get order informations",
              text: `Please try again !`,
              icon: "error",
              showCancelButton: false,
              showConfirmButton:true,
              allowOutsideClick: true
            });
            return
          }
          OrderAheadInfo(orderInfo);
        });
      }
    }).finally(() => {
      __load.order = true;
      loadingOrderAnimation('hide');
      console.log("after showing cart..", new Date());
    });
  }
  const OrderAheadInfo = function (info){
    let d = new Date();
    let _hour = d.getHours();
    let modal_title;
    if(_hour < 12){
       modal_title = 'Good morning !';
    }else if(12 <= _hour && _hour < 18) {
      modal_title = 'Good afternoon !';
    } else{
      modal_title = 'Good evening !';
    }
    let modal_html = `
        <div class="modal fade" id="order-ahead-info" tabindex="-1" role="dialog" aria-labelledby="OrderAheadInfo" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="OrderAheadInfo">${modal_title}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p class="h3">Are you sure you want to accept ${info.order_name.replace(' Order','')} invitation for ${moment(info.order_expected_date).format('MM/DD/YY')} at ${moment(info.order_expected_date).format('h:mm a')} for ${info.reservation_hours} hours ${info.reservation_minutes} minutes ?</p>
                <br>
                <p>Today: ${moment().format('MM/DD/YY')}</p>
              </div>
              <div class="modal-footer">
                <button type="button" id="decline-invitation" class="btn btn-danger" style="width: 100px;"> No </button>
                <button type="button" id="confirm-invitation" class="btn btn-primary" style="width: 100px;"> Yes </button>
              </div>
            </div>
          </div>
        </div>
    `;
    $( "body" ).append( modal_html );
    $('#order-ahead-info').modal('show');
    $('#confirm-invitation').on('click',function(){
      $('#order-ahead-info').modal('hide');
      let data = {
        parentId: info.order_id,
        parentUserId: info.user_id,
        venueId: $('#venueId').val(),
        rsvp: 'confirmed',
      }
      $.ajax({
        url: '/orders/order-ahead/invitation',
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(data),
        success: function (res) {
            if(res.status == 200){
              toastr.success('Confirm successfully');
            }else{
              toastr.error("Confirm failed");
            }
            Order.checkForInCartOrder().then((item)=>{
              loadCurrentCartItems();
            });
        },
        error: function (err) {
          toastr.error("Confirm failed");
        },
      });
    })
    $('#decline-invitation').on('click',function(){
      $('#order-ahead-info').modal('hide');
      let data = {
        parentId: info.order_id,
        parentUserId: info.user_id,
        venueId: $('#venueId').val(),
        rsvp: 'declined',
      }
      $.ajax({
        url: '/orders/order-ahead/invitation',
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(data),
        success: function (res) {
            if(res.status == 200){
              toastr.success('Decline successfully');
            }else{
              toastr.error("Decline failed");
            }
            Order.checkForInCartOrder().then((item)=>{
              loadCurrentCartItems();
            });
        },
        error: function (err) {
          toastr.error("Decline failed");
        },
      });
    })
  }

  window.OrderAheadInfo = OrderAheadInfo
  /**
   * EVENTS HANDLERS
   */
  //Buy Item Button from Restaurant Menu
  function swalSelectOrderType() {
    Swal.fire({
      title: "Select order type",
      icon: "warning",
      showCancelButton: false,
      showConfirmButton:false,
      allowOutsideClick: true
    });
  }

  function promptSwal() {
     Swal.fire({
        title: "Add items to:",
        icon: "warning",
        showCancelButton: false,
        showConfirmButton:false,
        allowOutsideClick: true
      });
  }

  // Let use choose to add selected items to sub-order or shopping cart
  function renderAddItemPrompt(){
      //Prompt user to select order type
      let wait = true;
      let buttons = {
        colors: [],
        buttonNames: [],
        functions: []
      }

      buttons.colors.push("#3CB371");
      buttons.buttonNames.push('Order 1');
      let func = function(){
          // resolve();
      }
      buttons.functions.push(func);

      swalExtend.call({}, {
          swalFunction: promptSwal,
          hasCancelButton: true,
          buttonNum: buttons.buttonNames.length,
          buttonColor: buttons.colors,
          buttonNames: buttons.buttonNames,
          clickFunctionList: buttons.functions
      });
  }

  function checkAlcoholDeliveryAllowed(hasAlcohol){
    let alcoholDeliveryAllowed = $("#alcohol-delivery-allowed").val();
    if(hasAlcohol && alcoholDeliveryAllowed != 'true'){
        Swal.fire({
          title: "Warning!",
          text: "You have items that contain alcohol which can’t be delivered (in red in the order).  Remove them to continue or change the order type.", type: "warning",
          icon: "warning",
        });
      return false;
    }
  }


   function addItemToCartByOrderType(orderType, selectedItems){

    __checkout_load.add_items = false
      const { crowdItem } = getMenuSelectedItems();

      if (orderType != 'only_rate'){
        if(crowdItem) {
          Swal.fire({
            title: "Oops!",
            text: `${crowdItem.title || 'Items(s)'} is crowd-sourced menu item and is not available for ${orderType} at this time`,
            icon: "warning",
            allowOutsideClick: true,
            confirmButtonText: "OK",
            confirmButtonColor: "#3cb371",
            showCancelButton:false,
          });
          return false;
        }
      }

      if(orderType == 'delivery') {
        let hasAlcohol = selectedItems && selectedItems[selectedItems.length - 1].alcohol? selectedItems[selectedItems.length - 1].alcohol : false;
        let alcoholDeliveryAllowed = $("#alcohol-delivery-allowed").val();
        if(hasAlcohol && alcoholDeliveryAllowed != 'true'){
          const addAlcohol = function () {
            Swal.fire({
              title: ('Are you sure you want to add this item?'),
              text: 'This item contains alcohol.  This restaurant can’t deliver alcohol, are you sure you want to add this item?',
              icon: "warning",
              allowOutsideClick: true,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }

        const buttonNames  = ["Yes", "No"];
        const buttonColors = ["#d43f3a", "mediumseagreen"];
          swalExtend.call({}, {
            swalFunction: addAlcohol,
            hasCancelButton: true,
            buttonNum: 2,
            buttonColor: buttonColors,
            buttonNames: buttonNames,
            clickFunctionList: [
              function () {
                continueOrder(orderType, selectedItems);
              },
              function () {
                return false;// no
              }
            ]
          });
        } else {
          continueOrder(orderType, selectedItems);
        }
      } else{
        continueOrder(orderType, selectedItems);
      }
   }

   function initPopUp() {
    // if(isOnMobile()) {
    //   //mobile handler
    //   if(isDisplayOnMobile) {
    //     isPopUpShown = false;
    //     document.addEventListener('mousemove', processMouseActivityPopup, false);
    //     document.addEventListener('mousedown', processMouseActivityPopup, false);
    //     assignPopUpMouseActivityPopup();
    //     assignSystemPopup();
    //   }
    // } else {
      //desktop handler
      //isPopUpShown = false;
      // document.addEventListener('mousemove', processMouseActivityPopup, false);
      // document.addEventListener('mousedown', processMouseActivityPopup, false);
      // assignPopUpMouseActivityPopup();
      // assignSystemPopup();
    //}
  }
  
   function continueOrder(orderType, selectedItems){
      Order.updateOrderDetail('orderType', orderType)
      localStorage.setItem('orderType', orderType);
      //Create cart order
      loadingOrderAnimation('show');

      POSITION_SCROLL_ORDER = [`#headingMyOrder`, `#collapseMyOrder`];

      Order.createOrder('cart', selectedItems)
       .then((orderCreated) => {
          if (orderCreated.data && orderCreated.data.inProcessId != 1){
            let orderNumber = orderCreated.data.inProcessId;
            $('#myorder-title').text(`My Order ${orderNumber}`);
          }
          $('#myorder-accordion').show(); //this is needed outside the if to show order popup for new user add to cart #17774
        _menuTreeDeselectAll();
        if(orderType == 'delivery') {
          let homeAddress = $("#head-location-input-search").val();
          let { deliveryAddress } = $('#delivery-address-edit-trigger').data('customTextEditTrigger').getData();
          let profileAddressEmpty = $("#hfProfileAddressIsSet").val() === "false";
        }
        return ShoppingCart.loadCurrentCartItems({scrollToMyOrder : true, formerAction: 'create-cart'}).then(() => {
          swalAddItemSuccess(selectedItems[0].item_name);
          //initPopUp();
          Order.renderCurrentOrderDetailsToDOM();
        });
      })
       .catch((err) => {
         console.log("in catch...", err);
          let url = new URL(window.location.href);
          let friend_order = url && url.searchParams ? url.searchParams.get('order_id') : null;
          if(friend_order){
            let redirect_to = window.location.href.split('?')[0] + '?show=MyOrder';
            console.log("in catch..redirecting to.", redirect_to);
            window.location.href = redirect_to;
            return
          }
          Swal.fire('Error adding item(s) to your cart', '', 'error');
       }).finally(()=>{
        __checkout_load.add_items = true;
         loadingOrderAnimation('hide');
       });
   }
   var autocomplete;
   function getLocationfun(){
     return false
      // if (navigator.geolocation) {
      //     navigator.geolocation.getCurrentPosition(function (position) {
      //         var geolocation = {
      //             lat: position.coords.latitude,
      //             lng: position.coords.longitude
      //         };
      //         var circle = new google.maps.Circle({
      //             center: geolocation,
      //             radius: position.coords.accuracy
      //         });
      //         autocomplete.setBounds(circle.getBounds());
      //     });
      // }
   }

   function initializeAutocompleteAddress(){
       let dAddress = document.getElementById('delivery-address_input');
       if(dAddress) {
           // Create the autocomplete object, restricting the search to geographical
           // location types.
           autocomplete = new google.maps.places.Autocomplete((dAddress), {types: ['geocode']});
           // When the user selects an address from the dropdown, populate the address
           // fields in the form.
           autocomplete.addListener('place_changed', fillInAddress);
           if(!check_iOSapp() && !check_androidApp()){
            var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            if (is_safari){
              var googlelocationValue=Cookies.get('locationPermission');
              if(googlelocationValue!='granted'){
                getLocationfun();
              }
              else{
                var savedLat = Cookies.get('posLat');
                var savedLon = Cookies.get('posLon');
                var position = {coords:{latitude: savedLat, longitude: savedLon}, fromIp:false};
                  var geolocation = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
                  };
                  var circle = new google.maps.Circle({
                      center: geolocation,
                      radius: position.coords.accuracy
                  });
                  autocomplete.setBounds(circle.getBounds());
              }
            }else{
              getLocationfun();
            }
          } else {
              var geolocation = {
                lat: $("#nativeAppLatitude").val(),
                lng: $("#nativeAppLongitude").val()
              };
              var circle = new google.maps.Circle({
                  center: geolocation
                  // radius: position.coords.accuracy //TODO: Rajat, Need to implement Accuracy also for latlong
              });
              autocomplete.setBounds(circle.getBounds());
          }
       }
   }

    function fillInAddress() {
        // Get the place details from the autocomplete object.
        var place = autocomplete.getPlace();
        console.log("place=====",place.formatted_address)
          Delivery.checkDeliveryAddress(place.formatted_address, place, '');
         
    }

   function _menuTreeDeselectAll() {
    // console.log("in _menuTreeDeselectAll");
   /* redesign
      $('#tree').fancytree('getTree').visit(function (node) {
        node.setSelected(false);
      });
*/
      __selectedMenuItems = [];
      localStorage.getItem('selectedItems', function(err, items) {
        localStorage.removeItem('selectedItems');
      });
   }

$('#request-platerate-contact').on('click', (e) => {
  Swal.fire({
    title: "Request PlateRate work with this restaurant",
     text: 'PlateRate will try to work with this restaurant so you can order from them via PlateRate!',
    icon: "info",
    showCancelButton: true,
    confirmButtonText: "Yes",
    cancelButtonText: "No",
    allowOutsideClick: true,
  }).then((result) => {
       if (result.isConfirmed) {
        // swal.close();
        let venueId =  $("#venueId").val();
        $.ajax({
          url: '/salescrm/requestrestaurant',
          method: 'POST',
          contentType: "application/json; charset=utf-8",
          data: JSON.stringify({
            venueId: venueId
          }),
          dataType: 'json',
          error: function (err) {
            Swal.fire({
              title: "Request error !!!",
              text: 'Please try agian!',
              icon: "error",
              // showCancelButton: true,
              confirmButtonText: "Yes",
              // cancelButtonText: "No",
              allowOutsideClick: true,
            })
          },
          success: function (res) {
            $('#request-platerate-contact').attr('disabled','disabled');
            $('#request-platerate-contact').off('click');
            Swal.fire({
              title: "Request success",
              text: 'Please wait. PlateRate will try to work with this restaurant',
              icon: "success",
              // showCancelButton: true,
              confirmButtonText: "Yes",
              // cancelButtonText: "No",
              allowOutsideClick: true,
            })
          }
        })
    }
  });
});
  $('#btn-rate-selected-items,.btn-rate-selected-items').on('click',()=>{
    rate_item_after_adding();
  })
window.rate_item_after_adding = (added_items)=>{
  if (userIsLoggedIn) {
    let orderId, orderType;
    orderId = Order.getOrderDetail('orderId');
    orderType = Order.getOrderDetail('orderType');
    let noSalesOnTheSystem = $('#noSalesOnTheSystem').val();
    if (orderId){
      if (orderType == 'only_rate'){
        //Add items to current order
        ShoppingCart.addItems(added_items, orderId, orderType, noSalesOnTheSystem).then((response) => {
          if (response.success){
            swalAddItemSuccess(added_items[0].item_name);
            ShoppingCart.loadCurrentCartItems();
          } else {
            Swal.fire('Unable to add item !', '', 'error');
          }
        })
      } else {
        Swal.fire(``,  `These items were crowd-sourced menu items which aren't available for order, please remove them if you'd like to purchase your order from this restaurant`, 'info')
      }
    } else {
      Order.updateOrderDetail('orderType', 'only_rate')
      Order.createOrder('cart', added_items).then((orderCreated) => {
        Order.createOrder('only-rate').then((response) => {
          Order.checkForOrdersInCheckout(false, false, {noScroll: true, firstLoad: true}).then((res) => {
            Order.clearOrderDetails();
            _menuTreeDeselectAll();
            $('html, body').animate({ scrollTop: $(`#headingCheckout-${response.orderId}`).offset().top }, 2000);
            $(`#collapseCheckout-${response.orderId}`).collapse('show');
            if($('#myorder-title').text() == "My Order"){
            console.log("my order, hence hiding myrder accordion");
              $('#myorder-accordion').hide();
            }else{
              $('#myorder-accordion').show();
            }
            let url = new URL(window.location.href);
            let friend_order = url.searchParams ? url.searchParams.get('order_id') : null;
            if(friend_order){
              Order.updateOrderDetail('inCartId',orderId);
            }else{
              Order.updateOrderDetail('orderId',orderId);
            }
            Order.updateOrderDetail('orderType',orderType);
          })
        })
        .catch((err) => {
          let url = new URL(window.location.href);
          let friend_order = url && url.searchParams ? url.searchParams.get('order_id') : null;
          if(friend_order){
            Order.updateOrderDetail('inCartId',orderId);
          }else{
            Order.updateOrderDetail('orderId',orderId);
          }
          Order.updateOrderDetail('orderType',orderType);
        });
      })
    }
  }else{
    if (typeof(Storage) !== "undefined") sessionStorage.action = 'btn-rate-selected-items';
    console.log("redirecting to login page from ====", Cookies.get('newuser'));
	  let userloggedcookie = Cookies.get('newuser');
    if ( typeof(userloggedcookie) === 'undefined' && userloggedcookie!='newuserval'){
       window.location.assign('/users/register?next=' + location.pathname);
    }else{
      window.location.assign('/users/login?next=' + location.pathname);
    }
  }
}
function checkValidation(item, addon, numSelected, showPopupMessageOn = false){
  let data = addon; //before redesign it was addon.data;
  let $validation = {
    valid: true
  };
  if(typeof numSelected === "undefined") numSelected = 0;
  let message = '';
  let errorType = '';
  if(_.hasIn(data,'min') && Number(data.min) > numSelected){
    errorType = 'min-limit-error';
    $validation.valid = false
    $validation.type = 'min'
    $validation.min = data.min
    $validation.message = `You must select at least ${Number(data.min)} add-ons`;
  }
  if(_.hasIn(data,'max') ){
    if(data.max && numSelected > Number(data.max)) {
      errorType = 'max-limit-error';
      $validation.valid = false
      $validation.type = 'max'
      $validation.max = data.max
      $validation.message = `You must select ${Number(data.max)} add-ons in maximum`;
    }
  }
  if(!$validation.valid){
    let listPath = [];
    listPath.push(addon.title)
    let theItem;
    let source = true;
    if(addon.parentItemKey){
      source = $("#menuitemData_"+addon.parentItemKey);
    } else {
      source=false;
    }
    while(source && source.key != item.key){
      if(!theItem && source.type == 'item') theItem = source;
      listPath.push(source.title)
      if(source.parentItemKey){
        source = $("#menuitemData_"+source.parentItemKey);
      } else {
        source=false;
      }
    }
    // listPath.push(source.title)
    listPath = _.reverse(listPath);
    // let html_errors = `
    //     <p class="h4" style="color:#CC0000"><b>Please check selected items or addons</b><p>
    //     <p>${$validation.message} under the <span style="color:green; font-weight:bold; font-size: 15px;">${listPath.join(' > ')}</span> menu item to add it to your order</p>
    // `;
    try{
      $(`#modal-addon-limit-info-${addon.key}`).css('color', "")
    }catch(e){}
    if (showPopupMessageOn == errorType || showPopupMessageOn == 'all'){
      let message = '';
      if (errorType == 'min-limit-error'){
        message = `
          <span style="color:green; font-weight:bold; font-size: 1.5rem;">${listPath.join(' > ')}</span>
          <br>
          Select at least ${data.min} add-ons
        `;
      } else if (errorType == 'max-limit-error'){
        message = `
          <span style="color:green; font-weight:bold; font-size: 1.5rem;">${listPath.join(' > ')}</span>
          <br>
          Select at most ${data.max} add-ons
        `;
      }
console.log("triggered======================")
setTimeout(() => {
      Swal.fire({
        title: message,
        icon: "warning", // SweetAlert2 uses `icon` instead of `type`
        confirmButtonText: "OK",
        allowOutsideClick: true, // Allows clicks outside the popup to close it
        showCloseButton: true // Adds a close button (optional)
      });
    }, 0);   
      if (addon) {
        try{
          $('#modal-order-item-addons .scrollModal').animate({
              scrollTop: 0
          }, 100, 'linear',
          function(){
            let positionScroll = $(`a[data-target="#modal-${addon.key}"]`).position().top;
            $("#modal-order-item-addons .scrollModal").animate({ scrollTop: positionScroll});
          });
          $(`#modal-addon-limit-info-${addon.key}`).css('color', "red")
        }catch(e){}
      }
    }
    // $('.selected-items-errors').html(html_errors);
    // $('.selected-items-errors').css({'display': 'block', 'width': '95%', 'margin': '2%'})
  } else {
    $('.selected-items-errors').css({'display': 'none'})
  }
  return $validation;
}

var thongTin = {};
var rootKey = '';
var validation = true;
function getSelectedNumber(sourceTree){
  let tongDaChon = 0;
  _.forEach(sourceTree.children,(addon)=>{
    if(_.size(addon.children)) return getSelectedNumber(addon);
    let inp = $('#modal-order-item-addons').find(`input[id="modal-node-${addon.key}"]`);
    // let inp = $(addon.tr).find('input');
    if(!inp) return true;
    let inp_val, inp_type;
    inp_type = inp.attr('type')
    if(_.includes(['radio','checkbox'],inp_type)){
      inp_val = Number(inp.prop('checked'));
      // validation = checkValidation(addon,inp_val)
    } else{
      inp_val = Number(inp.val());
      // validation = checkValidation(addon,inp_val)
    }
    if(!Number.isNaN(inp_val)) tongDaChon += inp_val;
    return validation;
  })
  if(!validation) return
  if(_.hasIn(thongTin, sourceTree.key)){
    thongTin[sourceTree.key] = thongTin[sourceTree.key] + tongDaChon;
  }else{
    thongTin[sourceTree.key] = tongDaChon;
  }
  if(!tongDaChon) return
  let parentSource = true;
  if(sourceTree.parentItemKey){
    parentSource = $("#menuitemData_"+sourceTree.parentItemKey).val();
  } else {
    parentSource=false;
  }
  while(parentSource && parentSource.key != rootKey){
    if(_.hasIn(thongTin, parentSource.key)){
      thongTin[parentSource.key] = thongTin[parentSource.key] + 1;
    }else{
      thongTin[parentSource.key] = 1;
    }
    if(parentSource.parentItemKey){
      parentSource = $("#menuitemData_"+parentSource.parentItemKey).val();
    } else {
      parentSource=false;
    }
  }
}
function validateItem(item, showPopupMessageOn = false){
  let checkSelection = { valid: true};
  _.forEach(item.children,(addon)=>{
    checkSelection = checkValidation(item, addon,thongTin[addon.key], showPopupMessageOn)
    if(!checkSelection.valid) return false;

    checkSelection = validateItem(addon, showPopupMessageOn)
    return checkSelection.valid;
  })
  return checkSelection
}
function validateMenu(opts = {}, validateBySection = {}){
  const showPopupMessageOn = opts.showPopupMessageOn || false;
  const node = validateBySection.node || null;
  const addonSectionKey = validateBySection.addonSectionKey || null;

  validation = {valid: true};

  if (node) node.children = node.children.filter(c => c.key == addonSectionKey); // filter by only set node

  let SELECTED = node ? [node] : __selectedMenuItems;

  _.remove(SELECTED,(item)=>{return item.type != "item"});
  _.forEach(SELECTED,(items)=>{
    thongTin = {};
    if(!_.size(items.children)) return true;
    rootKey = items.key
    getSelectedNumber(items)
    if(!validation.valid) return false;
    validation = validateItem(items, showPopupMessageOn)
    return validation.valid
  })
    return validation
}

//on click of cancel button, unselect all items from tree
  $(".btn-menuitempopup-cancel").on('click',(e) =>{
    _menuTreeDeselectAll();
  });

  $('#buy-btn,.buy-btn').on('click', function(e) {
    e.preventDefault();
    if(!validateMenu({ showPopupMessageOn: 'all' }).valid) return false;
    $("#modal-order-item-addons").modal('hide'); //hide the addtocart popup
    // $('.order-this-item').focus();
    let orderAheadInCheckout = SubOrder.getOrderAheadInCheckout();
    let paidOrder            = orderAheadInCheckout.filter(o => o.payment_status == "paid");
    let showSubOrder         = (orderAheadInCheckout.length > 0) && (paidOrder.length < orderAheadInCheckout.length);
    if(check_iOSapp() || check_androidApp()) {
      console.log("redirecting from orders for mobile");
      window.location = "plateratewebtonative:additemorder?userName="+USER_NAME+"&userId="+USER_ID+"";
    }
    else{
     /* if(kochava){
        kochava.activity('Add to Cart', {
          user_id: USER_ID,
          name:USER_NAME,
          }, function(a, b){
        });
      }*/
    }
    addCartItemHandler();
   });

  //  validate change crowd item
   function validateItemChangeOrder(crowdItem, orderType) {
//201102 commenting timeout    setTimeout(() => {
      Swal.fire({
        title: "Not available",
        text: `${crowdItem.item_name || 'Items(s)'} is crowd-sourced menu item and is not available for ${orderType} at this time`,
        icon: "warning",
        allowOutsideClick: true,
        confirmButtonText: "OK",
        confirmButtonColor: "#3cb371",
        showCancelButton:false,
     }).then((result) => {
          if (result.isConfirmed) {
       
        __selectedMenuItems.forEach((node) => {
          if (node.key == crowdItem.item_id) {
            __selectedMenuItems.filter(x=>x.key != crowdItem.item_id); // unselect this
            removeSelection(node.key); //remove from localforage
            node.extraClasses = crowdItem.item_id;
            $('html, body').animate({ scrollTop: $(`.${crowdItem.item_id}`).offset()},'slow');
          }
        })
      }
      });
//    }, 1000);
   }

   function record_actions(items){
    localStorage.setItem('actions', JSON.stringify(items));
  }

   function addCartItemHandler(go_to_rate = false){
     const noSalesOnTheSystem = ($NO_SALES_ON_THE_SYSTEM.val() === 'true');
     const hasDeliveryOrder   = ($('#hasDeliveryOrder').val() === 'true');
     const hasPickupOrder     = ($('#hasPickupOrder').val() === 'true');
     const hasOrderAhead      = ($('#hasOrderAhead').val() === 'true');
     const hasWaitList        = ($HAS_WAITLIST.val() === 'true');
     const { items, crowdItem, enableType } = getMenuSelectedItems();
     let selectedItems = items;
     const localStorageItems = localStorage.getItem('actions') ? JSON.parse(localStorage.getItem('actions')) : [];
     const orderedBeforeLogin = localStorage.getItem('orderedBeforeLogin');
     const isPartneredRestaurant = $('#isPartneredRestaurant').val();
     const buymenuitemquantity = localStorage.getItem('buymenuitemquantity');
     const buymenuitemid = localStorage.getItem('buymenuitemid');
  
     if(buymenuitemquantity && buymenuitemid && selectedItems && selectedItems.length > 0) {
       let ind = selectedItems.findIndex(x=>x.item_id.toString()==buymenuitemid.toString() );
       if(ind>=0 && !isNaN(buymenuitemquantity) ){
          selectedItems[ind].quantity = Number(buymenuitemquantity);
          selectedItems[ind].special_instructions=localStorage.getItem('description');
       }
     }
     if(!_.isEmpty(localStorageItems) && orderedBeforeLogin === 'true' && selectedItems.length == 0 ){
      selectedItems = [...localStorageItems];
      localStorage.removeItem('description');
      localStorage.removeItem('orderedBeforeLogin');
      localStorage.removeItem('actions');
     }
     let _user_id = $("#user-id").text();
     if (selectedItems && selectedItems.length > 0) {
       if (userIsLoggedIn || _user_id) {
        if(go_to_rate) return addItemToCartByOrderType('only_rate', selectedItems);
        let orderId = Order.getOrderDetail('orderId');
        if (!orderId) {
          let latestOrderType = $("#latestOrderType");
          console.log("in addcarttoitem, no orderid and latestOrderType=",latestOrderType);
          let latestOrderTypeAvailable = true;

          if (latestOrderType.val() == 'pickup') latestOrderTypeAvailable = $('#hasPickupOrder').val() == 'true';
          else if (latestOrderType.val() == 'delivery') latestOrderTypeAvailable = $('#hasDeliveryOrder').val() == 'true';
          else if (latestOrderType.val() == 'order-ahead') latestOrderTypeAvailable = $('#hasOrderAhead').val() == 'true';
          else latestOrderTypeAvailable = false;

          if( !latestOrderTypeAvailable ) $("#latestOrderType").val(null);
          //use the last order type for the user if exists
          if(latestOrderType && latestOrderType.val() && latestOrderTypeAvailable) {
            latestOrderType = latestOrderType.val();
            addItemToCartByOrderType(latestOrderType, selectedItems);
          } else {
            let $ChooseTypeModal = $('#modal-select-order-type')
            let order_type_buttons = {
              'only_rate': '#rate-only-type',
              'delivery': '#delivery-type',
              'pickup': '#pickup-type',
              'order-ahead': '#order-ahead-type',
              'waitlist': '#waitlist-type',
            }
            let available_types = {
              'delivery': hasDeliveryOrder,
              'pickup': hasPickupOrder,
              'order-ahead': hasOrderAhead,
              'waitlist': hasWaitList,
            }
            _.forIn(order_type_buttons, (value, key)=>{
              $ChooseTypeModal.find(value).unbind('click')
              if(key == 'only_rate') {
                if(enableType['only_rate']) {
                  if(_.includes(_.valuesIn(available_types), true)) return true
                  $ChooseTypeModal.find(value).addClass('btn-outline-success')
                  $ChooseTypeModal.find(value).attr('style', 'background-color: ""; color: green;')
                  $ChooseTypeModal.find(value).on('click', function(){
                    $ChooseTypeModal.iziModal('close');
                  // $('.order-this-item').focus();
                    return addItemToCartByOrderType('only_rate', selectedItems)
                  })
                }
                return true
              }
              if (available_types[key] && enableType[key]){
                $ChooseTypeModal.find(value).on('click', function(e){
                  if ($(e.target).is('#infor-order-in-ahead')) return true
                  $ChooseTypeModal.iziModal('close');
                  // $('.order-this-item').focus();
                  if(crowdItem && crowdItem.item_id) {
                    validateItemChangeOrder(crowdItem, key)
                  } else {
                    addItemToCartByOrderType(key, selectedItems);
                  }
                })
              }else{
                $ChooseTypeModal.find(value).removeClass('btn-success').attr('style', 'background-color: #BDC6C1 !important')
              }
            })
            // if(isPartneredRestaurant == true || isPartneredRestaurant == 'true'){
              // show modal select order type or auto create order type
             console.log("now the order type...",__isSelectOrderType);
              if(__isSelectOrderType){
                $ChooseTypeModal.iziModal('open');
//quan, commenting this since it was leading to order popup not showing up for  addtocart->new user->ordertype flow.               $("#collapseMyOrder").modal('hide');
              }else{
                if(hasDeliveryOrder){
                  addItemToCartByOrderType('delivery', selectedItems);
                }else if(hasPickupOrder){
                  addItemToCartByOrderType('pickup', selectedItems);
                }else if(hasOrderAhead){
                  addItemToCartByOrderType('order-ahead', selectedItems);
                }else{
                  addItemToCartByOrderType('only_rate', selectedItems);
                }
              }
             }
             //else{
            //   showPartneredRestaurantModal();
            //   return;
            // }
         // }
          if(!isMobile || $(window).width() > 424){
            $("#collapseMyOrder").modal({
                backdrop: 'static',
                keyboard: true
            });
          }

        } else {
            const orderType = Order.getOrderDetail('orderType');
            const enableAdd = enableType[orderType]
            if(enableAdd) {
              //Add items to existing order
              let inCartItems = ShoppingCart.getCurrentCartItems();
              let inCartItemIds = inCartItems.map((item) => {
                return item.item_id;
              });
              let selectedItemIds = selectedItems.filter((item) => {
                return inCartItemIds.includes(item.item_id);
              });
              let listAlreadyInCartItems = '';
              selectedItemIds.forEach((item) => {
                listAlreadyInCartItems += '<li>' + item.item_name + '</li>';
              });
              if(crowdItem && orderType !== 'only_rate') {
                validateItemChangeOrder(crowdItem, orderType)
              } else {
                //Add items to current order
                loadingOrderAnimation('show');

                 if ($(window).width() < 424) {
                   $("#myorder-accordion").hide();
                  }else{
                   $("#myorder-accordion").hide();
                  }
                  if(!isMobile || $(window).width() > 424){
                    $("#collapseMyOrder").modal({
                        backdrop: 'static',
                        keyboard: true
                    });
                  }
                  haveBackdrop()
                __checkout_load.get_orders = false
                POSITION_SCROLL_ORDER = ['#headingMyOrder', '#collapseMyOrder'];
                let url = new URL(window.location.href);
                let orderAheadId = url && url.searchParams ? url.searchParams.get('orderAheadId') : '';
               
                if(orderAheadId && orderType === 'order-ahead'){
                  orderId = orderAheadId;
                }
                ShoppingCart.updateItemsOrder(orderId, selectedItems).then((response) =>{
                  if(response.status == 200) {
                    _menuTreeDeselectAll();
                     swalAddItemSuccess(selectedItems[0].item_name);
                    ShoppingCart.loadCurrentCartItems({scrollToMyOrder : false}).finally(() => {
                      //initPopUp();
                      loadingOrderAnimation('hide');
                    });
                  } else {
                    Swal.fire('Unable to add item !', '', 'error');
                    loadingOrderAnimation('hide');
                  }
                })
              }
            } else {
              const orderItem = enableType.item;
              let selectedItem = {}
              if(orderItem && orderItem[orderType]) {
                selectedItem = orderItem[orderType]
              }
              // Add items(s) not order type
              Swal.fire({
                title: "Not available",
                text: `${selectedItem.item_name || 'Items(s)'} is not available for ${orderType} at this time.`,
                icon: "warning",
                allowOutsideClick: true,
                confirmButtonText: "OK",
                confirmButtonColor: "#3cb371",
                showCancelButton:false,
              }).then((result) => {
                if (result.isConfirmed) {
       
                __selectedMenuItems.forEach((node) => {
                  if (node.key == selectedItem.item_id) {
//redesign                    node.extraClasses = selectedItem.item_id;
//                    node.render();
                    let $scrollTo = {};
                    if($(`#menu-node-id-${selectedItem.item_id}:visible`).length) $scrollTo = $(`#menu-node-id-${selectedItem.item_id}:visible`)
                    else if($(`.favorite-item#${selectedItem.item_id}:visible`).length) $scrollTo = $(`.favorite-item#${selectedItem.item_id}:visible`)

                    if($scrollTo.length) $('html, body').animate({ scrollTop: $scrollTo.offset().top - 10 }, 'slow');
                  }
                })
              }
              });
            }
         }
       } else { //user not logged in
          record_actions(selectedItems);
          const item = selectedItems.find((item) => !item.parent_item_id);
          const itemKey = item.item_id;
          const subType = $('#favorites-menu').css('display') === 'none' ? 'menu' : 'favorites';
          let userloggedcookie = Cookies.get('newuser');
          if ( typeof(userloggedcookie) === 'undefined' && userloggedcookie!='newuserval'){
           var url = `/users/register?next=${location.pathname}&type=${subType}&collapse=${itemKey}&buymenuitemid=${itemKey}&buymenuitemquantity=${selectedItems[0].quantity}`
       
          }else{
            var url = `/users/login?next=${location.pathname}&type=${subType}&collapse=${itemKey}&buymenuitemid=${itemKey}&buymenuitemquantity=${selectedItems[0].quantity}`
       
          }
          let newurl= window.location.href.split('?')[0]+`?type=${subType}&collapse=${itemKey}&buymenuitemid=${itemKey}&buymenuitemquantity=${selectedItems[0].quantity}`
         localStorage.url_before_logging_in = newurl;
        localStorage.setItem('urlOrderBeforeLogin', newurl);
         window.location.assign(url);
       }
     } else if(selectedItems && selectedItems.item_name) {
      Swal.fire({
           title: "Oops!",
           text: `${selectedItems.item_name || 'Items(s)'} can't be purchased right now. Please ask your server about when/if it will be available again`,
           icon: "warning",
           allowOutsideClick: true,
           confirmButtonText: "OK",
           confirmButtonColor: "#3cb371",
           showCancelButton:false,
       }).then((result) => {
          if (result.isConfirmed) {
       
         __selectedMenuItems.forEach((node) => {
           if (node.key == selectedItems.item_id) {
             $('html, body').animate({ scrollTop: $(`.${selectedItems.item_id}`).offset().top - 10 }, 'slow');
           }
         })
       }
       });
     } else {
       //Let the user know
       Swal.fire("Oops!", "You haven't selected any item(s) or this item(s) may not be eligible for orders yet.", "warning");
     }
   }
  function showPartneredRestaurantModal(){
    $('#modal-partnered-restaurant').remove()
    $('body').append('<div id="modal-partnered-restaurant"></div>')
    let html = `
    <div class="modal fade " id="modal-partnered" role="dialog" style="padding-right: 17px; display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
    <div class="modal-body">
      <div class="" style="padding: 20px;">
        <button type="button" class="close float-right confirmorder" data-dismiss="modal" onclick="if(typeof ga === 'function'){ga('send', 'event', [buttons], [click], ['orderComplete']);}else{} " aria-label="Close">
          <span aria-hidden="true" style="color: black;float: right;background: white;font-weight: bold;">X</span>
        </button>
      </div>
      <div style="padding: 0px 20px 20px 20px;">
        <div class="text-center" id="platerateLogo" alt="platerate logo" style="font-size: 80px;color: #02843d;font-weight: bold;">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-exclamation-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"></path>
          </svg>
        </div>
        <p class=" style=" font-weight:="" style="font-weight: bolder;text-align: center;max-width: 300px;margin-left: auto;margin-right: auto;">Sorry, we aren't partnered here yet</p>
        <p class=" style=" font-weight:="" style="font-weight: bolder;font-size: 20px;text-align: center;color: black;margin-left: auto;margin-right: auto;"><b>Do you want to get up to 60% a credit in free food at this restaurant?</b></p>
        <p class=" style=" font-weight:="" style="font-weight: bolder;text-align: center;max-width: 300px;margin-left: auto;margin-right: auto;">If enough people do. PlateRate Will reach Out to this restaurant</p>
      </div>
      <div class="col-12">
        <div class="col-6 text-center float-left"><button class="btn btn-danger col-12 no-partnered" data-dismiss="modal">No</button></div>
        <div class="col-6 text-center float-left"><button class="btn btn-success  col-12 yes-partnered" data-dismiss="modal">Yes</button></div>
      </div>
    </div>
    </div>
    </div>
    </div>`;
    $('#modal-partnered-restaurant').html(html);

    $('#modal-partnered').modal({backdrop: false, keyboard: false});
    $('.yes-partnered').unbind();
    $('.yes-partnered').click(function(){
      let venueId =  $("#venueId").val();
      $.ajax({
        url: '/salescrm/requestrestaurant',
        method: 'POST',
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify({
          venueId: venueId
        }),
        dataType: 'json',
        success: function (data) {
          if(data){
              Swal.fire({
                  title: "Request success",
                  text: 'Please wait. PlateRate will try to work with this restaurant',
                  icon: "success",
                  confirmButtonText: "Yes",
                  allowOutsideClick: true,
              })
          }
        }
      })
    })
  }

   function addCartItemSubOrderHandler(orderId){
     const { items, crowdItem, enableType } = getMenuSelectedItems();
     const selectedItems = items;
     let _user_id = $("#user-id").text();
     if (selectedItems && selectedItems.length > 0) {
       if (userIsLoggedIn || _user_id) {
           //Add items to existing order
           const orderType = Order.getOrderDetail('orderType');
           let inCartItems = SubOrder.getItems(orderId);
           let inCartItemIds = inCartItems.map((item) => {
             return item.item_id;
           });
           let selectedItemIds = selectedItems.filter((item) => {
             return inCartItemIds.includes(item.item_id);
           });
           let listAlreadyInCartItems = '';
           selectedItemIds.forEach((item) => {
             listAlreadyInCartItems += '<li>' + item.item_name + '</li>';
           });
           if (selectedItemIds.length > 0){
              if(crowdItem && crowdItem.item_id && orderType !== 'only_rate') {
                validateItemChangeOrder(crowdItem, orderType);
              } else {
                //Add items to current order
                ShoppingCart.addItems(selectedItems, orderId).then((response) => {
                  if (response.success){
                    POSITION_SCROLL_ORDER = [`#headingCheckout-${orderId}`, `#collapseCheckout-${orderId}`];
                    _menuTreeDeselectAll();
                    swalAddItemSuccess(selectedItems[0].item_name);
                    // SubOrder.loadSubTotal(orderId);
                  } else {
                    Swal.fire('Unable to add item !', '', 'error');
                  }
                })
              }
           } else {
             //Add items to current order
             ShoppingCart.addItems(selectedItems, orderId).then((response) => {
               if (response.success){
                 // SubOrder.loadItems(orderId);
                 POSITION_SCROLL_ORDER = [`#headingCheckout-${orderId}`, `#collapseCheckout-${orderId}`];
                 _menuTreeDeselectAll();
                 swalAddItemSuccess(selectedItems[0].item_name);
               } else {
                Swal.fire('Unable to add item !', '', 'error');
               }
             })
           }

         }
     } else if(selectedItems && selectedItems.item_name) {
      Swal.fire({
           title: "Oops!",
           text: `${selectedItems.item_name} can't be purchased right now. Please ask your server about when/if it will be available again`,
           icon: "warning",
           allowOutsideClick: true,
           confirmButtonText: "OK",
           confirmButtonColor: "#3cb371",
           showCancelButton:false,
       }).then((result) => {
          if (result.isConfirmed) {
       
         __selectedMenuItems.forEach((node) => {
           if (node.key == selectedItems.item_id) {
//redesign tbd             node.extraClasses = selectedItems.item_id;
//             node.render();
             $('html, body').animate({ scrollTop: $(`.${selectedItems.item_id}`).offset().top - 10 }, 'slow');
           }
         })
       }
       });
     } else {
       //Let the user know
       Swal.fire("Oops!", "You have not selected any item(s) or this item(s) may not be eligible for orders yet.", "warning");
     }
   }
    function swalAddItemSuccess (itemname,strTitle = null) {
    // $(".shopping_cart_btn .scard").addClass("animated bounce")
    doBounce($(".shopping_cart_btn"), 1, '16px', 500);


    if ($(window).width() < 424) {
      $('#collapseMyOrder').modal('hide');
      // $('.order-this-item').focus();
      // $(".shopping_card").addClass("animated bounce")
      toastr.options = {
      "progressBar": true,
      "positionClass": "toast-bottom-right restaurant-toast-top-right toast-above-b-bar",
      }
      toastr.success(itemname+" added to order");
      doBounce($(".shopping_card"), 1, '16px', 500);
    }else{
      toastr.options = {
      "progressBar": true,
      "positionClass": "toast-top-right restaurant-toast-top-right",
      }
      toastr.success(itemname+" added to order");
    }
   }
  function validateTimeOrderType () {
    getAttributes((data) => {
      if (data && data.code === 0 && data.data && data.data.workingTime) {
        const time_week_pickup = checkTimeOrderType(data.data.workingTime.Pickup);
        const time_week_delivery = checkTimeOrderType(data.data.workingTime.Delivery);
        const time_week_order_ahead = checkTimeOrderType(data.data.workingTime.InStore);

        if(time_week_delivery){
          $('#hasDeliveryOrder').val("true")
        }else{
          $('#hasDeliveryOrder').val("false")
        }
        if(time_week_pickup){
          $('#hasPickupOrder').val("true")
        }else{
          $('#hasPickupOrder').val("false")
        }
        if(time_week_order_ahead){
          $('#hasOrderAhead').val("true")
        }else{
          $('#hasOrderAhead').val("false")
        }
      }
    })
   }

   function checkTimeOrderType(time){
    var orderDay = new Date();
    const weekday = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    var day = weekday[orderDay.getDay()];
    if(time && time[day]) {
      const today = time[day];
      const str_open = validateTime(today);
      if(str_open) {
        return true;
      } else {
        return false;
      }
    }else{
      return false;
    }
   }

   function sliptTime(time) {
      let hour = null;
      let min = null;
      let ampm = null;
      if(time) {
        let arrTime = _.split(_.replace(time,' ',''),':');
        hour = arrTime[0];
        min = arrTime[1].substring(0,arrTime[1].length-2);
        min = Number(min);
        ampm = _.upperCase(arrTime[1].substring(arrTime[1].length-2));
        if(ampm == 'PM') hour = Number(hour) + 12;
        else hour = Number(hour);
      }

      return {
        hour: hour > 0 ? hour : null,
        min: min > 0 ? min : 0
      }
  }

  function validateTime (time) {
    if(time) {
      let _theday = moment().format('MM/DD/YYYY')
      let datenow =  moment()
      if(time.open){
        let open_time = moment(_theday + ' ' +  time.open, 'MM/DD/YYYY HH:mmA');
        let close_time = moment(_theday + ' ' + time.close, 'MM/DD/YYYY HH:mmA');
        if(datenow.isBetween(open_time, close_time)){
          return  true;
        }
      }
      if(time.altOpen){
        let alt_open_time = moment(_theday + ' ' +  time.altOpen, 'MM/DD/YYYY HH:mmA');
        let alt_close_time = moment(_theday + ' ' + time.altClose, 'MM/DD/YYYY HH:mmA');
        if(datenow.isBetween(alt_open_time, alt_close_time)){
          return  true;
        }
      }
      return  false;
    }
    return  false;
   }


   function getAttributes(callback){
    $.ajax({
        url: `/restaurantadmin/${$('#venueId').val()}/getattributes`,
        method: 'GET',
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        data: {},
        success : function(data) {
            if (callback) callback(data);
        },
        fail: function(f){
            if (callback) callback();
            console.log("getAttributes fail..",f);
        },
        error: function(error){
            if (callback) callback();
            console.log("getAttributes error...",error);
        }
    });
  }

   function saveAction (action) {
    if (typeof(Storage) !== "undefined") {
      sessionStorage.action = action;
    }
  }
   //** Detect delivery location btn, commented temporaly */
   // $(".detect-delivery-location").on('click', (e) => {
   //    Delivery.detectUserDeliveryLocation();
   // });

  /* Datepicker */
  var nowDate = moment(new Date()).format('MM/DD/YYYY h:mm A');
  var orderDatePickerOptions = {
    defaultDate: nowDate,
    useCurrent:true,
    showClose: true,
    icons: {
    close: 'closeText'
    }
  };
  // if(checkIfClosed(RESTAURANT_CLOSE_TIME, RESTAURANT_LAST_ORDER_CALL)){
  //   orderDatePickerOptions['minDate'] = moment(nowDate).add(1, 'days');
  // } else {
  //   orderDatePickerOptions['minDate'] = nowDate;
  // }
  setTimeout(()=>{
    getLocalTimeZone()
  }, 1000)
  //to get the local timezone of the venue for delivery notifications
  function getLocalTimeZone(){
  let getVenueId = $('#venueId').val();
  if(getVenueId && typeof getVenueId !== undefined)
  $.ajax({
    url: '/restaurant/LocalTime/'+getVenueId,
    type: 'GET',
    async: true,
    success : function(getRestaurantLocalTime){
        let timeZone = getRestaurantLocalTime.timeZone;
        let date = new Date();
        let countryTime = new Date(date.toLocaleString('en-US', {
          timeZone: timeZone
        }));
        let time = moment(countryTime).format("MM/DD/YYYY HH:mm A");
        orderDatePickerOptions.defaultDate = time;
        $("#orderdatepicker").datetimepicker(orderDatePickerOptions).on('dp.hide', function(e){
          $("#orderdatepicker").prop('disabled', false);
          var date = e.date;

          Order.updateOrderDetail("orderDate", date);
          $('#create-paypal-order').prop('disabled', false);
          $('#orderdatepicker').parent().removeClass('has-error');
          $('#orderdatepicker').parent().find('span.help-block').html('');
          Order.setOrderTimeIntervals();
          $(".order-change-times").first().trigger('change', [{isDateChange:true}])
        });
      }
  });
  }

  let __orderChangeUpdateTimer = 0
  $('.order-change-times').on('change', (e, data) => {
    let isDateChange = data ? data.isDateChange : false
    let $target = $(e.currentTarget)
    let waitTime = 0
    if (isDateChange) waitTime = 1300
    else waitTime = 0

    if(__orderChangeUpdateTimer)  clearTimeout(__orderChangeUpdateTimer);
    __orderChangeUpdateTimer = setTimeout(()=>{
      $('.order-change-times').prop('disabled', false)
      const orderId = Order.getOrderDetail('orderId')
      const orderType = Order.getOrderDetail('orderType')

      let order_expected_time
      if (orderType == 'order-ahead'){
        order_expected_time = $("#order-ahead-date").val()
      } else {
        order_expected_time = $(".edit-order-time #later").is(":checked") && $("#orderdatepicker").val()
        ? $("#orderdatepicker").val() : 'asap'
      }

      return Order._submitOrderUpdates(orderId, {order_expected_time}).then(res => {
        return Order.renderOrderTotals()
      }).finally(() => {
        $('.order-change-times').prop('disabled', false)
      })
    }, waitTime)
  })

  $('#describe-delivery-contactless, #specialInstructions').focusout(function(){
     Order.renderOrderTotals();
  })

  $('#check-delivery-contactless').change( function(){
    if($(this).is(":checked")){
      $('.show-note-delivery-contactless').css('display', 'block');
    }else{
      $('.show-note-delivery-contactless').css('display', 'none');
    }

    Order.renderOrderTotals();
  })



  $('.pick-order-date-time').change((e) => {
    let _date = $('#orderdatepicker').val();
    const _time = moment(_date).format('LT');
    Order.updateOrderDetail('orderTime', _time);
    $('#create-paypal-order').prop('disabled', false);
    $('#order-time-hma').removeClass('has-error');
    $('#order-time-hma').find('span.help-block').html('');
  });

  $('#later').on('click',function(){
    $('.time-later-label').hide()
    $('#btn-orderdatepicker').show()
    let _date = $('#orderdatepicker').val();
    if(!_date) return true
    _date = moment(_date).format('L');
    if(moment($('#orderdatepicker').val()).isValid()) {
      Order.updateOrderDetail("orderDate", _date, true);
    }
  })
  $('.meal-prep-time-slots').on('change',function(){
    var orderId = Order.getOrderDetail('orderId');
    let updateData={};
    var $pickupElement = $('#pickupTimeSlot');
    var $deliveryElement = $('#deliveryTimeSlot');
    var $errorMsgTimeslot = $('.timeslot-error-msg');
    const orderType = Order.getOrderDetail('orderType')
    if(orderType==='delivery'){
      if ($deliveryElement.val() === "") {
          $deliveryElement.addClass('invalid-select');
          $errorMsgTimeslot.show();
      }else{
          $deliveryElement.removeClass('invalid-select');
          $errorMsgTimeslot.hide();
          updateData.mealPrepTimeSlot = $deliveryElement.val();
      }
    }else if(orderType==='pickup'){
      if ($pickupElement.val() === "") {
          $pickupElement.addClass('invalid-select');
          $errorMsgTimeslot.show();
      }else{
          $pickupElement.removeClass('invalid-select');
          $errorMsgTimeslot.hide();
          updateData.mealPrepTimeSlot = $pickupElement.val();
      }
    }
    console.log("$(this).val()============",$deliveryElement.val())
    console.log("$(this).val()============",$pickupElement.val())
    Order._submitOrderUpdates(orderId, updateData)
  })

  $('#asap').on('click',function(){
    $('.time-later-label').show();
    $('#btn-orderdatepicker').hide()
    Order.updateOrderDetail("orderDate", 'asap', true);
  })

  /* ORder time */
  /* Listen to order time selection */
  $("input:radio[name=optradio]").on("click", function() {
    Order.updateOrderTimeOptions();
  });
  $('#optradio, .order-time-option').on('change', function() {
    sessionStorage.setItem('ordertypeoption', this.id );
    if(this.value=='later' || this.id=='later'){
      $('.pick-order-date-time').show()
      $('.optradio').val(this.value);
      $("#asap").prop('checked', false).removeAttr('checked');
    }else{
      $("#later").prop('checked', false).removeAttr('checked');
    }
    setTimeout(Order.updateOrderTimeOptions, 500);
  });
  $('#napkins').on('click', function() {
    var orderId = Order.getOrderDetail('orderId');
    let isChecked = $(this).is(':checked')
    let updateData = {
      'noNapkinsPlasticware': isChecked
    }
    Order._submitOrderUpdates(orderId, updateData)
    .then((response) => {
      toastr.success('Saved successfully')
    })
    .catch((error) => {
      toastr.error('Save Failed')
    })
  })

  /**
   * change order time to show edit order time container
   */
  $('.change-ordertime').on('click', (e) => {
    e.preventDefault();
    $('.static-ordertime-container').fadeOut();
    $('.edit-order-time').fadeIn();
    $('#later').prop('checked', true);
    $('#later').trigger('change');
    Order.updateOrderTimeOptions();
  })

  /**
   * Cancel order -> clear cart
   */


  function cancelOrderUnPaid() {
    if (PAYMENT_IN_PROCESS) return;

    const orderId = Order.getOrderDetail('orderId');
    if (orderId) {
        Swal.fire({
            title: 'Cancel Order',
            text: 'Are you sure you want to cancel your order?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: true,
        }).then((result) => {
            if (result.isConfirmed) {
                // Proceed with order cancellation
                Order.deleteInCartOrder().then((res) => {
                    if (res.status == 200) {
                        setTimeout(() => {
                            Swal.fire({
                                title: 'Successfully cancelled order!',
                                icon: 'success',
                                showCancelButton: false,
                                showConfirmButton: true,
                            }).then(() => {
                                // Reload the page without parameters like buymenuitemid
                                let url = window.location.href.split('?')[0];
                                console.log("reload after cancelling order...", url);
                                window.location.href = url;
                            });
                        }, 200);
                    } else {
                        Swal.fire('Failed cancelled order!', '', 'error');
                    }
                }).catch(err => {
                    Swal.fire('Failed cancelled order!', '', 'error');
                });
            } else {
                // User canceled the action
                console.log("Order cancellation was canceled");
            }
        });
    }
}

  //$('.btn-cancel-order-paid').on('click', (e) => {
  function cancelOrderPaid(){
    if (PAYMENT_IN_PROCESS) return
    const orderId  = Order.getOrderDetail('orderId');
    $.ajax({
      url: '/orders/check/delete',
      method: 'POST',
      dataType: 'json',
      data : {orderId: orderId}
      }).then(function (res) {
          if (res.code == 0) {

            const cancelOrder = function () {
              Swal.fire({
                title: 'Cancel Order',
                text: `Your order was already submitted, if you cancel there will be a $${res.feeCancelOrder} cancellation fee. Are you sure you want to cancel?`,
                icon: "warning",
                allowOutsideClick: true,
                showCancelButton: false,
                showConfirmButton: false,
              });
            }

            const buttonNames  = ["Yes", "No"];
            const buttonColors = ["#d43f3a", "mediumseagreen"];
            const orderId = Order.getOrderDetail('orderId');
            if(orderId) {
              swalExtend.call({}, {
                swalFunction: cancelOrder,
                hasCancelButton: true,
                buttonNum: 2,
                buttonColor: buttonColors,
                buttonNames: buttonNames,
                clickFunctionList: [
                  () => {
                    Order.deleteOrderPaid().then(async (res) => {
                        if (res.status == 200) {
                          setTimeout(function(){
                            Swal.fire({
                              title: 'Successfully cancelled order!',
                              text: '',
                              icon: "success",
                              showCancelButton: false,
                              showConfirmButton: true,
                            }).then((result) => {
                              if (result.isConfirmed) {
       
                              console.log("reload after cancelling order2");
                              //load only the rest url, not params like buymenuitemid, since order is xld
                              let url = window.location.href.split('?')[0];
                              window.location.href = url;
                              }
                            });
                          }, 200);
                          let data = {};
                          data.userId = USER_ID || '';
                          data.orderId = Order.getOrderDetail('orderId') || '';
                          data.orderType = Order.getOrderDetail('orderType') || '';
                          data.parentName = USER_NAME || '';
                          data.parentId = Order.getOrderDetail('parentId') || '';
                          data.mySelf = true;
                          $.promisedAjax({
                            url: `/orders/removefromorder`,
                            type: 'POST',
                            data: JSON.stringify(data),
                            contentType: 'application/json',
                            dataType: 'json'
                          })
                        } else {
                          Swal.fire('Failed cancelled order!', '', 'error');
                        }
                      }).catch(err => {
                        Swal.fire('Failed cancelled order!', '', 'error');
                      });
                  },
                  function () { // no
                  }
                ]
              });
            }
          }else{
            Swal.fire('Failed cancelled order!', res.message, 'error');
          }
      }).catch(function (error) {
        Swal.fire('Failed cancelled order!', '', 'error');
      });
 }

  /**
   * Order something else -> Scroll to Menu items
   */
  $('#order-something-else').on('click', (e) => {
    $('html, body').animate({
        scrollTop: $("#headingSix").offset().top
    }, 1000, function() {
        $("#collapseSix").collapse('show');
    });
  })

  /**
   * Tell the restaurant I've arrived
   */
  $('#order-ahead-confirm-btn, #waitlist-ready-seat-btn').on('click', (e) => {
    if (PAYMENT_IN_PROCESS) return
    let orderId = Order.getOrderDetail('orderId');
    Order.confirmOrderAhead().then(res => {
      console.log("reloading again for orderahead payment");
      window.location.href = "/rate/order/"+orderId+'/menu-items';
    });
  })

  /**
   * Create paypal order -> Pay By Paypal
   */
  let open = 0;

  $('#create-paypal-order, #rate-menu-items, #btn-resquest-order-ahead, #btn-update-order-ahead').on('click', (event) => {
    const orderTotalState = $(event.currentTarget).data("order-total-state");
    const isMealPrep = $('#is-meal-preps').val();
    let orderType = $('#order-method-container').find('.active').attr('name');
    if(isMealPrep && isMealPrep==='true'){
        var $pickupElement = $('#pickupTimeSlot');
        var $deliveryElement = $('#deliveryTimeSlot');
        var $errorMsgTimeslot = $('.timeslot-error-msg');
        // Check if a slot is selected
        if (orderType === "pickup") {
          if ($pickupElement.val() === "") {
              $pickupElement.addClass('invalid-select');
              $errorMsgTimeslot.show();
              $('html, body').animate({
                  scrollTop: $pickupElement.offset().top
              }, 500);
              $pickupElement.focus();
              return false;
          } else {
              $pickupElement.removeClass('invalid-select');
              $errorMsgTimeslot.hide();
          }
        } else if((orderType === "delivery")){
          if ($deliveryElement.val() === "") {
              $deliveryElement.addClass('invalid-select');
              $errorMsgTimeslot.show();
              $('html, body').animate({
                  scrollTop: $deliveryElement.offset().top
              }, 500);
              $deliveryElement.focus();
              return false; 
          } else {
              $deliveryElement.removeClass('invalid-select');
              $errorMsgTimeslot.hide();
          }
        }
    }
    if(orderTotalState == 'calculating'){
      Swal.fire({
        title: "",
        html: `
          <div style="margin-top: 30px;">
            <img alt="spinner" src="/images/spin_loader.gif" id="calculating-loader" style="width: 100px;">
          </div>
          <p style="margin-top: 50px; font-weight: 600; font-size: 24px; letter-spacing: 0.0015em; color: #4A4A4A;">
            Calculating... please wait.
          </p>
        `,
        showCancelButton: true,
        showConfirmButton: false,
        customClass: {
          popup: 'calculating-order',
        },
        cancelButtonText: "",
        cancelButtonColor: "#d9534f",
      });
      
      $('.sweet-alert.calculating-order .sa-button-container button.cancel').html(`
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.5447 1.02347C23.2069 0.68491 22.7483 0.494643 22.27 0.494643C21.7918 0.494643 21.3332 0.68491 20.9954 1.02347L12.154 9.84678L3.31259 1.00539C2.97479 0.666829 2.51617 0.476562 2.03791 0.476562C1.55965 0.476562 1.10103 0.666829 0.763231 1.00539C0.0580897 1.71053 0.0580897 2.84961 0.763231 3.55475L9.60462 12.3961L0.763231 21.2375C0.0580897 21.9427 0.0580897 23.0817 0.763231 23.7869C1.46837 24.492 2.60745 24.492 3.31259 23.7869L12.154 14.9455L20.9954 23.7869C21.7005 24.492 22.8396 24.492 23.5447 23.7869C24.2499 23.0817 24.2499 21.9427 23.5447 21.2375L14.7033 12.3961L23.5447 3.55475C24.2318 2.86769 24.2318 1.71053 23.5447 1.02347Z" fill="black" fill-opacity="0.54"/>
        </svg>
      `);
      return;
    }

    // check delivery-contactless
    let info = $('#order-method-container').find('.active').attr('name');
    // let   describe_delivery_contactless = $('#describe-delivery-contactless').val();
    if($('#check-delivery-contactless').is(":visible") && $('#check-delivery-contactless').is(":checked") && $('#describe-delivery-contactless').val() == '' && info=='pickup'){
      $('.show-delivery-contactless').css('box-shadow', 'none');
    }else{
      $('.show-delivery-contactless').css('box-shadow', 'none');
    }

    let itemsCurrent = ShoppingCart.getCurrentCartItems();
    // Filter items with 'active' set to 'no'
    const inactiveItems = itemsCurrent.filter(item => item.active && item.active.toLowerCase() === 'no');

    // If there are inactive items, create a comma-separated list of item names
    if (inactiveItems.length > 0) {
        const inactiveItemNames = inactiveItems.map(item => item.item_name).join(', ');

        // Trigger the SweetAlert with the list of inactive items
        Swal.fire({
            title: "Oops!",
            text: `${inactiveItemNames} item(s) are not available currently.`,
            icon: "warning",
            allowOutsideClick: true,
            confirmButtonText: "OK",
            confirmButtonColor: "#3cb371",
            showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
       
            // Additional actions after SweetAlert confirmation
            loadCurrentCartItems();
            Order.renderOrderTotals();
            $("#collapseMyOrder").modal('show');
          }
        });
        return false;
    }
    // var userphonenumber=window.userData && window.userData.local.phone;
    var userphonenumber = $("#user-phone").val();
    let idClicked = '';
    userphonenumber = userphonenumber? Number(userphonenumber): null;
    if(event.target && event.target.id) {
      idClicked = event.target.id;
    }
    
    if(isNaN(userphonenumber) && ['delivery', 'pickup'].includes(info)){
      // const settings =  $.promisedAjax({ url: `/guru-settings`, method: 'GET' });
     // if (settings.enableAlternativePhoneVerificationMethod){
        $('#restaurant-phone-alert').iziModal('open');
        if (window.phoneNumberScript && typeof window.phoneNumberScript === 'function') {
          $('#collapseMyOrder').hide();
          // $('.order-this-item').focus();
          if (open === 0) {
            phoneNumberScript();
            open=1;
          }
        }
        return false;
      //}
    }
    if (PAYMENT_IN_PROCESS) return
    let buttonText = $(event.target).text();
    console.log("buttonText====+++++++0000", buttonText)
    $(event.target).attr('disabled', false);
    if(info == 'delivery'){
//checking if restaurant has delivery person available, for alcohol delivery type orders
        let venueId =  $("#venueId").val();
        let alcoholDeliveryAllowed = $("#alcohol-delivery-allowed").val();

        if(hasAlcoholItems && alcoholDeliveryAllowed != 'true'){
          Swal.fire({
            title: "Warning!",
            text: "You have items that contain alcohol which can’t be delivered (in red in the order).  Remove them to continue or change the order type.", type: "warning",
            icon: "warning",
          });
        return false;
	    }
      $(event.target).text('Checking address...');
      if($('#delivery-address-edit-trigger-container').find('#delivery-address_input').val()){
        $('#delivery-address-edit-trigger').click();
      }
      let { deliveryAddress, deliveryAddressDetails, apartmentNumber } = $('#delivery-address-edit-trigger').data('customTextEditTrigger').getData();
      Delivery.checkDeliveryAddress(deliveryAddress, deliveryAddressDetails, apartmentNumber).then((success)=>{
        let address = $(".not-available-in-only-rate").find('#create-paypal-order').data('outofrange');
       if(address && success && success.inRange==false){
          showModalCheckDeliveryAddress(address,buttonText,event);
          return
       }else{
          $(event.target).text(buttonText);
          $(event.target).prop('disabled', false);
          if(success && success.success == false && success.data && success.data.inRange == true) {

            Swal.fire('We are not able to deliver to this address.', '' , "warning");

            return;
          }else if(success && success.success==false){
            return;
          }
          if ($(event.target).data('order-type') == 'only-rate'){
            return executeToPayOrder(event);
          }

          if($('#check-delivery-contactless').is(":visible") && $('#check-delivery-contactless').is(":checked") && $('#describe-delivery-contactless').val() == '' && info == 'delivery'){
            Swal.fire({
              title: "Please, provide contactless delivery information.",
              icon: "warning",
              confirmButtonColor: "#3cb371",
            }).then((result) => {
              if (result.isConfirmed) {
       
              $('.show-delivery-contactless').css('box-shadow', 'rgb(204, 0, 0) 0px 0px 1px 2px');
              $('#describe-delivery-contactless').focus();
              cart_scroll_to('.show-delivery-contactless',true);
              }
            });
            return
          }else{
            $('.show-delivery-contactless').css('box-shadow', 'none');
          }
         
          checkWorkingTimeOfRestaurant(event).then((check_result)=>{
            if(check_result) return executeToPayOrder(event);
            $('#create-paypal-order').prop('disabled', false);
          })
        }
      });
    }else{
      if(!validateOrderAhead($(event.target).data('order-type'))){
        $('#btn-resquest-order-ahead').attr('disabled', false)
        return true;
      }
      const { enableType } = getMenuSelectedItems();
      checkWorkingTimeOfRestaurant(event).then((check_result)=>{
        if(check_result) {
              const orderItem = enableType.item;
              const orderType = Order.getOrderDetail('orderType');
              let selectedItem = {}
              if(orderItem && orderItem[orderType]) {
                selectedItem = orderItem[orderType]
              }
              let filteredItem = itemsCurrent.filter(item =>  item.item_id == selectedItem.item_id)
              if (selectedItem && selectedItem != {} && filteredItem && filteredItem.length > 0) {
        
            Swal.fire({
              title: "Oops!",
              text: `${selectedItem.item_name || 'Items(s)'} can't be ordered for ${orderType} right now. Please ask your server about when/if it will be available again`,
              icon: "warning",
              allowOutsideClick: true,
              confirmButtonText: "OK",
              confirmButtonColor: "#3cb371",
              showCancelButton:false,
            }).then((result) => {
             if (result.isConfirmed) {
       
              loadCurrentCartItems();
              Order.renderOrderTotals();
              $("#collapseMyOrder").modal('show');
              }
            });
        } else {
          return executeToPayOrder(event);
        }
      }
        $('#create-paypal-order').prop('disabled', false);
        $('#btn-resquest-order-ahead').prop('disabled', false);
        $('#btn-update-order-ahead').prop('disabled', false);
      })
    }
  })
  $('body').append(`
  <div id="restaurant-phone-alert" class="card">
    <div class="card-body">
      <div class="mt-1 d-flex justify-content-center">
       <img src="/images/phoneimage.png" alt="" style="width: 200px">

      </div>
      <div class="alert-content text-center mt-5 black">
        Your phone number is required so that we can get<br/>
         hold of you if there is a problem with your order.
      </div>
      <div class="form-inline justify-content-center mt-3">
        <a href="javascript:void()" class="btn btn-success create-order m-3" id="setpho" style="width: 150px" onclick="setPhone(this);">Set Your Phone #</a>
      </div>
    </div>
  </div>
`)
$('#restaurant-phone-alert').iziModal({
  width: '400px',
  zindex: 9999,
  onClosed: function(){
    $(".btn-payment").prop('disabled', false);
    $('#create-paypal-order').prop('disabled', false);
    $('#btn-resquest-order-ahead').prop('disabled', false);
    $('#btn-update-order-ahead').prop('disabled', false);
    $('#collapseMyOrder').show();
  }
})
  function showModalCheckDeliveryAddress(address,buttonText,event){

    let { deliveryAddress } = $('#delivery-address-edit-trigger').data('customTextEditTrigger').getData();
    if(!deliveryAddress){
    console.log("enpty address.......modal====")
      $('#modal-empty-delivery').modal({backdrop: false, keyboard: false});
      $('#empty-address-delivery-btn').unbind();
      cart_scroll_to('.cart-options--user-location',true);
      $('#empty-address-delivery-btn').click(function(){
        if($('#delivery-address-edit-trigger-container input').length <= 0) {
          $('#delivery-address-edit-trigger').click();
          $('#delivery-address_input').focus();
          $('#delivery').css('box-shadow', 'rgb(204, 0, 0) 0px 0px 1px 2px');
        }
      })
    }else{
      let venueAddress = $('.item-venue-address').data('address');
      $('#text-modal-change-address').text(`Oops! Your address is out of the delivery area for ${venueAddress}`)
      $('#modal-change-delivery').modal({backdrop: false, keyboard: false});
      $('.change-order-type').unbind();
      $('.change-order-type').click(function(){
        cart_scroll_to('.cart-options--delivery-method', true)
        $('#order-method-container').collapse('show')
        // let orderType =$(this).data('order-type');
        // $('.myorders-nav').find(`a[name="${orderType}"]`).trigger('click');
      })
      $('.change-address-delivery').unbind();
      $('.change-address-delivery').click(function(){
        cart_scroll_to('.cart-options--user-location',true);
        if($('#delivery-address-edit-trigger-container input').length <= 0) {
          $('#delivery-address-edit-trigger').click();
          $('#delivery-address_input').focus();
        }
      })
    }
    $(event.target).text(buttonText);
    $(event.target).attr('disabled', false);
  }

	$("#sendReq").on("click",function(){
		let data = $("#reqData").val() ? JSON.parse($("#reqData").val()) : '';
		let message = $("#serverMsg").val();
		data.message = message;
		$.ajax({
				url: '/orders/notifyWaitStaff',
				type: 'POST',
				contentType: 'application/json; charset=utf-8',
				data: JSON.stringify(data),
				success: function (res) {
						if(res.status == 200){
							toastr.success('Request Send successfully');
						}else{
							toastr.error("Unable to send request to server");
						}
					$("#modal-server-request").modal("hide");
				},
				error: function (err) {
					toastr.error("Unable to send request to server");
				},
			});
	});

$('body').append(`
  <div id="restaurant-time-alert" class="card">
    <div class="card-body">
      <div class="mt-1 d-flex justify-content-center">
        <img src="/images/restaurant_closed.png" alt="" style="width: 180px">
      </div>
      <div class="alert-content text-center mt-3 black">
      </div>
      <div class="form-inline justify-content-center mt-3">
        <button class="btn btn-danger cancel-order m-3" style="width: 100px">No</button>
        <button class="btn btn-success create-order m-3" style="width: 100px">Yes</button>
      </div>
    </div>
  </div>
`)
$('#restaurant-time-alert').iziModal({
  width: '400px',
  zindex: 9999,
  onClosed: function(){
    $(".btn-payment").prop('disabled', false);
    $('#create-paypal-order').prop('disabled', false);
    $('#btn-resquest-order-ahead').prop('disabled', false);
    $('#btn-update-order-ahead').prop('disabled', false);
  }
})
function checkWorkingTimeOfRestaurant(e){
  let orderInfor = Order.getOrderDetails(true);
  let orderType = orderInfor.orderType;
  if(orderType == 'only_rate') return Promise.resolve(true)
  return new Promise((resolve, reject)=>{
    $.ajax({
        url: `/restaurantadmin/${$('#venueId').val()}/getattributes`,
        method: 'GET',
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        data: {},
        fail: function(f){
          return false
        },
        error: function(err){
          return false
        }
    })
    .then((workingTime)=>{

      if(!workingTime || (workingTime.data && Object.keys(workingTime.data).length === 0)){
        Swal.fire("Oops!", "This restaurant does not appear to be open. Please place your order later.", "warning");
        $(".btn-payment").prop('disabled', false);
        $('#create-paypal-order').prop('disabled', false);
        $('#btn-resquest-order-ahead').prop('disabled', false);
        $('#btn-update-order-ahead').prop('disabled', false);
        return resolve(false)
      }

      let daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

      let order_time = 'at this time';
      if(orderType == 'delivery') orderType = 'Delivery';
      if(orderType == 'pickup') orderType = 'Pickup';
      let selected_time = orderInfor.orderDate;
      if(orderType == 'order-ahead') {
        orderType = 'InStore';
      }
      let haveNotSet = true;
      if(workingTime && workingTime.data && workingTime.data.workingTime && workingTime.data.workingTime[orderType]){
        _.forIn(workingTime.data.workingTime[orderType], (d)=>{
          if(d && (d.open || d.altOpen)){
            haveNotSet = false;
            return false
          }
        })
      }

      



  let avaiable_order = true;
  let dateTimeAskedForOrder;
  let time_now = moment();
  let order_date_time = time_now;
  //When business type is meal preparation need to calculate time correctly
  const isMealPrep = $('#is-meal-preps').val();
  if(isMealPrep && isMealPrep==='true'){
    selected_time="asap";
  }
  let dayIndex;
  if(moment(selected_time, 'MM/DD/YYYY hh:mm A').isValid()){
    order_date_time = moment(selected_time, 'MM/DD/YYYY hh:mm A');
    order_time = `on ${order_date_time.format('MM/DD/YYYY hh:mm A')}`;
    dayIndex = order_date_time.day();
  } else {
    dayIndex = moment().day();
  }

 var orderTime = order_date_time.format('HH:mm'); //12:00 

if (
  workingTime &&
  workingTime.data &&
  workingTime.data.workingTime &&
  workingTime.data.workingTime[orderType]
) {
  workingTime = workingTime.data.workingTime[orderType];
  dateTimeAskedForOrder = workingTime[daysOfWeek[dayIndex]];
}

 if (order_date_time.isBefore(time_now) || !dateTimeAskedForOrder  || !dateTimeAskedForOrder.open || !dateTimeAskedForOrder.close || (dateTimeAskedForOrder.open === undefined && dateTimeAskedForOrder.close === undefined) || (dateTimeAskedForOrder.open === '' && dateTimeAskedForOrder.close === '') ) {
  avaiable_order = false;
 /* $('#restaurant-time-alert').find('.alert-content').html(title_alert)
      $('#restaurant-time-alert').find('.create-order').on('click', function(){
        Order.updateOrderDetail('orderTime', next_time_order);
        Order.updateOrderDetail("orderDate", next_time_order);
        $('#restaurant-time-alert').iziModal('close')
        return resolve(true)
      }).hide();
      $('#restaurant-time-alert').find('.cancel-order').on('click', function(){

        $('#restaurant-time-alert').iziModal('close')
        $(".btn-payment").prop('disabled', false);
        return resolve(false)
      }).hide();
      $('#restaurant-time-alert').iziModal('open').hide();*/

} else if (!haveNotSet) {
  let _theday = order_date_time.format('MM/DD/YYYY');
  let open_time = moment(_theday + ' ' + dateTimeAskedForOrder.open, 'MM/DD/YYYY HH:mmA');
  let close_time = moment(_theday + ' ' + dateTimeAskedForOrder.close, 'MM/DD/YYYY HH:mmA');

 // console.log("open_time====", open_time.format('MM/DD/YYYY hh:mm A'));
 // console.log("close_time====", close_time.format('MM/DD/YYYY hh:mm A'));
  //console.log("dateTimeAskedForOrder====",dateTimeAskedForOrder);


 
const openingTime = open_time.format('HH:mm');
const closingTime = close_time.format('HH:mm'); //11:45
if ((closingTime < openingTime && (orderTime >= openingTime || orderTime < closingTime)) ||
    (orderTime >= openingTime && orderTime < closingTime)) {
  avaiable_order = true;
 
} else {
  avaiable_order = false;
}


  if (
    !avaiable_order &&
    dateTimeAskedForOrder &&
    dateTimeAskedForOrder.altOpen &&
    dateTimeAskedForOrder.altClose 
  ) {
    let altOpen_time = moment(
      _theday + ' ' + dateTimeAskedForOrder.altOpen,
      'MM/DD/YYYY HH:mmA'
    );
    let altClose_time = moment(
      _theday + ' ' + dateTimeAskedForOrder.altClose,
      'MM/DD/YYYY HH:mmA'
    );

    if (altClose_time.isBefore(altOpen_time)) {
      // Handle case where closing time is before opening time
      altClose_time.add(1, 'day');
    }

    if (order_date_time.isBetween(altOpen_time, altClose_time, null, '[)')) {
      avaiable_order = true;
      console.log("available_order (alt) ====", avaiable_order);
    }
  }
}




      if(avaiable_order) {
        let date =  moment(order_date_time).format('MM/DD/YYYY');
        let time = moment(order_date_time).format('hh:mma');

        let items_times = validate_items_time(date);
        let t = time_to_number(time);
        if(items_times.from.n <= t && t < items_times.to.n) return resolve(true);
        if(t > items_times.to.n) {
          // swal("Oops!", `There are some items that are not served at ${time}` , "warning");
          title_alert = `Reservation not available<br>
          Do you want the next available time which is ${day_string} ${time_infor.date} at ${time_infor.hour}?
        `;
          return resolve(false);
        }
        t = moment(date + ' ' + items_times.from.t,'MM/DD/YYYY hh:mmA').format('MM/DD/YYYY hh:mm a');
        return Swal.fire({
          title: "Oops!",
          html: `There are some items that are not served at ${time}.<br>
                Would you like to place the order on ${t}?`,
          icon: "warning",
          allowOutsideClick: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#3cb371",
          showCancelButton: true,
        }).then((result) => {
          if (!result.isConfirmed) {
            resolve(false);
            return;
          }
          Order.updateOrderDetail("orderTime", t);
          Order.updateOrderDetail("orderDate", t);
          resolve(t);
        });
      }
      // ---------------
      let time_infor = findNearestOpenTime(workingTime,orderTime);
      let items_times = validate_items_time(time_infor.date);

      let theTime = time_to_number(time_infor.hour)
      if( theTime < items_times.from.n || theTime > items_times.to.n) time_infor.hour = items_times.from.t;

      let dateToday = moment().format('MM/DD/YYYY')
      let dateTomorrow = moment().add('days', 1).format('MM/DD/YYYY')
      let day_string = moment(time_infor.date, 'MM/DD/YYYY').day()
      day_string = _.upperFirst(daysOfWeek[day_string])
      let title_alert = (dateToday==time_infor.date) ? `
        Would you like to place an order for when we open,<br>
        which is Today at ${time_infor.hour}?
        `
      : (dateTomorrow==time_infor.date) ? `
        Would you like to place an order for when we open,<br>
        which is Tomorrow at ${time_infor.hour}`
      : `
        Would you like to place an order for when we open,<br>
        which is ${day_string} ${time_infor.date} at ${time_infor.hour}
      `;

      let next_time_order = moment(time_infor.date + ' ' + time_infor.hour,'MM/DD/YYYY hh:mmA');
      if(orderType == 'InStore'){
        $('#order-ahead-date').val(next_time_order ? next_time_order.format('MM/DD/YYYY hh:mm a') :  moment(new Date()).format('MM/DD/YYYY h:mm A'));
        title_alert = `
          Reservation not available.<br>
          The restaurant can't take reservations at this time. Do you want the next available time which is ${day_string} ${time_infor.date} at ${time_infor.hour}?
        `;
      }
      //if(dateTomorrow!=time_infor.date && dateToday==time_infor.date){
       next_time_order = moment(next_time_order);
      //}

      $('#restaurant-time-alert').find('.alert-content').html(title_alert)
      $('#restaurant-time-alert').find('.create-order').on('click', function(){
     //   console.log("next_time_order====",moment(next_time_order).add(1,'days'))
        Order.updateOrderDetail('orderTime', next_time_order);
        Order.updateOrderDetail("orderDate", next_time_order);
        $('#restaurant-time-alert').iziModal('close')
        return resolve(true)
      })
      $('#restaurant-time-alert').find('.cancel-order').on('click', function(){

        $('#restaurant-time-alert').iziModal('close')
        $(".btn-payment").prop('disabled', false);
        return resolve(false)
      })
      $('#restaurant-time-alert').iziModal('open')
    });
  })
}

function findNearestOpenTime(workingTime,orderTime){
  if(workingTime){
  let daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

  let today = moment().format('MM/DD/YYYY');
  let _day = moment().day();
  console.log("+day====",_day)
  let validWorkingTime = workingTime[daysOfWeek[_day]];
  console.log("dayyy====",today);
  console.log("validWorkingTime====",validWorkingTime)
    if(!validWorkingTime){
      return false;
    }
    let _open = validWorkingTime.open;
    console.log("_open===",_open,orderTime)
    if(_open){
      if( moment().isBefore(moment(today + ' ' + _open,'MM/DD/YYYY hh:mmA'))){
        console.log("kjjjj====",today)
        return {
          date: today,
          hour: _open
        }
      }
   }
   let alt_open = validWorkingTime.altOpen;
    console.log("alt_open===",alt_open)
    if(alt_open){
        if(moment().isBefore(moment(today + ' ' + alt_open,'MM/DD/YYYY hh:mmA'))){
          return {
            date: today,
            hour: alt_open
          }
        }
    }
    for(var i = 0; i <= 7; i++ ){
      if(orderTime >_open){
        _day ++;
        i++;
      }
      
      console.log("_day=1===",_day,i)
      if(_day > 6) _day -= 7;
      let time_to_check = workingTime[daysOfWeek[_day]];
      console.log("time_to_check====",time_to_check)
      let open_time_infor = time_to_check.open || time_to_check.altOpen;
      console.log("open_time_infor====",open_time_infor)
      if(!open_time_infor) continue;
      return {
        date: moment().add(i,'days').format('MM/DD/YYYY'),
        hour: open_time_infor
      }
    }
  }
  return false;
}

let time_to_number = function(time_convert = "00:00am"){
  time_convert = time_convert.split(':');
  let _hour = Number(time_convert[0]);
  let _minute = Number(time_convert[1].substring(0,2));
  if(_.toLower(time_convert[1].substring(2)) == 'pm') _hour += 12;
  if(_hour > 23) _hour = 0;
  return (_hour * 60) + _minute;
}

function validate_items_time(theDate) {
  let items = ShoppingCart.getCurrentCartItems();
  let selected_menu = [];

  // Collect unique menu names
  _.forEach(items, (item) => {
    if (!_.includes(selected_menu, item.menu_name)) {
      selected_menu.push(item.menu_name);
    }
  });

  let menu_hour;
  try {
    // Attempt to parse sessionStorage item
    menu_hour = JSON.parse(sessionStorage.menu_hour);
  } catch (error) {
    console.error("Failed to parse menu_hour from sessionStorage:", error);
    menu_hour = []; // Assign a default value to avoid breaking the code
  }

  let DAYS_OF_WEEK = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  let time_range = [];

  // Process each selected menu
  _.forEach(selected_menu, (menu) => {
    let time_setting = _.find(menu_hour, (element) => {
      return element.menuName == menu;
    });

    if (time_setting) {
      time_setting = time_setting.hours;

      if (time_setting.anyTime) {
        return time_range.push({
          n_from: 0,
          n_to: 1440,
          t_from: '00:00am',
          t_to: '24:00pm'
        });
      } else {
        let day = DAYS_OF_WEEK[moment(theDate, 'MM/DD/YYYY').day()];
        let hours = time_setting.days[day];

        time_range.push({
          n_from: time_to_number(hours.fromTime),
          n_to: time_to_number(hours.toTime),
          t_from: hours.fromTime,
          t_to: hours.toTime,
        });
      }
    } else {
      console.log("In validate_items_time, time setting not found for menu:", menu);
    }
  });

  // Determine the available time range
  let available_time = {
    from: { n: 0, t: '00:00am' },
    to: { n: 1440, t: '24:00pm' },
  };

  _.forEach(time_range, (item) => {
    if (item.n_from > available_time.from.n) {
      available_time.from.n = item.n_from;
      available_time.from.t = item.t_from;
    }
    if (item.n_to < available_time.to.n) {
      available_time.to.n = item.n_to;
      available_time.to.t = item.t_to;
    }
  });

  return available_time;
}


function showModelConfirmOrder(orderId,redirect = true){
  let agreedcreditExpiresAfterPeriod =sessionStorage.getItem('agreedcreditExpiresAfterPeriod-'+orderId) || false;
  let totalCreditsEarn = sessionStorage.getItem('totalCreditsEarn-'+orderId) || false;
  let incentive_credits = JSON.parse(sessionStorage.getItem('total_credits_infor-'+orderId))
  $('#modal-confirm-order').remove()
  $('body').append('<div id="modal-confirm-order"></div>')
  let html = `
  <div class="modal fade" id="modal-confirm" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
      <div class="modal-body text-center">
        <div class="" style="padding: 20px;">
          <button type="button" class="close float-right confirmorder" data-dismiss="modal" onclick="if(typeof ga === 'function'){ga('send', 'event', [buttons], [click], ['orderComplete']);}else{} " aria-label="Close">
            <span aria-hidden="true" style="color: black;float: right;background: white;font-size: 30px;">X</span>
          </button>
          <h1 class="modal-title" id="exampleModalLongTitle" style="font-weight: bold;">
            <b>Order Confirmation</b>
          </h1>
        </div>
        <div style="padding: 0px 20px 20px 20px;">
          <div class="text-center" id="platerateLogo" alt="platerate logo" style="font-size: 120px;color: #32ac6b;">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check-circle" fill="currentColor">
              <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
              <path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"></path>
            </svg>
          </div>
          <p class="mb-1">Confirmation #${orderId}</p>
          <p style="font-weight: bolder; font-size: 20px;" class="${redirect ? "" : 'd-none'}">Thank You for ordering</p>
        </div>
          ${ totalCreditsEarn && totalCreditsEarn > 0 && agreedcreditExpiresAfterPeriod ? `
              <button class="btn btn-success mb-1 confirmorder"   style="font-weight: bold; font-size: 20px;">RATE to EARN $${parseFloat(totalCreditsEarn || 0).toFixed(2)}</button>
              ${!incentive_credits.unlimited_credits ? `<p>Credits expire in ${agreedcreditExpiresAfterPeriod} days</p>` : ''}
          ` :`<button class="btn btn-success mb-1 confirmorder" style="font-weight: bold; font-size: 20px;" >RATE</button>`}
        </div>
      </div>
    </div>
  </div>
  `;
  $('#modal-confirm-order').html(html);
  $('#modal-confirm').modal({backdrop: false, keyboard: false});
  $('.confirmorder').on("click",function(e) {
    $('#modal-confirm-order').find('#modal-confirm').modal('hide')
    $('#loading-icon').hide();
    if(redirect){
      console.log("redirecting again ro rate page");
      if (order.orderType == 'order-ahead') {
        window.location.href = "/rate/order/"+orderId+'menu-items';
      } else {
        window.location.href = "/rate/order/"+orderId;
      }
    }
  })
  sessionStorage.removeItem(`totalCreditsEarn-${orderId}`);
  sessionStorage.removeItem(`agreedcreditExpiresAfterPeriod-${orderId}`);
  return true;
}
window.showModelConfirmOrder=showModelConfirmOrder;
  async function checkHaveFriendOrder(){
    let hasOrderFriend;
    if($('#statusFriendIsOrdring').length){
      return hasOrderFriend = true
    } else {
      return hasOrderFriend = false
    }
  
  }

async function executeToPayOrder(e){
    try {
      const hasOrderFriend = await checkHaveFriendOrder()
    
      let backToWaitFriend = window.location.href;
      console.log(backToWaitFriend + "backkkkk");
      const noPay = $(e.target).attr('id');
      let order_type = $(e.target).data('order-type');
      let orderType = $(e.target).data('order-type');
      let winwidth = $(window).width();
      let isUpdate = false;
      if(noPay == 'create-paypal-order' && !TYPE_FREE_FOOD) {
        console.log("🚀 ~ file: orders.js ~ line 10394 ~ executeToPayOrder ~ noPay", noPay)
        orderType = 'pay-free-food';
      }
      if(noPay == 'btn-update-order-ahead') {
        isUpdate = true;
      }
      const btnText = $(e.target).text();
      if (ShoppingCart.hasItems() || noPay == 'btn-resquest-order-ahead') {
        //const kochavaorder = Order.getOrderDetails();
        let orderItems = ShoppingCart.getCurrentCartItems();
        console.log("from shopping cart, order items...", orderItems);
        //kochavaorder.orderItems = orderItems;
        if(orderItems && orderItems.length) {
          orderItems = orderItems.map((item) => item.item_id);
        }
        let userPref="";
        USER_DIETARY_PREFERENCES && USER_DIETARY_PREFERENCES.forEach((pref) =>{
          userPref += pref.name+":"+pref.value +", "
        });
        //kochavaorder.userDietaryPreferences = userPref.replace(/,\s*$/, "");
        let tasterProfile="";
        if(USER_TASTER_PROFILE){
          Object.getOwnPropertyNames(USER_TASTER_PROFILE).forEach(
            function (val, idx, array) {
              let pref = USER_TASTER_PROFILE[val];
              if(pref && pref.high){
                tasterProfile += val+": low="+pref.low+", high="+pref.high+"\n";
              } else {
                console.log("skipping taster profile without high...", pref, val);
              }
            }
          );
        }
      //  kochavaorder.userTasterProfile = tasterProfile;

        if(!validateOrderAhead(orderType)){
          $('#btn-resquest-order-ahead').attr('disabled', false)
          return true;
        }
        const { enableTime, order_type, daysItem} = validateHoursItems(orderItems, isUpdate);
        if(enableTime && !daysItem) {
          $(e.target).attr('disabled', true);
          $(e.target).text('Processing ...');
          PAYMENT_IN_PROCESS = true
          Order.createOrder(orderType, null, order_type).then((response) => {
            let rateUrl = null;
            if(hasOrderFriend) {
              rateUrl = backToWaitFriend;
            } else{
              if(response && response.data){
                rateUrl = "/rate/order/"+response.data.orderId;
              }
            }
            
            if (orderType == 'paypal'){
              if (response.data && response.data.redirectApprovalLink) {
                //redirect user to paypal payment approval
                console.log("redirecting again forpaypal kochava..", response.data.redirectApprovalLink );
                // function redirectApprovalLink (){
                  window.location = response.data.redirectApprovalLink
                // }
                // redirectApprovalLink().then(window.location.href = rateUrl);
                /*window.localStorage.setItem('kochavaOrder', JSON.stringify(kochavaorder));
                window.localStorage.setItem('kochavaUserId', JSON.stringify(USER_ID));
                window.localStorage.setItem('kochavaUserName', JSON.stringify(USER_NAME));*/

              } else if (response.data && response.data.fullyPaidByOrganization) {

                Order.clearOrderDetails();
                Swal.fire({
                  title: 'Your order has been fully paid by your Organization!',
                  text: '',
                  icon: "success",
                  showCancelButton: false,
                  showConfirmButton: true,
                  allowOutsideClick: true
              });
              $('#loading-icon').hide();
              
              console.log("redirecting again for orgn fully paid");
          } else if(response.checkFreeFood) {

                Swal.fire({
                  title: 'Your free food credit is not enough to pay!',
                  text: '',
                  icon: "warning",
                  showCancelButton: false,
                  showConfirmButton: true,
                  allowOutsideClick: true
                }).then((result) => {
                  if (result.isConfirmed) {
                     Order.renderUpdateTotals()
                  }
                });
              } else {
                Swal.fire('We had problem connecting for payment Redirect', '', 'error');
              }
            } else if (orderType == 'only-rate'){
              Order.clearOrderDetails();
            } else if (orderType == 'order-ahead') {
              checkChooseTip = false;
              isShowModalTips = false;
              const textUpdate = $(e.target).data('update');
              let email_update = $(e.target).data('addmails');
              let title_alert = '';
              let confirmButtonText = "Ok";
              if(email_update){
                title_alert = `Invitation(s) sent!`;
                confirmButtonText = "Ok";
              }else{
                textUpdate ? title_alert = (textUpdate || 'Request') + ' reservation successfully' :  title_alert = 'You successfully requested a reservation';
              }
              Order.clearOrderDetails();
              Order.checkForInCartOrder().then(() => {
                if($('.showSweetAlert').length) return
                Swal.fire({
                  title: title_alert,
                  text: '',
                  icon: "success",
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonColor: '#4CAF50',
                  confirmButtonText: confirmButtonText,
                  allowOutsideClick: true
                })
              }).then(() => {
                // load the rate url
                Order.renderOrderTotals();
              });
            } else if (orderType == 'pay-free-food') {
              checkChooseTip = false;
              isShowModalTips = false;

              $('#collapseMyOrder').modal('hide');
              // $('.order-this-item').focus();
              if(response.link) {
                if(hasOrderFriend == false) {
                  showModelConfirmOrder(response.orderId, order_type == 'order ahead' ? false : true);
                  $('#modal-confirm').on('hide.bs.modal', function (e) {
                    // load the rate url
                      if(order_type != 'order ahead') {
                        console.log("redirecting again not orderahead");
                        window.location.href = rateUrl;
                      } 
                      else 
                        window.location.reload();
                    })
                } else {
                  Swal.fire({
                    title: 'Payment success!',
                    text: '',
                    icon: "success",
                    showCancelButton: false,
                    showConfirmButton: true,
                    allowOutsideClick: true
                  })
                  window.location.reload();

                }
                
              } else if(response.checkFreeFood) {
                Swal.fire({
                  title: 'Your free food credit is not enough to pay!',
                  text: '',
                  icon: "warning",
                  showCancelButton: false,
                  showConfirmButton: true,
                  allowOutsideClick: true
                }).then((result) => {
                  if (result.isConfirmed) {
       
                  Order.renderUpdateTotals()
                  }
                });
              } else {
                Order.clearOrderDetails();
                $('#collapseMyOrder').modal('hide');
                // $('.order-this-item').focus();
                console.log("order_type111111=======", order_type)
                showModelConfirmOrder(response.orderId,order_type == 'order ahead' ? false : true);
                Order.renderOrderTotals();
              }
              //window.location = response.data.redirectApprovalLink
            // window.localStorage.setItem('kochavaOrder', JSON.stringify(kochavaorder));
            // window.localStorage.setItem('kochavaUserId', JSON.stringify(USER_ID));
            // window.localStorage.setItem('kochavaUserName', JSON.stringify(USER_NAME));
            window.localStorage.setItem('orderTotalAmount',response.data.orderTotalPrice);

          
        }
        //__get_all_orders_infor();
        }).catch((err) => {
          console.log("get all orders info error...", err);
          let errorObject = err.responseJSON;
            console.log("get all orders info error.11..",  err.details);
          if ((errorObject && errorObject.error == 'missing-value' && errorObject.details.includes('orderTotal')) || (err.error == 'missing-value' && err.details.includes('orderTotal'))){
            Swal.fire('Your order total is $0.00', '', 'warning');
          } else if ((errorObject && errorObject.error == 'unable-to-calculate-total') || (err.error == 'unable-to-calculate-total' )){
            Swal.fire('Ops ! Sorry, try again!', '', 'error');
          } else if (errorObject && errorObject.name == 'delivery-order-under-amount'){
            Swal.fire(errorObject.message, '', 'warning');
          } else if (errorObject && errorObject.canDeliver === false){
            Swal.fire(errorObject.error, '', 'error');
          }else if ( (err.error == 'missing-value' && err.details.includes('deliveryAddress'))){
             console.log("jjjj")
             Swal.fire('Please change delivery address, its  out of range', '', 'warning');
          }
           else {
            Swal.fire('We had a problem initiating payment process', '', 'error');
          }
        })
        .finally(() => {
          $(e.target).text(btnText);
          $(e.target).attr('disabled', false);
          PAYMENT_IN_PROCESS = false
        })
      } 
      // else if(daysItem && daysItem.item) {
      //   swal({
      //     title: "Oops!",
      //     html:true,
      //     text: `<b class="green">${daysItem.item.title}</b> must order ${daysItem.days} days in advance, please choose a time they are available by looking at the hours this menu is available at this restaurant.`,
      //     type: "warning",
      //     allowOutsideClick: false,
      //     //confirmButtonText: "OK",
      //     confirmButtonColor: "#3cb371",
      //     showCancelButton:false,
      //     showConfirmButton: true
      //   }, function () {
      //     $(e.target).attr('disabled', false);
      //   });
      // } else {
      //   setTimeout(()=>{
      //     swal({
      //       title: "Oops!",
      //       text: `Some of these menu items are not available via ${order_type || ''} right now, please choose a time they are available by looking at the hours this menu is available at this restaurant.`,
      //       type: "warning",
      //       allowOutsideClick: false,
      //       confirmButtonText: "OK",
      //       confirmButtonColor: "#3cb371",
      //       showCancelButton:false,
      //     }, function () {
      //       $(e.target).attr('disabled', false);
      //     });
      //   }, 1000)
       // }
      } else {
        Swal.fire("No Items On Cart", "You don't have any items on your shopping cart to purchase. You can add items to your cart from the Restaurant Menu.", "info");
      }
    } catch(e) {
      console.log("error.days..",e);
    }
  
}
$('#order-ahead-date').on('click', function(){
  $(this).parent().parent().removeClass('has-error');
  $(this).parent().parent().find('span.help-block').html('');
})

$('#space-people').on('click', function(){
  $(this).parent().removeClass('has-error');
  $(this).parent().find('span.help-block').html('');
})

$('#invites-day').on('click change input', function(){
  let val = $(this).val();
  $(this).parent().removeClass('has-error');
  $(this).parent().find('span.help-block').html('');
  $("#invite-day-number").text(val);
})


  $('.order-ahead-container').on('change', function(e){
    let elem = e.target
    $(elem).parent().removeClass('has-error').find('.help-block').html('')
  })
  // Validate order ahead
  const validateOrderAhead = (orderType) => {
    let isError = true;

    if(!COMPLEX_ORDER_TYPES.includes(orderType)) return isError;
    let maximum_hour = 0;
    let maximum_minute = 0;
    let maximum = false;

    if (['order-ahead'].includes(orderType)){
      if($('#orderAheadIsMaximum').length && $('#orderAheadIsMaximum').val() == 'true') {
        maximum = true;
        let _hour = Number($('#orderAheadMaxHour').val());
        let _minute = Number($('#orderAheadMaxMinute').val());
        if(_hour > 0) maximum_hour = _hour;
        if(_minute > 0) maximum_minute = _minute;
      }
    }
    // const order_ahead_date = $.trim($('#order-ahead-date').val())
    // if (['order-ahead'].includes(orderType)){
    //   if(!order_ahead_date) {
    //     cart_scroll_to('input#order-ahead-date', isError);
    //     isError = false;
    //     $('#order-ahead-date').parent().parent().addClass('has-error');
    //     $('#order-ahead-date').parent().parent().find('span.help-block').html('This field is required');
    //   } else if (!moment(order_ahead_date, 'MM/DD/YYYY hh:mm A').isValid()) {
    //     cart_scroll_to('input#order-ahead-date', isError);
    //     isError = false;
    //     $('#order-ahead-date').parent().parent().addClass('has-error');
    //     $('#order-ahead-date').parent().parent().find('span.help-block').html('This field is not a valid date!');
    //   }
    // }

    if (['order-ahead', 'waitlist'].includes(orderType)){ // WAITLIST
      const spacepeople = $.trim($(`#${orderType} #space-people`).val())
      if(!spacepeople) {
        cart_scroll_to(`#${orderType} #space-people`, isError);
        isError = false;
        $(`#${orderType} #space-people`).parent().addClass('has-error');
        $(`#${orderType} #space-people`).parent().find('.help-block').html('This field is required');
      } else if(!Number(spacepeople)) {
        cart_scroll_to(`#${orderType} #space-people`, isError);
        isError = false;
        $(`#${orderType} #space-people`).parent().addClass('has-error');
        $(`#${orderType} #space-people`).parent().find('.help-block').html('This field must be greater than 0');
      }else{
        $(`#${orderType} #space-people`).parent().removeClass('has-error');
        $(`#${orderType} #space-people`).parent().find('.help-block').html('');
      }
    }

    if (['order-ahead', 'waitlist'].includes(orderType)){ // WAITLIST
      const reservation_hours = $.trim($('#reservation-hours').val())
      if(!reservation_hours) {
        cart_scroll_to('#reservation-hours', isError);
        isError = false;
        $('#reservation-hours').parent().addClass('has-error');
        $('#reservation-hours').parent().find('span.help-block').html('This field is required');
      }
      if(Number(reservation_hours) < 0) {
        cart_scroll_to('#reservation-hours', isError);
        isError = false;
        $('#reservation-hours').parent().addClass('has-error');
        $('#reservation-hours').parent().find('span.help-block').html('Hour(s) is invalid');
      }

      const reservation_minutes = $.trim($('#reservation-minutes').val())
      if(!reservation_minutes) {
        cart_scroll_to('select#reservation-minutes', isError);
        isError = false;
        $('select#reservation-minutes').parent().addClass('has-error');
        $('select#reservation-minutes').parent().find('span.help-block').html('This field is required');
      }

      if(maximum && reservation_hours && reservation_minutes) {
        const timeLength = Number(reservation_minutes) + (Number(reservation_hours) * 60);
        const maximum_reservation = Number(maximum_minute) + (Number(maximum_hour) * 60);

        if(maximum_reservation < timeLength) {
          cart_scroll_to('#reservation-hours', isError);
          isError = false;
          $('#reservation-hours').parent().addClass('has-error');
          $('#reservation-hours').parent().find('span.help-block').html(`Maximum reservation time is ${maximum_hour} hour ${maximum_minute} minutes`);
        }
      }
    }

    if (['order-ahead'].includes(orderType)){
      const inviteday = $.trim($('#invites-day').val())
      if(!inviteday) {
        cart_scroll_to('input#invites-day', isError);
        isError = false;
        $('#invites-day').parent().addClass('has-error');
        $('#invites-day').parent().find('span.help-block').html('This field is required');
      }
    }

    if (['order-ahead', 'waitlist'].includes(orderType)){ // WAITLIST
      let myfriends = $.trim($(`#${orderType} #email-my-friends`).val());
      if(myfriends) {
        myfriends = myfriends.replace(/\s/g, '');
        let arrayEmail = myfriends.split(',');
        let list_emails = '';
        arrayEmail.forEach((email) => {
          if(email){
            list_emails += email + ',';
            email = email ? email.toLowerCase().trim(): '';
            if(!validateEmail(email)) {
              cart_scroll_to(`#${orderType} textarea#email-my-friends`, isError);
              isError = false;
              $(`#${orderType} #email-my-friends`).parent().addClass('has-error');
              $(`#${orderType} #email-my-friends`).parent().find('span.help-block').html('This ' + email + ' is not a valid email!');
            }
          }
        })
        $(`#${orderType} #email-my-friends`).val(list_emails.substring(0,list_emails.length-1));
      }
    }

    if (['order-ahead', 'waitlist'].includes(orderType)){ // WAITLIST
      let invite_additional = $.trim($(`#${orderType} #invite-additional-people`).val());
      if(invite_additional) {
        invite_additional = invite_additional.replace(/\s/g, '');
        let arrayEmail = invite_additional.split(',');
        let list_emails = '';
        arrayEmail.forEach((email) => {
          if(email){
            list_emails += email + ',';
            email = email ? email.toLowerCase().trim(): '';
            if(!validateEmail(email)) {
              cart_scroll_to(`#${orderType} #invite-additional-people`, isError);
              isError = false;
              $(`#${orderType} #invite-additional-people`).parent().parent().addClass('has-error');
              $(`#${orderType} #invite-additional-people`).parent().parent().find('span.help-block').html('This ' + email + ' is not a valid email!');
            }
          }
        })
        $(`#${orderType} #invite-additional-people`).val(list_emails.substring(0,list_emails.length-1));
      }
    }
    return isError;
  }


  $('.order-ahead-hour, .order-ahead-minute, .order-ahead-apm, .maximum-length').on('change', function(){
    $(this).parent().parent().removeClass('has-error');
    $(this).parent().removeClass('has-error');
    $(this).parent().parent().find('span.help-block').html('');
  });
  function validateEmail(field) {
    var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
    return (regex.test(field)) ? true : false;
  }

  var cart_scroll_to = function(element, scroll) {
    if (!scroll || !$(element).length) return true
    let $container = $('#collapseMyOrder').find('.modal-content').eq(0)
    let $scrollTo = $(element)
    $container.animate({
        scrollTop: $scrollTo.offset().top - $container.offset().top + $container.scrollTop() - 50
    })
  }

  $('.datepicker').datetimepicker({
      inline: true,
			defaultDate: new Date(),
      // onSelect: function(date) {
      //   changeDatePicker(date);
      // }
  }).on('dp.change', function(date){
    changeDatePicker(date);
  });
  $('#icon-calendar').on('click',function(){
      $('#order-ahead-date').trigger('focus');
  })

  function changeDatePicker (date) {
    let currentDate = new Date();
    let changeDate = new Date(date);
    if(currentDate.getDate() == changeDate.getDate()) {
      $('.timepicker').datetimepicker('option', 'minTime', minTimepicker());
    } else {
      $('.timepicker').datetimepicker('option', 'minTime', '0');
    }
  }

  function minTimepicker () {
    let date = new Date();
    let minute = date.getMinutes();
    while ((minute % 5) != 0) {
      minute++;
    }
    minute += 15;
    date = new Date(date.setMinutes(minute))
    return date;
  }

  // Disable Paypal button after changes in address field
  $("#delivery-address").keyup(()=>{
    $(".distance-message").hide();
    $('#delivery-address').css('box-shadow', 'none');
    $(".not-available-in-only-rate").find('#create-paypal-order').data('outofrange',true)
  });

  $(document).ready(function(){
    $('#other-orders-label [data-toggle="popover"]').popover();
    $(document.body).on('click', function (e) {
      $('[data-toggle="popover"]').each(function () {
          if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
              var bsPopover = $(this).data('bs.popover');
              if (bsPopover) bsPopover.hide();
          }
      });
  });
  });
  /**
   * Handle the order Navigation Type selection
   */
  let firstLoadTab = 0;
  let flag = false

  $('.myorders-nav > li > a').on('show.bs.tab, click', (e, updateFromParentOrder = false) => {
    
    $('#order-method-container').collapse('hide');
    if (!updateFromParentOrder && ( $(e.target).parent().hasClass('disabled') || $(e.target).parent().hasClass('active') )){
      e.preventDefault();
      if(flag){
        Swal.fire({
          title: 'Cancel order to change',
          text: 'You must cancel this order to change the order type because you already made a reservation',
          icon: "warning",
          showCancelButton: false,
          showConfirmButton:true,
          allowOutsideClick: true
        })
      }
      flag = true;
      return false;
    } else {
      const orderTypeSelected = $(e.target).attr('name');
      localStorage.setItem('orderType', orderTypeSelected);
      Order.checkForInCartOrder(orderTypeSelected)
      Order.updateOrderTimeOptions(orderTypeSelected)
      if(orderTypeSelected === "order-ahead") {
        $('#latest-order-type-selected').text("Dine In");
        $('#orderTypeLabel').contents().filter(function() {return this.nodeType == 3;}).last().replaceWith('Dine Now');
        $('#orderDurationLabel').contents().filter(function() {return this.nodeType == 3;}).replaceWith('Request Reservation');
        $('#delivery-term').hide();
      } else {
        $('#latest-order-type-selected').text(orderTypeSelected.charAt(0).toUpperCase() + orderTypeSelected.slice(1));
        $('#orderTypeLabel').contents().filter(function() {return this.nodeType == 3;}).replaceWith('Order ASAP');
        $('#orderDurationLabel').contents().filter(function() {return this.nodeType == 3;}).replaceWith('Order For Later');
       
        $('#delivery-term').show();
      }
      // $('#latest-order-type-selected').text(orderTypeSelected.toUpperCase());
      $(".order-type li").removeClass('active');
      const $latestOrderTypeTab  = $(".order-type li.active");
      const orderId = Order.getOrderDetail('orderId');

      if (orderId) { //Check is there is an order loaded
        let orderItems = ShoppingCart.getCurrentCartItems();
        if(orderItems && orderItems.length) {
          orderItems = orderItems.map((item) => item.item_id);
        }
        const { enableType, crowdItem } = validateTypeOrderItems(orderItems);
        loadingOrderAnimation('show');
        if(!enableType[orderTypeSelected]) {
          // Disable change
          const orderItem = enableType.item;
          let selectedItem = {}
          if(orderItem && orderItem[orderTypeSelected]) {
            selectedItem = orderItem[orderTypeSelected]
          }
          Swal.fire({
            title: "Not available",
            text: `${selectedItem.item_name || 'Items(s)'} is not available for ${orderTypeSelected} at this time`,
            icon: "warning",
            allowOutsideClick: false,
            confirmButtonText: "OK",
            confirmButtonColor: "#3cb371",
            showCancelButton:false,
          }).then((result) => {
            if (result.isConfirmed) {
       
            $(".order-type li").removeClass('active');
            $latestOrderTypeTab.addClass('active');
            loadingOrderAnimation('hide');
           }
          });
        } else if(crowdItem && crowdItem.item_name) {
          Swal.fire({
            title: "Not available",
            text: `${crowdItem.item_name || 'Items(s)'} is crowd-sourced menu item and is not available for ${orderTypeSelected} at this time`,
            icon: "warning",
            allowOutsideClick: false,
            confirmButtonText: "OK",
            confirmButtonColor: "#3cb371",
            showCancelButton:false,
          }).then((result) => {
          if (result.isConfirmed) {
       
            $(".order-type li").removeClass('active');
            $latestOrderTypeTab.addClass('active');
            loadingOrderAnimation('hide');
          }
          });
        } else {
          // Update order type when an order type is selected in the order bar
          const currentOrderType = Order.getOrderDetail('orderType');
          const userOrderTip = Order.getOrderDetail('userOrderTip');
          Order.updateOrderDetail('orderType', orderTypeSelected, true, firstLoadTab > 0 || !userOrderTip.type).then((res) => {
            firstLoadTab += 1;
            if (res.code == '0'){
              let formerAction = (!currentOrderType || currentOrderType != orderTypeSelected) ? 'change-order-type' : null;
              loadCurrentCartItems({ formerAction: formerAction });
              //Hide the delivery address container is the order Type is not delivery
              let orderType = localStorage.getItem("orderType");
              // $('#latest-order-type-selected').text(orderTypeSelected.toUpperCase());
              if(orderTypeSelected === "order-ahead" || orderType=='order-ahead') {
                $('#latest-order-type-selected').text("Dine In");
              } else { 
                $('#latest-order-type-selected').text(orderTypeSelected.charAt(0).toUpperCase() + orderTypeSelected.slice(1));
              }
              const $activeNav = $('.myorders-nav .active');
              $('#latest-order-type-icon > i')
                .attr('class', $activeNav.siblings('span').find('i').attr('class'));
              $('.delivery-section, .cart-options--user-location').toggleClass('hide', orderTypeSelected !== 'delivery');
              // $('.pickup-section, .cart-options--restaurant-location').toggleClass('hide', orderTypeSelected !== 'pickup');
              $('.order-ahead-container').toggleClass('hide', orderTypeSelected !== 'order-ahead');
              $('.not-available-in-only-rate').show();
              $('.available-in-only-rate').hide();
              $('.available-in-order-ahead').hide();
              $('.available-in-waitlist').hide();
              let activeTerm = getEstimateTime();
              if (orderTypeSelected === 'delivery'){
                $('.est-time-order').text(activeTerm.delivery);
              }else if (orderTypeSelected === 'pickup'){
                $('.est-time-order').text(activeTerm.pickup);
              }
              if (orderTypeSelected != 'only_rate'){
              //get the last tip percent of the selected order type
                latestOrderTipPercent = getLatestOrderTipPercent(orderTypeSelected);
              }
              if (!updateFromParentOrder && orderTypeSelected === 'delivery'){
                //call to check rest allowed alcohol delivery or not
                let isAllowed = checkAlcoholDeliveryAllowed(hasAlcoholItems);
                if(!isAllowed) return false;
                let { deliveryAddress, deliveryAddressDetails, apartmentNumber } = $('#delivery-address-edit-trigger').data('customTextEditTrigger').getData();
                Delivery.checkDeliveryAddress(deliveryAddress, deliveryAddressDetails, apartmentNumber);
              } else if (orderTypeSelected === 'only_rate'){
                $('.available-in-only-rate').show();
                $('.not-available-in-only-rate').hide();
                $('.available-in-order-ahead').hide();
                $('.available-in-waitlist').hide();
              } else if(orderTypeSelected === 'order-ahead'){
                $('.available-in-order-ahead').show();
                $('.not-available-in-only-rate').show();
                $('.available-in-only-rate').hide();
                $('.available-in-waitlist').hide();
              } else if(orderTypeSelected === 'waitlist'){
                $('.available-in-waitlist').show();
                $('.available-in-order-ahead').hide();
                $('.not-available-in-only-rate').hide();
                $('.available-in-only-rate').hide();

                checkForInCartOrder()
              } else {
                $("#create-paypal-order").prop('disabled', false);
              }
              if(check_iOSapp() || check_androidApp()){
              console.log("redirecting again forpaypal kochava ordertype");
                window.location = "plateratewebtonative:changeordertype?userId="+USER_ID+"&orderId="+orderId+"";
              } else{
                /*if(kochava){
                  kochava.activity('Add to Cart', {
                    order_id: orderId,
                    user_id: USER_ID,
                  }, function(a, b){
                  });
                }*/
              }
              //Re-render the order total so that the order total its recalculated based
              //on the order type selected
              // if (!updateFromParentOrder && ShoppingCart.hasItems()) {
              //   return Order.renderOrderTotals();
              // }
            } else {
              $(".order-type li").removeClass('active');
              $latestOrderTypeTab.addClass('active');
              Swal.fire(res.msg, '', 'error');
            }
          }).catch((err)=>{
            $(".order-type li").removeClass('active');
            $latestOrderTypeTab.addClass('active');
            Swal.fire("Unable to change order type", 'Please try again.', 'error');
          }).finally(()=>{
            loadingOrderAnimation('hide');
          });
        }
      }else{
        if(orderTypeSelected == 'order-ahead') {
          $('.available-in-waitlist').hide('fadeOut');
          $('.not-available-in-only-rate').hide('fadeOut');
          $('.available-in-order-ahead').show('fadeIn');
        }else if (orderTypeSelected == 'waitlist'){
          $('.not-available-in-only-rate').hide('fadeOut');
          $('.available-in-order-ahead').hide('fadeOut');
          $('.available-in-waitlist').show('fadeIn');
        }else if (orderTypeSelected == 'only_rate'){
          $('.available-in-waitlist').hide('fadeOut');
          $('.available-in-order-ahead').hide('fadeOut');
          $('.not-available-in-only-rate').hide('fadeOut');
        } else {
          $('.available-in-waitlist').hide('fadeOut');
          $('.available-in-order-ahead').hide('fadeOut');
          $('.not-available-in-only-rate').show('fadeIn');
        }
      }
    }
    
  });


  $('#go_to_my_order').unbind('click').bind('click', function(){
    checkOutMyOrder();
  });
  $("#shopping_cart_btn").on("click",function(){
    __checkout_my_order();
  });

  function checkOutMyOrder (orderId = null) {
		// $('html, body').animate({ scrollTop: $(POSITION_SCROLL_ORDER[0]).offset().top }, 1000);
		// $(POSITION_SCROLL_ORDER[1]).collapse('show');
		//$('html, body').animate({ scrollTop: $('#myorder-accordion').offset().top-150 }, 1000);
		$("#collapseMyOrder").collapse('show');
  }

 $('body').on('click', '#save-delivery-address', function(){
    var newAddress= $('#delivery-address_input').val();
    setTimeout(function(){
      if($('#delivery-address-edit-trigger-container input').length > 0) {
        $('#delivery-address-edit-trigger').click();
        $('#delivery-address_input').focus();
        if(newAddress){
          $('#delivery-address').text(newAddress);
          $('#delivery-apartment').text('');
          Order.updateOrderDetail("delivery_address", newAddress);
        }
      }
    }, 500);
  });

  // ORDER MODULES
const OrderModule = (() => {
  const has_new_orders = (venueId) =>{
    let venue_id = $('#venueId').val();
    if(venueId != venue_id) return
    if(!$('.open-restaurant-orders').length) return
    $('#servername-drop').trigger('change')
  }
  const pushJoinOrderNotification = (venueId, joinRequest, checkout = false, orderType, orderId) => {
    if (VENUE_ID == venueId){
      let allJoinRequest = addJoinOrderNotification(joinRequest);
      if(checkout && orderType === 'order-ahead') {
        $.promisedAjax({
          url: `/orders/checkouts/notification?orderId=${orderId}`,
          type: 'GET',
          contentType: 'application/json',
          dataType: 'json'
        }).then((result) =>{
          renderjoinRequestNotifications(result.data, true);
          joinRequestNotifications(result.data);
        }).catch((error)=>{
          console.error('Unable to load notification checkout', error)
        })
      } else {
        renderjoinRequestNotifications(allJoinRequest);
        joinRequestNotifications();
      }
    }
  };

  const pushJoinOrderApprovedNotification = (venueId, joinRequest) => {
    if (VENUE_ID == venueId){
      if (joinRequest.status == 'approve'){
        Swal.fire(`You have now joined ${joinRequest.user}'s order !`, "", "success");
        if( ['order-ahead', 'waitlist'].includes($(".order-type li.active").find('a').attr('name')) ) checkForInCartOrder();
        loadCurrentCartItems();
      } else if (joinRequest.status == 'reject') {
        Swal.fire(`Your request to join ${joinRequest.user}'s order has been rejected !`, "", "error");
      }
    }
  };


  const pushOrderAheadInvitationNotification = (venueId, info) => {
    if (VENUE_ID == venueId){
      let hostId_orderAhead = info.hostId_orderAhead
      let userEmail         = info.userEmail
      if(hostId_orderAhead){
        //&& !(foundIncartOrder && foundIncartOrder.parent_order_id)){
        let userId = $('#userId').val();
        GetOrderInfo({order_id: hostId_orderAhead, user_id: userId, email: userEmail}).then((orderInfo)=>{
          if(orderInfo == 403){
            Swal.fire({
              title: "Current account is not avaiable",
              text: `You need to log in with account: ${userEmail} to confirm the invitation !`,
              icon: "error",
              showCancelButton: false,
              showConfirmButton:true,
              allowOutsideClick: true
            });
            return
          }else if(orderInfo == 404 || orderInfo == 402){
            Swal.fire({
              title: "Can not get order informations",
              text: `Please try again !`,
              icon: "error",
              showCancelButton: false,
              showConfirmButton:true,
              allowOutsideClick: true
            });
            return
          }
          OrderAheadInfo(orderInfo);
        })
      }
    }
  };





  const pushJoinOrderUpdateType = (venueId, data) => {
    if (VENUE_ID == venueId){
      if (data.popup){
        Swal.fire(data.popup, '', 'info');
      }
      if (data.toastr){
        toastr.info(data.toastr);
      }
      if(data.order_type == 'order-ahead'){
        loadCurrentCartItems({parentOrderUpdate: true, RequestedReservation:true});
        checkForInCartOrder();
      }else{
        loadCurrentCartItems({parentOrderUpdate: true});
      }
    }
  };

  const pushRefreshIncartOrder = (venueId, data) => {
    if (VENUE_ID == venueId){
      refreshOtherOrders();
    }
  };

  var update_joinable_orders = (venue_id, data) => {
    if(VENUE_ID != venue_id) return true
    if(!JOIN_REQUESTS.find(el => el.orderId == data.orderId)) {
      JOIN_REQUESTS.push(data)
    }
    loadCurrentCartItems();
    return renderOtherOrders(JOIN_REQUESTS)
  }

  const pushDeliveryPhoneNumber = (data)=>{
    if(data.personType == 'delivery-staff' && data.status == 'ordered'){
      if($('#checkout-timing-'+data.orderId).length>0){
        let namePersonDelivery ='';
        let arrayStaff = data.personName.split(' ');
        if(arrayStaff.length >=2){
          namePersonDelivery = arrayStaff[0]+' '+ arrayStaff[1].slice(0,1)+'.';
        }else{
          namePersonDelivery = arrayStaff;
        }
        $('#checkout-timing-'+data.orderId).find('#changePhone').text(`${namePersonDelivery} at ${data.personPhone}`);
        $('#checkout-timing-'+data.orderId).find('#changePhone').removeAttr("style");
        Swal.fire({
          title: "Update shipping phone number",
          text: `Order number ${data.orderId} will be delivered by ${data.personName} with phone number: ${data.personPhone}`,
          icon: "success"
        });
      }
    }
  }
  const pushChangeTimeReserveOrderAhead = (data)=>{
    if (VENUE_ID == data.venue_id){
      $('#order-ahead-date').val(data.order_expected_date ? moment(data.order_expected_date).format('MM/DD/YYYY h:mm A') : moment(new Date()).format('MM/DD/YYYY h:mm A'));
      var dateReservationTime = moment(data.order_expected_date).format('MMMM DD, h:mma');
      Swal.fire({
        title: "Your reservation was updated",
        text: `The restaurant has updated your reservation to be on ${dateReservationTime}`,
        icon: "success"
      });
    }
  }

  const pushConfirmDeliveredOrder = (data)=>{
    function swalConfirm() {
      Swal.fire({
        title: `Confirm received order`,
        text: 'Did you receive the delivery?',
        icon: "warning",
        showCancelButton: false,
        showConfirmButton:false,
        allowOutsideClick: true
      });
    }
    swalExtend.call(self, {
      swalFunction: swalConfirm,
      hasCancelButton: true,
      buttonNum: 2,
      buttonColor: ["#b30000", "#3CB371"],
      buttonNames: ["No","Yes"],
      clickFunctionList: [
        function() {
          return false;
        },
        function() {
          if (data.orderId) {
            return $.promisedAjax({
              url: `/orders/confirmReceivedOrder`,
              type: 'POST',
              data: JSON.stringify({
                orderId: data.orderId,
              }),
              contentType: 'application/json',
              dataType: 'json'
            }).then((res) => {
              if(res && res.data && res.data.delivery_received_order){
                let timeConfirm = moment(res.data.delivery_received_order).format('MM/DD/YYYY HH:mm a');
                $(`#receipt-confirmed-order-${data.orderId}`).text(timeConfirm);
              }
              return res;
            })
            .catch((err) => {
              console.error('Unable to update order values', err);
            })
          }
        },
      ]
    });
  }

  const pushJoinOrderCheckoutUpdate = (venueId, data) => {
    if (VENUE_ID == venueId){
      if (data.popup){
        Swal.fire(data.popup, '', 'info');
      }
    }
  };
  const AlertToUser = (data) => {
    if (!data.popup) return

    if (data.reloadCart) {
      console.log("~~~~~~~~~~~~~~~~~~~ checkForInCartOrder");
      checkForInCartOrder();
    }

    Swal.fire({
      title: data.popup,
      icon: data.popupType || 'success',
      showCancelButton: false,
      confirmButtonColor: '#02843d',
      confirmButtonText: 'OK'
    }).then((result) => {
      if (result.isConfirmed) {
       if(data.reload_page) window.location.reload();
      }
    })
  };

  const refreshCancelItemOrder = (venueId, data) => {
    if (VENUE_ID == venueId){
      let cancelledItemsHTML = '';
      data.cancelledItems.forEach((item) => {
         cancelledItemsHTML += '<li>' + item.item_name + '</li>';
      });

      Swal.fire({
        title: "The restaurant has just cancelled your order for these item(s)",
        html: `
          <hr>
          <ul style="width:50%; margin:0 auto; text-align: left; list-style-type: disc; margin-bottom: 10px;">
            ${cancelledItemsHTML}
          </ul>
        `,
        icon: "warning",
        allowOutsideClick: true,
        showConfirmButton: true,
        confirmButtonText: "Close",
      });
      

      loadCurrentCartItems();
      refreshFreeFoodScreen();
    }
  }

  const pushRefreshOrder = (venueId) => {
    if(venueId === VENUE_ID){
      loadCurrentCartItems();
    }
  };
  //Public methods
  return {
    has_new_orders,
    pushJoinOrderNotification,
    pushJoinOrderApprovedNotification,
    pushJoinOrderUpdateType,
    pushJoinOrderCheckoutUpdate,
    pushRefreshIncartOrder,
    update_joinable_orders,
    AlertToUser,
    refreshCancelItemOrder,
    pushOrderAheadInvitationNotification,
    pushDeliveryPhoneNumber,
    pushConfirmDeliveredOrder,
    pushChangeTimeReserveOrderAhead,
    pushRefreshOrder,
  }
})();

});


$(document).on( 'click', '.free-food-pop-over', function(e){
  if (!$(e.currentTarget).data("bs.popover")){
    e.preventDefault();
    $(e.currentTarget).popover({
      placement: 'bottom'
    });
    $(e.currentTarget).click();
  }
});

function saveDeliveryAddress() {
  setTimeout(function(){
    if($('#delivery-address-edit-trigger-container input').length > 0) {
      $('#delivery-address-edit-trigger').click();
      $('#delivery-address_input').focus();
    }
  }, 500);
}

function setPhone(){
  console.log("phone alert=======*******88888")
  localStorage.setItem('setPhoneFirstOrder', window.location.href)
  window.location.href = "/users/healthy-eating-profile?tab=collapseTasterPhone"
}
function saveDeliveryAddressOnEnter(event) {
  if (event.which === 13) {
    var newAddress= $('#delivery-address_input').val();
    if(newAddress){
      $('#delivery-address').text(newAddress);
      $('#delivery-apartment').text('');
      Order.updateOrderDetail("delivery_address", newAddress, true);
    }
  }
}

$('#delivery-address, #delivery-apartment').click(function() {
  console.log("$('#delivery-address span').text()=====",$('#delivery-address span').text())
  if(!this.children[0]) {
    $('#delivery-address-edit-trigger').click();
    $('#delivery-address_input').focus();
  }
  if($('#delivery-address span').text() == 'Click Edit to set Delivery address') {
    $('#delivery-address-edit-trigger').click();
    $('#delivery-address_input').focus();
  }
});

$('body').on('change', '.order-time-option', e => {
  $('.order-time-option').prop('checked', false);
  $(e.target).prop('checked', true).attr('checked', 'checked');
});
$(document).ready(function(){
  if($('label.yourItemPaid').length){
    $(".wrap-btn-cart-pay").hide();
  }
})
